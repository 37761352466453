export const VALUATION_PURPOSE_DISPLAY_NAMES = {
  AUCTION: 'Auction',
  FORECLOSURE: 'Foreclosure',
  FORENSIC_DUE_DILIGENCE: 'Forensic Due Diligence',
  GENERAL_LISTING: 'General Listing',
  HOME_EQUITY: 'Home Equity',
  LITIGATION: 'Litigation',
  LOSS_MITIGATION: 'Loss Mitigation',
  NEW_CONSTRUCTION: 'New Construction',
  ORIGINATION: 'Origination',
  OTHER: 'Other',
  POOL_ACQUISITION: 'Pool Acquisition',
  POOL_SALE: 'Pool Sale',
  PORTFOLIO_MANAGEMENT: 'Portfolio Management',
  PURCHASE_SALE: 'Purchase Sale',
  REFINANCE: 'Refinance',
  REO: 'REO',
  SERVICING_EVENT_DRIVEN: 'Servicing Event Driven',
  SERVICING_REFRESH: 'Servicing Refresh',
  SHORT_SALE: 'Short Sale',
  SPECIAL_PROJECT: 'Special Project',
}
