import {OPEN_MODAL, CLOSE_MODAL, REMOVE_MODAL} from 'constants/actionTypes'

// modalType needs to match the name of the modal component to be opened
// as a string. ModalsSystem will import all modal components and the
// component to render will be looked up there via the modalType string
// payload = {
//   modalType: string,
//   handleClose?: () => {},
//   handleExited?: () => {},
// }
export function openModal(payload) {
  return {
    type: OPEN_MODAL,
    payload,
  }
}

// id is optional. If closeModal is called without an id it will trigger
// all modals to close
export function closeModal(id) {
  return {
    type: CLOSE_MODAL,
    payload: id,
  }
}

export function removeModal(id) {
  return {
    type: REMOVE_MODAL,
    payload: id,
  }
}
