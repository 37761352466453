import {createSelector} from 'reselect'

export const organizationsSelector = (state) => state.organizations

export const organizationsPreferencesSelector = createSelector(
  [organizationsSelector],
  (organizations) => organizations.preferences
)

// The preferences are not typed on the BE so we need to parse the json for this boolean flag
export const isOrderManagementEnabledSelector = createSelector(
  [organizationsPreferencesSelector],
  (preferences) => JSON.parse(preferences.orderManagementEnabled ?? false)
)

export const isClearPropEnabledSelector = createSelector(
  [organizationsPreferencesSelector],
  (preferences) => JSON.parse(preferences.CLEAR_PROP ?? false)
)

export const isHideActiveQueueFromReviewersEnabledSelector = createSelector(
  [organizationsPreferencesSelector],
  (preferences) => JSON.parse(preferences.HIDE_ACTIVE_QUEUE_FROM_REVIEWERS ?? false)
)
