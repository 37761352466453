import {
  ADD_LOADING,
  REMOVE_ALL_LOADING,
  REMOVE_LOADING,
  SET_AUTH_USER,
  SET_AUTH_PERMISSIONS,
  SET_INITIAL_APP_DATA_STATUS,
  SET_APP_STATE,
  SET_AUTH_STATE,
  SET_SIDEBAR_DRAGGED_WIDTH,
  SET_SIDEBAR_WIDTH,
  TOGGLE_LOADING,
  TOGGLE_ORDER_FORM,
  TOGGLE_SIDEBAR,
} from 'constants/actionTypes'
import {REVIEW_FORM, SIDEBAR_BASE_WIDTH} from 'constants/app'
import {UNSET} from 'constants/reduxStatuses'

export const initialState = {
  activeCaseId: '',
  activeTeamIds: [],
  activeUserId: '',
  initialAppDataStatus: UNSET,
  authentication: {
    user: null,
    permissions: [],
  },
  callTimes: {},
  loading: [],
  lookAheadResults: [],
  orderFormActive: false,
  recentActivity: [],
  sidebar: {
    active: false,
    content: '',
    draggedWidth: null,
    width: SIDEBAR_BASE_WIDTH,
  },
}

export default function app(state = initialState, action) {
  let nextLoading
  let nextCallTimes
  switch (action.type) {
    case SET_INITIAL_APP_DATA_STATUS:
      return {
        ...state,
        initialAppDataStatus: action.payload,
      }
    case SET_APP_STATE:
      return {
        ...initialState,
        ...action.payload,
      }
    case SET_AUTH_STATE: {
      return {
        ...state,
        authentication: action.payload,
      }
    }
    case SET_AUTH_USER:
      return {
        ...state,
        authentication: {
          ...state.authentication,
          user: action.payload,
        },
      }
    case SET_AUTH_PERMISSIONS:
      return {
        ...state,
        authentication: {
          ...state.authentication,
          permissions: action.payload,
        },
      }
    case TOGGLE_SIDEBAR: {
      const {content, active} = action.payload

      const nextSidebarState = {
        ...state.sidebar,
        ...action.payload,
      }
      // if we are setting the content to anything other than REVIEW_FORM we need to reset the width
      if (content !== REVIEW_FORM && active === true) {
        nextSidebarState.width = SIDEBAR_BASE_WIDTH
      }

      return {
        ...state,
        sidebar: nextSidebarState,
      }
    }
    case SET_SIDEBAR_WIDTH:
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          width: action.payload,
        },
      }
    case SET_SIDEBAR_DRAGGED_WIDTH: // this handles the actual dragging
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          draggedWidth: action.payload,
          width: action.payload,
        },
      }
    case TOGGLE_ORDER_FORM:
      return {
        ...state,
        orderFormActive: action.payload,
      }
    case TOGGLE_LOADING:
      nextLoading = [...state.loading]

      if (nextLoading.indexOf(action.payload) === -1) {
        nextLoading.push(action.payload)
      } else {
        nextLoading = nextLoading.filter((key) => key !== action.payload)
      }

      return {
        ...state,
        loading: nextLoading,
      }
    case ADD_LOADING:
      // `callTimes` is used to track the time that the most recent
      // instance of a call was made.  a given `loadingString`s time
      // can be updated each time the ADD_LOADING action is dispatched.
      // this allows up to ignore any responses that aren't from the
      // most recently made call and only update redux state when the
      // call we care about returns.  this is necessary to avoid bugs
      // that may surface if calls don't return in the order they are
      // made.  see `getAllocations` in `allocationsActions.js` for
      // an example of how this is done.
      return {
        ...state,
        loading: [...state.loading, action.payload.loadingString],
        callTimes: {
          ...state.callTimes,
          [action.payload.loadingString]: action.payload.callTime,
        },
      }
    case REMOVE_ALL_LOADING:
      // removes all instances of a specific `loadingString` from the
      // `loading` array.

      // there are times when we need to track multiple instances of the
      // same call (using ADD_LOADING). sometimes we only care about one
      // instance of the call and once it returns we don't care about the
      // others. since we no longer want to track the loading state of any
      // remaining instances of the call we need to remove all instances of
      // the calls' loading string. this ensures that the UI only shows a
      // loading state until the call of concern returns.
      nextCallTimes = {...state.callTimes}
      nextLoading = [...state.loading]

      delete nextCallTimes[action.payload.loadingString]

      nextLoading = nextLoading.filter((loadingString) => {
        return loadingString !== action.payload.loadingString
      })

      return {
        ...state,
        loading: nextLoading,
        callTimes: nextCallTimes,
      }
    case REMOVE_LOADING:
      // removes 1 instance of the `loadingString` from the `loading` array
      nextCallTimes = {...state.callTimes}
      nextLoading = [...state.loading]

      nextLoading.splice(nextLoading.indexOf(action.payload.loadingString), 1)

      if (!nextLoading.includes(action.payload.loadingString)) {
        // cleanup if there's no additional calls of this type being tracked
        delete nextCallTimes[action.payload.loadingString]
      }

      return {
        ...state,
        loading: nextLoading,
        callTimes: nextCallTimes,
      }
    default:
      return state
  }
}
