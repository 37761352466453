import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {CustomReactSelect} from '@clearcapital/ui-resources'
import {STATE_SELECT_OPTIONS} from 'constants/productOrderFormConstants'

const StateSelect = ({onChange, value, ...props}) => (
  <StateSelect.Styled>
    <CustomReactSelect
      {...props}
      name='state'
      value={value}
      placeholder='State'
      onChange={(value) => onChange({target: {value}})}
      options={STATE_SELECT_OPTIONS}
    />
  </StateSelect.Styled>
)

StateSelect.Styled = styled.div`
  .CC-react-select__option {
    font-size: 0.875rem;
  }
`

StateSelect.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
}

export default StateSelect
