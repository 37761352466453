// 404
export const NOT_FOUND = 'The page you are trying to access does not exist'

export const CLEARQC = 'CLEARQC'
export const FAIL = 'FAIL'
export const FRE = 'FRE'
export const FNM = 'FNM'
export const FHA = 'FHA'
export const NO_DATA = '\u2014' // em dash
export const QA_NO_PREFIX = '??'
export const SUCCESS = 'SUCCESS'
export const PAGING_LIMIT = 10
export const PASSWORD_EXPIRATION_LIMIT = 15
export const PROD_ORIGIN = 'https://clearcollateral.com'
export const XE_ORIGIN = 'https://xe-dev.clearcollateral.com'

// Sidebar contentWidth
export const APP_SEARCH_FORM = 'APP_SEARCH_FORM'
export const EMPTY_FORM = 'EMPTY_FORM'
export const HOLD_ORDER_FORM = 'HOLD_ORDER_FORM'
export const REVIEW_FORM = 'REVIEW_FORM'
export const SIDEBAR_BASE_WIDTH = 480 // the pixel width of a standard sidebar

// Loading keys
export const LOADING_CASE = 'LOADING_CASE'
export const PRODUCT_ORDERS = 'PRODUCT_ORDERS'
export const PROVIDER_ORDERS = 'PROVIDER_ORDERS'
export const BULK_ORDERS = 'BULK_ORDERS'
export const LOADING_TIERS = 'LOADING_TIERS'

// file types
export const BULK_ORDER_FILE_ACCEPT = '.csv, .xls, .xlsx'
export const BULK_ORDER_UPDATE_FILE_ACCEPT = '.csv, .xls, .xlsx'
export const BULK_SET_CMV_FILE_ACCEPT = '.csv, .xls, .xlsx'
export const BULK_SUPPORTING_DOC_FILE_ACCEPT = '.zip'
export const VALUATION_DOC_FILE_ACCEPT = '.pdf, .xml'
export const SUPPORTING_DOC_FILE_ACCEPT =
  '.pdf, .xml, .doc, .docx, .odt, .txt, .rtf, .csv, .xls, .xlsx, .jpg, .jpeg, .gif, .png, .tif, .tiff'

// generic actions (used in review and recon forms)
export const REMOVE = 'REMOVE'
export const ADD = 'ADD'
export const DESCRIPTION_MISSING = 'No Description'

// Smart View
export const LOADING_SMART_VIEW = 'LOADING_SMART_VIEW'
export const SMART_VIEW_POPOUT = 'SMART_VIEW_POPOUT'

// Resizable
export const RESIZABLE_DEFAULT_WIDTH = 730
