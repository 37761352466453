import {
  INVALID_EMAIL_MSG,
  INVALID_PHONE_MSG,
  REQUIRED_FIELD_MSG,
  FILE_REQUIRED_MSG,
} from 'constants/validationErrorMessages'
import validators from 'services/validationRules'

export const DATE_DISPLAY_FORMAT = 'MMDDYYYY'

// set these up once and reuse them to avoid recreating a new array for each validated input in render
export const requiredValidationRules = [
  {
    rule: 'required',
    errorMsg: REQUIRED_FIELD_MSG,
  },
]

export const requiredFileValidationRules = [
  {
    rule: 'required',
    errorMsg: FILE_REQUIRED_MSG,
  },
]

export const phoneValidationRules = [
  {
    rule: 'validateTelephone',
    errorMsg: INVALID_PHONE_MSG,
  },
]

export const emailValidationRules = [
  {
    rule: 'isEmail',
    errorMsg: INVALID_EMAIL_MSG,
  },
]

export const requiredFHACaseNumberValidationRules = [
  ...requiredValidationRules,
  {
    rule: 'matchRegexp',
    // We rely on react number format to enforce input format
    ruleParams: ['^\\d{10}$'],
    errorMsg: 'Must match xxx-xxxxxxx format',
  },
]

export const requiredEmailValidationRules = [
  ...requiredValidationRules,
  {
    rule: 'isEmail',
    errorMsg: INVALID_EMAIL_MSG,
  },
]

export const requiredDateRules = [
  ...requiredValidationRules,
  {
    rule: 'validateDate',
    ruleParams: [DATE_DISPLAY_FORMAT],
    errorMsg: 'Date must match MM/DD/YYYY format',
  },
]

export const requiredDateMinToday = [
  ...requiredDateRules,
  {
    rule: 'validateMinDateToday',
    errorMsg: 'Date cannot be in the past',
  },
]

export const requiredMultiSelectRules = [
  {
    rule: 'isArrayWithLength',
    errorMsg: REQUIRED_FIELD_MSG,
  },
]

export const requiredPhoneValidationRules = [...requiredValidationRules, ...phoneValidationRules]

export const createOneOfManyRequiredValidationRules = (values = [], errorMsg) => [
  {
    rule: () => values.some((val) => validators.required(val)),
    errorMsg,
  },
]
