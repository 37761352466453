import {SET_TEMPLATE_DATAPOINTS} from 'constants/actionTypes'

export const initialState = {
  data: {},
}

export default function dataPoints(state = initialState, action) {
  switch (action.type) {
    case SET_TEMPLATE_DATAPOINTS:
      return {
        ...state,
        data: action.payload,
      }
    default:
      return state
  }
}
