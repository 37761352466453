import {SET_PROPERTY_OVERVIEW} from 'constants/actionTypes'

export default function propertyCharacteristicsReducer(state = {}, action) {
  switch (action.type) {
    case SET_PROPERTY_OVERVIEW:
      return action.payload
    default:
      return state
  }
}
