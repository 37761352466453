import {combineReducers} from 'redux'
import {DELETE_STATE} from 'constants/actionTypes'
import {reducer as notificationsReducer} from 'reapop'
import activeComp from './activeCompReducer'
import activeDocument from './activeDocumentReducer'
import allProducts from './allProductsReducer'
import app from './appReducer'
import businessUnits from './businessUnitsReducer'
import caseCountsByUser from './caseCountsByUserReducer'
import caseDocumentsOrders from './caseOrdersReducer'
import caseEvents from './caseEventsReducer'
import casesOnHold from './casesOnHoldReducer'
import caseOrdersProviderInfo from './caseOrdersProviderInfoReducer'
import caseReview from './caseReviewReducer'
import caseSuggestions from './caseSuggestionsReducer'
import clearProp from './clearPropReducer'
import clearPropToken from './clearPropTokenReducer'
import configurations from './configurationsReducer'
import coreCase from './coreCaseReducer'
import customerOrganization from './customerOrganizationReducer'
import dataPoints from './dataPointsReducer'
import parsedComps from './compsReducer'
import {documentSummaries, primaryDocumentSummaries} from './documentSummariesReducer'
import documentNoteTemplate from './documentActionsMenuReducer'
import mlsPhotos from './mlsPhotosReducer'
import modals from './modalsReducer'
import orderCreatedStatus from './orderCreatedStatusReducer'
import order from './orderReducer'
import orderReview from './orderReviewReducer'
import orderActivity from './orderActivityReducer'
import organizations from './organizationsReducer'
import product from './productReducer'
import relatedOrders from './relatedOrdersReducer'
import reviewDashboard from './reviewDashboardReducer'
import revisionRequests from './revisionRequestsReducer'
import popouts from './popoutsReducer'
import productProviders from './productProvidersReducer'
import propertyCharacteristics from './propertyCharacteristicsReducer'
import providerInfo from './providerInfoReducer'
import queuePriority from './queuePriorityReducer'
import relatedCases from './relatedCasesReducer'
import {reportList} from './reportsReducer'
import {reportUrl} from './reportUrlReducer'
import reviewScopes from './reviewScopesReducer'
import reviewQueue from './reviewQueueReducer'
import roles from './rolesReducer'
import ruleSets from './ruleSetsReducer'
import ruleLibrary from './ruleLibraryReducer'
import ruleBasedAnalyses from './ruleBasedAnalysesReducer'
import caseOrderReviews from './caseOrderReviewsReducer'
import sessionTimeout from './sessionTimeoutReducer'
import smartView from './smartViewReducer'
import users from './usersReducer'
import streetViews from './streetViewReducer'
import tiers from './tierReducer'
import validation from './validationReducer'

const combinedReducer = combineReducers({
  activeComp,
  activeDocument,
  allProducts,
  app,
  businessUnits,
  caseCountsByUser,
  caseDocumentsOrders,
  caseEvents,
  caseOrdersProviderInfo,
  caseOrderReviews,
  caseReview,
  caseSuggestions,
  casesOnHold,
  clearProp,
  clearPropToken,
  configurations,
  coreCase,
  customerOrganization,
  dataPoints,
  documentNoteTemplate,
  documentSummaries,
  mlsPhotos,
  modals,
  notifications: notificationsReducer(),
  order,
  orderReview,
  orderActivity,
  orderCreatedStatus,
  organizations,
  parsedComps,
  popouts,
  primaryDocumentSummaries,
  product,
  productProviders,
  propertyCharacteristics,
  providerInfo,
  queuePriority,
  relatedCases,
  relatedOrders,
  reportList,
  reportUrl,
  reviewDashboard,
  reviewScopes,
  reviewQueue,
  revisionRequests,
  roles,
  ruleSets,
  ruleLibrary,
  ruleBasedAnalyses,
  sessionTimeout,
  smartView,
  streetViews,
  tiers,
  users,
  validation,
})

const rootReducer = (state, action) => {
  switch (action.type) {
    case DELETE_STATE:
      state = undefined
      break
  }
  return combinedReducer(state, action)
}

export default rootReducer
