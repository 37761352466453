import {
  SET_ORDER,
  CLEAR_ORDER,
  SET_ORDER_HAS_UNREAD_MESSAGE,
  LOADING_MESSAGE,
  SET_ORDER_IS_STILL_PROCESSING,
} from 'constants/actionTypes'

export const initialState = {
  actionRequired: null,
  caseId: null,
  completed: null,
  createdTime: null,
  events: [],
  eventsRequiringAction: [],
  onHold: null,
  orderId: null,
  orgKey: null,
  productCode: null,
  providerOrgKey: null,
  status: null,
  updatedTime: null,
  dashboardData: {},
  hasUnreadMessage: null,
  loadingMessage: false,
  isStillProcessing: false,
}

export default function orderReducer(state = initialState, action) {
  switch (action.type) {
    case LOADING_MESSAGE:
      return {
        ...state,
        loadingMessage: action.payload,
      }
    case SET_ORDER:
      return action.payload
    case SET_ORDER_HAS_UNREAD_MESSAGE:
      return {
        ...state,
        hasUnreadMessage: action.payload,
      }
    case CLEAR_ORDER:
      return initialState
    case SET_ORDER_IS_STILL_PROCESSING:
      return {
        ...state,
        isStillProcessing: true,
      }
    default:
      return state
  }
}
