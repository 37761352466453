import {selectOptionsByLabel} from 'services/sort'
import {FORM_CONFIG_PAGE_OBJECT} from 'constants/formConstants'
import forIn from 'lodash/forIn'
import cloneDeep from 'lodash/cloneDeep'

// This will create a shallow copy of the array, sort it and then return the copy. It defaults to being sorted 'ASCENDING'
export function getSortedOptionsListByLabel(options, sortDirection) {
  // we need to create a copy of the array (with .concat()) and then return the sorted copy.
  return options.concat().sort(selectOptionsByLabel, sortDirection)
}

export function mapFormConfigToPages(config) {
  const formConfig = cloneDeep(config)
  const pages = []
  switch (formConfig.configType) {
    case FORM_CONFIG_PAGE_OBJECT:
      forIn(formConfig.pageObj, (page) => {
        page.fields = []
        forIn(page.fieldObj, (field) => page.fields.push(field))
        delete page.fieldObj
        return pages.push(page)
      })
      break
    // map any other types of form configs to structure that validator expects
  }
  return pages
}
