import {
  RESET_REVIEW_QUEUE,
  SET_REVIEW_QUEUE_STATUS,
  SET_REVIEW_QUEUE_CASES_PER_PAGE,
  SET_REVIEW_QUEUE_CURRENT_PAGE,
  SELECT_ALL_REVIEW_QUEUE_CASES,
  SELECT_REVIEW_QUEUE_CASE,
  SET_REVIEW_QUEUE_CASE_DATA,
} from 'constants/actionTypes'
import {QUEUE_STATUS_VIEW_IDLE} from 'constants/queueConstants'

export const initialState = {
  cases: [],
  casesPerPage: 50,
  currentPage: 1,
  lastUpdatedTimestamp: null,
  selectedIds: [],
  status: QUEUE_STATUS_VIEW_IDLE,
  totalCases: 0,
  view: null,
}

export default function reviewQueueReducer(state = initialState, action) {
  switch (action.type) {
    case SET_REVIEW_QUEUE_CASES_PER_PAGE: {
      return {
        ...state,
        casesPerPage: action.payload,
        selectedIds: initialState.selectedIds,
        currentPage: initialState.currentPage,
      }
    }
    case SET_REVIEW_QUEUE_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.payload,
        selectedIds: [],
      }
    }
    case SELECT_ALL_REVIEW_QUEUE_CASES: {
      const selectedIds = state.selectedIds.length === 0 ? state.cases.map(({id}) => id) : []

      return {
        ...state,
        selectedIds,
      }
    }
    case SELECT_REVIEW_QUEUE_CASE: {
      const selectedIds =
        state.selectedIds.indexOf(action.payload) > -1
          ? state.selectedIds.filter((id) => id !== action.payload)
          : [...state.selectedIds, action.payload]

      return {
        ...state,
        selectedIds,
      }
    }
    case SET_REVIEW_QUEUE_CASE_DATA: {
      const {cases, totalCases} = action.payload

      return {
        ...state,
        cases,
        totalCases,
        selectedIds: initialState.selectedIds,
        lastUpdatedTimestamp: new Date().getTime(),
      }
    }
    case SET_REVIEW_QUEUE_STATUS: {
      return {
        ...state,
        status: action.payload,
      }
    }
    case RESET_REVIEW_QUEUE: {
      return initialState
    }
    default:
      return state
  }
}
