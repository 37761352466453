import {caseApi} from 'services/apis.js'
import {
  SET_CORE_CASE_ON_HOLD_REASONS,
  SET_CORE_CASE_ON_HOLD_DISABLE_LINK,
  SET_CORE_CASE_ON_HOLD_SHOW_CARD,
} from 'constants/actionTypes'

export const getAllCasesOnHoldReasons = () => {
  return async (dispatch) => {
    try {
      const response = await caseApi.get('onHoldReasons')
      dispatch(setCasesOnHoldReason(response))
    } catch (err) {
      console.error(err)
    }
  }
}

export const setCasesOnHoldReason = (payload) => ({
  type: SET_CORE_CASE_ON_HOLD_REASONS,
  payload,
})

export const setCasesOnHoldShowCard = (payload) => ({
  type: SET_CORE_CASE_ON_HOLD_SHOW_CARD,
  payload,
})

export const setCasesOnHoldDisableLink = (payload) => ({
  type: SET_CORE_CASE_ON_HOLD_DISABLE_LINK,
  payload,
})
