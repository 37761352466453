import {enumToTitlecase} from 'services/string'
import {titleCase} from './formatData'

// Expected output: street city, state zipcode
export function createCaseViewHeaderAddressDisplayString(streetAddress, city, state, zipcode) {
  let streetString = ''
  if (streetAddress) {
    if (city) {
      streetString = `${streetAddress} `
    } else {
      streetString = `${streetAddress}`
    }
  }
  let joinString = ''
  if ((streetAddress || city) && (state || zipcode)) {
    joinString = ', '
  }
  let stateString = ''
  if (state) {
    if (zipcode) {
      stateString = `${state} `
    } else {
      stateString = `${state}`
    }
  }
  return `${streetString}${city ? `${city}` : ''}${joinString}${stateString}${
    zipcode ? `${zipcode}` : ''
  }`
}

// Expected output: street, city, state zipcode
export function createSearchAddressDisplayString(
  streetAddress,
  city,
  state,
  zipcode,
  twoLines = false
) {
  let streetString = ''
  if (streetAddress) {
    if ((city || state || zipcode) && !twoLines) {
      streetString = `${enumToTitlecase(streetAddress)}, `
    } else {
      streetString = `${enumToTitlecase(streetAddress)}`
    }
  }

  let cityString = ''
  if (city) {
    if (state || zipcode) {
      cityString = `${enumToTitlecase(city)}, `
    } else {
      cityString = `${enumToTitlecase(city)}`
    }
  }

  let stateString = ''
  if (state) {
    if (zipcode) {
      stateString = `${state} `
    } else {
      stateString = `${state}`
    }
  }

  if (twoLines && streetString) {
    return `${streetString}
    ${cityString}${stateString}${zipcode ? `${zipcode}` : ''}`
  }

  return `${streetString}${cityString}${stateString}${zipcode ? `${zipcode}` : ''}`
}

// Expected output: street <unit #> city, state zipcode
export function createPropertyOverviewAddressDisplayString(
  streetAddress,
  unitNumber,
  city,
  state,
  zipcode,
  twoLines = false
) {
  let streetString = ''
  if (streetAddress) {
    if (city || unitNumber) {
      streetString = `${titleCase(streetAddress)} `
    } else {
      streetString = `${titleCase(streetAddress)}`
    }
  }

  let unitString = ''
  if (unitNumber) {
    if (city) {
      unitString = `${titleCase(unitNumber)} `
    } else {
      unitString = `${titleCase(unitNumber)}`
    }
  }

  let joinString = ''
  if ((streetAddress || unitNumber || city) && (state || zipcode)) {
    joinString = ', '
  }

  let stateString = ''
  if (state) {
    if (zipcode) {
      stateString = `${state} `
    } else {
      stateString = `${state}`
    }
  }

  if (twoLines && streetString) {
    return `${streetString}${unitString}
    ${city ? `${city}` : ''}${joinString}${stateString}${zipcode ? `${zipcode}` : ''}`
  }

  return `${streetString}${unitString}${city ? `${city}` : ''}${joinString}${stateString}${
    zipcode ? `${zipcode}` : ''
  }`
}
