import {INITIALIZE_SORT_RANK_DATA, SET_SORT_RANK} from 'constants/actionTypes'

export const initialState = {
  loaded: false,
}

export default function queuePriorityReducer(state = initialState, action) {
  switch (action.type) {
    case INITIALIZE_SORT_RANK_DATA: {
      return {
        ...state,
        loaded: true,
        ...action.payload,
      }
    }
    case SET_SORT_RANK: {
      return {
        ...state,
        loaded: true,
        ...action.payload,
      }
    }
    default:
      return state
  }
}
