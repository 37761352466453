// IMPORTANT!
// DO NOT UPDATE THE VALUE OF THESE IDS
// ONCE SET THE VALUE OF THESE IDS MUST REMAIN THE SAME
// The ids defined in this file are persisted on the backend as a part of scope
// definitions and on case scopes.
// Changing these values will break any cases that already have a case scope set.

// VIEWS
export const ALL_VIEWS_ID = 'all_views'
export const ORIGINAL_REPORT_ID = 'view_original_report'
export const QUICK_VIEW_ID = 'view_quick'
// TOOLS
export const ALL_TOOLS_ID = 'all_tools'
export const SCORES_AND_FINDINGS_ID = 'tool_score_findings'
export const COMPARABLES_ID = 'tool_comparables'
// FORMS
export const ALL_FORMS_ID = 'all_forms'
export const NONE_RECOMMENDED_FORM_ID = 'recommended_form_none'

// IMPORTANT!
// DO NOT UPDATE THE VALUE OF THESE IDS
// ONCE SET THE VALUE OF THESE IDS MUST REMAIN THE SAME
