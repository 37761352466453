import {SET_REVIEW_DASHBOARD_DATA} from 'constants/actionTypes'

const initialState = {}

export default function reviewDashboardReducer(state = initialState, action) {
  switch (action.type) {
    case SET_REVIEW_DASHBOARD_DATA:
      return action.payload
    default:
      return state
  }
}
