import {getSortedOptionsListByLabel} from 'services/forms'

export const PRODUCTS_ORDER_FORM_MODAL_TITLE = 'Order Products'

export const EMAIL_TYPE = 'WORK_EMAIL'
export const PRODUCT_ORDER_SQA_BASE_ID = 'product-order-form'

// Cache keys for localstorage
export const ORDER_ANOTHER_PRODUCT_LOCAL_CACHE = 'ORDER_ANOTHER_PRODUCT_LOCAL_CACHE'
export const OPTIONAL_LOAN_FIELDS_EXPANDED_LOCAL_CACHE = 'OPTIONAL_LOAN_FIELDS_EXPANDED_LOCAL_CACHE'

// Form constants
export const PRODUCTS_FIELD_ARRAY_NAME = 'products'
export const PRODUCTS_CONTACT_FIELD_ARRAY_NAME = 'contacts'
export const FHA_INPUT_FORMAT = '###-#######'

// Provider assignment constants
export const PROVIDER_PANEL_ASSIGNMENT_ID = 'PANEL_ASSIGNMENT'
export const PROVIDER_AUTO_ALLOCATE_ID = 'AUTO_ALLOCATE'
export const MAX_SPECIAL_INSTRUCTIONS_LENGTH = 240

export const CONTACT_TYPE_OPTIONS = getSortedOptionsListByLabel([
  {value: 'BORROWER', label: 'Borrower'},
  {value: 'COBORROWER', label: 'Coborrower'},
  {value: 'OWNER', label: 'Owner'},
  {value: 'OCCUPANT', label: 'Occupant'},
  {value: 'REQUESTOR', label: 'Requestor'},
  {value: 'AGENT', label: 'Agent'},
  {value: 'BROKER', label: 'Broker'},
  {value: 'OTHER', label: 'Other'},
])

export const FLAGS_OPTIONS = [
  {value: 'rush', label: 'Rush', color: 'ruby', sqaPrefix: 'rush'},
  {value: 'vip', label: 'VIP', color: 'citrine', sqaPrefix: 'vip'},
]

export const LOAN_TYPE_OPTIONS = getSortedOptionsListByLabel([
  {value: 'CONVENTIONAL', label: 'Conventional'},
  {value: 'FHA', label: 'FHA'},
  {value: 'USDA', label: 'USDA'},
  {value: 'VA', label: 'VA'},
  {value: 'OTHER', label: 'Other'},
])

export const PHONE_TYPE = 'WORK_PHONE'

export const STATE_SELECT_OPTIONS = [
  {value: 'AL', label: 'Alabama'},
  {value: 'AK', label: 'Alaska'},
  {value: 'AS', label: 'American Samoa'},
  {value: 'AZ', label: 'Arizona'},
  {value: 'AR', label: 'Arkansas'},
  {value: 'CA', label: 'California'},
  {value: 'CO', label: 'Colorado'},
  {value: 'CT', label: 'Connecticut'},
  {value: 'DC', label: 'District of Columbia'},
  {value: 'DE', label: 'Delaware'},
  {value: 'FL', label: 'Florida'},
  {value: 'GA', label: 'Georgia'},
  {value: 'GU', label: 'Guam'},
  {value: 'HI', label: 'Hawaii'},
  {value: 'ID', label: 'Idaho'},
  {value: 'IL', label: 'Illinois'},
  {value: 'IN', label: 'Indiana'},
  {value: 'IA', label: 'Iowa'},
  {value: 'KS', label: 'Kansas'},
  {value: 'KY', label: 'Kentucky'},
  {value: 'LA', label: 'Louisiana'},
  {value: 'ME', label: 'Maine'},
  {value: 'MH', label: 'Marshall Islands'},
  {value: 'MD', label: 'Maryland'},
  {value: 'MA', label: 'Massachusetts'},
  {value: 'MI', label: 'Michigan'},
  {value: 'FM', label: 'Micronesia'},
  {value: 'MN', label: 'Minnesota'},
  {value: 'MS', label: 'Mississippi'},
  {value: 'MO', label: 'Missouri'},
  {value: 'MT', label: 'Montana'},
  {value: 'NE', label: 'Nebraska'},
  {value: 'NV', label: 'Nevada'},
  {value: 'NH', label: 'New Hampshire'},
  {value: 'NJ', label: 'New Jersey'},
  {value: 'NM', label: 'New Mexico'},
  {value: 'NY', label: 'New York'},
  {value: 'NC', label: 'North Carolina'},
  {value: 'ND', label: 'North Dakota'},
  {value: 'MP', label: 'Northern Marianas'},
  {value: 'OH', label: 'Ohio'},
  {value: 'OK', label: 'Oklahoma'},
  {value: 'OR', label: 'Oregon'},
  {value: 'PW', label: 'Palau'},
  {value: 'PA', label: 'Pennsylvania'},
  {value: 'PR', label: 'Puerto Rico'},
  {value: 'RI', label: 'Rhode Island'},
  {value: 'SC', label: 'South Carolina'},
  {value: 'SD', label: 'South Dakota'},
  {value: 'TN', label: 'Tennessee'},
  {value: 'TX', label: 'Texas'},
  {value: 'UT', label: 'Utah'},
  {value: 'VT', label: 'Vermont'},
  {value: 'VI', label: 'Virgin Islands'},
  {value: 'VA', label: 'Virginia'},
  {value: 'WA', label: 'Washington'},
  {value: 'WV', label: 'West Virginia'},
  {value: 'WI', label: 'Wisconsin'},
  {value: 'WY', label: 'Wyoming'},
]

export const VALUATION_PURPOSE_OPTIONS = {
  ORIGINATION: getSortedOptionsListByLabel([
    {value: 'ORIGINATION', label: 'Origination'},
    {value: 'PURCHASE_SALE', label: 'Purchase Sale'},
    {value: 'HOME_EQUITY', label: 'Home Equity'},
    {value: 'REFINANCE', label: 'Refinance'},
    {value: 'NEW_CONSTRUCTION', label: 'New Construction'},
  ]),
}
