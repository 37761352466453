import {createStore, applyMiddleware, compose} from 'redux'
import thunk from 'redux-thunk'
import rootReducer from 'reducers'
import {saveState} from 'services/localStorage'
import throttle from 'lodash/throttle'
import get from 'lodash/get'
import * as Sentry from '@sentry/react'
import {actionTransformer, stateTransformer} from 'services/sentryServices'

export default function configureStore(initialState = {}) {
  const middleware = [thunk]
  const enhancers = [applyMiddleware(...middleware)]

  const composeEnhancers =
    typeof window === 'object' &&
    typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ !== 'undefined' &&
    process.env.NODE_ENV !== 'production'
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({maxAge: 10})
      : compose

  const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    actionTransformer, // this is where we can modify the action data that is sent to sentry
    stateTransformer, // this is where we can modify the state data that is sent to sentry
    attachReduxState: false, // we don't want to set the state until we can redact any NPI/PII
  })

  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(...enhancers, sentryReduxEnhancer)
  )

  // For hot reloading of react components
  // Also for debugging
  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('../reducers', () => {
      const nextReducer = require('../reducers').default
      store.replaceReducer(nextReducer)
    })
  }

  store.subscribe(
    throttle(() => {
      const state = store.getState()
      const authentication = get(state, 'app.authentication', {})
      saveState({app: {authentication}})
    }, 1000)
  )

  return store
}
