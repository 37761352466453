import {NO_DATA} from 'constants/app'

export function sqftToAcre(input) {
  const acrePerSqft = 1 / 43560
  let acres = 0

  if (input === null || input === undefined || isNaN(input)) {
    acres = NO_DATA
  } else {
    acres = (parseFloat(input) * acrePerSqft).toFixed(2)
  }

  return acres
}
