import {productsApiV2} from 'services/apis'
import history from 'services/history'
import {addNotification as notify} from 'reapop'
import {ERROR_NOTIFICATION, SUCCESS_NOTIFICATION} from 'constants/notifications'
import {
  IGNORE,
  SET_AUTH_USER,
  SET_SIDEBAR_DRAGGED_WIDTH,
  SET_SIDEBAR_WIDTH,
  TOGGLE_LOADING,
  TOGGLE_ORDER_FORM,
  TOGGLE_SIDEBAR,
} from 'constants/actionTypes'
import {FORM_TO_POPOUT_DICTIONARY} from 'constants/formToPopoutDictionary'

export function updateAuthUser(user) {
  return {
    type: SET_AUTH_USER,
    payload: user,
  }
}

export function toggleSideBar(content, active) {
  return (dispatch, getState) => {
    const store = getState()
    const {app, popouts} = store

    const nextActive = typeof active === 'boolean' ? active : !app.sidebar.active

    let nextAction = {
      type: TOGGLE_SIDEBAR,
      payload: {
        content,
        active: nextActive,
      },
    }

    // If we're about to toggle the slideout to true, first check if there is a corresponding popout that's open.
    // If there is one, then focus the popout, otherwise open the slideout
    if (nextActive) {
      const popout = FORM_TO_POPOUT_DICTIONARY[content]
      if (popout && popouts[popout] && popouts[popout].reference) {
        popouts[popout].reference.focus() // just focus the already opened instance
        // send an empty action since we really don't need to do anything anymore, but need to satisfy Redux and the dispatch
        nextAction = {
          type: IGNORE,
        }
      }
    }

    dispatch(nextAction)
  }
}

export function setSidebarDraggedWidth(width) {
  return {
    type: SET_SIDEBAR_DRAGGED_WIDTH,
    payload: width,
  }
}

export function setSidebarWidth(width) {
  return {
    type: SET_SIDEBAR_WIDTH,
    payload: width,
  }
}

export function toggleOrderForm() {
  return (dispatch, getState) => {
    const store = getState()

    dispatch({
      type: TOGGLE_ORDER_FORM,
      payload: !store.app.orderFormActive,
    })
  }
}

// TODO: move changeRoute, backRoute, getSearchParams into the history service
export function changeRoute(route, state = null) {
  state ? history.push({pathname: route, state}) : history.push(route)
}

export function backRoute() {
  history.goBack()
}

export function getSearchParams() {
  return history.location.search
    .slice(1)
    .split('&')
    .reduce((obj, str) => {
      str = str.split('=')
      obj[str[0]] = decodeURIComponent(str[1])
      return obj
    }, {})
}

export function toggleLoading(key) {
  return {
    type: TOGGLE_LOADING,
    payload: key,
  }
}

export function acceptPanelInvitation(panelId) {
  return function acceptPanelInvitation(dispatch) {
    return productsApiV2
      .post(`invitations/${panelId}/accept`)
      .then(() => {
        dispatch(
          notify({
            ...SUCCESS_NOTIFICATION,
            title: `You have been added to your new panel`,
          })
        )
      })
      .catch(() => {
        dispatch(
          notify({
            ...ERROR_NOTIFICATION,
            title: `Unable to add to panel`,
          })
        )
      })
  }
}
