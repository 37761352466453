import {CLEAR_ORDER_CREATED_STATUS, SET_ORDER_CREATED_STATUS} from 'constants/actionTypes'

const initialState = null

export default function orderCreatedStatus(state = initialState, action) {
  switch (action.type) {
    case SET_ORDER_CREATED_STATUS:
      return action.payload
    case CLEAR_ORDER_CREATED_STATUS:
      return initialState
    default:
      return state
  }
}
