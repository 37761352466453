// import cloneDeep from 'lodash/cloneDeep'
import {ADD_CASE_SUGGESTIONS, DELETE_CASE_SUGGESTIONS} from 'constants/actionTypes'
import CaseSuggestions from 'models/CaseSuggestions'

export const initialState = []

export default function users(state = initialState, action) {
  switch (action.type) {
    case ADD_CASE_SUGGESTIONS:
      return action.payload.map((c) => new CaseSuggestions(c))
    case DELETE_CASE_SUGGESTIONS:
      return initialState
    default:
      return state
  }
}
