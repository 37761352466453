import {FETCHING_PROVIDER_INFO, SET_PROVIDER_INFO_DATA} from 'constants/actionTypes'

const initialState = {}

export default function providerInfo(state = initialState, action) {
  switch (action.type) {
    case FETCHING_PROVIDER_INFO:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          isLoading: true,
        },
      }
    case SET_PROVIDER_INFO_DATA:
      const {id, data} = action.payload
      return {
        ...state,
        [id]: {
          ...state[id],
          isLoading: false,
          data,
        },
      }
    default:
      return state
  }
}
