import {Product} from 'models'
import {CLEAR_PRODUCT, SET_PRODUCT} from 'constants/actionTypes'

const initialState = null

export default function product(state = initialState, action) {
  switch (action.type) {
    case SET_PRODUCT:
      return new Product(action.payload)
    case CLEAR_PRODUCT:
      return initialState
    default:
      return state
  }
}
