import {
  RESET_CASE_COUNTS_BY_USER,
  SET_CASE_COUNTS_BY_USER,
  SET_CASE_COUNTS_BY_USER_LOADING,
} from 'constants/actionTypes'
import {LOADED, LOADING, UNSET} from 'constants/reduxStatuses'

export const initialState = {
  status: UNSET,
  data: [],
}

export default function caseCountsByUser(state = initialState, action) {
  switch (action.type) {
    case SET_CASE_COUNTS_BY_USER:
      return {
        ...state,
        status: LOADED,
        data: action.payload,
      }
    case SET_CASE_COUNTS_BY_USER_LOADING:
      return {
        ...state,
        status: LOADING,
      }
    case RESET_CASE_COUNTS_BY_USER:
      return initialState
    default:
      return state
  }
}
