import React, {Component} from 'react'
import store from 'services/store'
import {webPermissionsSelector} from 'selectors/permissionSelectors'
import {isHideActiveQueueFromReviewersEnabledSelector} from 'selectors/organizationsSelectors'

export const hasPermission = (requiredPermission) => (AuthComponent) => {
  class HasPermission extends Component {
    render() {
      const hasPermission = userHasPermission(requiredPermission)
      return hasPermission ? <AuthComponent {...this.props} /> : null
    }
  }

  return HasPermission
}

export function userHasPermission(requiredRole) {
  const state = store.getState()
  const permissions = webPermissionsSelector(state)
  let hasPermission = false
  if (Array.isArray(requiredRole)) {
    for (let i = 0; i < requiredRole.length; i++) {
      if (permissions && permissions.indexOf(requiredRole[i]) !== -1) {
        hasPermission = true
        break
      }
    }
  } else {
    hasPermission = permissions && permissions.indexOf(requiredRole) !== -1
  }
  return hasPermission
}

// There is a preference configured for a small number of organizations, where
// if its enabled, we don't want reviewers to have access to the "Active" review queue
export function isActiveQueueHiddenFromUser() {
  const state = store.getState()
  const isReviewerInHiddenActiveQueueOrg =
    isHideActiveQueueFromReviewersEnabledSelector(state) &&
    !userHasPermission('WEB:CASE_REVIEW:MANAGE')
  return isReviewerInHiddenActiveQueueOrg
}
