import {
  appUserDisplayNameSelector,
  orderReviewFormSubmissionMetaDataPayloadSelector,
  orderReviewFormSubmissionAdditionalInfoPayloadSelector,
  orderReviewCompsSelector,
  orderReviewRulesSelector,
  orderReviewFormValuesSelector,
  orderReviewSelectedTemplateSelector,
  orderReviewVisibleQuestionsSelector,
} from 'selectors'
import validators, {isExisty} from 'services/validationRules'
import {tuidToMilliseconds} from 'services/datetime'
import {createSearchAddressDisplayString} from 'services/addressDisplay'

import has from 'lodash/has'
import cloneDeep from 'lodash/cloneDeep'

export const getInputValidity = (value, rules) => {
  let fieldIsValid = true
  let errorMsg = ''
  const len = rules.length
  for (let i = 0; i < len; i++) {
    const ruleObj = rules[i]
    const {rule, ruleParams = []} = ruleObj
    const validatorMethod = typeof rule === 'string' ? validators[rule] : rule
    if (validatorMethod) {
      fieldIsValid = validatorMethod(value, ...ruleParams)
    }
    if (!fieldIsValid) {
      errorMsg = ruleObj.errorMsg
      break
    }
  }

  return {
    fieldIsValid,
    errorMsg,
  }
}

const rulesDictionary = {
  required: {
    rule: 'required',
    errorMsg: 'This question is required',
  },
  pattern: (pattern) => ({
    rule: 'matchRegexp',
    ruleParams: [pattern],
    errorMsg: `Field must match expected pattern: ${pattern}`,
  }),
  minLength: (minLength) => ({
    rule: 'minStringLength',
    ruleParams: [minLength],
    errorMsg: `${minLength} characters minimum`,
  }),
  maxLength: (maxLength) => ({
    rule: 'maxStringLength',
    ruleParams: [maxLength],
    errorMsg: `${maxLength} characters maximum`,
  }),
  min: (min) => ({
    rule: 'minNumber',
    ruleParams: [min],
    errorMsg: `${min} minimum`,
  }),
  max: (max) => ({
    rule: 'maxNumber',
    ruleParams: [max],
    errorMsg: `${max} maximum`,
  }),
}

const getDefaultValue = (inputType) => {
  let defaultValue
  switch (inputType) {
    case 'text':
    case 'currency':
      defaultValue = ''
      break

    default:
      defaultValue = undefined
  }

  return defaultValue
}

export const validateForm = (values, template) => {
  const visibleQuestions = getVisibleQuestions(template, values)

  return Object.keys(visibleQuestions).reduce((errors, questionId) => {
    const nextErrors = {...errors}
    const question = visibleQuestions[questionId]

    // Ignore inception question validation
    if (question?.input?.validation && question?.input?.type !== 'inception') {
      // map over the rules for the question and return the appropriate rule object
      // from the rulesDictionary
      const rules = Object.keys(question.input.validation).reduce((acc, ruleName) => {
        const ruleParam = question.input.validation[ruleName]

        // If the rule is 'required' and the value (ruleParam) is false
        // ignore the required rule completely
        if (ruleName === 'required' && !ruleParam) {
          return acc
        } else {
          // the returned rule could either be an object or a function
          // depending on if it needs to accept arguments or not
          const rule = rulesDictionary[ruleName]
          let returnVal = {}
          if (rule) {
            returnVal = typeof rule === 'function' ? rule(ruleParam) : rule
          }

          acc.push(returnVal)
        }

        return acc
      }, [])

      const defaultValue = getDefaultValue(question?.input?.type)
      const {fieldIsValid, errorMsg} = getInputValidity(values[questionId] || defaultValue, rules)
      if (!fieldIsValid) {
        nextErrors[questionId] = errorMsg
      }
    }

    return nextErrors
  }, {})
}

export const createFormValuesFromDefaultInputValues = (reviewTemplate) =>
  Object.values(reviewTemplate?.template?.questions ?? {}).reduce((values, question) => {
    // Default value should be text, an optionId, or an array of optionIds (checkbox only)
    const defaultValue = question.input.defaultValue
    // Only add values that have a default value
    if (defaultValue) {
      switch (question.input.type) {
        case 'checkbox': {
          // Checkbox default values should be an array of optionIds
          // We are creating values that look like this: {optionId: true, optionId: true}
          if (defaultValue.length) {
            values[question.id] = defaultValue.reduce((answerObj, optionId) => {
              answerObj[optionId] = true
              return answerObj
            }, {})
          }
          break
        }
        case 'select': {
          // set value that looks like {label: 'displayText', value: 'optionId}
          const label = reviewTemplate?.template?.options[defaultValue]?.displayText ?? ''
          values[question.id] = {label, value: defaultValue}
          break
        }
        default: {
          // Radios, text, currency all just need the optionId/text
          values[question.id] = defaultValue
        }
      }
    }
    return values
  }, {})

export const createOrderReviewFormValuesFromFormSubmission = (submission, reviewTemplate) => {
  const answers = submission?.formSubmissions?.answers ?? {}
  const compSets = submission?.formSubmissions?.comps ?? []

  // First create any default values
  const formValues = createFormValuesFromDefaultInputValues(reviewTemplate)

  // Next set values for any answers in the submission
  Object.keys(answers).length &&
    Object.keys(answers).reduce((values, questionId) => {
      const type = answers[questionId].type
      const value = answers[questionId].value

      switch (type) {
        case 'text':
        case 'number':
        case 'currency': {
          values[questionId] = value?.toString()
          break
        }
        case 'checkbox': {
          values[questionId] = value.reduce((acc, option) => {
            acc[option.optionId] = true

            // Check for option input
            if (option.inputValue) {
              values[`${questionId}-${option.optionId}-input`] = option.inputValue
            }

            return acc
          }, {})
          break
        }
        case 'radio': {
          // There is only one option value for radios
          const option = value[0]
          values[questionId] = option.optionId

          // Check for option input
          if (option.inputValue) {
            values[`${questionId}-${option.optionId}-input`] = option.inputValue
          }
          break
        }
        case 'select': {
          // There is currently only one option value for selects
          const option = value[0]
          values[questionId] = {
            value: option.optionId,
            label: option.optionValue,
          }

          // Check for option input
          if (option.inputValue) {
            values[`${questionId}-${option.optionId}-input`] = option.inputValue
          }
          break
        }
        default: {
          throw new Error('Error: Invalid answer type while parsing formSubmissions response.')
        }
      }

      return values
    }, formValues)

  // Loop through comp sets and find comments for each comp
  compSets.forEach((set) => {
    set.comps.forEach((comp) => {
      if (comp.comment) {
        formValues[`${comp.id}-comment`] = comp.comment
      }
    })
  })

  return formValues
}

const createFormSubmissionsAnswersObject = (state) => {
  const formValues = orderReviewFormValuesSelector(state)
  const visibleQuestions = orderReviewVisibleQuestionsSelector(state)
  const {
    template: {questions, options},
  } = orderReviewSelectedTemplateSelector(state)

  // Get answers for visible questions
  return Object.keys(visibleQuestions).reduce((answers, questionId) => {
    const questionAnswer = formValues[questionId]
    // Only add to answers if question has a value
    if (questionAnswer) {
      const answerObj = {}
      const type = questions?.[questionId]?.input?.type

      switch (type) {
        case 'text':
        case 'number':
        case 'currency': {
          answerObj.value = questionAnswer
          break
        }
        case 'radio': {
          const optionInput = {}
          const optionInputValue = formValues[`${questionId}-${questionAnswer}-input`]

          // Check if the radio has an option input
          if (optionInputValue) {
            optionInput.inputType = 'text' // Currently the only supported option input
            optionInput.inputValue = optionInputValue
          }

          answerObj.value = [
            {
              optionId: questionAnswer,
              optionValue: options[questionAnswer].displayText,
              ...optionInput,
            },
          ]
          break
        }
        case 'checkbox': {
          answerObj.value = Object.keys(questionAnswer).reduce((values, optionId) => {
            // Only add the checkbox answers that are checked
            if (questionAnswer[optionId]) {
              const optionInput = {}
              const optionInputValue = formValues[`${questionId}-${optionId}-input`]

              // Check if the checkbox has an option input
              if (optionInputValue) {
                optionInput.inputType = 'text' // Currently the only supported option input
                optionInput.inputValue = optionInputValue
              }

              values.push({
                optionId,
                optionValue: options[optionId].displayText,
                ...optionInput,
              })
            }

            return values
          }, [])
          break
        }
        case 'select': {
          answerObj.value = [
            {
              optionId: questionAnswer.value,
              optionValue: questionAnswer.label,
            },
          ]
          break
        }
        default: {
          throw new Error('Input type not supported')
        }
      }

      answerObj.type = type
      answers[questionId] = answerObj
    }

    return answers
  }, {})
}

const createFormSubmissionCompsArray = (state) => {
  const comps = orderReviewCompsSelector(state)
  const formValues = orderReviewFormValuesSelector(state)

  // Map over comp sets and for each comp see if there is a
  // comment in the form values
  return comps.map((compSet) => ({
    ...compSet,
    comps: compSet.comps.map((comp) => ({
      ...comp,
      comment: formValues[`${comp.id}-comment`] ?? '',
    })),
  }))
}

export const createOrderReviewFormSubmissionPayload = (state) => {
  const currentUserDisplayName = appUserDisplayNameSelector(state)
  const metadata = orderReviewFormSubmissionMetaDataPayloadSelector(state)
  const additionalInformation = orderReviewFormSubmissionAdditionalInfoPayloadSelector(state)
  const rules = orderReviewRulesSelector(state)
  const answers = createFormSubmissionsAnswersObject(state)
  const comps = createFormSubmissionCompsArray(state)

  const payload = {
    metadata: {...metadata},
    additionalInformation: {
      ...additionalInformation,
      reviewer: currentUserDisplayName,
      lastUpdateTimestamp: new Date().toISOString(),
    },
    formSubmissions: {
      answers,
      rules,
      comps,
    },
  }

  return payload
}

export const getVisibleSections = (template, values) => {
  const visibleSections = []
  const arrangement = template?.arrangement ?? []
  const sections = template?.sections ?? {}

  arrangement.forEach((sectionId) => {
    const section = sections[sectionId]

    // Check to verify the section exist
    if (section) {
      handleConditionals(section, values, () => {
        visibleSections.push(section)
      })
    }
  })

  return visibleSections
}

export const getVisibleQuestions = (template, values) => {
  const visibleQuestions = {}
  const arrangement = template?.arrangement ?? []
  const sections = template?.sections ?? {}
  const questions = template?.questions ?? {}
  const options = template?.options ?? {}

  arrangement.forEach((sectionId) => {
    const section = sections[sectionId]
    const questionIds = section?.children

    // Check to make sure the section has questions and the section exist
    if (Array.isArray(questionIds) && section) {
      handleConditionals(section, values, () => {
        addVisibleQuestions(questionIds, questions, options, values, visibleQuestions)
      })
    }
  })

  return visibleQuestions
}

const addVisibleQuestions = (questionIds, questions, options, values, visibleQuestions) => {
  questionIds.forEach((questionId) => {
    const questionObj = questions[questionId]
    handleConditionals(questionObj, values, () => {
      visibleQuestions[questionId] = questionObj

      // If the question is visible we need to check if it has options
      if (Array.isArray(questionObj.options)) {
        // If the question has options we need to check each option for
        // conditional requirements
        questionObj.options.forEach((optionId) => {
          const optionsObj = options[optionId]
          const optionQuestions = optionsObj?.conditionalRequirements?.questions
          // The option has conditional requirements
          if (Array.isArray(optionQuestions)) {
            const questionAnswer = values[questionId]

            // If the answer is a string and it is equal to the option add the question
            // to the visible questions object
            const isStringAnswerAndMatches =
              typeof questionAnswer === 'string' && questionAnswer === optionId
            // Select options will have a 'value' key while checkboxes will have
            // the optionId as the key
            const isObjectAnswerAndMatches =
              typeof questionAnswer === 'object' &&
              (questionAnswer[optionId] || questionAnswer?.value === optionId)

            if (isStringAnswerAndMatches || isObjectAnswerAndMatches) {
              optionQuestions.forEach((optionQuestionId) => {
                const optionQuestionObject = questions[optionQuestionId]
                visibleQuestions[optionQuestionId] = optionQuestionObject

                // we need to recurse through the selection option's questions too
                handleConditionals(optionQuestionObject, values, () =>
                  addVisibleQuestions(optionQuestions, questions, options, values, visibleQuestions)
                )
              })
            }
          }
        })
      }

      // If the question type is inception and visible we need to recursively add those questions as well
      if (questionObj?.input?.type === 'inception') {
        const inceptionQuestionIds = questionObj?.conditionalRequirements?.questions ?? []
        addVisibleQuestions(inceptionQuestionIds, questions, options, values, visibleQuestions)
      }
    })
  })
}

const handleConditionals = (obj, values, cb) => {
  if (obj?.dependentOn?.type === 'conditional') {
    const {question, answers} = obj.dependentOn
    if (answers.includes(values[question])) {
      cb()
    }
  } else {
    cb()
  }
}

// a key will look like 'Rule_C0_R23_V1', and we want to trim off the _Vx part
// for comparison
export const getTrimmedRuleKey = (key) => {
  return key.slice(0, key.lastIndexOf('_'))
}

export const addRuleOrCompToValues = (dataSet, payload) => {
  const isRuleSet = has(payload, 'rule')
  const items = cloneDeep(dataSet)

  // Find documentId for the data set
  const matchedIndex = dataSet.findIndex((set) => set.documentId === payload.documentId)

  // If documentId is not found add the new data object
  if (matchedIndex === -1) {
    const key = isRuleSet ? 'rules' : 'comps'
    const data = isRuleSet ? payload.rule : payload.comp

    // Create the rule set with the correct keys
    const newDataSet = {
      documentId: payload.documentId,
      mergedAppraiserProviderNames: payload.mergedAppraiserProviderNames,
      [key]: [data],
    }

    items.push(newDataSet)
  } else {
    // Add the item to the end of the array
    isRuleSet
      ? items[matchedIndex].rules.push(payload.rule)
      : items[matchedIndex].comps.push(payload.comp)
  }

  return items
}

export const removeRuleOrCompFromValues = (dataSet, payload) => {
  const isRuleSet = has(payload, 'rule')
  const itemToRemove = isRuleSet ? getTrimmedRuleKey(payload?.rule?.key) : payload?.id

  return dataSet
    .map((items) => {
      isRuleSet
        ? (items.rules = items.rules.filter(
            (r) => getTrimmedRuleKey(r?.rule?.key ?? '') !== itemToRemove
          ))
        : (items.comps = items.comps.filter((c) => c.id !== itemToRemove))
      return items
    })
    .filter((items) => (isRuleSet ? items.rules.length > 0 : items.comps.length > 0))
}

export const getLatestDocumentSummary = (documentSummaries = []) =>
  documentSummaries.reduce(
    (latest, current) =>
      tuidToMilliseconds(latest.documentUpdateId) > tuidToMilliseconds(current.documentUpdateId)
        ? latest
        : current,
    {}
  )

export const getGroupedDataPointsByTemplateId = (templateData) => {
  return templateData.reduce((accumulator, currentValue) => {
    const formId = currentValue.id
    const formUpdateId = currentValue.updateId
    const questions = currentValue.template?.questions
    const prefillDataPoints = []
    if (questions) {
      Object.keys(questions).forEach((question) => {
        const prefillDataPoint = questions[question]?.prefillDataPoint
        if (prefillDataPoint) {
          prefillDataPoints.push(prefillDataPoint)
        }
      })
    }

    if (accumulator[formId]) {
      accumulator[formId][formUpdateId] = prefillDataPoints
    } else {
      accumulator[formId] = {}
      accumulator[formId][formUpdateId] = prefillDataPoints
    }

    return accumulator
  }, {})
}

export const getPrefillFormValues = ({template}, response) => {
  const {questions} = template
  return Object.keys(questions).reduce((accumulator, question) => {
    const inputType = questions[question].input.type
    const prefillDataPoint = questions[question]?.prefillDataPoint
    const prefillResponse = response[prefillDataPoint]
    if (prefillDataPoint && prefillResponse) {
      const value = prefillResponse.value
      if (!prefillResponse?.failure) {
        switch (inputType) {
          case 'text':
          case 'number':
          case 'currency':
            if (isExisty(value)) {
              if (prefillDataPoint === 'appraisal.propertyAddress') {
                accumulator[question] = createSearchAddressDisplayString(
                  value.addressLine1,
                  value.city,
                  value.state,
                  value.zip
                )
              } else {
                accumulator[question] = value.toString()
              }
            }
            break
          case 'checkbox':
            const checkboxInitialValues = questions[question].options.reduce(
              (accumulatorKey, option) => {
                const prefillValue = template.options[option]?.prefillValue
                // we are converting to string as some of these values can be
                // booleans and this will prevent exiting the loop when its false
                if (isExisty(value) && value.includes(prefillValue)) {
                  accumulatorKey[option] = true
                }
                return accumulatorKey
              },
              {}
            )
            accumulator[question] = {...checkboxInitialValues}
            break
          case 'radio':
            const radioOptions = questions[question].options
            for (let i = 0; i < radioOptions.length; i++) {
              const option = radioOptions[i]
              const prefillValue = template.options[option]?.prefillValue
              // we are converting to string as some of these values can be
              // booleans and this will prevent exiting the loop when its false
              if (isExisty(value) && prefillValue === value) {
                accumulator[question] = option
                break
              }
            }
            break
          case 'select':
            const selectOptions = questions[question].options
            for (let i = 0; i < selectOptions.length; i++) {
              const option = selectOptions[i]
              const prefillValue = template.options[option]?.prefillValue
              // we are converting to string as some of these values can be
              // booleans and this will prevent exiting the loop when its false
              if (isExisty(value) && prefillValue === value) {
                accumulator[question] = {
                  value: template.options[option].id,
                  label: template.options[option].displayText,
                }
                break
              }
            }
            break
          default:
            break
        }
      } else {
        console.error(prefillResponse.failure)
      }
    }
    return accumulator
  }, {})
}

export const showAutoFill = ({
  type,
  formOptions,
  initialValue,
  dataPointValue,
  questionId,
  input,
  optionId,
  modified,
}) => {
  let showSource = false
  // this condition checks if the form has any value entered and if the
  // datapoint api has a corresponding entry for the question
  if (isExisty(initialValue) && isExisty(dataPointValue)) {
    switch (type) {
      case 'text':
      case 'number':
      case 'currency':
        if (questionId === 'propertyAddress') {
          const address = createSearchAddressDisplayString(
            dataPointValue.addressLine1,
            dataPointValue.city,
            dataPointValue.state,
            dataPointValue.zip
          )
          showSource = initialValue === address
        } else {
          showSource = initialValue.toString() === dataPointValue.toString()
        }
        break
      case 'radio':
        if (
          optionId === initialValue &&
          formOptions[initialValue]?.prefillValue === dataPointValue
        ) {
          showSource = true
        }
        break
      case 'select':
        const value = input?.value?.value ?? ''
        if (formOptions[value]?.prefillValue === dataPointValue) {
          showSource = true
        }
        break
      case 'checkbox':
        if (
          initialValue[optionId] &&
          dataPointValue?.includes(formOptions[optionId]?.prefillValue)
        ) {
          showSource = true
        }
        break
      default:
        throw new Error('Input type not supported')
    }
    showSource = !modified && showSource
  }
  return showSource
}

export const getSelectedFormTemplate = (formTemplates, selectedTemplateIds) =>
  formTemplates.find(
    (template) =>
      template.id === selectedTemplateIds.id && template.updateId === selectedTemplateIds.updateId
  ) ?? {
    template: {
      arrangement: [],
      sections: {},
      questions: {},
      options: {},
    },
  }
