import {DEFAULT_EMPTY_CRITERIA} from 'constants/predicateCriteriaConstants'
import {
  ALL_FORMS_ID,
  ALL_TOOLS_ID,
  ALL_VIEWS_ID,
  COMPARABLES_ID,
  NONE_RECOMMENDED_FORM_ID,
  ORIGINAL_REPORT_ID,
  QUICK_VIEW_ID,
  SCORES_AND_FINDINGS_ID,
} from 'constants/reviewScopeFeatureIdConstants'

export const CASE_REVIEW_SCOPE_ACTIVITY_TYPE = 'Review Scope Removed'
export const CASE_REVIEW_SCOPE_RESET_TEXT =
  'Full Review was enabled resulting in all review features being made available'
export const CASE_REVIEW_FORMS_EMTPY_MESSAGE = 'No review forms have been selected for this scope'
export const REVIEW_SCOPE_EMPTY_MESSAGE = 'Create review scopes to manage your review process'
export const SAVE_REVIEW_SCOPE_CREATE = 'SAVE_REVIEW_SCOPE_CREATE'
export const SAVE_REVIEW_SCOPE_UPDATE = 'SAVE_REVIEW_SCOPE_UPDATE'
export const SAVE_REVIEW_SCOPE_DELETE = 'SAVE_REVIEW_SCOPE_DELETE'
export const SAVE_REVIEW_SCOPE_REORDER = 'SAVE_REVIEW_SCOPE_REORDER'
export const AVAILABLE_FEATURES_SECTION_TITLE = 'Available Review Features'
export const AVAILABLE_FEATURES_SECTION_DESCRIPTION =
  'These are the tools, views, and review forms that will be available to reviewers when this scope is applied.'
export const AUTO_APPROVE_SECTION_DESCRIPTION =
  'Enabling this feature automatically approves the order and closes the affiliated case when the above criteria are met.'
export const AUTO_APPROVE_DISCLAIMER_TITLE = 'Note: When there is more than one order in a case'
export const AUTO_APPROVE_DISCLAIMER_POINT_ONE =
  'Only the first submitted order will be auto-approved.'
export const AUTO_APPROVE_DISCLAIMER_POINT_TWO =
  'The case, revisions of the auto-approved order, and all subsequent orders will remain open for manual review'
export const RESET_CASE_SCOPE_DIALOG_TITLE = 'Enabling Full Review'
export const RESET_CASE_SCOPE_DIALOG_CONTENT =
  'By proceeding with this action, you are overriding the calculated scope of this review. All tools, report views, and review forms will be made available, and this action can not be undone.'
export const RECOMMENDED_FORM_TOOLTIP_TEXT =
  'Selected form is marked as "Recommended" and moved to the front of the review form selection list.'
export const RECOMMENDED_BANNER_TEXT = 'Recommended'
export const FEATURE_TYPE_VIEWS = 'FEATURE_TYPE_VIEWS'
export const FEATURE_TYPE_TOOLS = 'FEATURE_TYPE_TOOLS'
export const FEATURE_TYPE_FORMS = 'FEATURE_TYPE_FORMS'

// TODO: define types to ensure that new review and default scope bodies don't have sequence defined
// TODO: define types to ensure that `defaultScope` is always false for user created scopes
export const NEW_REVIEW_SCOPE_BODY = {
  name: '',
  description: '',
  enabled: true,
  defaultScope: false,
  criteria: {...DEFAULT_EMPTY_CRITERIA},
  settings: {
    recommendedFormId: NONE_RECOMMENDED_FORM_ID,
    forms: [ALL_FORMS_ID],
    tools: [ALL_TOOLS_ID],
    views: [ALL_VIEWS_ID],
  },
}

// TODO: define types to ensure that defaultScope is always true for the default scope
export const NEW_DEFAULT_SCOPE_BODY = {
  ...NEW_REVIEW_SCOPE_BODY,
  criteria: {
    predicateType: 'AlwaysMatch',
  },
  name: 'Default Review Scope',
  description: 'The default scope is applied when the criteria for all other scopes is not met.',
  defaultScope: true,
  settings: {
    recommendedFormId: null,
    forms: null,
    tools: null,
    views: null,
  },
}

// componentName is used in ReportSummary to render the correct component,
// if new views are added be sure to update viewsAndToolsMap in ReportSummary.js
export const AVAILABLE_VIEWS = [
  {
    id: ALL_VIEWS_ID,
    displayName: 'All Views',
  },
  {
    id: ORIGINAL_REPORT_ID,
    displayName: 'Original Report',
    componentName: 'OriginalReportViewCard',
  },
  {
    id: QUICK_VIEW_ID,
    displayName: 'Quick View',
    componentName: 'QuickViewCard',
  },
]

// componentName is used in ReportSummary to render the correct component,
// if new tools are added be sure to update viewsAndToolsMap in ReportSummary.js
export const AVAILABLE_TOOLS = [
  {
    id: ALL_TOOLS_ID,
    displayName: 'All Tools',
  },
  {
    id: SCORES_AND_FINDINGS_ID,
    displayName: 'Scores and Findings',
    componentName: 'ScoresAndFindings',
  },
  {
    id: COMPARABLES_ID,
    displayName: 'Comparables',
    componentName: 'Comparables',
  },
]

export const ALL_FORMS_OPTION = {
  id: ALL_FORMS_ID,
  displayName: 'All Review Forms',
}

export const REVIEW_SCOPE_RECOMMENDED_FORM_OPTION_NONE = {
  id: NONE_RECOMMENDED_FORM_ID,
  displayName: 'None',
}
