// Styles, themes, etc.
import styled from 'styled-components'
import {theme, Button} from '@clearcapital/ui-resources'

// Components, actions, models, etc.
import React from 'react'
import PropTypes from 'prop-types'
import {ERROR_DEFAULT_TITLE, ERROR_DEFAULT_MESSAGE} from 'constants/appMessages'

export const ErrorPage = (props) => {
  const {
    customError: {title, message, showDetails, allowRefresh},
    error = {},
  } = props
  const {message: details} = error

  const refresh = () => {
    window.location.reload()
  }

  return (
    <ErrorPage.Styled className='error-wrap'>
      <i className='material-icons error-icon'>info</i>
      <h1 className='error-title'>{title || ERROR_DEFAULT_TITLE}</h1>
      <p className='error-msg'>"{message || ERROR_DEFAULT_MESSAGE}"</p>
      {showDetails && details && <p className='error-details'>Details: {details}</p>}
      {allowRefresh && (
        <Button color='primary' onClick={refresh}>
          Try Now
        </Button>
      )}
    </ErrorPage.Styled>
  )
}

ErrorPage.propTypes = {
  customError: PropTypes.shape({
    title: PropTypes.string,
    message: PropTypes.string,
    showDetails: PropTypes.bool,
    allowRefresh: PropTypes.bool,
  }),
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
}

ErrorPage.defaultProps = {
  customError: {
    title: '',
    message: '',
    showDetails: false,
    allowRefresh: true,
  },
}

ErrorPage.Styled = styled.div`
  &.error-wrap {
    padding: 30px;
    text-align: center;

    .error-icon {
      font-size: 6.25rem;
      color: ${theme.palette.colors.red[450]};
    }

    .error-title {
      font-size: 20px;
      font-weight: normal;
      color: #86939e;
      text-transform: none;
      margin-top: 20px;
    }

    .error-msg,
    .error-details {
      font-size: 14px;
      color: ${theme.palette.dsm.interface.base};
      margin-bottom: 40px;
    }
  }
`
