import {createSelector} from 'reselect'
import {removeCharactersSpacesTrimAndLowercase} from 'services/string'
import {getBusinessUnitDisplayName} from 'services/businessUnitsHelpers'

export const businessUnitsSelector = (state) => state.businessUnits

export const businessUnitsDataSelector = createSelector(
  [businessUnitsSelector],
  (businessUnits) => businessUnits.data
)

export const businessUnitsDataWithDefaultUnitFirstSelector = createSelector(
  [businessUnitsDataSelector],
  (businessUnitsData) =>
    businessUnitsData.reduce((acc, curr) => {
      if (curr.defaultIndicator) {
        acc.unshift(curr)
      } else {
        acc.push(curr)
      }
      return acc
    }, [])
)

export const businessUnitsDictionarySelector = createSelector(
  [businessUnitsDataSelector],
  (businessUnits) =>
    businessUnits?.reduce((accumulator, current) => {
      accumulator[current.id] = current
      return accumulator
    }, {})
)

export const businessUnitsStatusSelector = createSelector(
  [businessUnitsSelector],
  (businessUnits) => businessUnits.status
)

export const businessUnitsOptionsSelector = createSelector(
  [businessUnitsDataSelector],
  (businessUnits) =>
    businessUnits.reduce((accumulator, businessUnit) => {
      if (businessUnit.enabledIndicator) {
        accumulator.push({
          label: getBusinessUnitDisplayName(businessUnit),
          value: businessUnit.id,
        })
      }
      return accumulator
    }, [])
)

export const businessUnitDefaultSelector = createSelector(
  [businessUnitsDataSelector],
  (businessUnits) => businessUnits.find(({defaultIndicator}) => defaultIndicator) || {}
)

export const businessUnitsNameSetSelector = createSelector([businessUnitsDataSelector], (data) => {
  const unitNames = new Set()
  data.forEach(({name}) => {
    unitNames.add(removeCharactersSpacesTrimAndLowercase(name))
  })
  return unitNames
})
