import React, {Fragment} from 'react'
import {Link} from 'react-router-dom'
import {productsApiV2} from 'services/apis'
import {handleSuccessfulLogin} from 'actions/authActions'
import {addNotification as notify} from 'reapop'
import {SUCCESS_NOTIFICATION} from 'constants/notifications'
import {getQueryStringParams} from 'services/queryString'
import {appUserSelector} from 'selectors'

// Loads an invitation on the panel signup screen, if you are logged out
function getInvitation({params, onInvitation}) {
  return productsApiV2
    .get(`invitations/${params.id}`)
    .then((invitation) => {
      let error
      if (invitation.status !== 'PENDING') {
        error = 'This invite has expired or been used already.'
      }

      onInvitation({invitation, error})
    })
    .catch(() => onInvitation({error: 'No invitation found'}))
}

// If a user is logged in, joins the panel and redirects to home
function joinPanel({params, onAccept, dispatch}) {
  return productsApiV2.post(`invitations/${params.id}/accept`).then(() => {
    onAccept()
    dispatch(
      notify({
        ...SUCCESS_NOTIFICATION,
        title: `You have been added to your new panel`,
      })
    )
  })
}

// Friendly errors for the user based on api message or status
const duplicateEmail = (
  <Fragment>
    This email address is already in use.
    <br />
    <Link to={`/login${window.location.search}`}>Log in to accept your invitation.</Link>
  </Fragment>
)

const errors = {
  generic: 'Unable to signup at this time',
  400: (message) => {
    let content = message

    if (content.match(/Unexpected Status/)) {
      content = 'This invite has expired or been used already'
    }

    if (content.match(/email is a duplicate key/)) {
      content = duplicateEmail
    }

    return content
  },
  409: () => duplicateEmail,
  // This should never happen
  403: () => 'Please log out and try again',
}

// Checks if user is logged in and calls the correct action
export function acceptOrLoadInvitation({onAccept, onInvitation}) {
  return (dispatch, getState) => {
    const params = getQueryStringParams()
    const state = getState()
    const currentUser = appUserSelector(state)

    if (params.id) {
      // if user is logged in already, accept and redirect automatically
      if (currentUser) {
        return joinPanel({params, onAccept, onInvitation, dispatch}).catch(() =>
          getInvitation({params, onInvitation})
        )
      } else {
        return getInvitation({params, onInvitation})
      }
    } else {
      onInvitation({error: 'Invalid invitation ID'})
    }
  }
}

// Triggered after filling out the signup form
export function signupNewUser({invitation, signUpData, onError}) {
  return (dispatch) => {
    return productsApiV2
      .post(`invitations/${invitation.id}/signup-and-accept`, signUpData)
      .then((session) =>
        handleSuccessfulLogin({
          dispatch,
          session,
          redirect: true,
          storeSession: true,
          prevRoute: '/profile',
        })
      )
      .catch((e) => {
        if (e.json) {
          onError(
            errors[e.response.status] ? errors[e.response.status](e.json.message) : errors.generic
          )
        } else {
          onError(errors.generic)
        }
      })
  }
}
