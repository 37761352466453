// Styles
import styled from 'styled-components'

// Core
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import history from 'services/history'

// Components, services, etc.
import Footer from 'components/Footer'
import AuthForm from 'components/AuthForm/MainAuthForm'
import {TextField} from '@clearcapital/ui-resources'
import {errorBoundary} from 'components/Error'
import {validateEmail} from 'services/validators'
import {resetPassword} from 'actions/usersActions'
import {USER_PASSWORD_CHANGE_MESSAGE, USER_EMAIL_ADDRESS_INVALID} from 'constants/appMessages'
import InitiallyEnabledButton from 'components/InitiallyEnabledButton'
import ParticleAnimation from 'components/LoginAnimation/AnimationWrapper'

const mapStateToProps = (state) => ({})
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resetPassword,
    },
    dispatch
  )

@errorBoundary()
@connect(mapStateToProps, mapDispatchToProps)
class ResetPassword extends Component {
  static propTypes = {
    validateEmail: PropTypes.func.isRequired,
    resetPassword: PropTypes.func.isRequired,
  }

  static defaultProps = {
    validateEmail,
    resetPassword,
  }

  state = {
    username: history.location.state ? history.location.state.username : '',
    highlightRed: false,
  }

  submit = (se) => {
    const {username} = this.state
    se.preventDefault()
    return this.props.validateEmail(username)
      ? this.props.resetPassword(username)
      : this.handleValidationError()
  }

  handleValidationError = () => {
    this.setState({
      username: '',
    })
    alert(USER_EMAIL_ADDRESS_INVALID)
  }

  handleChange = (se) => {
    this.setState({
      username: se.target.value,
    })
    if (this.state.highlightRed) {
      this.setState({highlightRed: !this.props.validateEmail(this.state.username)})
    }
  }

  handleBlur = () => {
    this.setState({highlightRed: !this.props.validateEmail(this.state.username)})
  }

  render() {
    const enableSubmit = this.props.validateEmail(this.state.username)

    return (
      <ResetPassword.Styled>
        <div className='auth-form-wrapper'>
          <AuthForm heading='Forgot Password' introText={USER_PASSWORD_CHANGE_MESSAGE}>
            <a href='/' className='login-logo__link' />
            <form id='user-forgot-form' onSubmit={this.submit}>
              <div className='auth-row'>
                <TextField
                  error={this.state.highlightRed}
                  fullWidth
                  label='Email'
                  data-sqa-id='Email'
                  name='name'
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                  type='text'
                  value={this.state.username}
                  {...(this.state.highlightRed ? {helperText: USER_EMAIL_ADDRESS_INVALID} : {})}
                />
              </div>
              <div className='button' data-sqa-id='Submit'>
                <InitiallyEnabledButton
                  color='primary'
                  disabled={!enableSubmit}
                  className='auth-button'
                  form='user-forgot-form'
                  type='submit'
                >
                  Submit
                </InitiallyEnabledButton>
              </div>
            </form>
          </AuthForm>
          <ParticleAnimation />
        </div>
        <Footer />
      </ResetPassword.Styled>
    )
  }

  static Styled = styled.div`
    display: flex;
    height: 100%;

    & .auth-form-wrapper {
      align-items: center;
      display: flex;
      width: 100%;

      .auth-container {
        width: 35vw;
      }

      .animation-wrapper {
        width: inherit;
      }
    }
  `
}

export default ResetPassword
