import {CLEAR_REPORT_LIST, SET_REPORT_LIST} from 'constants/actionTypes'

const initialState = null

export function reportList(state = initialState, action) {
  switch (action.type) {
    case SET_REPORT_LIST:
      return action.payload
    case CLEAR_REPORT_LIST:
      return initialState
    default:
      return state
  }
}
