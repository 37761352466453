import {closeModal, removeModal} from 'actions/modalActions'
import {OPEN_MODAL, CLOSE_MODAL, REMOVE_MODAL} from 'constants/actionTypes'
import uuid from 'services/uuid'
import store from 'services/store'

export const initialState = []

export default function modals(state = initialState, action) {
  const {type, payload} = action

  switch (type) {
    case OPEN_MODAL: {
      const id = uuid()
      const {handleClose, handleExited, ...rest} = payload
      return [
        ...state,
        {
          ...rest,
          open: true,
          id,
          // we include the handleClose and handleExited functions as a convenience
          // so the modal will receive them as props
          handleClose: (...args) => {
            store.dispatch(closeModal(id))
            handleClose && handleClose(...args)
          },
          handleExited: (...args) => {
            store.dispatch(removeModal(id))
            handleExited && handleExited(...args)
          },
        },
      ]
    }
    case CLOSE_MODAL:
      return state.map((modal) => {
        let returnModal = modal
        if (payload) {
          if (modal.id === payload) {
            returnModal = {
              ...modal,
              open: false,
            }
          }
        } else {
          returnModal = {
            ...modal,
            open: false,
          }
        }
        return returnModal
      })
    case REMOVE_MODAL:
      return state.filter((modal) => modal.id !== payload)
    default:
      return state
  }
}
