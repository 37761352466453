import DocumentSummary from 'models/DocumentSummary'
import {
  RESET_DOCUMENT_SUMMARIES,
  SET_DOCUMENT_SUMMARIES,
  SET_PRIMARY_DOCUMENT_SUMMARIES,
} from 'constants/actionTypes'

export const initialState = null

export function documentSummaries(state = initialState, action) {
  switch (action.type) {
    case RESET_DOCUMENT_SUMMARIES:
      return initialState
    case SET_DOCUMENT_SUMMARIES:
      return action.payload.map((docSummary) => {
        return new DocumentSummary(docSummary)
      })
    default:
      return state
  }
}

export function primaryDocumentSummaries(state = initialState, action) {
  switch (action.type) {
    case RESET_DOCUMENT_SUMMARIES:
      return initialState
    case SET_PRIMARY_DOCUMENT_SUMMARIES:
      return action.payload.map((docSummary) => {
        return new DocumentSummary(docSummary)
      })
    default:
      return state
  }
}
