import {createSelector} from 'reselect'
import get from 'lodash/get'

import {getLoanNumber} from 'services/loanInfo'
import {organizationUsersSelector} from 'selectors/usersSelectors'
import {appUserIdSelector, loadingSelector} from 'selectors/appSelectors'
import {tierDictionarySelector, tierDropdownOptionsSelector} from 'selectors/tierSelectors'
import {VALUATION_PURPOSE_DISPLAY_NAMES} from 'constants/valuationPurposeDisplayNames'
import {LOADING_CASE_DROPDOWN} from 'constants/actionTypes'
import {createPropertyOverviewAddressDisplayString} from 'services/addressDisplay'
import {USER_NOT_QUALIFIED_TO_REVIEW_CASE} from 'constants/appMessages'
import {personByDisplayName} from 'services/sort'
import {NO_DATA} from 'constants/app'
import {USER_UNASSIGNED} from 'constants/userConstants'
import {IS_COMPLETED, IS_CANCELED} from 'constants/statusesConstants'
import {
  isCaseReviewManagerSelector,
  isCasesOnHoldManagePermissionSelector,
} from 'selectors/permissionSelectors'
import {casesOnHoldFields} from 'constants/casesOnHoldConstants'
import {caseCountsByUserIdDictionarySelector} from 'selectors/caseCountsByUserSelectors'

export const coreCaseSelector = (state) => state.coreCase

export const caseLoanNumberSelector = createSelector([coreCaseSelector], (coreCase) => {
  return coreCase ? getLoanNumber(coreCase) : ''
})

export const caseLoanInfoSelector = createSelector([coreCaseSelector], (coreCase) =>
  get(coreCase, 'loanInfo', {})
)

export const caseAssignedToSelector = createSelector([coreCaseSelector], (coreCase) => {
  return get(coreCase, 'assignedTo.id', null)
})

export const isCaseAssignedToCurrentUserSelector = createSelector(
  [caseAssignedToSelector, appUserIdSelector],
  (userIdCaseIsAssignedTo, currentUserId) => userIdCaseIsAssignedTo === currentUserId
)

export const caseIdSelector = createSelector([coreCaseSelector], (coreCase) => get(coreCase, 'id'))

export const casePropertySelector = createSelector(
  coreCaseSelector,
  (coreCase) => coreCase.property
)

export const caseAddressSelector = createSelector([casePropertySelector], (property) =>
  get(property, 'address', {})
)

export const coreCaseStatusSelector = createSelector([coreCaseSelector], (coreCase) =>
  get(coreCase, 'status', null)
)

export const caseValuationPurposeSelector = createSelector([coreCaseSelector], (coreCase) => {
  const valuationPurpose = get(coreCase, 'valuationPurpose.purpose')
  return valuationPurpose ? VALUATION_PURPOSE_DISPLAY_NAMES[valuationPurpose] : ''
})

export const caseMappedTierSelector = createSelector(
  [tierDropdownOptionsSelector, coreCaseSelector, tierDictionarySelector],
  (tierDropdownOptions, coreCase = {}, tierCollection) => {
    const defaultOption = {label: 'No Tier', value: 'notier'}
    let caseTier = defaultOption
    if (Object.keys(tierCollection).length) {
      caseTier = tierDropdownOptions.find((tier) => tier.value === coreCase.tierId)
      if (!caseTier) {
        // search for disabled tiers
        const disabledTier = get(tierCollection, `${coreCase.tierId}.name`)
        if (disabledTier) {
          caseTier = {label: disabledTier, value: disabledTier}
        } else {
          // If tier is deleted from Tier Service through API this condition will get executed as core
          // service will have a tier id mapping but a match will not be found in tier service.
          caseTier = defaultOption
        }
      }
    }

    return caseTier
  }
)

export const coreCaseTierNameSelector = createSelector(
  [coreCaseSelector, tierDictionarySelector],
  (coreCase = {}, tierCollection) => {
    const caseTierId = coreCase?.tierId
    if (caseTierId) {
      return get(tierCollection, `${caseTierId}.name`)
    } else {
      return ''
    }
  }
)

export const caseDropdownLoadingSelector = createSelector([loadingSelector], (loadingSelector) =>
  loadingSelector.includes(LOADING_CASE_DROPDOWN)
)

export const caseAddressStringWithUnitSelector = createSelector([coreCaseSelector], (coreCase) =>
  createPropertyOverviewAddressDisplayString(
    coreCase?.property?.address?.street,
    coreCase?.property?.address?.unitNumber,
    coreCase?.property?.address?.city,
    coreCase?.property?.address?.state,
    coreCase?.property?.address?.zipcode
  )
)

export const caseAssignedToDropdownOptionsSelector = createSelector(
  [
    organizationUsersSelector,
    isCaseReviewManagerSelector,
    coreCaseSelector,
    caseCountsByUserIdDictionarySelector,
  ],
  (orgUsers, isCaseReviewManager, coreCase, caseCountsByUserIdDictionary) => {
    const caseTierId = coreCase?.tierId

    const formattedUsers = orgUsers.sort(personByDisplayName).map((user) => {
      let isQualified = !caseTierId || isCaseReviewManager

      if (!isCaseReviewManager && caseTierId) {
        const userQualifiedTierIds = user?.qualificationsPredicate?.qualifiedTierIds ?? []
        isQualified = userQualifiedTierIds.includes(caseTierId)
      }

      const disabled = !isQualified
      const tooltip = disabled ? USER_NOT_QUALIFIED_TO_REVIEW_CASE : ''

      const userCaseCount = caseCountsByUserIdDictionary[user.id] ?? 0
      const value = user?.id
      const label = `${user?.person?.displayName ?? NO_DATA} (${userCaseCount})`

      return {
        value,
        label,
        disabled,
        tooltip,
      }
    })

    return [{value: USER_UNASSIGNED, label: 'Unassigned'}, ...formattedUsers]
  }
)

export const caseAssignedToDropdownValueSelector = createSelector(
  [caseAssignedToSelector, organizationUsersSelector],
  (assignedId, orgUsers) => {
    const assignedTo = orgUsers.find((user) => user.id === assignedId)
    return assignedTo
      ? {
          label: assignedTo?.person?.displayName ?? NO_DATA,
          value: assignedId,
        }
      : {
          label: 'Unassigned',
          value: USER_UNASSIGNED,
        }
  }
)

export const isCaseCompletedSelector = createSelector(
  [coreCaseStatusSelector],
  (status) => status === IS_COMPLETED
)

export const isCaseCanceledSelector = createSelector(
  [coreCaseStatusSelector],
  (status) => status === IS_CANCELED
)

export const isCaseOnHoldSelector = createSelector([coreCaseSelector], (cases) =>
  Boolean(cases.onHold)
)

export const casesOnHoldFormInitialValuesSelector = createSelector([coreCaseSelector], (cases) => {
  const {onHoldDetails} = cases
  return casesOnHoldFields.reduce((accumulator, current) => {
    accumulator[current.id] = onHoldDetails?.[current.id]
    return accumulator
  }, {})
})

export const coreCaseOnHoldDetailsSelector = createSelector([coreCaseSelector], (data) => {
  const result = data.onHoldDetails ? {...data.onHoldDetails} : {}
  result.notes = result.notes ?? NO_DATA
  return result
})

export const isCasesOnHoldManageSelector = createSelector(
  [isCasesOnHoldManagePermissionSelector, isCaseAssignedToCurrentUserSelector],
  (hasPermission, isAssignedTo) => hasPermission || isAssignedTo
)

export const caseAutoReviewedSelector = createSelector(
  [coreCaseSelector],
  (coreCase) => !!coreCase.autoReviewed
)
