import {
  CUSTOMER_ORGANIZATION_FETCH_COMPLETE,
  SET_CUSTOMER_ORGANIZATION,
  UPDATE_CUSTOMER_ORGANIZATION_SETTINGS,
} from 'constants/actionTypes'

const initialState = {
  loaded: false,
}

export default function customerOrganization(state = initialState, action) {
  switch (action.type) {
    case SET_CUSTOMER_ORGANIZATION:
      return {
        ...state,
        loaded: true,
        org: org(state.org, action),
      }
    case CUSTOMER_ORGANIZATION_FETCH_COMPLETE:
      return {
        ...state,
        loaded: true,
      }
    case UPDATE_CUSTOMER_ORGANIZATION_SETTINGS:
      return {
        ...state,
        org: org(state.org, action),
      }
    default:
      return state
  }
}

const orgInitialState = {}

function org(state = orgInitialState, action) {
  switch (action.type) {
    case SET_CUSTOMER_ORGANIZATION:
      return action.payload
    case UPDATE_CUSTOMER_ORGANIZATION_SETTINGS:
      return {
        ...state,
        panel: panel(state.panel, action),
      }
    default:
      return state
  }
}

const panelInitialState = {}

function panel(state = panelInitialState, action) {
  switch (action.type) {
    case UPDATE_CUSTOMER_ORGANIZATION_SETTINGS:
      return {
        ...state,
        settings: action.payload,
      }
    default:
      return state
  }
}
