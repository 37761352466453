import {
  CLEAR_ORDER_REVIEW,
  CLEAR_ORDER_REVIEW_COMPS,
  CLEAR_ORDER_REVIEW_RULES,
  INITIALIZE_ORDER_REVIEW_FORM,
  INITIALIZE_ORDER_REVIEW_FORM_VALUES,
  SET_ORDER_REVIEW_FORM_VALUES_LOADING,
  SET_ORDER_REVIEW_SAVING,
  SET_ORDER_REVIEW_LOADING,
  SET_ORG_REVIEW_TEMPLATES,
  SET_CORE_CASE,
  SET_SELECTED_FORM_TEMPLATE_IDS,
  UPDATE_ORDER_REVIEW_FORM_SUBMISSION,
  ADD_RULE_TO_REVIEW,
  ADD_COMP_TO_REVIEW,
  REMOVE_RULE_FROM_REVIEW,
  REMOVE_COMP_FROM_REVIEW,
  UPDATE_ORDER_REVIEW_FORM_VALUES,
  TOGGLE_POPOUT,
} from 'constants/actionTypes'
import {ORDER_REVIEW_FORM_POPOUT} from 'constants/popouts'
import {Case as CaseModel} from 'models'
import {removeRuleOrCompFromValues, addRuleOrCompToValues} from 'services/orderReviewHelpers'

const initialState = {
  coreCase: null,
  documentSummaries: null,
  isLoading: false,
  isSaving: false,
  isOpen: false,
  selectedFormTemplateId: null,
  selectedFormTemplateUpdateId: null,
  orderReviewFormTemplates: [],
  orderReviewFormSubmission: {},
  orderReviewFormValues: {},
  orderReviewComps: [],
  orderReviewRules: [],
  orderReviewRuleBasedAnalyses: [],
  isOrderReviewFormValuesLoading: false,
  orderId: null,
}

export default function orderReview(state = initialState, action) {
  switch (action.type) {
    case CLEAR_ORDER_REVIEW: {
      return initialState
    }
    case INITIALIZE_ORDER_REVIEW_FORM: {
      const {
        coreCase,
        documentSummaries,
        orderReviewFormTemplates,
        orderReviewFormSubmission,
        orderReviewRuleBasedAnalyses,
        orderId,
        order,
      } = action.payload

      const selectedFormTemplateId = orderReviewFormSubmission?.metadata?.formId ?? null
      const selectedFormTemplateUpdateId = orderReviewFormSubmission?.metadata?.formUpdateId ?? null
      const orderReviewComps = orderReviewFormSubmission?.formSubmissions?.comps ?? []
      const orderReviewRules = orderReviewFormSubmission?.formSubmissions?.rules ?? []

      return {
        ...state,
        coreCase,
        documentSummaries,
        orderReviewFormTemplates,
        orderReviewFormSubmission,
        orderReviewComps,
        orderReviewRules,
        orderReviewRuleBasedAnalyses,
        selectedFormTemplateId,
        selectedFormTemplateUpdateId,
        isLoading: false,
        isSaving: false,
        isOrderReviewFormValuesLoading: true,
        orderId,
        order,
      }
    }
    case INITIALIZE_ORDER_REVIEW_FORM_VALUES: {
      return {
        ...state,
        orderReviewFormValues: action.payload,
      }
    }
    case SET_ORDER_REVIEW_FORM_VALUES_LOADING:
      return {
        ...state,
        isOrderReviewFormValuesLoading: action.payload,
      }
    case SET_ORG_REVIEW_TEMPLATES: {
      return {
        ...state,
        orderReviewFormTemplates: action.payload,
      }
    }
    case UPDATE_ORDER_REVIEW_FORM_VALUES: {
      return {
        ...state,
        orderReviewFormValues: action.payload,
      }
    }
    case UPDATE_ORDER_REVIEW_FORM_SUBMISSION: {
      return {
        ...state,
        orderReviewFormSubmission: action.payload,
      }
    }
    case SET_ORDER_REVIEW_LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      }
    }
    case SET_ORDER_REVIEW_SAVING: {
      return {
        ...state,
        isSaving: action.payload,
      }
    }
    case SET_SELECTED_FORM_TEMPLATE_IDS: {
      const nextState = {
        ...state,
        selectedFormTemplateId: action.payload.id,
        selectedFormTemplateUpdateId: action.payload.updateId,
      }

      return nextState
    }
    case CLEAR_ORDER_REVIEW_COMPS:
      return {
        ...state,
        orderReviewComps: initialState.orderReviewComps,
      }

    case CLEAR_ORDER_REVIEW_RULES:
      return {
        ...state,
        orderReviewRules: initialState.orderReviewRules,
      }
    case TOGGLE_POPOUT: {
      return action.payload.popout === ORDER_REVIEW_FORM_POPOUT
        ? {
            ...state,
            isOpen: action.payload.active,
          }
        : state
    }
    case SET_CORE_CASE: {
      const nextState = {
        ...state,
      }

      // only set the coreCase if there is one
      // (this avoids setting the case to an empty one when leaving the case page)
      if (action.payload) {
        nextState.coreCase = new CaseModel(action.payload)
      }

      return nextState
    }
    case ADD_RULE_TO_REVIEW: {
      return {
        ...state,
        orderReviewRules: addRuleOrCompToValues(state.orderReviewRules, action.payload),
      }
    }
    case REMOVE_RULE_FROM_REVIEW: {
      return {
        ...state,
        orderReviewRules: removeRuleOrCompFromValues(state.orderReviewRules, action.payload),
      }
    }
    case ADD_COMP_TO_REVIEW: {
      return {
        ...state,
        orderReviewComps: addRuleOrCompToValues(state.orderReviewComps, action.payload),
      }
    }
    case REMOVE_COMP_FROM_REVIEW: {
      let nextState = {
        ...state,
        orderReviewComps: removeRuleOrCompFromValues(state.orderReviewComps, action.payload),
      }

      // Check to see if there is a comment associated with the comp
      // if so, remove it
      const commentKey = `${action.payload.id}-comment`
      if (nextState.orderReviewFormValues[commentKey]) {
        const {[commentKey]: remove, ...rest} = nextState.orderReviewFormValues
        nextState = {
          ...nextState,
          orderReviewFormValues: rest,
        }
      }

      return nextState
    }
    default:
      return state
  }
}
