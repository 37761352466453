// support email
export const PLATFORM_SUPPORT_EMAIL = 'clientservices@clearcapital.com'

// 404
export const NOT_FOUND = 'The page you are trying to access does not exist'

// Null data for grids
export const NULL_DOCUMENTS = 'There are no document types available'

// Debug messages
export const DEBUG_PUBLIC_ROUTE = 'Session not required'
export const DEBUG_NO_SESSION = 'No session'

// Notification messages
export const ERROR = 'Error'
export const SUCCESS = 'Success'

// App actions
export const DATA_LOAD_FAIL = 'Failed to load required data'
export const DATA_LOAD_FAIL_TITLE = 'Data Error'
export const SESSION_EXPIRED = 'Your session has expired'
export const SESSION_EXPIRED_TITLE = 'Session Expired'
export const LOGGED_OUT_NOTIFICATION_TITLE = 'Logged Out'
export const LOGGED_OUT_NOTIFICATION_MESSAGE =
  'You have been automatically logged out due to inactivity.'
export const FETCH_ERROR_MESSAGE_PREFIX = 'Api Error'

// Case Status Metadata
export const CASE_POP_OVER_INFO = {
  label: 'More',
}

// Case Event actions
export const CASE_EVENT_ERROR = 'Could not get case events'

// Case Assignment Messages
export const USER_NOT_QUALIFIED_TO_REVIEW_CASE = 'User is not qualified to review this case'

// ClearProp messaging
export const CREATE_CLEAR_PROP_MSG_UNSUCCESSFUL =
  'A ClearProp cannot be generated for this address.'
export const CREATE_CLEAR_PROP_MSG_ERROR =
  'It looks like we were unable to generate this ClearProp report for you. Please contact ' +
  PLATFORM_SUPPORT_EMAIL +
  ' if the problem persists.'
export const CREATE_CLEAR_PROP_MSG_PROCESSING = 'Creating ClearProp'
export const CREATE_CLEAR_PROP_MSG_SUCCESS = 'ClearProp created successfully'
export const CREATE_CLEAR_PROP_TITLE_UNSUCCESSFUL = 'Request Completed'
export const CREATE_CLEAR_PROP_TITLE_ERROR = 'Request Failed'
export const CREATE_CLEAR_PROP_TITLE_PROCESSING = 'Processing Request'
export const CREATE_CLEAR_PROP_TITLE_SUCCESS = 'Request Processed'
export const ERROR_GET_CLEAR_PROP = 'Could not get ClearProp'
export const ERROR_GET_CLEAR_PROP_TOKEN = 'Could not get ClearProp token'

// Core Case Actions
export const NO_DATA_CORE_CASES = 'There is no data available for this case.'
export const NO_CORE_CASE_FOUND =
  "We couldn't find a case with this ID. Please verify the case number in the address bar or contact " +
  PLATFORM_SUPPORT_EMAIL +
  ' for assistance.'
export const ERROR_LOAD_CORE_CASE = 'Could not load case'
export const ERROR_UPDATE_CORE_CASE = 'Could not update case'
export const ERROR_GET_VALIDATION = 'Could not get validation'
export const ERROR_GET_RULE_BASED_ANALYSES = 'Could not get Rule Based Analyses'

// Case All Orders
export const EMPTY_CASE_ALL_ORDERS = 'No orders available for this case. Please reload this page.'

// Core Case On Hold
export const UPDATE_ON_HOLD_CASE_SUCCESS = 'Case Successfully put on hold.'
export const UPDATE_ON_HOLD_REMOVE_CASE_SUCCESS = 'Case Successfully removed from hold'
export const CASES_ON_HOLD_DISABLED_TOOLTIP =
  'Available only to the Assigned Reviewer or Review Manager'

// Review Dashboard
export const ERROR_GET_REVIEW_DASHBOARD = 'Could not get Review Dashboard Data'
export const READY_FOR_REVIEW_TOOLTIP =
  'Includes all cases In Review except cases on hold and cases which have open revision requests'
export const EMPTY_REVIEWERS_WITH_TIER_MESSAGE = 'No reviewers available in the selected tier'
export const EMPTY_REVIEWERS_ALL_TIER_MESSAGE = 'No reviewers available'
export const EMPTY_TIME_IN_REVIEW_WITH_TIER_MESSAGE =
  'No cases currently in review in the selected tier'
export const EMPTY_TIME_IN_REVIEW_ALL_TIER_MESSAGE = 'No cases currently in review'
export const NO_TIERS_MESSAGE = 'No tiers available'

// Document Actions
export const DOCUMENT_UPLOAD_FAILED =
  'We were unable to upload your document. Please contact ' +
  PLATFORM_SUPPORT_EMAIL +
  ' if the problem persists.'
export const DOCUMENT_UPLOAD_SUCCESS = 'Document successfully uploaded.'
// Document Orders
export const ERROR_GET_CASE_DOC_ORDERS =
  'Could not acquire document order events. Please reload this page.'

// Document Types Actions, ERROR_GET_DOCUMENT_TYPES repeated from Document Actions
export const ERROR_DOCUMENT_TYPE_DELETED = 'Did not delete'
export const ERROR_DOCUMENT_TYPE_NAME_CONFLICT = 'Name Conflict'
export const ERROR_DOCUMENT_TYPE_UPDATED =
  'We ran into a problem updating that document type for you. Please contact ' +
  PLATFORM_SUPPORT_EMAIL +
  ' if the problem persists.'
export const ERROR_GET_DOCUMENT_TYPES = 'Could not acquire document types. Please reload the page.'
export const SUCCESS_DOCUMENT_TYPE_CREATED = 'Document Type Created'
export const SUCCESS_DOCUMENT_TYPE_UPDATED = 'Document Type Created' // intentionally the same as created because I think maybe they should be different messages?

// Document Action Menu Actions
export const CANCEL_REVISION_REQUEST_SUCCESS = 'Revision request cancelled'
export const CANCEL_REVISION_REQUEST_FAIL =
  'We ran into a problem canceling that revision request for you. Please contact ' +
  PLATFORM_SUPPORT_EMAIL +
  ' if the problem persists.'
export const CANCEL_REVISION_REQUEST_FAIL_NOT_FOUND = 'Failed to find revision request'
export const DOCUMENT_VIEW_ACTIVITY = `View this document's activity details`
export const DOCUMENT_VIEW_FULL_DETAILS = `View this document's full-page view`
export const DOCUMENT_VIEW_UPLOADED_DOC = `View document`
export const DOCUMENT_DOWNLOAD_UPLOADED_DOC = `Download document`

// Case Note
export const SUCCESS_ADD_CASE_NOTE = 'Successfully added note to the case'

// Login messages
export const LOGIN_FAIL_TITLE = 'Failed Login'
export const LOGIN_FAIL_DEFAULT =
  "We couldn't find an account with that username and password combination. Reset your password or contact " +
  PLATFORM_SUPPORT_EMAIL +
  ' if the problem persists.'
export const LOGIN_FAIL_UNRECOGNIZED_CODE = 'Unknown Login Error'
export const LOGIN_FAIL_USER_OBJECT_ERROR =
  "There is an error with your user's configuration. Please contact support at " +
  PLATFORM_SUPPORT_EMAIL +
  '.'

// Next Case actions
export const NEXT_AVAILABLE_ASSIGNED = 'You have successfully been assigned to this case.'

// Order Events Communication
export const SUCCESS_RESOLVE_EVENT_TITLE = 'Order Events Communication'
export const SUCCESS_RESOLVE_EVENT = 'Successfully resolved event requiring action!'
export const SUCCESS_EVENT_MESSAGE = 'Successfully sent event message!'
export const ERROR_RESOLVE_EVENT = 'Unable to resolve event. Please reload this page.'
export const ERROR_EVENT_MISSING_NOTES_MESSAGE = 'Unable to send event message. Please add notes.'
export const NO_ACTIVITY_DATA = 'There is no activity data presently available.'

// Order Update Events
export const SUCCESS_ORDER_UPDATE = 'Successfully updated the order.'
export const ERROR_ORDER_UPDATE =
  "We're having trouble updating this order. Please contact " +
  PLATFORM_SUPPORT_EMAIL +
  ' if the problem persists.'

// Performance Allocation
export const NO_ALLOCATIONS = 'There is no allocation data for your selection.'

// Product actions
export const SET_PRODUCTS_FAILED = 'Could not get products.'
export const SET_PRODUCT_TYPES_FAILED = 'Could not get product types.'

// Product Orders
export const NO_ORDER_FOUND =
  "We couldn't find an order with this ID. Please verify the case number in the address bar or contact " +
  PLATFORM_SUPPORT_EMAIL +
  ' for assistance.'
export const SET_ORDER_ERROR = 'There was a problem loading order details.'
export const CREATE_CASE_FAILED_TITLE = 'Failed to Order Products'
export const CREATE_CASE_FAILED_MESSAGE = `Please try reordering, if the issue persists, contact ${PLATFORM_SUPPORT_EMAIL}`
export const CREATE_ORDER_FAILED_MESSAGE = `There was an error placing the order, if the issue persists, contact ${PLATFORM_SUPPORT_EMAIL}`
export const PRODUCT_ORDER_FORM_ORDER_ANOTHER_TOOLTIP =
  'Place consecutive orders for different properties without leaving this view.'
export const REOPEN_ORDER_CONFIRMATION_TITLE = 'Reopen Order'
export const REOPEN_ORDER_CONFIRMATION_MESSAGE =
  'Reopening the order will reopen the associated case (if closed). You will be required to complete and submit a review form in order to close the order again. Are you sure you want to continue?'
export const REOPEN_ORDER_ERROR_MESSAGE = `There was an error reopening the order, if the issue persists, contact ${PLATFORM_SUPPORT_EMAIL}.`
export const REOPEN_ORDER_SUCCESS_TITLE = 'Order Reopened'

// Product Order Message
export const CREATE_ORDER_MESSAGE_FAILED = 'There was a problem composing your message.'
export const ORDER_MESSAGE_MARK_AS_READ_FAILED = 'Could not mark the message as read.'

// Property characteristics
export const SET_PROPERTY_CHARACTERISTICS_FAIL = 'Unable to set property valuation overview.'

// Provider actions
export const GET_PROVIDER_FAIL = 'Could not get provider.'
export const GET_PROVIDER_PRODUCTS = "Could not get provider's products."
export const GET_PROVIDERS_FAILED = 'Could not get providers.'
export const PROVIDER_CREATE_FAILED = 'Could not create provider.'
export const PROVIDER_DELETE_FAILED = 'Could not delete provider.'
export const PROVIDER_DELETED = 'Provider deleted.'
export const PROVIDER_UPDATE_FAILED = 'Could not update provider.'
// Provider Product actions
export const ERROR_GET_PRODUCTS = 'Cannot get products.'

// Related Cases actions
export const ERROR_LOAD_PRIOR_VALUE_CONCLUSIONS = 'Could not load prior value conclusions'
export const NO_PRIOR_VALUE_CONCLUSIONS_MESSAGE = 'No past cases available'

// Reporting messages
export const NO_REPORTS = 'No reports are available.'
export const REPORT_UNAVAILABLE =
  'This report is not available. Please contact ' +
  PLATFORM_SUPPORT_EMAIL +
  ' if the problem persists.'
export const ERROR_LOADING_REPORT_LIST =
  'There was an error loading the report list. Please contact ' +
  PLATFORM_SUPPORT_EMAIL +
  ' if the problem persists.'
export const ERROR_CONNECTING_TO_REPORT_ENGINE =
  'There was an error connecting to the report engine. Please contact ' +
  PLATFORM_SUPPORT_EMAIL +
  ' if the problem persists.'
export const ERROR_USER_NOT_SETUP_IN_REPORT_ENGINE = 'Your account is not setup to view reports.'

// Servicer Actions Names- I wasn't sure if I should pull these names or not
export const NAME_SPEEDY_TITLE_AND_APPRAISAL_REVIEW_SERVICES =
  'Speedy Title & Appraisal Review Services.'
export const NAME_GUARANTEED_RATE = 'Guaranteed Rate.'
export const NAME_GOLDMAN_SACHS = 'Goldman Sachs.'
export const NAME_WELLS_FARGO = 'Wells Fargo.'

// Slideout messages
export const AUTO_COMPLETED_NO_REVIEW_FORM =
  "This case was auto-completed by the system and doesn't have a completed review."
export const CURRENTLY_ASSIGNED = 'This case has already been assigned to the individual below.'
export const COMPLETED_BY_BULK_ACTION = 'Case completed by bulk action'
export const NOT_CURRENTLY_ASSIGNED = 'To edit this review, you must be assigned to this case'
export const NOT_QUALIFIED_CASE = 'You are not qualified to edit this review'

// Supplemental Data Actions - only message is ERROR_GET_DOCUMENT_TYPES from Document Actions

// Universal/global search
export const APP_SEARCH_NO_RESULTS = 'No search results found.'

// User action & form messages
export const USER_EMAIL_IN_USE = 'That email address is already in use by another user.'
export const USER_UPDATED = 'User successfully updated.'
export const USER_UPDATE_FAILED = 'Could not update user.'
export const USER_NAME_CHANGE_FAILED = 'Could not change name.'
export const USER_CREATE_FAILED = 'Could not add user.'
export const USER_PASSWORD_RESET =
  'If an account is found, a security code will be sent to the email address provided. If you do not receive an email within 10 minutes, please contact support at 530.550.2524 or ' +
  PLATFORM_SUPPORT_EMAIL +
  '.'
export const USER_PASSWORD_RESET_FAILED = 'Unable to reset password.'
export const USER_PASSWORD_RESET_EXISTS = 'A password reset request already exists for this user.'
export const USER_PASSWORD_CHANGED = 'Successfully changed password.'
export const USER_PASSWORD_CHANGE_FAILED = 'Could not change password.'
export const USER_PASSWORD_CHANGE_NOMATCH = 'Passwords do not match.'
export const USER_PREVIOUS_PASSWORD = 'Password cannot be a previous password.'
export const USER_PASSCODE_EXPIRED =
  'Passcode expired or already used. Please contact your administrator to have a new passcode sent to you.'
export const USER_PARAMS_MISSING = 'Username or Password is missing'
export const USER_PASSWORD_CHANGE_MESSAGE =
  'Enter your email address below to receive a password reset link.'
export const USER_EMAIL_ADDRESS_INVALID = 'Please enter a valid email address.'
export const USER_NAME_CHANGED = 'Changed name to'
export const USER_CREATED =
  "will remain in Pending status until they've activated their account using the link sent to their email address."
export const USER_RESEND_ACTIVATION = 'Resend Activation Email'
export const USER_RESEND_ACTIVATION_SUCCESS = 'A new code has been sent. Please check your email.'
export const USER_RESEND_ACTIVATION_FAILED =
  'An unexpected error occurred while requesting a passcode.'
export const USER_SEND_PASSWORD_RESET_EMAIL = 'Send Password Reset Email'

// Working Document messages
export const CLEAR_ACTIVE_DOCUMENT_FAILED = 'We were unable to clear the working document.'
export const SET_ACTIVE_DOCUMENT_FAILED = 'We were unable to set the working document.'

// Error boundary messages
export const ERROR_DEFAULT_TITLE = 'Something went wrong.'
export const ERROR_DEFAULT_MESSAGE =
  "We're experiencing some issues at the moment. Please contact " +
  PLATFORM_SUPPORT_EMAIL +
  ' if the problem persists.'

// documents
export const UPLOAD_DOCUMENT_MODAL_SUBTITLE = 'Document will be uploaded to the case.'
export const LOAD_DOCUMENT_SUMMARIES_ERROR = 'Could not load document summaries'

// User Management
export const EDIT_DISABLED_USER_TOOLTIP = 'You do not have permission to edit Org Admin users'

// Tiers
export const CREATE_TIER_ERROR_MESSAGE = 'Unable to create new tier.'
export const EDIT_TIER_ERROR_MESSAGE = 'Unable to update tier.'
export const GET_TIERS_ERROR = 'There was an error loading tiers data.'
export const SET_TIERS_ERROR = 'There was an error saving tiers data.'
export const SET_TIERS_SUCCESS = 'Successfully saved tiers data.'

// Tier Qualifications
export const UPDATE_TIER_CASE_SUCCESS = 'Successfully updated tier.'
export const SUCCESS_UNASSIGNED_CASE = 'Unassigned Case.'

// Order Review
export const ORDER_REVIEW_SUBMITTED_TITLE_SUCCESS = 'Review Submitted'
export const ORDER_REVIEW_SUBMITTED_MESSAGE_SUCCESS =
  'A PDF version of your review is available in the Document Center'
export const ORDER_REVIEW_SUBMITTED_MESSAGE_ERROR =
  "We're having trouble submitting your order review. Please contact " +
  PLATFORM_SUPPORT_EMAIL +
  ' if the problem persists.'
export const ORDER_REVIEW_SUBMIT_REVISION_PENDING_MESSAGE =
  'Please wait for the revised report or cancel the pending revision request to complete this review.'
export const ORDER_REVIEW_SELECTION_MODAL_TITLE = 'Select your preferred method of review'
export const ORDER_REVIEW_SELECTION_MODAL_UNASSIGNED_TITLE =
  'To edit this review, you must be assigned to this case'
export const ORDER_REVIEW_SELECTION_MODAL_UNQUALIFIED_TITLE =
  'You are not qualified to edit this review'
export const ORDER_REVIEW_SUBMIT_ERROR_TOOLTIP =
  'Complete all required questions to submit the review'
export const ORDER_REVIEW_SUBMIT_REVISION_REQUEST_SUBMITTED_TOOLTIP =
  'Please wait for the revised report or cancel the open revision request to complete this review.'
export const ORDER_REVIEW_SUBMIT_REVISION_REQUEST_RESOLVABLE_TOOLTIP =
  'Please review and close the open revision request to complete this review.'

// Smart View
export const ORIGINAL_REPORT_VIEW_UNAVAILABLE =
  'View unavailable as the report does not contain a PDF.'
export const ORIGINAL_REPORT_VIEW_DESCRIPTION = 'Report submitted by the appraiser in PDF format.'
export const SMART_VIEW_UNAVAILABLE = 'View unavailable as the report does not contain any images.'
export const SMART_VIEW_DESCRIPTION = 'Includes subject characteristics, photos, map and sketch.'
export const SKETCH_PHOTOS_UNAVAILABLE = 'Sketch is unavailable'
export const COMP_PHOTOS_UNAVAILABLE = 'Comp photos are unavailable'
export const LOCATION_MAP_UNAVAILABLE = 'Location map is unavailable'
export const AERIAL_MAP_UNAVAILABLE = 'Aerial map is unavailable'
export const SUBJECT_PHOTOS_UNAVAILABLE = 'Subject photos are unavailable'

// ClearQC Rule Management
export const GET_RULE_SETS_ERROR =
  "We're having trouble loading ClearQC rule sets. Please contact " +
  PLATFORM_SUPPORT_EMAIL +
  ' if the problem persists.'
export const REORDER_RULE_SETS_ERROR =
  "We're having trouble reordering ClearQC rule sets. Please contact " +
  PLATFORM_SUPPORT_EMAIL +
  ' if the problem persists.'
export const REORDER_RULE_SETS_SUCCESS = 'Successfully reordered ClearQC rule sets.'
export const CREATE_RULE_SET_ERROR =
  "We're having trouble creating a ClearQC rule set. Please contact " +
  PLATFORM_SUPPORT_EMAIL +
  ' if the problem persists.'
export const CREATE_RULE_SET_SUCCESS = 'Successfully created ClearQC rule set.'
export const DELETE_RULE_SET_ERROR =
  "We're having trouble deleting this ClearQC rule set. Please contact " +
  PLATFORM_SUPPORT_EMAIL +
  ' if the problem persists.'
export const DELETE_RULE_SET_SUCCESS = 'Successfully deleted ClearQC rule set.'
export const UPDATE_RULE_SET_ERROR =
  "We're having trouble updating this ClearQC rule set. Please contact " +
  PLATFORM_SUPPORT_EMAIL +
  ' if the problem persists.'
export const UPDATE_RULE_SET_SUCCESS = 'Successfully updated ClearQC rule set.'
export const RULE_SET_INFO_MESSAGE =
  'Create rule sets with different rules, thresholds, and weights based on criteria like the investor, location, or appraised value. Because the rule sets are ordered, the first set of criteria that matches a specific report is the set of rules that will run and display for that file.'
export const RULE_SET_CRITERIA_EMPTY_MESSAGE =
  'This rule set has no limiting criteria; it will run on all reports which do not meet the criteria of another rule set.'
export const GET_RULE_LIBRARY_ERROR =
  "We're having trouble loading ClearQC rules. Please contact " +
  PLATFORM_SUPPORT_EMAIL +
  ' if the problem persists.'
export const APPLIED_RULES_EMPTY_STATE = 'This rule set has no rules applied'
export const APPLIED_RULES_EMPTY_STATE_ERROR_MESSAGE = 'At least one rule is required'
export const ADD_RULES_MODAL_EMPTY_STATE_TITLE = 'No Rules Available'
export const ADD_RULES_MODAL_EMPTY_STATE_MESSAGE =
  'All the rules have already been added to the rule set.'
export const DELETE_RULE_SET_CONFIRMATION_TITLE = 'Delete Rule Set'
export const DELETE_RULE_SET_CONFIRMATION_MESSAGE = 'Are you sure you want to delete this rule set?'
export const DELETE_RULES_MESSAGE =
  'Are you sure you want to delete the selected rules from the rule set?'
export const COMPLETE_CRITERIA_CONFIRMATION_MESSAGE =
  'Please finish editing the criteria you are currently working on before saving.'
export const COMPLETE_CRITERIA_CONFIRMATION_TITLE = 'Complete Criteria'
export const UNSAVED_CHANGES_TITLE = 'Unsaved Changes'
export const UNSAVED_CHANGES_MESSAGE =
  'All unsaved changes will be lost. Are you sure you want to continue?'
export const FAILURE_RULE_SETS_MESSAGE = 'Failed to Load Rule Sets'
export const CONFIRM_AUTO_APPROVE_TITLE = 'Auto-Approve Enabled'
export const CONFIRM_AUTO_APPROVE_MESSAGE =
  'This scope has auto-approve enabled. Are you sure you want to do this?'

// Rule Library
export const FAILURE_RULE_LIBRARY_MESSAGE =
  'There was a problem loading rule library. Please try refreshing the page.'

// Predicate criteria builder
export const DELETE_CRITERIA_CONFIRMATION_DIALOG_TITLE = 'Remove Criteria'
export const DELETE_CRITERIA_CONFIRMATION_DIALOG_MESSAGE =
  'Are you sure you want to remove this criteria?'
export const DELETE_SORT_CONFIRMATION_DIALOG_TITLE = 'Remove Sorting'
export const DELETE_SORT_CONFIRMATION_DIALOG_MESSAGE =
  'Are you sure you want to remove this sorting?'

// Review Queue
export const GET_NEXT_CASE_NO_CASE_AVAILABLE_TITLE = 'No Case Available'
export const GET_NEXT_CASE_NO_CASE_AVAILABLE_MESSAGE =
  'There are no new cases matching your qualifications.'
export const GET_NEXT_CASE_ERROR_NOTIFICATION =
  "We're having trouble getting the next case. Please contact " +
  PLATFORM_SUPPORT_EMAIL +
  ' if the problem persists.'
export const GET_NEXT_CASE_TOOLTIP_MESSAGE =
  'Gets the next highest priority case you qualify to review'
export const BULK_UPDATE_REVIEWER_NOT_QUALIFIED_MESSAGE =
  'Reviewer not qualified for one or more selected cases'
export const REVIEW_QUEUE_EMPTY_CASES_TITLE = 'No Cases Available'
export const REVIEW_QUEUE_EMPTY_CASES_MESSAGE =
  'There are currently no cases in the queue. Refresh the page or try again later.'
export const REVIEW_QUEUE_UNAUTHORIZED_TITLE = '403, Access Denied'
export const REVIEW_QUEUE_UNAUTHORIZED_MESSAGE =
  'Sorry, you don’t have permission to access this page.'
export const BULK_UPDATE_TIER_ERROR_NOTIFICATION =
  "We're having trouble updating the tier. Please contact " +
  PLATFORM_SUPPORT_EMAIL +
  ' if the problem persists.'
export const BULK_UPDATE_REVIEWER_ERROR_NOTIFICATION =
  "We're having trouble updating the reviewer. Please contact " +
  PLATFORM_SUPPORT_EMAIL +
  ' if the problem persists.'
export const BULK_CLOSE_CASE_ERROR_NOTIFICATION =
  "We're having trouble closing cases. Please contact " +
  PLATFORM_SUPPORT_EMAIL +
  ' if the problem persists.'
export const BULK_UPDATE_TIER_CONFIRMATION_MESSAGE =
  'This will unassign reviewers from any assigned cases. Are you sure you want to continue?'
export const BULK_UPDATE_TIER_CONFIRMATION_BUTTON_TEXT = 'Update Tier'
export const BULK_CLOSE_CASE_CONFIRMATION_MESSAGE =
  'This will close cases with orders in review as well. Are you sure you want to continue?'
export const BULK_CLOSE_CASE_CONFIRMATION_BUTTON_TEXT = 'Close Cases'
export const REVIEW_QUEUE_CASES_NOT_FOUND_TITLE = 'No Cases Found'
export const REVIEW_QUEUE_CASES_NOT_FOUND_MESSAGE =
  'Try adjusting your filters to find what you are looking for.'
export const BULK_ACTION_SUCCESS_MESSAGE =
  'It may take a few seconds for the queue to reflect the changes.'

// Review Scopes
export const REORDER_REVIEW_SCOPES_ERROR =
  "We're having trouble reordering the Review Scopes. Please contact " +
  PLATFORM_SUPPORT_EMAIL +
  ' if the problem persists.'
export const REORDER_REVIEW_SCOPES_SUCCESS = 'Successfully reordered the Review Scopes.'
export const CREATE_REVIEW_SCOPE_ERROR =
  "We're having trouble creating a Review Scope. Please contact " +
  PLATFORM_SUPPORT_EMAIL +
  ' if the problem persists.'
export const CREATE_REVIEW_SCOPE_SUCCESS = 'Successfully created Review Scope.'
export const DELETE_REVIEW_SCOPE_CONFIRMATION_TITLE = 'Delete Review Scope'
export const DELETE_REVIEW_SCOPE_CONFIRMATION_MESSAGE =
  'Are you sure you want to delete this Review Scope?'
export const DELETE_REVIEW_SCOPE_ERROR =
  "We're having trouble deleting this Review Scope. Please contact " +
  PLATFORM_SUPPORT_EMAIL +
  ' if the problem persists.'
export const DELETE_REVIEW_SCOPE_SUCCESS = 'Successfully deleted Review Scope.'
export const UPDATE_CASE_REVIEW_SCOPE_ERROR =
  "We're having trouble updating the case's Review Scope. Please contact " +
  PLATFORM_SUPPORT_EMAIL +
  ' if the problem persists.'
export const UPDATE_CASE_REVIEW_SCOPE_SUCCESS = "Successfully updated the case's Review Scope."
export const UPDATE_REVIEW_SCOPE_ERROR =
  "We're having trouble updating this Review Scope. Please contact " +
  PLATFORM_SUPPORT_EMAIL +
  ' if the problem persists.'
export const UPDATE_REVIEW_SCOPE_SUCCESS = 'Successfully updated Review Scope.'
export const REVIEW_SCOPES_SET_INFO_MESSAGE =
  'Create Review Scopes based on criteria like investor, location, or appraised value, and then select which tools, report views, and review forms are available to reviewers when that scope is applied.'
export const REVIEW_SCOPES_EMPTY_MESSAGE =
  'There was a problem loading the review scopes. Please try refreshing the page.'
export const REVIEW_SCOPES_CRITERIA_EMPTY_MESSAGE =
  'This review scope has no limiting criteria; it will run on all reports which do not meet the criteria of another review scope.'
export const NO_REVIEW_SCOPE_APPLIED = 'No review scope applied'

// Comparables Table
export const CLEAR_RANK_TOOLTIP =
  'ClearRank compares hundreds of sold and listing comparables, then intelligently selects and ranks them according to their similarity to the subject.'

// Report Summary
export const REPORT_SUMMARY_PROCESSING =
  "Your report summary will be available here when it's delivered"
export const REPORT_SUMMARY_NO_FEATURES =
  "The case's review scope does not contain any report views or review tools."

// ClearCollateral Scores
export const FAILURE_CLEAR_COLLATERAL_SCORES = 'ClearCollateral analysis failed to generate'

// UCDP/EAD Scores
export const FAILURE_UCDPEAD_SCORES = 'Failed to generate UCDP / EAD Results'

// Customer Support Email
export const CUSTOMER_SUPPORT_EMAIL_MESSAGE =
  'Please contact customer support at ' + PLATFORM_SUPPORT_EMAIL

// Revision Request
export const REVISION_REQUEST_EMPTY_STATE_TITLE = 'No Revision Items'
export const REVISION_REQUEST_EMPTY_STATE_MESSAGE =
  'Click on ‘Add Item’ at the top to add a new revision item'
export const REVISION_REQUEST_SUBMITTED_SUCCESS_TITLE = 'Revision Request Submitted'
export const REVISION_REQUEST_SUBMITTED_SUCCESS_MESSAGE =
  'The Revision Request has been successfully sent.'
export const REVISION_REQUEST_CLOSED_SUCCESS_TITLE = 'Revision Request Closed'
export const REVISION_REQUEST_CLOSED_SUCCESS_MESSAGE =
  'The Revision Request has been successfully closed.'
export const REVISION_REQUEST_CANCELED_SUCCESS_TITLE = 'Revision Request Canceled'
export const REVISION_REQUEST_CANCELED_SUCCESS_MESSAGE =
  'The Revision Request has been successfully canceled.'
export const REVISION_REQUEST_SUBMISSION_ERROR_MESSAGE =
  "We're having trouble updating that revision request. Please contact " +
  PLATFORM_SUPPORT_EMAIL +
  ' if the problem persists.'
export const CANCEL_REVISION_REQUEST_DIALOG_TITLE = 'Cancel Revision Request'

// common app messages
export const OPEN_IN_NEW_TAB = 'Open in New Tab'
export const ERROR_MESSAGE = 'This issue may be temporary, please go back or try again later.'

// Admin Environment tab
export const ENVIRONMENT_TAB_DESCRIPTION =
  "Utilize Clear Collateral's Valuation API and submit integration credentials to send and receive orders, enable scores and rule findings, and more."
export const DELETE_VALUATION_API_KEY_CONFIRMATION_MODAL_TITLE = 'Delete Valuation Key'
export const DELETE_VALUATION_API_KEY_CONFIRMATION_MODAL_CONTENT =
  'Deleting this key will break any integrations utilizing it and will need to be replaced with a new key. Are you sure you want to delete?'
export const DELETE_VALUATION_API_KEY_ERROR_MESSAGE =
  "We're having trouble removing the API key. Please contact " +
  PLATFORM_SUPPORT_EMAIL +
  ' if the problem persists.'
export const VALUATION_API_CARD_TITLE = 'Valuation API Keys'
export const VALUATION_API_CARD_MESSAGE =
  'Connects your account to external applications, allowing you to send and receive orders, status updates, and more.'
export const EMPTY_VALUATION_API_CARD_MESSAGE =
  'Connect your account to external applications to send and receive orders, status updates, and more.'
export const BUSINESS_UNIT_INTEGRATION_CREDENTIALS_TITLE = 'Business Unit Integration Credentials'
export const BUSINESS_UNIT_INTEGRATION_CREDENTIALS_MESSAGE =
  'Integrates your account with external services, enabling valuation scoring and rule findings in eligible reports.'
export const BUSINESS_UNIT_DEFAULT_MESSAGE =
  'The default business unit is assigned if a different unit is not selected while placing an order.'
export const BUSINESS_UNIT_DEFAULT_DISABLED_MESSAGE =
  'To set as default, enable the business unit first'
export const VALUATION_API_CREATE_KEY_TITLE = 'Create Valuation Key'
export const VALUATION_API_CREATE_KEY_MESSAGE = 'Provide a unique name to identify this key.'
export const VALUATION_API_KEY_CREATED_TITLE = 'Key Successfully Created'
export const VALUATION_API_KEY_CREATED_MESSAGE =
  'You will not be able to view this key again, be sure to copy it before closing this dialog.'
export const CREATE_VALUATION_API_KEY_ERROR_MESSAGE =
  "We're having trouble creating the API key. Please contact " +
  PLATFORM_SUPPORT_EMAIL +
  ' if the problem persists.'
export const GET_VALUATION_API_KEYS_ERROR_MESSAGE =
  "We're having trouble getting the API keys. Please contact " +
  PLATFORM_SUPPORT_EMAIL +
  ' if the problem persists.'

// Business Unit Integration Credentials
export const CREATE_BUSINESS_UNIT_SUCCESS_MESSAGE = 'Successfully created Business Unit'
export const UPDATE_BUSINESS_UNIT_SUCCESS_MESSAGE = 'Successfully updated Business Unit'
export const GET_BUSINESS_UNITS_ERROR_MESSAGE =
  "We're having trouble retrieving the Business Units. Please contact " +
  PLATFORM_SUPPORT_EMAIL +
  ' if the problem persists.'
export const BUSINESS_UNIT_CREATE_ERROR_MESSAGE =
  "We're having trouble creating a new Business Unit. Please contact " +
  PLATFORM_SUPPORT_EMAIL +
  ' if the problem persists.'
export const BUSINESS_UNIT_UPDATE_ERROR_MESSAGE =
  "We're having trouble updating this Business Unit. Please contact " +
  PLATFORM_SUPPORT_EMAIL +
  ' if the problem persists.'

// Document Center
export const EMPTY_DOCUMENTS_CENTER_MESSAGE = 'No documents available'
export const CASE_DOCUMENTS_ERROR_MESSAGE =
  "We're having trouble fetching case documents. Please contact " +
  PLATFORM_SUPPORT_EMAIL +
  ' if the problem persists.'
