import {RESET_TIERS, SET_TIERS} from 'constants/actionTypes'

const initialState = {
  isDataFetched: false,
  data: {},
}

export default function tiers(state = initialState, action) {
  switch (action.type) {
    case SET_TIERS:
      return {
        isDataFetched: true,
        data: {
          ...action.payload,
        },
      }
    case RESET_TIERS:
      return initialState
    default:
      return state
  }
}
