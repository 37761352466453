// Styles, theming, etc.
import './styles/app.scss'
import './styles/libs/material-design-icons/iconfont/material-icons.css'
import 'font-awesome/css/font-awesome.min.css'
import theme from '@clearcapital/reapop-theme-clear-capital'
import {MuiThemeProvider, muiThemeCurrent} from '@clearcapital/ui-resources'
import {createGenerateClassName, jssPreset, StylesProvider} from '@material-ui/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import {ThemeProvider as ScThemeProvider} from 'styled-components'

// Core
import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import {Provider} from 'react-redux'
import {Router, Switch, Route, Redirect} from 'react-router-dom'

// Components, views, etc.
import history from 'services/history'
import LoginView from './views/LoginView'
import ResetPassword from './views/Password/ResetPassword'
import PanelSignup from './views/PanelSignup'
import TermsOfService from './views/TermsOfService'
import ActivationForm from './components/ActivationForm'
import NotificationsSystem from 'reapop'
import RootRoute from 'views/RootRoute'
import {initializeMixpanel} from 'services/mixpanel'

// 3rd-party
import {create} from 'jss'
import MomentUtils from '@date-io/moment'
import {MuiPickersUtilsProvider} from '@material-ui/pickers'
import get from 'lodash/get'

const generateClassName = createGenerateClassName({
  productionPrefix: 'ccp',
  seed: 'ccp',
})

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById('ccp-insertion-point-jss'),
})

const ApplicationNode = ({store}) => {
  useEffect(() => {
    initializeMixpanel()
  }, [])
  return (
    <StylesProvider jss={jss} generateClassName={generateClassName}>
      <MuiThemeProvider theme={muiThemeCurrent}>
        <CssBaseline />
        <ScThemeProvider theme={muiThemeCurrent}>
          <Provider store={store}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <div className='app-node'>
                <NotificationsSystem theme={theme} />
                <Router history={history}>
                  <Switch>
                    <Route
                      path='/login'
                      render={({location}) => {
                        const state = store.getState()
                        const hasUser = Boolean(get(state, 'app.authentication.user'))
                        const hasLogoutRedirect = Boolean(get(location, 'state.logoutRedirect'))

                        return hasLogoutRedirect || !hasUser ? <LoginView /> : <Redirect to='/' />
                      }}
                    />
                    <Route path='/password/forgot' component={ResetPassword} />
                    <Route path='/password/reset' component={ActivationForm} />
                    <Route path='/account/activation' component={ActivationForm} />
                    <Route path='/panel/signup' component={PanelSignup} />
                    <Route path='/terms-of-service' component={TermsOfService} />
                    <Route path='/' component={RootRoute} />
                  </Switch>
                </Router>
              </div>
            </MuiPickersUtilsProvider>
          </Provider>
        </ScThemeProvider>
      </MuiThemeProvider>
    </StylesProvider>
  )
}

ApplicationNode.propTypes = {
  store: PropTypes.object.isRequired,
}

export default ApplicationNode
