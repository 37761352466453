import {sortByObjKey} from '@clearcapital/ui-resources/lib/services/sortUtils'
import {
  createAllFieldsWithValuesDictionary,
  createCollectionNameToMembersDictionary,
  createConditionalDependenciesDictionary,
} from 'services/caseReviewHelpers'

// 3rd-party
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import has from 'lodash/has'

/* complexConclusionState looks like this (only used for display):
  {
    adjustedValue,
    appraisedValue,
    selectedDocument,
    decisionType
  }

  The complexConclusion that we create (and that gets saved to the server when merged into the form fields) looks like this:
  [
    selectedDoc: value, * only for decisionType.value === 'Other'
    adjustedValue: value, * only for decisionType.value === 'Other'
    valueDecision: value,
    conclusion: value
  ]
*/
export function createComplexConclusionFromComplexConclusionState(complexConclusionState) {
  const {decisionType, selectedDocument, appraisedValue, adjustedValue} = complexConclusionState
  const updates = {
    selectedDoc: selectedDocument
      ? `${selectedDocument.documentId}-${selectedDocument.documentUpdateId}`
      : '',
  }

  if (decisionType && decisionType.value === 'Other') {
    updates.valueDecision = decisionType.value
    updates.conclusion = adjustedValue + ''
    updates.adjustedValue = adjustedValue + ''
  } else {
    updates.valueDecision = appraisedValue + ''
    updates.conclusion = appraisedValue + ''
  }

  return Object.keys(updates).map((key) => ({
    fieldName: key,
    value: updates[key],
  }))
}

export const createComplexConclusionInitialState = ({
  documentSummaries,
  formValues,
  formTemplate,
}) => {
  const complexConclusionTemplate = has(formTemplate, 'template.fields')
    ? formTemplate.template.fields.find((field) => field.type === 'complexConclusion')
    : null
  const selectedDocFormValue = formValues
    ? formValues.find((field) => field.fieldName === 'selectedDoc')
    : null
  let selectedDoc = null
  if (documentSummaries && documentSummaries.length && selectedDocFormValue) {
    const [documentId, documentUpdateId] = selectedDocFormValue.value.split('-')
    selectedDoc = documentSummaries.find(
      (ds) => ds.documentId === documentId && ds.documentUpdateId === documentUpdateId
    )
  }

  const decisionTypeFormValue = formValues
    ? formValues.find((field) => field.fieldName === 'valueDecision')
    : null
  let decisionType = null
  if (decisionTypeFormValue) {
    const valueDecision = complexConclusionTemplate
      ? complexConclusionTemplate.fields.find((field) => field.fieldName === 'valueDecision')
      : null
    if (valueDecision && valueDecision.options) {
      decisionType = valueDecision.options.find(
        (option) => option.value === decisionTypeFormValue.value
      )
    }
  }

  const adjustedValueField = formValues
    ? formValues.find((field) => field.fieldName === 'adjustedValue')
    : null
  const adjustedValue = adjustedValueField ? adjustedValueField.value : ''

  const nextState = {
    adjustedValue,
    appraisedValue: selectedDoc ? selectedDoc.value : '',
    selectedDocument: selectedDoc,
    decisionType,
  }

  return nextState
}

export const createInitialFormState = ({documentSummaries, formValues, formTemplate}) => {
  const nextComplexConclusionState = createComplexConclusionInitialState({
    documentSummaries,
    formValues,
    formTemplate,
  })
  const complexConclusion = createComplexConclusionFromComplexConclusionState(
    nextComplexConclusionState
  )
  const nextState = {
    complexConclusion,
    complexConclusionState: nextComplexConclusionState,
    formValues,
    formTemplate,
  }

  const conclusion = formValues
    ? formValues.find((field) => field.fieldName === 'conclusion')
    : null
  if (conclusion) {
    nextState.complexConclusion = [conclusion]
  }

  if (get(formTemplate, 'template.fields')) {
    // this changes the format of complexConclusion, so it should be used in the other functions below
    const preppedFields = getPreppedFields(formTemplate.template.fields)
    nextState.formFields = preppedFields
    nextState.collectionNameToMembersDictionary = createCollectionNameToMembersDictionary(
      preppedFields
    )
    nextState.fieldValuesDictionary = createAllFieldsWithValuesDictionary(preppedFields, formValues)
    nextState.conditionalDependenciesDictionary = createConditionalDependenciesDictionary(
      preppedFields
    )
  }

  return nextState
}

export function getPreppedFields(fields) {
  const preppedFields = cloneDeep(fields).sort(sortByObjKey('weight'))

  const complexConclusion = preppedFields.find((field) => {
    return field.type === 'complexConclusion'
  })

  if (complexConclusion) {
    complexConclusion.fieldName = 'complexConclusion'
    complexConclusion.isRequired = true
  }

  return preppedFields
}
