import {
  SET_DEFAULT_RULE_SET,
  SET_FINAL_FORM_INITIAL_VALUES_RULE_SET,
  SET_RULE_SETS,
  INITIALIZE_RULE_SET_DATA,
  SET_RULE_SETS_STATUS,
  RESET_RULE_SETS,
  SET_ACTIVE_RULE_SET,
  SET_MERGED_RULE_SET_RULES,
} from 'constants/actionTypes'
import {STATUS_INIT, STATUS_LOADED} from 'constants/loadingStatusConstants'

const initialState = {
  status: STATUS_INIT,
  ruleSets: [],
  defaultRuleSet: null,
  activeRuleSet: null,
  mergedRuleSetRules: [],
}

export default function ruleSets(state = initialState, action) {
  switch (action.type) {
    case INITIALIZE_RULE_SET_DATA: {
      const {ruleSets, defaultRuleSet} = action.payload

      return {
        status: STATUS_LOADED,
        ruleSets,
        defaultRuleSet,
      }
    }
    case SET_RULE_SETS: {
      return {
        ...state,
        ruleSets: action.payload,
      }
    }
    case SET_DEFAULT_RULE_SET: {
      return {
        ...state,
        defaultRuleSet: action.payload,
      }
    }
    case SET_RULE_SETS_STATUS: {
      return {
        ...state,
        status: action.payload,
      }
    }
    case RESET_RULE_SETS: {
      return initialState
    }
    case SET_ACTIVE_RULE_SET: {
      return {
        ...state,
        activeRuleSet: action.payload,
      }
    }
    case SET_MERGED_RULE_SET_RULES: {
      return {
        ...state,
        mergedRuleSetRules: action.payload,
      }
    }
    case SET_FINAL_FORM_INITIAL_VALUES_RULE_SET: {
      return {
        ...state,
        formInitialValues: action.payload,
      }
    }
    default:
      return state
  }
}
