export default class Person {
  constructor(props) {
    Object.keys(props).forEach((key) => {
      this[key] = props[key]
    })
  }
}

/*
https://auth-qa.clearcollateral.com/api-ui/#!/people/readPerson_get_1

auth Person {
  id (integer, optional),
  role (PersonRoleEnum, optional) = ['APPRAISER' or 'BORROWER' or 'LENDER' or 'OWNER' or 'REVIEW_APPRAISER' or 'USER_ADMIN'],
  firstName (string, optional),
  lastName (string, optional),
  displayName (string, optional),
  companyName (string, optional),
  initials (string, optional),
  email (string, optional),
  phone (string, optional),
  sourceId (integer, optional),
  sourceName (SourceNameEnum, optional) = ['APPRAISAL' or 'CCP' or 'CLEAR_RE' or 'COLLATERAL_ANALYTICS' or 'DIGITALMAP' or 'GOOGLE' or 'HVE' or 'MELISSADATA' or 'MLS' or 'PROPERTY_ACTIVITY_IMPORT' or 'RELAR' or 'UNKNOWN'],
  organizationKey (string, optional),
  updateId (integer, optional)
}
*/
