import {SET_ACTIVE_DOCUMENT, CLEAR_ACTIVE_DOCUMENT} from 'constants/actionTypes'

export const initialState = {}

export default function activeDocument(state = initialState, action) {
  switch (action.type) {
    case SET_ACTIVE_DOCUMENT:
      return action.payload
    case CLEAR_ACTIVE_DOCUMENT:
      return initialState
    default:
      return state
  }
}
