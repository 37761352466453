import {createSelector} from 'reselect'
import {tierDetailsSelector} from 'selectors/tierSelectors'
import {todayTileTypeToTitleMap} from 'constants/dashboardConstants'
import {mergeCasesByTierDataWithTiers} from 'services/dashboardHelpers'
import {loadingSelector} from 'selectors/appSelectors'
import {LOADING_REVIEW_DASHBOARD_DATA} from 'constants/actionTypes'

export const reviewDashboardDataSelector = (state) => state.reviewDashboard

export const app = (state) => state.app

export const reviewDashboardDataLoadingSelector = createSelector(
  (state) => loadingSelector(state),
  (appLoading) => appLoading.includes(LOADING_REVIEW_DASHBOARD_DATA)
)

export const casesByReviewerSelector = createSelector(
  [reviewDashboardDataSelector],
  (dashboardData) => dashboardData?.casesByReviewer ?? []
)

export const casesByTierSelector = createSelector(
  [reviewDashboardDataSelector],
  (dashboardData) => dashboardData?.casesByTier ?? []
)

export const casesByDurationSelector = createSelector(
  [reviewDashboardDataSelector],
  (dashboardData) => dashboardData?.casesByDuration ?? []
)

export const casesByTierGridRowDataSelector = createSelector(
  [(state) => tierDetailsSelector(state), (state) => casesByTierSelector(state)],
  (tiers, data) => mergeCasesByTierDataWithTiers(tiers, data)
)

export const caseTodayTileDataSelector = createSelector([reviewDashboardDataSelector], (today) => {
  return Object.keys(todayTileTypeToTitleMap).map((key) => ({
    title: todayTileTypeToTitleMap[key],
    total: today[key],
  }))
})

export const reviewDashboardTierComparisonChartDataSelector = createSelector(
  [casesByTierGridRowDataSelector],
  (casesByTierGridRowData = []) => {
    return casesByTierGridRowData.reduce((accumulator, current) => {
      const {casesAssigned = 0, casesUnassigned = 0, enabled, sequence, tierName} = current
      const sharedData = {
        tierRank: sequence ?? '-',
        yScaleDomain: tierName,
        yScaleDomainTotal: casesUnassigned + casesAssigned,
        enabled,
      }
      const dataArray = [
        {
          ...sharedData,
          adder: casesUnassigned,
          count: casesAssigned,
          xScaleDomain: 'ASSIGNED',
        },
        {
          ...sharedData,
          adder: 0,
          count: casesUnassigned,
          xScaleDomain: 'UNASSIGNED',
        },
      ]
      return [...accumulator, ...dataArray]
    }, [])
  }
)
