import {RESET_CORE_CASE, SET_CORE_CASE} from 'constants/actionTypes'
import {Case as CaseModel} from 'models'
export const initialState = null

export default function coreCase(state = initialState, action) {
  switch (action.type) {
    case RESET_CORE_CASE:
      return initialState
    case SET_CORE_CASE:
      return new CaseModel(action.payload)
    default:
      return state
  }
}
