import {CASE_ORDERS_PROVIDER_INFO} from 'constants/actionTypes'

export const initialState = {
  loaded: false,
  data: [],
}

export default function caseOrdersProviderInfo(state = initialState, action) {
  switch (action.type) {
    case CASE_ORDERS_PROVIDER_INFO:
      return {
        loaded: true,
        data: action.payload.providersInfo.map((providerInfo, i) => ({
          ...providerInfo,
          orderId: action.payload.requestedOrders[i],
        })),
      }
    default:
      return state
  }
}
