import mixpanel from 'mixpanel-browser'
import {orderReviewIsOpenSelector} from 'selectors'
import {getToken} from 'services/getMixpanelToken'
import {caseMappedTierSelector, caseIdSelector} from 'selectors/coreCaseSelectors'
import {orderReviewOrderIdSelector} from 'selectors/orderReviewSelectors'
import {clearPropSelector} from 'selectors/clearPropSelectors'
import store from 'services/store'

const mixpanelToken = getToken()

export const initializeMixpanel = () => {
  mixpanel.init(mixpanelToken, {
    // Once Mixpanel is initialized, then we identify the user based on their userId.
    // We also set their userId and customerId in Mixpanel
  })
}

export const identifyMixpanelUser = (id, organizationKey, role, companyName) => {
  mixpanel.identify(id)
  mixpanel.people.set_once({
    'First Mixpanel Event': new Date(),
    'User Id': id,
    Role: role,
    'Organization Key': organizationKey,
    'Company Name': companyName,
  })
}

export const trackMixpanelEvent = (eventType, eventData) => {
  mixpanel.track(eventType, eventData)
}

export const createRevisionRequestPayload = ({
  closedRevisions,
  caseId,
  orderId,
  documentUpdateId,
}) => {
  const categoryNames = closedRevisions.reduce((acc, currentRevision) => {
    return acc.concat(currentRevision.revisionRequestItems.map((item) => item.categoryName))
  }, [])

  return {
    caseId,
    orderId,
    documentUpdateId,
    'revisionRequest.categoryNames': categoryNames,
    'revisionRequest.count': closedRevisions.length,
    'revisionRequest.data': closedRevisions.map((revision) => {
      const {
        caseId,
        documentId,
        documentUpdateId,
        revisionRequestItems,
        submittedDate,
        updatedDate,
      } = revision

      return {
        caseId,
        documentId,
        documentUpdateId,
        revisionRequestItems,
        submittedDate,
        updatedDate,
      }
    }),
  }
}

export const createValidationDataLoadedPayload = ({
  caseId,
  orderId,
  documentUpdateId,
  ruleResults,
}) => {
  return {
    caseId,
    orderId,
    documentUpdateId,
    ...ruleResults.reduce((acc, currentRuleResultObj) => {
      const {result, rule: {name} = {}} = currentRuleResultObj
      acc[name] = result

      return acc
    }, {}),
  }
}

export const createUserAssignPayload = ({component, eventPayload}) => {
  let assignedCaseCount = 0
  let reassignedCaseCount = 0
  switch (component) {
    case 'Bulk Action':
      const {selectedIds, reviewQueueDisplayedCases} = eventPayload
      selectedIds.forEach((id) => {
        const selectedCase = reviewQueueDisplayedCases.find(
          (reviewQueueCase) => reviewQueueCase.id === id
        )
        selectedCase.assignedToName ? reassignedCaseCount++ : assignedCaseCount++
      })
      break
    case 'Get Next Case Button':
      assignedCaseCount++
      break
    case 'Self Assign Button':
    case 'Case Header':
      const {isReassignment} = eventPayload
      isReassignment ? reassignedCaseCount++ : assignedCaseCount++
      break
  }
  return {
    assignedCaseCount,
    reassignedCaseCount,
    component,
  }
}

export const createAddUserPayload = ({userObj, tierQualificationOptions}) => {
  const {
    roles,
    qualificationsPredicate: {qualifiedTierIds},
  } = userObj
  const tiers = qualifiedTierIds.map((tierId) => {
    return tierQualificationOptions.find(({value}) => value === tierId).label
  })

  return {
    userRole: roles,
    tier: tiers,
  }
}

export const createInteractWithPhotosPayload = (action) => {
  const state = store.getState()
  const caseId = caseIdSelector(state)
  const mappedTier = caseMappedTierSelector(state)
  const {label: tier} = mappedTier
  return {action, caseId, tier}
}

export const createClickViewsPayload = (component) => {
  const state = store.getState()
  const mappedTier = caseMappedTierSelector(state)
  const caseId = caseIdSelector(state)
  const orderId = orderReviewOrderIdSelector(state)
  const {label: tier} = mappedTier
  return {caseId, orderId, tier, component}
}

export const createReopenOrderPayload = (orderId) => {
  const state = store.getState()
  const mappedTier = caseMappedTierSelector(state)
  const caseId = caseIdSelector(state)
  const {label: tier} = mappedTier
  return {caseId, orderId, tier}
}

export const createAccessClearPropPayload = ({component, action}) => {
  const state = store.getState()
  const mappedTier = caseMappedTierSelector(state)
  const caseId = caseIdSelector(state)
  const {reportId: orderId} = clearPropSelector(state)
  const {label: tier} = mappedTier
  return {
    component,
    action,
    caseId,
    orderId,
    tier,
  }
}

export const createViewSummaryPayload = (component) => {
  const state = store.getState()
  const isReviewFormOpen = orderReviewIsOpenSelector(state)
  const caseId = caseIdSelector(state)
  const orderId = orderReviewOrderIdSelector(state)
  const mappedTier = caseMappedTierSelector(state)
  const {label: tier} = mappedTier
  return {
    isReviewFormOpen,
    component,
    orderId,
    caseId,
    tier,
  }
}

export const createFullReviewPayload = (activeReviewScope) => {
  const state = store.getState()
  const mappedTier = caseMappedTierSelector(state)
  const caseId = caseIdSelector(state)
  const {label: tier} = mappedTier
  return {
    caseId,
    tier,
    activeReviewScope,
  }
}
