import {SET_CASE_DOC_ORDERS} from 'constants/actionTypes'

export const initialState = {}

export default function caseDocumentsOrders(state = initialState, action) {
  switch (action.type) {
    case SET_CASE_DOC_ORDERS:
      return action.payload
    default:
      return state
  }
}
