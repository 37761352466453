import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {ErrorPage} from './ErrorPage'

export const errorBoundary = (customError) => (DecoratedComponent) => {
  class ErrorBoundary extends Component {
    state = {
      hasError: false,
      error: {},
    }

    componentDidCatch(error) {
      // we want to ensure the error gets logged (like in the case of maximum update depth where
      // the error gets swallowed and never logged)
      console.error(error)
      this.setState({hasError: true, error})
    }

    render() {
      const {hasError, error} = this.state

      if (hasError) {
        return <ErrorPage error={error} customError={customError} />
      } else {
        return <DecoratedComponent {...this.props} />
      }
    }
  }

  return ErrorBoundary
}

errorBoundary.propTypes = {
  customError: PropTypes.shape({
    title: PropTypes.string,
    message: PropTypes.string,
    showDetails: PropTypes.bool,
    allowRefresh: PropTypes.bool,
  }),
}
