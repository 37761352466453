import get from 'lodash/get'
import moment from 'moment'

export function clearQcRuleResultsByCategory(resultA, resultB) {
  const categoryA = resultA?.categoryDescription?.toUpperCase()
  const categoryB = resultB?.categoryDescription?.toUpperCase()

  return compare(categoryA, categoryB)
}

export function clearQcRuleResultsByWeight(resultA, resultB) {
  const weightA = resultA.weight ? +resultA.weight : -1
  const weightB = resultB.weight ? +resultB.weight : -1

  return compare(weightA, weightB, 'DESCENDING')
}

export function clearQcRuleResultsByWeightThenCategory(resultA, resultB) {
  const sortByWeightResult = clearQcRuleResultsByWeight(resultA, resultB)

  return sortByWeightResult !== 0
    ? sortByWeightResult
    : clearQcRuleResultsByCategory(resultA, resultB)
}

export function relatedCasesByConclusionDate(
  relatedCaseA,
  relatedCaseB,
  sortDirection = 'DESCENDING'
) {
  const dateA = relatedCaseA.conclusionDate
  const dateB = relatedCaseB.conclusionDate

  return compare(dateA, dateB, sortDirection)
}

// We expect the dates (item.date) to be in the format of 'Sep 4, 1986' - small in the services/datetime.js file
export function itemsBySmallDateString(a, b, sortDirection = 'DESCENDING') {
  const dateA = moment(a.date, 'MMM D, YYYY')
  const dateB = moment(b.date, 'MMM D, YYYY')
  return compare(dateA, dateB, sortDirection)
}

export function compare(a, b, sortDirection = 'ASCENDING') {
  if (sortDirection === 'ASCENDING') {
    return a < b ? -1 : a > b ? 1 : 0
  } else {
    return a > b ? -1 : a < b ? 1 : 0
  }
}

// large ints are sometimes stored in string form if they are too large to preserve
// precision in a JavaScript Number.  This is a simple Compare function, useful
// for sorting, which returns 'true' if string a repesents a number >= b and false
// otherwise.  Presently it works on ints as long as about 25 digits.
// DANGER DANGER DANGER
// as written this service assumes that both strings have the same number of chars (digits).
export function compareLargeIntStrings(a, b, sortDirection = 'ASCENDING') {
  let result
  const firstInts = ~~a.slice(0, 10) - ~~b.slice(0, 10)

  if (firstInts < 0) {
    result = -1
  } else if (firstInts > 0) {
    result = 1
  } else {
    // firstInts === 0 or any other unexpected condition
    const secondInts = ~~a.slice(10) - ~~b.slice(10)

    if (secondInts < 0) {
      result = -1
    } else if (secondInts > 0) {
      result = 1
    } else {
      // secondInts === 0 or any other unexpected condition
      result = 0
    }
  }

  if (sortDirection === 'DESCENDING' && result !== 0) {
    result = result * -1
  }

  return result
}

export function headersByIdAsStrings(headerA, headerB, sortDirection) {
  const varA = headerA.id.toUpperCase()
  const varB = headerB.id.toUpperCase()
  return compareLargeIntStrings(varA, varB, sortDirection)
}

export function objectByName(objA, objB) {
  const varA = objA.name.toUpperCase()
  const varB = objB.name.toUpperCase()

  return compare(varA, varB)
}

export function personByDisplayName(personA, personB) {
  const varA = personA.person.displayName.toUpperCase()
  const varB = personB.person.displayName.toUpperCase()
  return compare(varA, varB)
}

export function selectOptionsByLabel(optionA, optionB, sortDirection) {
  const varA = optionA.label.toUpperCase()
  const varB = optionB.label.toUpperCase()
  return compare(varA, varB, sortDirection)
}

export function ucdpEadRuleResultsByKey(resultA, resultB) {
  const keyA = resultA.rule.key.toUpperCase()
  const keyB = resultB.rule.key.toUpperCase()

  return compare(keyA, keyB)
}

export function ucdpEadRuleResultsBySeverity(resultA, resultB) {
  const severityMap = {
    fatal: 3,
    warning: 2,
    overrideable: 1,
  }

  const severityA = resultA.severity ? severityMap[resultA.severity.toLowerCase()] : 0
  const severityB = resultB.severity ? severityMap[resultB.severity.toLowerCase()] : 0

  return compare(severityA, severityB, 'DESCENDING')
}

export function ucdpEadRuleResultsBySeverityThenKey(resultA, resultB) {
  const sortBySeverityResult = ucdpEadRuleResultsBySeverity(resultA, resultB)

  return sortBySeverityResult !== 0
    ? sortBySeverityResult
    : ucdpEadRuleResultsByKey(resultA, resultB)
}

export const sortByDate = function (dir = '') {
  return function (a, b) {
    return dir === 'asc' ? a - b : b - a
  }
}

export const sortBystring = function (a = '', b = '') {
  const textA = a.toLowerCase()
  const textB = b.toLowerCase()

  return textA < textB ? -1 : textA > textB ? 1 : 0
}

export const sortByOrder = function (order) {
  const flippedArr = order.map((i) => i.toLowerCase()).reverse() // We flip this to make the first item has the highest index and thus gets sorted first

  // We end up just sorting on the index of the item in the array
  return function (a, b) {
    const aVal = flippedArr.indexOf(a.toLowerCase())
    const bVal = flippedArr.indexOf(b.toLowerCase())

    return aVal > bVal ? -1 : aVal < bVal ? 1 : 0
  }
}

export function sortProductsByCode(productA, productB, sortDirection) {
  const codeA = get(productA, 'product.code', 0)
  const codeB = get(productB, 'product.code', 0)

  return compare(Number(codeA), Number(codeB), sortDirection)
}

export const localCompareSortByKey = (data, key) =>
  data.sort((a, b) => (a?.[key] ?? '').localeCompare(b?.[key] ?? ''))

export const activityLogSortHandler = (header = {}, sortDirection = 'DESCENDING') => {
  let sortFunction

  switch (header.name) {
    case 'Date & Time':
    default:
      sortFunction = (headerA, headerB) => {
        return headersByIdAsStrings(headerA, headerB, sortDirection)
      }
  }

  return sortFunction
}
