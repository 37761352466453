import {
  RESET_CLEAR_PROP,
  SET_CLEAR_PROP_TOKEN,
  SET_CLEAR_PROP_TOKEN_ERROR,
  SET_CLEAR_PROP_TOKEN_LOADING,
} from 'constants/actionTypes'
import {ERROR, LOADED, LOADING, UNSET} from 'constants/reduxStatuses'

const initialState = {
  status: UNSET,
  data: {
    token: '',
  },
  error: null,
}

export default function clearPropToken(state = initialState, action) {
  switch (action.type) {
    case RESET_CLEAR_PROP:
      return initialState
    case SET_CLEAR_PROP_TOKEN:
      return {
        ...state,
        status: LOADED,
        data: {
          ...state.data,
          token: action.payload,
        },
      }
    case SET_CLEAR_PROP_TOKEN_ERROR:
      return {
        ...initialState,
        status: ERROR,
        error: action.payload,
      }
    case SET_CLEAR_PROP_TOKEN_LOADING:
      return {
        ...initialState,
        status: LOADING,
      }
    default:
      return state
  }
}
