// Styles
import styled from 'styled-components'

// Core
import React, {Component} from 'react'

// Components, actions, models, etc.
import Login from 'components/Login'
import Footer from 'components/Footer'

export default class LoginView extends Component {
  render() {
    return (
      <LoginView.Styled className='login-view-wrapper'>
        <Login sqaPrefix='LoginView' />
        <Footer />
      </LoginView.Styled>
    )
  }

  static Styled = styled.div`
    height: 100%;
    width: 100%;
  `
}
