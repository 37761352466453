// Styles
import styled from 'styled-components'

// Core
import React, {Component} from 'react'
import PropTypes from 'prop-types'

// Components, actions, services
import ccHorizontalGreyLogo from './assets/CC_Horizontal_Grey_Logo.svg'
import {theme, Icon} from '@clearcapital/ui-resources'
import {QA_NO_PREFIX} from 'constants/app'

const StyledLoginFooter = styled.footer`
  bottom: 30px;
  display: flex;
  left: 0;
  position: fixed;
  width: 100vw;
  z-index: 10;

  .logo__container {
    min-width: 375px;
    text-align: center;
    width: 30vw;

    .cc-logo {
      height: 20px;
      opacity: 0.5;
      width: 102px;
    }
  }

  .more-products__container {
    width: 70vw;

    .more-products-by-cle {
      align-items: center;
      color: ${theme.palette.text.primary};
      display: flex;
      font-size: 1rem;
      justify-content: flex-end;
      margin-right: 48px;
      opacity: 0.5;

      &:hover {
        opacity: 0.8;
      }
    }
  }
`

export default class Footer extends Component {
  static propTypes = {
    sqaPrefix: PropTypes.string,
  }

  static defaultProps = {
    sqaPrefix: QA_NO_PREFIX,
  }

  render() {
    return (
      <StyledLoginFooter className='login-footer'>
        <div className='logo__container'>
          <img src={ccHorizontalGreyLogo} data-sqa-id='CCLogoFooter' className='cc-logo' />
        </div>
        <div className='more-products__container'>
          <a
            href='https://www.clearcapital.com/'
            className='more-products-by-cle'
            data-sqa-id={this.props.sqaPrefix + 'LoginProducts'}
          >
            More products by Clear Capital
            <Icon className='stroke-1' icon='navigate_next' />
          </a>
        </div>
      </StyledLoginFooter>
    )
  }
}
