/*
 * Converts a camel case string to a readable string with spaces
 * loanNumber: loan Number
 */
import {NO_DATA} from 'constants/app'

export function camelCaseToSpaces(string) {
  return string.replace(/([a-z])([A-Z])/g, '$1 $2')
}

/*
 * Enum's are snake case, all upper-case strings
 * This function will remove the underscores and title case the string
 */
export function enumToTitlecase(string) {
  if (string && string.length) {
    return string
      .trim() // remove any extra whitespace at the beginning or end of the string
      .replace(/_/g, ' ')
      .replace(/ {2,}/g, ' ')
      .split(' ')
      .map((w) => w[0].toUpperCase() + w.substr(1).toLowerCase())
      .join(' ')
  } else {
    return NO_DATA
  }
}

/**
 * Convert a string to Pascal (Camel) Case (removing non alphabetic characters).
 *
 * @example
 * 'hello_world' will return `HelloWorld`
 * 'fOO BAR' will return `FooBar`
 *
 * @returns {string}
 *   The Pascal Cased string.
 *
 */
export function stringToPascalCase(string = '') {
  return string
    .match(/[a-z]+/gi)
    .map((word) => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase())
    .join('')
}

export const keepAlphanumericDashPeriodCommaCharactersOnly = (string) =>
  string.replace(/[^-a-zA-Z0-9,. ]/g, '')

export const trimAndRemoveExtraSpacesBetweenWords = (string) => string.replace(/\s+/g, ' ').trim()

// this keeps the following characters ',', '.', '-'
export const removeCharactersSpacesTrimAndLowercase = (string) =>
  keepAlphanumericDashPeriodCommaCharactersOnly(
    trimAndRemoveExtraSpacesBetweenWords(string)
  ).toLowerCase()
