import {
  CASE_REVIEW_STATUS,
  CLEAR_CASE_REVIEW,
  INITIALIZE_CASE_REVIEW_FORM,
  CASE_REVIEW_FORM_LOADING,
  RESET_CASE_REVIEW_FORM,
  SAVE_CASE_REVIEW_PROGRESS_START,
  SAVE_CASE_REVIEW_PROGRESS_END,
  SET_CORE_CASE,
  SET_CASE_REVIEW_VALUES,
  UPDATE_CASE_COMPLEX_CONCLUSION,
  UPDATE_CASE_REVIEW_FORM_FIELD,
  UPDATE_CASE_REVIEW_FORM_FIELD_VALUE,
  CASE_REOPENED,
} from 'constants/actionTypes'
import {Case as CaseModel} from 'models'
import {createAllFieldsWithValuesDictionary} from 'services/caseReviewHelpers'
import {
  createComplexConclusionFromComplexConclusionState,
  createInitialFormState,
} from 'services/caseReviewReducerHelpers'

export const initialState = {
  coreCase: null,
  documentSummaries: null,

  collectionNameToMembersDictionary: {},
  complexConclusion: null, // used to send back to server
  complexConclusionState: null, // used as the display
  conditionalDependenciesDictionary: {},
  initialFormValues: [],
  isLoading: true,
  isSaving: false,
  reviewComplete: false,
  formTemplate: {}, // This is the template object that comes from core
  formValues: [], // fields returned from core
  fieldValuesDictionary: {},
  formFields: [], // a sorted version of formTemplate.template.fields
}

export default function caseReview(state = initialState, action) {
  switch (action.type) {
    case CASE_REOPENED: {
      /*
        We need to reset the case review form state because
        the form uses redux to store/transform the form values
        which causes an error if you immediately open the case
        review form 
      */
      const {documentSummaries, formValues, formTemplate} = state
      return {
        ...state,
        reviewComplete: false,
        ...createInitialFormState({documentSummaries, formValues, formTemplate}),
      }
    }
    case CASE_REVIEW_STATUS:
      return {
        ...state,
        reviewComplete: action.payload.status === 'COMPLETED',
      }
    case CLEAR_CASE_REVIEW:
      return {...initialState}
    case INITIALIZE_CASE_REVIEW_FORM: {
      const {coreCase, documentSummaries, formValues, formTemplate, reviewStatus} = action.payload
      const nextState = {
        ...initialState,
        initialFormValues: formValues,
        isLoading: false,
        coreCase: new CaseModel(coreCase),
        documentSummaries,
        reviewComplete: reviewStatus === 'COMPLETED',
        ...createInitialFormState({documentSummaries, formValues, formTemplate}),
      }

      return nextState
    }
    case CASE_REVIEW_FORM_LOADING:
      return {
        ...state,
        isLoading: true,
      }
    case RESET_CASE_REVIEW_FORM:
      return {
        ...state,
        ...createInitialFormState({
          documentSummaries: state.documentSummaries,
          formValues: state.initialFormValues,
          formTemplate: state.formTemplate,
        }),
      }
    case SAVE_CASE_REVIEW_PROGRESS_START:
      return {
        ...state,
        isSaving: true,
      }
    case SAVE_CASE_REVIEW_PROGRESS_END: {
      const nextState = {
        ...state,
        isSaving: false,
      }

      // If there was an error, action.payload will be undefined. Otherwise action.payload is
      // a small array that contains the lastUpdateDate and lastUpdateUserId
      if (action.payload && action.payload.length) {
        // it's possible that the form hasn't been started yet, so state.formValues is undefined
        // in that case we just want to use the action.payload array
        nextState.formValues = state.formValues
          ? state.formValues.map((field) => {
              const updatedValue = action.payload.find((f) => f.fieldName === field.fieldName)
              return updatedValue || field
            })
          : action.payload
      }

      return nextState
    }
    case SET_CORE_CASE: {
      const nextState = {
        ...state,
      }

      // only set the coreCase if there is one (this avoids setting the case to an empty one when leaving the case page)
      if (action.payload) {
        nextState.coreCase = new CaseModel(action.payload)
      }

      return nextState
    }
    case SET_CASE_REVIEW_VALUES: {
      const nextState = {
        ...state,
        formValues: [...action.payload],
      }

      if (state.formTemplate.template && state.formTemplate.template.fields) {
        nextState.fieldValuesDictionary = createAllFieldsWithValuesDictionary(
          state.formTemplate.template.fields,
          action.payload
        )
      }

      return nextState
    }
    case UPDATE_CASE_COMPLEX_CONCLUSION: {
      const {complexConclusion, complexConclusionState} = action.payload
      const nextState = {
        ...state,
      }

      if (complexConclusionState) {
        nextState.complexConclusionState = complexConclusionState
      }

      // In the case that we're sending our own complexConclusion use it, otherwise derive it from the complexConclusionState.
      // We send our own complexConclusion value when the case has no documents, but we still want to include a conclusion value.
      // See how the ReviewSlideout handles the rendering of the complexConclusion with a documentSummaries length of 0
      // to see where this is possible.
      if (complexConclusion) {
        nextState.complexConclusion = complexConclusion
      } else if (complexConclusionState) {
        nextState.complexConclusion = createComplexConclusionFromComplexConclusionState(
          complexConclusionState
        )
      }

      return nextState
    }
    // replace the whole field
    case UPDATE_CASE_REVIEW_FORM_FIELD: {
      const {name, field} = action.payload
      return {
        ...state,
        fieldValuesDictionary: {
          ...state.fieldValuesDictionary,
          [name]: field,
        },
      }
    }
    // update the field's value
    case UPDATE_CASE_REVIEW_FORM_FIELD_VALUE: {
      const {name, value} = action.payload
      return {
        ...state,
        fieldValuesDictionary: {
          ...state.fieldValuesDictionary,
          [name]: {
            ...state.fieldValuesDictionary[name],
            value,
          },
        },
      }
    }
    default:
      return state
  }
}
