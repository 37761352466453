import {createSelector} from 'reselect'
import {tierDictionarySelector} from 'selectors/tierSelectors'

// 3rd-party
import get from 'lodash/get'

export const appSelector = (state) => state.app

export const appSidebarSelector = createSelector([appSelector], (app) => app.sidebar)

export const loadingSelector = createSelector([appSelector], (app) => app.loading)

export const appAuthSelector = createSelector([appSelector], (app) => app.authentication)

export const appUserSelector = createSelector([appAuthSelector], (authentication = {}) =>
  get(authentication, 'user')
)

export const appUserIdSelector = createSelector([appUserSelector], (user = {}) => get(user, 'id'))

export const appUserDisplayNameSelector = createSelector([appUserSelector], (user = {}) =>
  get(user, 'person.displayName', null)
)

export const userRolesSelector = createSelector([appUserSelector], (user = {}) =>
  get(user, 'roles', [])
)

export const appUserQualificationsPredicateSelector = createSelector([appUserSelector], (user) =>
  get(user, 'qualificationsPredicate')
)

export const appUserOrgKeySelector = createSelector([appUserSelector], (user) =>
  get(user, 'organizationKey')
)

export const appUserQualifiedTierIdSelector = createSelector([appUserSelector], (user) =>
  get(user, 'qualificationsPredicate.qualifiedTierIds', [])
)

export const appUserQualifiedTierNameSelector = createSelector(
  [appUserQualifiedTierIdSelector, (state) => tierDictionarySelector(state)],
  (userTierIds, tierDictionary) => {
    const sessionUserTiers = []
    if (Object.keys(tierDictionary).length && userTierIds.length) {
      userTierIds.forEach((tierId) => {
        const tierName = get(tierDictionary, `${[tierId]}['name']`)
        if (tierName) {
          sessionUserTiers.push(tierName)
        }
      })
    }
    return sessionUserTiers
  }
)

export const orderFormActiveSelector = createSelector([appSelector], (app) => app.orderFormActive)

export const initialAppDataStatusSelector = createSelector(
  [appSelector],
  (app) => app.initialAppDataStatus
)
