import {READY_FOR_REVIEW_TOOLTIP} from 'constants/appMessages'
import {theme} from '@clearcapital/ui-resources'

// Review Dashboard
export const todayTileTypeToTitleMap = {
  casesCreatedToday: 'Created Today',
  casesAssignedToday: 'Assigned Today',
  casesAutoReviewedToday: 'Auto Approved Today',
  casesClosedToday: 'Closed Today',
}

export const casesTodayToQueryStringKeyMap = {
  'Auto Approved Today': 'autoApprovedToday',
  'Closed Today': 'casesClosedToday',
}

export const ALL_TIERS_ID = 'ALL_TIERS_ID'
export const NO_TIER_ID = 'NO_TIER_ID'

export const allTiersOption = {
  value: ALL_TIERS_ID,
  label: 'All Tiers',
}

export const noTierOption = {
  value: NO_TIER_ID,
  label: 'No Tier',
}

export const BUCKET_24_H = '< 24 hrs'
export const BUCKET_1_2_D = '1-2 days'
export const BUCKET_2_7_D = '2-7 days'
export const BUCKET_1_4_W = '1-4 weeks'
export const BUCKET_4_W = '>= 4 weeks'

export const timeInReviewPieChartColors = [
  theme.palette.dsm.turquoise.lighter,
  theme.palette.dsm.turquoise.base,
  theme.palette.dsm.gold.base,
  theme.palette.dsm.citrine.base,
  theme.palette.dsm.ruby.base,
]

export const timeInReviewBucketOrder = [
  BUCKET_24_H,
  BUCKET_1_2_D,
  BUCKET_2_7_D,
  BUCKET_1_4_W,
  BUCKET_4_W,
]

export const timeInReviewLegendBucketData = [
  {
    label: BUCKET_24_H,
    color: theme.palette.dsm.turquoise.lighter,
  },
  {
    label: BUCKET_1_2_D,
    color: theme.palette.dsm.turquoise.base,
  },
  {
    label: BUCKET_2_7_D,
    color: theme.palette.dsm.gold.base,
  },
  {
    label: BUCKET_1_4_W,
    color: theme.palette.dsm.citrine.base,
  },
  {
    label: BUCKET_4_W,
    color: theme.palette.dsm.ruby.base,
  },
]

export const TIER_OVERVIEW_COLUMNS = [
  {key: 'tierName', label: 'Tier', width: 200},
  {
    key: 'casesReadyForReview',
    label: 'Ready for Review',
    tooltip: READY_FOR_REVIEW_TOOLTIP,
  },
  {key: 'casesRevisionRequested', label: 'Revision Req.'},
  {key: 'casesOnHold', label: 'On Hold'},
  {key: 'casesClosedToday', label: 'Closed Today'},
]

export const tierComparisonLegendBucketData = [
  {
    label: 'Unassigned',
    color: theme.palette.dsm.interface.light,
  },
  {
    label: 'Assigned',
    color: theme.palette.dsm.emerald.lighter,
  },
]
