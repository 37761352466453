// App Actions
export const ADD_LOADING = 'ADD_LOADING'
export const DELETE_STATE = 'DELETE_STATE'
export const IGNORE = 'IGNORE'
export const REMOVE_ALL_LOADING = 'REMOVE_ALL_LOADING'
export const REMOVE_LOADING = 'REMOVE_LOADING'
export const SET_APP_STATE = 'SET_APP_STATE'
export const SET_SIDEBAR_DRAGGED_WIDTH = 'SET_SIDEBAR_DRAGGED_WIDTH'
export const SET_SIDEBAR_WIDTH = 'SET_SIDEBAR_WIDTH'
export const TOGGLE_LOADING = 'TOGGLE_LOADING'
export const TOGGLE_ORDER_FORM = 'TOGGLE_ORDER_FORM'
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR'
export const SET_AUTH_STATE = 'SET_AUTH_STATE'
export const SET_AUTH_USER = 'SET_AUTH_USER'
export const SET_AUTH_PERMISSIONS = 'SET_AUTH_PERMISSIONS'
export const SET_INITIAL_APP_DATA_STATUS = 'SET_INITIAL_APP_DATA_STATUS'

// Business Unit Actions
export const SET_BUSINESS_UNITS = 'SET_BUSINESS_UNITS'
export const SET_BUSINESS_UNITS_LOADING = 'SET_BUSINESS_UNITS_LOADING'

// Case Events Actions
export const RESET_CASE_EVENTS = 'RESET_CASE_EVENTS'
export const SET_CASE_EVENTS = 'SET_CASE_EVENTS'
export const ADD_CASE_EVENTS = 'ADD_CASE_EVENTS'
export const LOADING_CASE_EVENTS = 'LOADING_CASE_EVENTS'
export const ASSIGNING_CASE_USER = 'ASSIGNING_CASE_USER'

// Case Review Forms
export const CASE_REVIEW_STATUS = 'CASE_REVIEW_STATUS'
export const CLEAR_CASE_REVIEW = 'CLEAR_CASE_REVIEW'
export const SET_CASE_REVIEW_VALUES = 'SET_CASE_REVIEW_VALUES'
export const SAVE_CASE_REVIEW_PROGRESS_START = 'SAVE_CASE_REVIEW_PROGRESS_START'
export const SAVE_CASE_REVIEW_PROGRESS_END = 'SAVE_CASE_REVIEW_PROGRESS_END'

// ClearProp actions
export const SET_CLEAR_PROP = 'SET_CLEAR_PROP'
export const CLEAR_PROP_LOADING = 'CLEAR_PROP_LOADING'
export const RESET_CLEAR_PROP = 'RESET_CLEAR_PROP'
export const SET_CLEAR_PROP_TOKEN = 'SET_CLEAR_PROP_TOKEN'
export const SET_CLEAR_PROP_TOKEN_ERROR = 'SET_CLEAR_PROP_TOKEN_ERROR'
export const SET_CLEAR_PROP_TOKEN_LOADING = 'SET_CLEAR_PROP_TOKEN_LOADING'

// Comps Actions
export const RESET_COMPS = 'RESET_COMPS'
export const SET_FILTERED_COMPS = 'SET_FILTERED_COMPS'

// Configurations Actions
export const SET_CONFIGURATIONS = 'SET_CONFIGURATIONS'

// Core Case Actions
export const SET_PARSED_COMPS = 'SET_PARSED_COMPS'

// Active Comps
export const SET_ACTIVE_COMP = 'SET_ACTIVE_COMP'
export const CLEAR_ACTIVE_COMP = 'CLEAR_ACTIVE_COMP'

// Core Case Actions
export const RESET_CORE_CASE = 'RESET_CORE_CASE'
export const SET_CORE_CASE = 'SET_CORE_CASE'

// Cases on Hold Reasons
export const SET_CORE_CASE_ON_HOLD_REASONS = 'SET_CORE_CASE_ON_HOLD_REASONS'
export const SET_CORE_CASE_ON_HOLD_SHOW_CARD = 'SET_CORE_CASE_ON_HOLD_SHOW_CARD'
export const SET_CORE_CASE_ON_HOLD_DISABLE_LINK = 'SET_CORE_CASE_ON_HOLD_DISABLE_LINK'

// Case Reopened
export const CASE_REOPENED = 'CASE_REOPENED'

export const SET_CASE_DOC_ORDERS = 'SET_CASE_DOC_ORDERS'
export const LOADING_CASE_DOC_ORDERS = 'LOADING_CASE_DOC_ORDERS'

// Document Actions Menu Actions
export const SET_FORM_TEMPLATE = 'SET_FORM_TEMPLATE'

// Document Summaries Actions
export const RESET_DOCUMENT_SUMMARIES = 'RESET_DOCUMENT_SUMMARIES'
export const SET_DOCUMENT_SUMMARIES = 'SET_DOCUMENT_SUMMARIES'
export const SET_PRIMARY_DOCUMENT_SUMMARIES = 'SET_PRIMARY_DOCUMENT_SUMMARIES'

// MLS Photo Actions
export const SET_MLS_PHOTOS = 'SET_MLS_PHOTOS'
export const ERROR_MLS_PHOTOS = 'ERROR_MLS_PHOTOS'
export const CLEAR_MLS_PHOTOS = 'CLEAR_MLS_PHOTOS'

// Org Actions
export const SET_ORG_PREFERENCES = 'SET_ORG_PREFERENCES'
export const SET_ORG_USERS = 'SET_ORG_USERS'
export const SET_ORG_USER_ROLES = 'SET_ORG_USER_ROLES'

// Order Events Communication
export const RESOLVE_EVENT_REQUIRING_ACTION = 'RESOLVE_EVENT_REQUIRING_ACTION'

// Order Review Actions
export const CLEAR_ORDER_REVIEW = 'CLEAR_ORDER_REVIEW'
export const INITIALIZE_ORDER_REVIEW_FORM = 'INITIALIZE_ORDER_REVIEW_FORM'
export const UPDATE_ORDER_REVIEW_FORM_VALUES = 'UPDATE_ORDER_REVIEW_FORM_VALUES'
export const SET_ORG_REVIEW_TEMPLATES = 'SET_ORG_REVIEW_TEMPLATES'
export const SET_SELECTED_FORM_TEMPLATE_IDS = 'SET_SELECTED_FORM_TEMPLATE_IDS'
export const UPDATE_ORDER_REVIEW_FORM_SUBMISSION = 'UPDATE_ORDER_REVIEW_FORM_SUBMISSION'
export const SET_ORDER_REVIEW_LOADING = 'SET_ORDER_REVIEW_LOADING'
export const SET_ORDER_REVIEW_SAVING = 'SET_ORDER_REVIEW_SAVING'
export const ADD_RULE_TO_REVIEW = 'ADD_RULE_TO_REVIEW'
export const REMOVE_RULE_FROM_REVIEW = 'REMOVE_RULE_FROM_REVIEW'
export const ADD_COMP_TO_REVIEW = 'ADD_COMP_TO_REVIEW'
export const REMOVE_COMP_FROM_REVIEW = 'REMOVE_COMP_FROM_REVIEW'
export const INITIALIZE_ORDER_REVIEW_FORM_VALUES = 'INITIALIZE_ORDER_REVIEW_FORM_VALUES'
export const CLEAR_ORDER_REVIEW_COMPS = 'CLEAR_ORDER_REVIEW_COMPS'
export const CLEAR_ORDER_REVIEW_RULES = 'CLEAR_ORDER_REVIEW_RULES'
export const SET_ORDER_REVIEW_FORM_VALUES_LOADING = 'SET_ORDER_REVIEW_FORM_VALUES_LOADING'

// Popouts Actions
export const SET_POPOUT_REFERENCE = 'SET_POPOUT_REFERENCE'
export const TOGGLE_POPOUT = 'TOGGLE_POPOUT'
export const UPDATE_POPOUT_DATA = 'UPDATE_POPOUT_DATA'

// Product Order
export const CLEAR_ALL_RELATED_ORDERS = 'CLEAR_ALL_RELATED_ORDERS'
export const CLEAR_ORDER = 'CLEAR_ORDER'
export const CLEAR_ORDER_CREATED_STATUS = 'CLEAR_ORDER_CREATED_STATUS'
export const CLEAR_ORDER_PROVIDER_DATA = 'CLEAR_ORDER_PROVIDER_DATA'
export const LOADING_ORDER = 'LOADING_ORDER'
export const LOADING_RELATED_ORDERS = 'LOADING_RELATED_ORDERS'
export const SET_ALL_RELATED_ORDERS = 'SET_ALL_RELATED_ORDERS'
export const SET_ORDER = 'SET_ORDER'
export const SET_ORDER_CREATED_STATUS = 'SET_ORDER_CREATED_STATUS'
export const SET_ORDER_PROVIDER_DATA = 'SET_ORDER_PROVIDER_DATA'
export const SET_ORDER_IS_STILL_PROCESSING = 'SET_ORDER_IS_STILL_PROCESSING'

// Products
export const CLEAR_ORG_PRODUCTS = 'CLEAR_ORG_PRODUCTS'
export const CLEAR_PRODUCT = 'CLEAR_PRODUCT'
export const SET_ORG_PRODUCTS = 'SET_ORG_PRODUCTS'
export const SET_PRODUCT = 'SET_PRODUCT'

// Property Actions
export const LOADING_PROPERTY_CHARACTERISTICS = 'PROPERTY_CHARACTERISTICS'

// Property Overview Actions
export const SET_PROPERTY_OVERVIEW = 'SET_PROPERTY_OVERVIEW'

// Provider Actions
export const CLEAR_PRODUCT_PANEL_PROVIDERS_AMCS_WITH_ORG_KEY =
  'CLEAR_PRODUCT_PANEL_PROVIDERS_AMCS_WITH_ORG_KEY'
export const SET_PRODUCT_PANEL_PROVIDERS_AMCS_WITH_ORG_KEY =
  'SET_PRODUCT_PANEL_PROVIDERS_AMCS_WITH_ORG_KEY'

// Related Cases Actions
export const SET_LOADING_RELATED_CASES = 'SET_LOADING_RELATED_CASES'
export const RESET_RELATED_CASES = 'RESET_RELATED_CASES'
export const SET_RELATED_CASES = 'SET_RELATED_CASES'
export const ERROR_RELATED_CASES = 'ERROR_RELATED_CASES'

// Report Actions
export const CLEAR_REPORT_LIST = 'CLEAR_REPORT_LIST'
export const LOADING_REPORT_LIST = 'LOADING_REPORT_LIST'
export const SET_REPORT_LIST = 'SET_REPORT_LIST'
export const CLEAR_REPORT_URL = 'CLEAR_REPORT_URL'
export const LOADING_REPORT_URL = 'LOADING_REPORT_URL'
export const SET_REPORT_URL = 'SET_REPORT_URL'

// Review Form Actions
export const INITIALIZE_CASE_REVIEW_FORM = 'INITIALIZE_CASE_REVIEW_FORM'
export const CASE_REVIEW_FORM_LOADING = 'CASE_REVIEW_FORM_LOADING'
export const RESET_CASE_REVIEW_FORM = 'RESET_CASE_REVIEW_FORM'
export const UPDATE_CASE_COMPLEX_CONCLUSION = 'UPDATE_CASE_COMPLEX_CONCLUSION'
export const UPDATE_CASE_REVIEW_FORM_FIELD = 'UPDATE_CASE_REVIEW_FORM_FIELD'
export const UPDATE_CASE_REVIEW_FORM_FIELD_VALUE = 'UPDATE_CASE_REVIEW_FORM_FIELD_VALUE'
export const LOADING_ORG_REVIEW_TEMPLATES = 'LOADING_ORG_REVIEW_TEMPLATES'

// Rule Based Analyses Actions
export const RESET_RULE_BASED_ANALYSES = 'RESET_RULE_BASED_ANALYSES'
export const SET_RULE_BASED_ANALYSES = 'SET_RULE_BASED_ANALYSES'
export const ERROR_RULE_BASED_ANALYSES = 'ERROR_RULE_BASED_ANALYSES'
export const LOADING_RULE_BASED_ANALYSES = 'LOADING_RULE_BASED_ANALYSES'

// Search Actions
export const ADD_CASE_SUGGESTIONS = 'GET_CASE_SUGGESTIONS'
export const DELETE_CASE_SUGGESTIONS = 'DELETE_CASE_SUGGESTIONS'

// Street View Actions
export const CLEAR_STREET_VIEW = 'CLEAR_STREET_VIEW'
export const SET_SUBJECT_STREET_VIEW = 'SET_SUBJECT_STREET_VIEW'
export const ERROR_SUBJECT_STREET_VIEW = 'ERROR_SUBJECT_STREET_VIEW'
export const SET_SUBJECT_STREET_VIEW_AVAILABILITY = 'SET_SUBJECT_STREET_VIEW_AVAILABLE'

// Users Actions
export const CREATE_USER = 'CREATE_USER'
export const UPDATE_USER = 'UPDATE_USER'

// Validation Actions
export const RESET_VALIDATION = 'RESET_VALIDATION'
export const SET_VALIDATION = 'SET_VALIDATION'

// Working Document Actions
export const CLEAR_ACTIVE_DOCUMENT = 'CLEAR_ACTIVE_DOCUMENT'
export const SET_ACTIVE_DOCUMENT = 'SET_ACTIVE_DOCUMENT'

// Session actions
export const SET_SESSION_THRESHOLD = 'SET_SESSION_THRESHOLD'

// panel provider relationship
export const CUSTOMER_ORGANIZATION_FETCH_COMPLETE = 'CUSTOMER_ORGANIZATION_FETCH_COMPLETE'
export const SET_CUSTOMER_ORGANIZATION = 'SET_CUSTOMER_ORGANIZATION'
export const UPDATE_CUSTOMER_ORGANIZATION_SETTINGS = 'UPDATE_CUSTOMER_ORGANIZATION_SETTINGS'

// Panel Emails invites
export const SET_SHOW_PANEL_INVALID_EMAILS = 'SET_SHOW_PANEL_INVALID_EMAILS'
export const SET_HIDE_PANEL_INVALID_EMAILS = 'SET_HIDE_PANEL_INVALID_EMAILS'

// Provider orders actions
export const SET_PROVIDER_ORDERS = 'SET_PROVIDER_ORDERS'
export const UPDATE_PROVIDER_ORDER = 'UPDATE_PROVIDER_ORDER'

// Order activity
export const SET_ORDER_ACTIVITY = 'SET_ORDER_ACTIVITY'

// Dashboard
export const SET_REVIEW_DASHBOARD_DATA = 'SET_REVIEW_DASHBOARD_DATA'
export const LOADING_REVIEW_DASHBOARD_DATA = 'LOADING_REVIEW_DASHBOARD_DATA'

// DataPoints
export const SET_TEMPLATE_DATAPOINTS = 'SET_TEMPLATE_DATAPOINTS'
// Provider info
export const FETCHING_PROVIDER_INFO = 'FETCHING_PROVIDER_INFO'
export const SET_PROVIDER_INFO_DATA = 'SET_PROVIDER_INFO_DATA'
export const CASE_ORDERS_PROVIDER_INFO = 'CASE_ORDERS_PROVIDER_INFO'

// Tier Builder
export const RESET_TIERS = 'RESET_TIERS'
export const SET_TIERS = 'SET_TIERS'

// communications
export const SET_ORDER_HAS_UNREAD_MESSAGE = 'SET_ORDER_HAS_UNREAD_MESSAGE'
export const LOADING_MESSAGE = 'LOADING_MESSAGE'

// All Products
export const SET_ALL_PRODUCTS = 'SET_ALL_PRODUCTS'

// Case Order Review Form Submissions
export const RESET_CASE_ORDER_REVIEWS = 'RESET_CASE_ORDER_REVIEWS'
export const SET_CASE_ORDER_REVIEWS = 'SET_CASE_ORDER_REVIEWS'
export const SET_CASE_ORDER_REVIEWS_LOADING = 'SET_CASE_ORDER_REVIEWS_LOADING'

// Case Page Dropdown
export const LOADING_CASE_DROPDOWN = 'LOADING_CASE_DROPDOWN'

// Smart Photos
export const SET_SMART_VIEW_PHOTOS = 'SET_SMART_VIEW_PHOTOS'
export const CLEAR_SMART_VIEW_PHOTOS = 'CLEAR_SMART_VIEW_PHOTOS'

// ClearQC Management - Rule Sets
export const INITIALIZE_RULE_SET_DATA = 'INITIALIZE_RULE_SET_DATA'
export const SET_RULE_SETS_STATUS = 'SET_RULE_SETS_STATUS'
export const SET_DEFAULT_RULE_SET = 'SET_DEFAULT_RULE_SET'
export const SET_RULE_SETS = 'SET_RULE_SETS'
export const RESET_RULE_SETS = 'RESET_RULE_SETS'
export const LOADING_RULE_SETS = 'LOADING_RULE_SETS'
export const SET_ACTIVE_RULE_SET = 'SET_ACTIVE_RULE_SET'
export const SET_MERGED_RULE_SET_RULES = 'SET_MERGED_RULE_SET_RULES'
export const SET_FINAL_FORM_INITIAL_VALUES_RULE_SET = 'SET_FINAL_FORM_INITIAL_VALUES_RULE_SET'

// ClearQC Management - Rule Library
export const INITIALIZE_RULE_LIBRARY_DATA = 'INITIALIZE_RULE_LIBRARY_DATA'
export const SET_RULE_LIBRARY_RULES = 'SET_RULE_LIBRARY_RULES'
export const SET_RULE_LIBRARY_STATUS = 'SET_RULE_LIBRARY_STATUS'
export const LOADING_RULE_LIBRARY = 'LOADING_RULE_LIBRARY'
export const SET_ACTIVE_RULE = 'SET_ACTIVE_RULE'

// Review Scopes
export const INITIALIZE_REVIEW_SCOPES_DATA = 'INITIALIZE_REVIEW_SCOPES_DATA'
export const LOADING_CASE_REVIEW_SCOPE = 'LOADING_CASE_REVIEW_SCOPE'
export const RESET_REVIEW_SCOPES = 'RESET_REVIEW_SCOPES'
export const RESET_CASE_REVIEW_SCOPE = 'RESET_CASE_REVIEW_SCOPE'
export const SET_ACTIVE_REVIEW_SCOPE = 'SET_ACTIVE_REVIEW_SCOPE'
export const SET_CASE_REVIEW_SCOPE = 'SET_CASE_REVIEW_SCOPE'
export const SET_REVIEW_SCOPE_STATUS = 'SET_REVIEW_SCOPE_STATUS'
export const SET_REVIEW_SCOPES = 'SET_REVIEW_SCOPES'

// Review Queue
export const RESET_REVIEW_QUEUE = 'RESET_REVIEW_QUEUE'
export const SET_REVIEW_QUEUE_STATUS = 'SET_REVIEW_QUEUE_STATUS'
export const SET_REVIEW_QUEUE_CASES_PER_PAGE = 'SET_REVIEW_QUEUE_CASES_PER_PAGE'
export const SET_REVIEW_QUEUE_CURRENT_PAGE = 'SET_REVIEW_QUEUE_CURRENT_PAGE'
export const SELECT_ALL_REVIEW_QUEUE_CASES = 'SELECT_ALL_REVIEW_QUEUE_CASES'
export const SELECT_REVIEW_QUEUE_CASE = 'SELECT_REVIEW_QUEUE_CASE'
export const SET_REVIEW_QUEUE_CASE_DATA = 'SET_REVIEW_QUEUE_CASE_DATA'

// Queue Priority
export const LOADING_QUEUE_SORT_RANKING = 'LOADING_QUEUE_SORT_RANKING'
export const INITIALIZE_SORT_RANK_DATA = 'INITIALIZE_SORT_RANK_DATA'
export const SET_SORT_RANK = 'SET_SORT_RANK'

// Revision Requests
export const SET_REVISION_REQUESTS = 'SET_REVISION_REQUESTS'
export const RESET_REVISION_REQUESTS = 'RESET_REVISION_REQUESTS'
export const SET_REVISION_REQUEST_CATEGORIES = 'SET_REVISION_REQUEST_CATEGORIES'
export const SET_ACTIVE_REQUEST = 'SET_ACTIVE_REQUEST'
export const RESET_ACTIVE_REQUEST = 'RESET_ACTIVE_REQUEST'
export const UPDATE_ACTIVE_REQUEST = 'UPDATE_ACTIVE_REQUEST'
export const SET_REVISION_PANEL_OPEN = 'SET_REVISION_PANEL_OPEN'
export const ADD_REVISION_REQUEST_ITEM = 'ADD_REVISION_REQUEST_ITEM'
export const DELETE_REVISION_REQUEST_ITEM = 'DELETE_REVISION_REQUEST_ITEM'
export const SET_ACTIVE_REVISION_REQUEST_ITEMS = 'SET_ACTIVE_REVISION_REQUEST_ITEMS'
export const SET_IS_CANCEL_REVISION_REQUEST_OPEN = 'SET_IS_CANCEL_REVISION_REQUEST_OPEN'
export const REMOVE_REVISION_REQUEST_BY_ID = 'REMOVE_REVISION_REQUEST_BY_ID'
export const UPDATE_REVISION_REQUEST_BY_ID = 'UPDATE_REVISION_REQUEST_BY_ID'

// Modals
export const OPEN_MODAL = 'OPEN_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'
export const REMOVE_MODAL = 'REMOVE_MODAL'

// Case Counts By User
export const RESET_CASE_COUNTS_BY_USER = 'RESET_CASE_COUNTS_BY_USER'
export const SET_CASE_COUNTS_BY_USER = 'SET_CASE_COUNTS_BY_USER'
export const SET_CASE_COUNTS_BY_USER_LOADING = 'SET_CASE_COUNTS_BY_USER_LOADING'
