export const isElementVerticallyOverflowing = (element) => {
  return element.scrollHeight > element.clientHeight
}

export const getDecimalDigitsCount = (number) => {
  let result = 0
  if (number) {
    const characterArray = number?.toString().split('') // split every single char
    const notDecimal = characterArray.lastIndexOf('.')
    result = notDecimal < 0 ? 0 : characterArray.length - notDecimal - 1
  }
  return result
}
