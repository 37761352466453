import {SET_SESSION_THRESHOLD} from 'constants/actionTypes'
import {
  DEFAULT_BUFFERED_SESSION_LENGTH,
  SESSION_BUFFER,
  SESSION_TIMER_COUNTDOWN_LENGTH_IN_MS,
} from 'constants/sessionConstants'

const createSessionExpirationTimestamp = (thresholdInMilliseconds) => {
  return Math.floor(new Date().valueOf() / 1000) + thresholdInMilliseconds / 1000
}

const initialState = {
  sessionThreshold: DEFAULT_BUFFERED_SESSION_LENGTH,
  // We track the timestamp of when the session will expire so we can use
  // it in SessionTimer. If the session has expired while the tab was
  // inactive, we check when the tab is active again and automatically
  // log the user out if so.
  sessionExpirationTimestamp: createSessionExpirationTimestamp(
    DEFAULT_BUFFERED_SESSION_LENGTH - SESSION_TIMER_COUNTDOWN_LENGTH_IN_MS
  ),
}

export default function sessionReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SESSION_THRESHOLD:
      // buffered session length in milliseconds
      const bufferedSessionThreshold = action.payload - SESSION_BUFFER
      return {
        ...state,
        sessionThreshold: bufferedSessionThreshold,
        sessionExpirationTimestamp: createSessionExpirationTimestamp(
          // The session will expire after the bufferedSessionThreshold, plus the session countdown
          // and we add an extra second to only trigger after the session countdown modal hits 0
          bufferedSessionThreshold + SESSION_TIMER_COUNTDOWN_LENGTH_IN_MS + 1000
        ),
      }
    default:
      return state
  }
}
