import {SET_ORG_USER_ROLES} from 'constants/actionTypes'

const initialState = []

export default function roles(state = initialState, action) {
  switch (action.type) {
    case SET_ORG_USER_ROLES:
      return action.payload
    default:
      return state
  }
}
