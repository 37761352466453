import {SET_POPOUT_REFERENCE, TOGGLE_POPOUT, UPDATE_POPOUT_DATA} from 'constants/actionTypes'

/** state will look like this:
 * {
 *    <POPOUT_NAME>: {
 *      active: <boolean>,
 *      reference: <external window ref>,
 *      data: <optional object>
 *    },
 *    <POPOUT_NAME>: {
 *      active: <boolean>,
 *      reference: <external window ref>,
 *      data: <optional object>
 *    },
 *    ...
 * }
 */

const initialState = {}

export default function popouts(state = initialState, action) {
  switch (action.type) {
    case SET_POPOUT_REFERENCE: {
      // action.payload will look like this: {popout: <POPOUT_NAME>, reference: ref to the external window}
      const {reference, popout} = action.payload
      const nextState = {
        ...state,
        [popout]: {
          ...state[popout],
          reference,
        },
      }

      return nextState
    }
    case TOGGLE_POPOUT: {
      // action.payload will look like this: {popout: <POPOUT_NAME>, active: boolean, data: {...} (optional) }
      const {active, popout, data} = action.payload
      const nextPopoutState = active
        ? {
            ...state[popout],
            active,
            data,
          }
        : {active} // we dont' want to keep the reference if we're setting active to false

      const nextState = {
        ...state,
        [popout]: nextPopoutState,
      }

      return nextState
    }
    case UPDATE_POPOUT_DATA: {
      const {popout, data} = action.payload
      const nextState = {
        ...state,
        [popout]: {
          ...state[popout],
          data,
        },
      }

      return nextState
    }
    default:
      return state
  }
}
