import {listToArray} from 'services/formatData'
import moment from 'moment'
import {getDecimalDigitsCount} from 'services/utils'

const isEmpty = (value) => {
  return value === '' || value === undefined || value == null
}

export const isExisty = (value) => {
  return value !== null && value !== undefined
}

const isEmptyTrimmed = (value) => {
  if (typeof value === 'string') {
    return value.trim() === ''
  }
  return true
}

// return true to pass test, false to fail
const validators = {
  required: (value) =>
    typeof value === 'string' ? !isEmpty(value) && !isEmptyTrimmed(value) : !isEmpty(value),

  isArrayWithLength: (value) => value && value.length,

  matchRegexp: (value, regexp) => {
    const validationRegexp = regexp instanceof RegExp ? regexp : new RegExp(regexp)
    return !isExisty(value) || isEmpty(value) || validationRegexp.test(value)
  },

  isEmail: (value) =>
    validators.matchRegexp(
      value,
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    ),

  isEmpty: (value) => isEmpty(value),

  trim: (value) => !isEmptyTrimmed(value),

  isNumber: (value) => validators.matchRegexp(value, /^-?[0-9]\d*(\d+)?$/i),

  isFloat: (value) => validators.matchRegexp(value, /^(?:[1-9]\d*|0)?(?:\.\d+)?$/i),

  isPositive: (value) => {
    if (isExisty(value)) {
      return (validators.isNumber(value) || validators.isFloat(value)) && value >= 0
    }
    return true
  },

  isNotDuplicate: (value) => {
    const testArray = listToArray(value)
    return new Set(testArray).size === testArray.length
  },

  isUniqueAmongSet: (value, names) => {
    return !names.has(value)
  },

  isString: (value) => !isEmpty(value) || typeof value === 'string' || value instanceof String,

  maxNumber: (value, max) =>
    !isExisty(value) || isEmpty(value) || parseFloat(value) <= parseFloat(max),

  minNumber: (value, min) =>
    !isExisty(value) || isEmpty(value) || parseFloat(value) >= parseFloat(min),

  minStringLength: (value, length) => validators.isString(value) && value.length >= length,

  maxStringLength: (value, length) => validators.isString(value) && value.length <= length,

  precision: (value, precision) => getDecimalDigitsCount(value) <= precision,

  validateTelephone: (value) =>
    validators.matchRegexp(value, /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im),

  validateUrl: (value) =>
    validators.matchRegexp(
      value,
      /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i
    ),

  validateDate: (value, format) => moment(value, format, true).isValid(),

  validateMinDateToday: (value) => moment(value).isSameOrAfter(moment().startOf('day')),
}

// This function creates a closure around passed validation rules and returns
// a validate function for React Final Form, which passes the value arg
export const composeValidators = (validationRules = []) =>
  validationRules.length > 0
    ? (value) =>
        validationRules.reduce((error, {rule, errorMsg, ruleParams = []}) => {
          const isValid =
            typeof rule === 'function'
              ? rule(value, ...ruleParams)
              : validators[rule](value, ...ruleParams)
          const currentError = isValid ? undefined : errorMsg
          return isEmpty(error) ? currentError : error
        }, undefined)
    : undefined

export default validators
