import {
  SET_CORE_CASE_ON_HOLD_REASONS,
  SET_CORE_CASE_ON_HOLD_SHOW_CARD,
  SET_CORE_CASE_ON_HOLD_DISABLE_LINK,
} from 'constants/actionTypes'

export const initialState = {
  data: [],
  showTile: false,
  disablePutCaseOnHold: false,
}

export default function casesOnHoldReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CORE_CASE_ON_HOLD_REASONS:
      return {
        ...state,
        data: action.payload,
      }

    case SET_CORE_CASE_ON_HOLD_SHOW_CARD: {
      return {
        ...state,
        showTile: action.payload,
      }
    }
    case SET_CORE_CASE_ON_HOLD_DISABLE_LINK: {
      return {
        ...state,
        disablePutCaseOnHold: action.payload,
      }
    }
    default:
      return state
  }
}
