import Address from './Address'

export default class Property {
  constructor(props) {
    Object.keys(props).forEach((key) => {
      switch (key) {
        case 'address':
          this[key] = new Address(props[key])
          break
        default:
          this[key] = props[key]
      }
    })
  }
}

/*
core Property {
  id (integer, optional),
  address (Address, optional),
  lat (number, optional),
  lng (number, optional),
  parcelApn (string, optional),
  geocodeType (GeocodeTypeEnum, optional) = ['OTHER' or 'ZIPCODE' or 'LOCAL_AREA' or 'STREET_INTERSECTION' or 'STREET_CENTERLINE' or 'STREET_SIDE' or 'PARCEL' or 'FRONT_DOOR' or 'ROOFTOP'],
  geocoderSourceId (integer, optional),
  geocoderSourceName (SourceNameEnum, optional) = ['APPRAISAL' or 'CCP' or 'CLEAR_RE' or 'COLLATERAL_ANALYTICS' or 'DIGITALMAP' or 'GOOGLE' or 'HVE' or 'MELISSADATA' or 'MLS' or 'PROPERTY_ACTIVITY_IMPORT' or 'RELAR' or 'UNKNOWN'],
  neighborhood (string, optional),
  taxID (string, optional),
  updateId (integer, optional)
}
*/
