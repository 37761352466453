// Popout Types
export const REVIEW_FORM_POPOUT = 'REVIEW_FORM_POPOUT'
export const ORDER_REVIEW_FORM_POPOUT = 'ORDER_REVIEW_FORM_POPOUT'
export const SMART_VIEW_POPOUT = 'SMART_VIEW_POPOUT'

// Popout Misc
export const POPOUT_HEADER_SCROLL_LIMIT = 60
export const DEFAULT_POPOUT_HEIGHT = 800
export const DEFAULT_POPOUT_OPTIONS = 'location=no,toolbar=no,menubar=no,resizable=yes'
export const DEFAULT_POPOUT_WIDTH = 480
