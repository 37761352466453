// criteria builder constants
export const CONTAINS_ONE_OF = 'ContainsOneOf'
export const IS_ONE_OF = 'IsOneOf'
export const IS_NOT = 'IsNot'
export const HAS_ANY_VALUE = 'HasAnyValue'
export const HAS_NO_VALUE = 'HasNoValue'
export const CONSTRAINT = 'Constraint'

export const AND = 'And'
export const OR = 'Or'

export const TRUE = 'TRUE'
export const FALSE = 'FALSE'

// field types
export const TEXT = 'text'
export const SELECT = 'select'

// input types
export const TEXT_WITH_CHIPS = 'TEXT_WITH_CHIPS'
export const TEXT_FIELD = 'TEXT_FIELD'
export const NUMERIC_TEXT_FIELD = 'NUMERIC_TEXT_FIELD'
export const SELECT_WITH_CHIPS = 'SELECT_WITH_CHIPS'
export const SELECT_FIELD = 'SELECT_FIELD'

export const DEFAULT_CRITERIA = {
  predicateType: OR,
  childPredicates: [],
}

export const DEFAULT_EMPTY_AND_PREDICATE = {
  predicateType: AND,
  childPredicates: [{}],
}

export const DEFAULT_EMPTY_CRITERIA = {
  predicateType: 'AlwaysMatch',
}
