import {
  ERROR_RELATED_CASES,
  RESET_RELATED_CASES,
  SET_RELATED_CASES,
  SET_LOADING_RELATED_CASES,
} from 'constants/actionTypes'
import {ERROR, LOADED, LOADING, UNSET} from 'constants/reduxStatuses'
const initialState = {
  status: UNSET,
  data: [],
}

export default function relatedCases(state = initialState, action) {
  switch (action.type) {
    case SET_RELATED_CASES:
      return {
        ...initialState,
        data: action.payload,
        status: LOADED,
      }
    case RESET_RELATED_CASES:
      return initialState
    case ERROR_RELATED_CASES:
      return {
        ...initialState,
        status: ERROR,
      }
    case SET_LOADING_RELATED_CASES:
      return {
        ...initialState,
        status: LOADING,
      }

    default:
      return state
  }
}
