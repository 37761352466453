import {SET_ORG_PREFERENCES} from 'constants/actionTypes'

const initialState = {
  configurations: {},
  preferences: {},
}

export default function organizations(state = initialState, action) {
  switch (action.type) {
    case SET_ORG_PREFERENCES:
      return {
        ...state,
        preferences: action.payload,
      }
    default:
      return state
  }
}
