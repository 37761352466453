// Styles
import styled from 'styled-components'

// Core
import React from 'react'

// Components, actions, models, etc.
import StaticHeader from './Components/StaticHeader'
import {theme} from '@clearcapital/ui-resources'

const TermsOfService = () => {
  // As the Markup for this component is quite Large, I tried to add comments in helpful places
  // so developers can use code collapse settings in IDE to only display pertinent sections
  return (
    <TermsOfService.Styled>
      <StaticHeader />
      <div className='tos-content-wrap'>
        <div className='tos-header'>
          <h2 data-sqa-id='cc-tos-header'>Terms of Service</h2>
          <p data-sqa-id='cc-tos-last-updated'>Last Updated Feb 23, 2022</p>
        </div>
        <div className='tos-body' data-sqa-id='cc-tos-body'>
          <section id='introduction' data-sqa-id='cc-tos-introduction'>
            <p>
              Please carefully read these Terms of Service (this “Agreement”). Clear Capital will
              provide you with online access to Clear Capital’s valuation review platform software
              known as ClearCollateral® Review (“ClearCollateral® Review or CCR”). CCR is a
              Software-as-a-Service (SaaS) workflow system that centralizes and streamlines the
              appraisal review process. Other data services and valuation products may be available
              within CCR, separate and in addition to the Services, at Clear Capital’s discretion.
              This Agreement between you and ClearCapital.com, Inc. as well as our affiliates and
              subsidiaries (collectively “Clear Capital”, “us”, “our”, or “we”) governs the use of
              CCR, the websites, applications, and electronic communications that link to this
              Agreement (collectively, the “Platform”) and the training materials, content, and
              services available through the Platform (collectively, Platform and all related
              materials, content, and services are referred to herein as the “Services”).{' '}
              <span className='bold'>
                Additional terms to this Agreement may be stated in Exhibit B (below) – Please
                Review Carefully.
              </span>
            </p>
            <p>
              By accessing the SERVICES, you agree to be bound by this Agreement WITHOUT ANY
              MODIFICATION. DO NOT ACCESS OR USE THE SERVICES IN ANY WAY IF YOU DO NOT AGREE TO THIS
              AGREEMENT. IF YOU ARE ACCESSING AND USING THE SERVICES ON BEHALF OF AN ENTITY, YOU
              HEREBY REPRESENT AND WARRANT THAT YOU ARE AUTHORIZED TO ACT ON BEHALF OF SUCH ENTITY
              AND TO BIND SUCH ENTITY TO THE TERMS OF THIS AGREEMENT.
            </p>
            <p>
              IMPORTANT NOTICE: YOUR USE OF THE SERVICES IS SUBJECT TO AN ARBITRATION PROVISION IN
              SECTION 10, REQUIRING ALL CLAIMS TO BE RESOLVED VIA INDIVIDUAL BINDING ARBITRATION.
            </p>
          </section>
          <section id='terms-list' data-sqa-id='cc-tos-terms'>
            <ol>
              {/* 1. Registration */}
              <li>
                <h4>Registration and Eligibility for Services.</h4>
                <ol type='a'>
                  <li>
                    <p className='bold'>Registration.</p>
                    <p>
                      In order to utilize some of the Services’ features, you need to register and
                      create an account. In order to create an account, you must complete the
                      registration process by providing Clear Capital with complete and accurate
                      information as may be required, including but not limited to, contact
                      information, a username, and password. You shall protect your password and
                      take full responsibility for your own and third-party activities that occur
                      under your account. You agree to notify Clear Capital immediately of any
                      unauthorized use of your account or any other breach of security. If you
                      create an account on behalf of an entity, this Agreement binds both you and
                      the entity.
                    </p>
                  </li>
                  <li>
                    <p className='bold'>Refusal of Service.</p>
                    <p>
                      Clear Capital reserves the right, with or without notice, to terminate the
                      account of, or refuse service to, any persons that violate this Agreement,
                      violate any party’s intellectual property rights, abuse other users of the
                      Services, misuse the Services, or otherwise engage in inappropriate conduct,
                      as determined by Clear Capital in its sole discretion.
                    </p>
                  </li>
                  <li>
                    <p className='bold'>Information Submission and Age Restriction.</p>
                    <p>
                      By submitting any information through the Services, you represent and warrant
                      that you are 18 years of age or older, and, if under the age of majority in
                      your state, you are either an emancipated minor, or have obtained the legal
                      consent of your parent or legal guardian to enter into this Agreement and use
                      the Services. Clear Capital does not intend to use the Services to collect any
                      information from children under age 18. Pursuant to 47 U.S.C. § 230(d), you
                      are notified that parental control protections (such as computer hardware,
                      software, or filtering services) are commercially available that may assist
                      you in limiting access to material that is harmful to minors. For information
                      on providers of such services, contact your information technology
                      professional.
                    </p>
                  </li>
                </ol>
              </li>
              {/* 2. Revisions to Agreement */}
              <li>
                <h4>Revisions to this Agreement.</h4>
                <span>
                  We may revise and update this Agreement from time to time, and will post the
                  updated Agreement to the Services. Unless otherwise stated in the amended version
                  of the Agreement, any changes to this Agreement will apply immediately upon
                  posting. Other than updating the date at the top of the Agreement, we generally
                  will not notify you of any such changes by email or other personal contact, but we
                  reserve the right to do so. You should revisit these Terms of Service on a regular
                  basis as revised versions will be binding upon you. Your continued use of the
                  Services will constitute your agreement to any new provisions within the revised
                  Agreement.
                </span>
              </li>
              {/* 3. Access to Services */}
              <li>
                <h4>Access to and Use of the Services; Proprietary Rights.</h4>
                <ol type='a'>
                  <li>
                    <p className='bold'>Ownership.</p>
                    <p>
                      All content included on the Platform or in the context of any related
                      Services, is the intellectual property of Clear Capital. Said Copyrighted
                      Content includes, but is not limited to, all text, graphics, video, logos,
                      audio, and any combination of the foregoing, as well as any and all software
                      effectuating the foregoing or otherwise implementing any and all functionality
                      related to the Platform and its related Services. All Copyrighted Content,
                      derivations and compilations of the same, are protected by all applicable U.S.
                      and international copyright laws. The Platform and related Services and any
                      and all Copyrighted Content may only be used as permitted by Clear Capital.
                      All other uses, including reproduction, copying, derivation, distribution,
                      sale, transfer, rental, lease, lending, performance, display, or transmission,
                      of Copyrighted Content are strictly prohibited.
                    </p>
                  </li>
                  <li>
                    <p className='bold'>Access and Use of the Services.</p>
                    <p>
                      Subject to, and in accordance with, this Agreement, and contingent upon all
                      required payments, Clear Capital agrees to provide you with a revocable,
                      non-exclusive, non-transferable, limited right that enables you to access and
                      use the Services and not for service bureau, time-sharing, or similar
                      services. Each user account is valid for one user only and may not be shared
                      concurrently or otherwise by or among multiple users.You are not permitted to
                      use, share or transfer access to the Services in excess of the usage
                      limitations set forth in this Agreement or in any manner not expressly
                      authorized by this Agreement or applicable law. Your authorization to access
                      and use the Services is automatically revoked if you violate any of this
                      Agreement. Except as otherwise provided in this Agreement, no part of the
                      Services and no content forming any part of the Services may be copied,
                      reproduced, uploaded, posted, publicly displayed, transmitted, or distributed
                      in any way to any other computer, server, services, or other medium for
                      publication or distribution or for any commercial use without Clear Capital’s
                      prior express written consent. Your access to these Services is provided on a
                      temporary basis with no guarantee for future availability.
                    </p>
                  </li>
                  <li>
                    <p className='bold'>Services Availability.</p>
                    <p>
                      Clear Capital will provide the Services in a highly professional manner
                      consistent with the quality provided by other firms providing comparable
                      services in the United States and in accordance with legal requirements
                      applicable to performance or receipt of the Services and the requirements
                      described in this Agreement. Clear Capital is committed to providing a highly
                      available, performant and secure Platform to support its customers and
                      partners. Delivering reliability is the basis for the commitments defined in
                      Exhibit B. We reserve the right to withdraw or amend this Platform, and any
                      Services we provide on the Platform, in our sole discretion and will provide
                      reasonable notice for material changes. There may be times when the Services
                      are unavailable due to technical errors or for maintenance and support
                      activities. We do not represent, warrant, or guarantee that the Services will
                      always be available or are completely free of human or technological errors.
                      We will not be liable if, for any reason, all or any part of the Platform are
                      unavailable at any time or for any period.You must provide the equipment and
                      Internet connections necessary to access the Services at your own expense. We
                      do not guarantee that the Services will operate with your computer, mobile
                      device, internet service plans, or mobile provider service plans or with any
                      particular computer or other piece of hardware, software, equipment, or device
                      you install on or use with your computer.
                    </p>
                  </li>
                  <li>
                    <p className='bold'>Changes to the Platform.</p>
                    <p>
                      We may update the content on the Platform from time to time, but its content
                      is not necessarily complete or up-to-date. Any of the material on the Platform
                      may be out of date at any given time, and we are under no obligation to update
                      such material. We may from time to time develop and provide Platform updates,
                      which may include upgrades, bug fixes, patches and other error corrections or
                      new features (collectively, including related documentation, “Updates”).
                      Updates may also modify or delete in their entirety certain features and
                      functionality. You further agree that all Updates will be deemed part of the
                      Services and be subject to all terms and conditions of this Agreement. You
                      agree that we have no obligation to provide any Updates or to continue to
                      provide or enable any particular features or functionality.
                    </p>
                  </li>
                  <li>
                    <p className='bold'>Security.</p>
                    <p>
                      You shall be solely responsible for the security, confidentiality and
                      integrity of all information that you receive, transmit through or store
                      related to the Services. You understand that we cannot and do not guarantee or
                      warrant that files available for downloading from the Internet or the Platform
                      will be free of viruses or other destructive code. You are responsible for
                      implementing sufficient procedures and checkpoints to satisfy your particular
                      requirements for anti-virus protection and accuracy of data input and output,
                      and for maintaining a means external to our Platform for any reconstruction of
                      any lost data. No data transmission over the Internet can be guaranteed to be
                      100% safe. Thus, we cannot warrant that your information will be absolutely
                      secure. You shall be solely responsible for any authorized or unauthorized
                      access data transmitted and use of your account by any person. You have the
                      affirmative responsibility to monitor and control access to your account
                      information.
                    </p>
                  </li>
                  <li>
                    <p className='bold'>Offerings and Pricing.</p>
                    <p>
                      The Services provided under the Agreement may vary from current form, may
                      varying levels of content or requests for information, or may have different
                      different pricing. We may make changes in price or availability of any new or
                      changes in Services at any time and for any reason, with or without notice and
                      without any liability whatsoever. To the extent you have a separate agreement
                      with Clear Capital or an authorized Clear Capital reseller regarding pricing
                      for specified Services, that agreement shall take precedence over this
                      sub-section of the Agreement.
                    </p>
                  </li>
                  <li>
                    <p className='bold'>Disclaimers.</p>
                    <p>
                      Any analysis performed by the Services has not been performed in accordance
                      with the Uniform Standards of Professional Appraisal Practice which requires
                      valuers to act as unbiased, disinterested third parties with impartiality,
                      objectivity and independence and without accommodation of personal interest.
                      Any analysis performed by the Services is not an appraisal or broker price
                      opinion and should not be relied on as such. Use of any analysis included in
                      the Services is subject to this Agreement by the user and any contracts in
                      place between Clear Capital and the user. Although the information included in
                      the Services has been derived from sources that are deemed to be reliable, the
                      information may be inaccurate or incomplete, and electronic versions of this
                      report may contain additional information not included herein. Further, any
                      analysis has been generated on an automated basis from available data and did
                      not include a physical inspection of the applicable property or neighborhood.
                      Therefore, no guarantees or warranties are made as to the accuracy of the
                      analysis, the characteristics, condition or existence of the property, or any
                      other information included in the analysis.
                    </p>
                  </li>
                  <li>
                    <p className='bold'>Feedback.</p>
                    <p>
                      Any and all suggestions, solutions, improvements, corrections, and other
                      contributions provided by you regarding the Services shall be owned by Clear
                      Capital.
                    </p>
                  </li>
                  <li>
                    <p className='bold'>Data Use.</p>
                    <p>
                      Notwithstanding anything in this Agreement or any other agreement to the
                      contrary, you consent to Clear Capital’s collection, use, reproduction,
                      storage and review of data related to the Services in connection with: (a)
                      Clear Capital’s legal and regulatory record keeping requirements, (b) archival
                      services performed by Clear Capital on your behalf, (c) Clear Capital’s
                      quality assurance and vendor ranking efforts, and (d) Clear Capital’s
                      collection, storage, reproduction, and use of statistical and other
                      information for purposes of improving its products and services. Clear Capital
                      will comply with all applicable laws and regulations, including but not
                      limited to the Gramm-Leach-Bliley Act, in connection with these activities,
                      and will not specifically identify you in association with any statistical
                      information.
                    </p>
                  </li>
                </ol>
              </li>
              {/* 4. User Representations */}
              <li className='user-representations'>
                <h4>User Representations; Restrictions on Use of Services.</h4>
                <ol type='a'>
                  <li>
                    <span className='bold'>You represent and warrant to Clear Capital that:</span>
                    <ol type='i'>
                      <li>You are at least 18 years of age;</li>
                      <li>
                        Your use of the Platform and Services does not and will not constitute a
                        breach or violation of any other agreement, contract, terms of use, or
                        similar policy or understanding to which you are or may be subject;
                      </li>
                      <li>
                        You will not use the Platform or the Services to violate any statute, law,
                        rule or regulation, any agreement between Clear Capital or an authorized
                        Clear Capital reseller and you, or to otherwise violate the legal rights of
                        Clear Capital, an authorized Clear Capital reseller, or any third person;
                      </li>
                      <li>
                        All information provided by you to Clear Capital is truthful, accurate and
                        complete;
                      </li>
                      <li>
                        You have provided and will maintain accurate and complete registration
                        information with Clear Capital, including, without limitation, your legal
                        name, address and telephone number;
                      </li>
                      <li>
                        You will not access or use the Services in order to gain competitive
                        intelligence about Clear Capital, the Services, or any product or service
                        offered by Clear Capital or to otherwise compete with Clear Capital;
                      </li>
                      <li>
                        You are in fact an authorized representative of the entity on behalf of
                        which you purport to act;
                      </li>
                      <li>
                        You will use the Services for a legitimate business purpose related to
                        professional activities related to real estate collateral valuation
                        professional services; and
                      </li>
                      <li>You will comply with all the terms and conditions of this Agreement.</li>
                    </ol>
                  </li>
                  <li>
                    <span className='bold'>
                      In addition to complying with any other posted terms and conditions applicable
                      to your use of the Services, you agree that when using the Services, you will
                      not:
                    </span>
                    <ol type='i'>
                      <li>
                        Upload, post, transmit or otherwise make available any information that is
                        unlawful, harmful, threatening, abusive, harassing, tortious, defamatory,
                        vulgar, obscene, libelous, invasive of another’s privacy, hateful, or
                        racially, ethnically or otherwise objectionable;
                      </li>
                      <li>Harm any person(s) in any way;</li>
                      <li>
                        Use the Services or any content forming any part of the Services in any way
                        or for any purpose that would violate, or would have the effect of
                        violating, any applicable laws, rules or regulations or any rights of any
                        third parties, including without limitation, any law or right regarding any
                        copyright, patent, trademark, trade secret, or other proprietary or property
                        right, false advertising, telemarketing, unfair competition, defamation,
                        invasion of privacy, rights of celebrity, or other federal or state law,
                        rule, or regulation;
                      </li>
                      <li>
                        Delete, modify, or attempt to change or alter any of the content forming any
                        part of the Services or notices on the Services;
                      </li>
                      <li>
                        Introduce into the Services any virus, rogue program, time bomb, drop dead
                        device, back door, trojan horse, worm or other malicious or destructive
                        code, software routines, denial of service attack, or equipment components
                        designed to permit unauthorized access to the Services content forming any
                        part of the Services, or to otherwise harm other users, or any third
                        parties, or perform any such actions;
                      </li>
                      <li>
                        Use the Platform in any manner that could disable, overburden, damage, or
                        impair the Platform or interfere with any other party’s use of the Platform,
                        including their ability to engage in real time activities through the
                        Platform;
                      </li>
                      <li>
                        Use the Services to commit fraud or conduct other unlawful activities or to
                        impersonate any person or otherwise falsely state or misrepresent your
                        relationship with a person;
                      </li>
                      <li>
                        Access or attempt to access any other person’s account, information, or
                        content without permission;
                      </li>
                      <li>
                        Copy, modify, create derivative works, reverse engineer, decompile,
                        disassemble, or otherwise attempt to learn the source code, structure, or
                        ideas upon which the Services is based;
                      </li>
                      <li>
                        Use any bot, spider, or other automatic or manual device or process for the
                        purpose of harvesting or scraping the information contained on the Services
                        for any reason;
                      </li>
                      <li>Frame or mirror any part of the Services;</li>
                      <li>
                        Connect to or access any Clear Capital computer system or network without
                        authorization;
                      </li>
                      <li>
                        Use any of Clear Capital’s trademarks without approval, or remove or modify
                        any copyright, trademark, or other intellectual property notice that appear
                        on the Platform or the Services;
                      </li>
                      <li>
                        Use the information in the Services to create or sell a similar service;
                      </li>
                      <li>
                        Resell the Services or any portion thereof without the express written
                        permission of Clear Capital; or
                      </li>
                      <li>
                        Attempt to, or permit or encourage any third party, to do any of the above.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <span>
                      In order to protect the integrity of the Services, Clear Capital reserves the
                      right at any time in its sole discretion to block users from certain IP
                      addresses from accessing the Services. You may not use any technologies or
                      processes to circumvent any IP blocks or other mechanism put in place by Clear
                      Capital to limit, restrict, or prevent access to the Services.
                    </span>
                  </li>
                </ol>
              </li>
              {/* 5. User Privacy */}
              <li>
                <h4>User Privacy.</h4>
                <p>
                  The Services are governed by, as applicable, by the Clear Capital Privacy Policy .
                  Please read the Privacy Policy before accessing the Services or providing any
                  personal information through the Services.
                </p>
              </li>
              {/* 6. Third Party... */}
              <li>
                <h4>Third Party Websites and Services.</h4>
                <p>
                  The Services may use or provide access to one or more third parties to process
                  payments, process account or user registrations, or provide other services. Your
                  interactions with any of these third parties is controlled by the terms and
                  conditions imposed by those third parties. Any disputes arising regarding a third
                  party’s services must be resolved directly between user and the third party. Clear
                  Capital disclaims all warranties or representations regarding any third-party
                  services. Clear Capital, in its sole discretion, and without notice to you or any
                  user, may subcontract any services related to the Services to be performed by a
                  third party.
                </p>
                <p>
                  A link from the Services to a non-Clear Capital website does not mean that Clear
                  Capital endorses or accepts any responsibility for the content, functioning,
                  policies, or use of such services, and you enter any such website at your own
                  risk. It is your responsibility to take precautions to ensure that whatever
                  website or other online materials that you select for use are free of viruses and
                  other items of a destructive nature. Additionally, we suggest you review the
                  linked site’s terms of service and privacy policy, and if you do not agree to be
                  bound by the terms of that site, terminate your visit to that site. We are not
                  responsible for the privacy policies and practices of the sites operated by our
                  business partners or other third parties. Clear Capital expressly disclaims any
                  liability related to such sites. Clear Capital also prohibits unauthorized
                  hypertext links to the Services or the framing of any content available through
                  the Services. Clear Capital reserves the right to disable any unauthorized links
                  or frames.
                </p>
                <ol type='a'>
                  <li>
                    Some of the Services include Google Maps features and content, and your use of
                    Google Maps features and content is subject to the then-current versions of the:
                    (1) Google Maps/Google Earth Additional Terms of Service at{' '}
                    <a href='https://maps.google.com/help/terms_maps.html'>
                      https://maps.google.com/help/terms_maps.html
                    </a>
                    ; and (2) Google Privacy Policy at{' '}
                    <a href='https://www.google.com/policies/privacy/'>
                      https://www.google.com/policies/privacy/
                    </a>
                    .
                  </li>
                </ol>
              </li>
              {/* 7. Disclaimer of Warranties */}
              <li>
                <h4>Disclaimer of Warranties.</h4>
                <ol type='a'>
                  <li>
                    EXCEPT AS WE OTHERWISE EXPRESSLY PROVIDE IN THIS AGREEMENT, THE SERVICES AND ALL
                    RELATED SERVICES OF CLEAR CAPITAL ARE PROVIDED “AS IS”. USE OF THE SERVICES IS
                    AT YOUR SOLE RISK. WE, OUR THIRD-PARTY LICENSORS, AND BUSINESS PARTNERS DO NOT
                    WARRANT OR MAKE ANY PROMISES REGARDING THE CORRECTNESS, USEFULNESS, ACCURACY,
                    AVAILABILITY, OR RELIABILITY OF: (i) YOUR USE OR THE RESULTS OF YOUR USE OF THE
                    SERVICES; (ii) ANY ADVICE YOU GLEAN FROM THE SERVICES WHETHER PROVIDED BY US OR
                    A THIRD PARTY; OR (iii) ANY OTHER CONTENT AVAILABLE THROUGH THE SERVICES. WE DO
                    NOT PROMISE THAT THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE, OR THAT ANY
                    DEFECTS WILL BE CORRECTED. WE GIVE NO WARRANTY OF ANY KIND, INCLUDING ANY
                    WARRANTY OF TITLE, MERCHANTABILITY, NON-INFRINGEMENT, OR FITNESS FOR A
                    PARTICULAR PURPOSE. WE, OUR THIRD-PARTY LICENSORS, AND BUSINESS PARTNERS WILL
                    HAVE NO RESPONSIBILITY FOR THE TIMELINESS, DELETION, MISDELIVERY, OR FAILURE TO
                    STORE ANY COMMUNICATION, OR CONTENT. WE DO NOT MAKE ANY REPRESENTATION OR
                    WARRANTY CONCERNING ERRORS, OMISSIONS, DELAYS, OR DEFECTS IN THE SERVICES OR ANY
                    INFORMATION SUPPLIED TO YOU VIA THE SERVICES, OR THAT FILES AVAILABLE THROUGH
                    SERVICES ARE FREE OF VIRUSES, WORMS, TROJAN HORSES, OR OTHER CODE THAT INCLUDE
                    OR MANIFEST CONTAMINATING OR DESTRUCTIVE CHARACTERISTICS. CLEAR CAPITAL IS NOT A
                    BACKUP SERVICE FOR STORING USER DATA, AND CLEAR CAPITAL SHALL HAVE NO LIABILITY
                    REGARDING ANY LOSS OF USER DATA. USERS ARE SOLELY RESPONSIBLE FOR CREATING
                    BACKUPS OF ANY USER DATA UPLOADED USING THE SERVICES.
                  </li>
                  <li>
                    WHILE CLEAR CAPITAL USES COMMERCIALLY REASONABLE EFFORTS TO UPDATE THE SERVICES
                    IN ACCORDANCE WITH CONTINUALLY CHANGING LAWS, CODES, STANDARDS, REQUIREMENTS AND
                    REGULATIONS (COLLECTIVELY, “LAWS”), INFORMATION AND FORMS, USER MUST ALWAYS
                    EXAMINE THE MOST CURRENT LAWS, INFORMATION AND FORMS TO ENSURE THAT USER IS IN
                    FULL COMPLIANCE WITH ANY AND ALL APPLICABLE LAWS. WITHOUT LIMITING THE
                    GENERALITY OF THE FOREGOING DISCLAIMERS, CLEAR CAPITAL DOES NOT WARRANT THAT USE
                    OF THE SERVICES WILL RESULT IN USER’S COMPLIANCE WITH ANY APPLICABLE LAWS, AND
                    USER UNDERSTANDS AND ACKNOWLEDGES THAT IT IS SOLELY RESPONSIBLE FOR ENSURING ITS
                    COMPLIANCE WITH ANY AND ALL APPLICABLE LAWS. BY PROVIDING THE SERVICES, CLEAR
                    CAPITAL IS NOT PROVIDING USER WITH LEGAL ADVICE.
                  </li>
                  <li>
                    THE FOREGOING EXCLUSIONS AND DISCLAIMERS ARE AN ESSENTIAL PART OF THIS
                    AGREEMENT. SOME STATES DO NOT ALLOW EXCLUSION OF AN IMPLIED WARRANTY, SO THESE
                    DISCLAIMERS MAY NOT APPLY TO YOU.
                  </li>
                </ol>
              </li>
              {/* 8. Limitation of Liability */}
              <li>
                <h4>Limitation of Liability.</h4>
                <ol type='a'>
                  <li>
                    <p className='bold'>Services and Related Conduct.</p>
                    <p>
                      NEITHER CLEAR CAPITAL, NOR ANY OF ITS SUBSIDIARIES, AFFILIATES, OR THEIR
                      RESPECTIVE OFFICERS, DIRECTORS, AGENTS, CONTRACTORS, BUSINESS PARTNERS,
                      LICENSORS, EMPLOYEES, ASSIGNEES, AND SUCCESSORS-IN-INTEREST (COLLECTIVELY, THE
                      “<span className='bold'>CLEAR CAPITAL PARTIES</span>”), WILL BE LIABLE TO YOU
                      OR ANY THIRD PARTY FOR ANY CLAIMS, DEMANDS, OR CAUSES OF ACTION, DIRECT OR
                      INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES
                      OR LOST PROFITS OR LOSS OF GOODWILL OR BUSINESS REPUTATION OR LOSS OF DATA OR
                      COST OF PROCUREMENT OF SUBSTITUTE PRODUCTS OR SERVICES OR OTHER INTANGIBLE
                      LOSS, HOWEVER CAUSED AND UNDER ANY THEORY OF LIABILITY, RELATING TO THIS
                      AGREEMENT, YOUR USE OF THE SERVICES, OR ANY INFORMATION YOU OBTAIN ON IT, OR
                      ANY OTHER INTERACTION WITH THE SERVICES, AND YOU VOLUNTARILY AND UNEQUIVOCALLY
                      WAIVE ANY LIABILITY OF THE CLEAR CAPITAL PARTIES. FURTHER, CLEAR CAPITAL SHALL
                      NOT BE LIABLE FOR ANY LOSS OR DAMAGE TO YOU AS A RESULT OF: (A) THE USE OF OR
                      INABILITY TO USE THE PLATFORM OR THE SERVICES; (B) PERSONAL INJURY OR PROPERTY
                      DAMAGE OF ANY KIND WHATSOEVER CAUSED BY YOUR ACCESS TO, USE, OR MISUSE OF THE
                      SERVICES; (C) ANY RELIANCE, WHETHER DIRECTLY OR INDIRECTLY, PLACED BY YOU ON
                      THE COMPLETENESS, ACCURACY OR EXISTENCE OF THE SERVICES, INCLUDING BUT NOT
                      LIMITED TO, AS A RESULT OF ANY RELATIONSHIP OR TRANSACTION BETWEEN YOU AND ANY
                      FINANCIAL INSTITUTION, LENDER, LICENSOR, DISTRIBUTOR, FULFILLMENT CENTER,
                      SUPPLIER, SPONSOR OR ANY OTHER THIRD PARTY FOR WHOM YOU ARE ASKING PRODUCTS OR
                      SERVICES; (D) ANY CHANGES WHICH CLEAR CAPITAL MAY MAKE TO THE SERVICES, OR FOR
                      ANY PERMANENT OR TEMPORARY CESSATION IN THE PROVISION OF THE SERVICES (OR ANY
                      FEATURES WITHIN THE SERVICES); (E) THE DELETION OF, CORRUPTION OF, OR FAILURE
                      TO CURE, ANY CONTENT, DATA, INFORMATION AND/OR OTHER COMMUNICATIONS DATA
                      MAINTAINED OR TRANSMITTED BY OR THROUGH YOUR USE OF THE SERVICES; OR (F) YOUR
                      FAILURE TO PROVIDE CLEAR CAPITAL WITH ACCURATE INFORMATION. CLEAR CAPITAL IS
                      NOT AN INSURER WITH REGARD TO PERFORMANCE OF THE SERVICES. THE DISCLAIMER OF
                      WARRANTIES AND THE LIMITATION OF LIABILITY AND REMEDY ARE A REFLECTION OF THE
                      RISKS ASSUMED BY THE PARTIES IN ORDER FOR USER TO OBTAIN THE RIGHTS TO USE THE
                      SERVICES AT THE SPECIFIED PRICE, IF ANY. USER AGREES TO ASSUME THE RISK FOR:
                      (i) ALL LIABILITIES DISCLAIMED BY CLEAR CAPITAL CONTAINED HEREIN; AND (ii) ALL
                      ALLEGED DAMAGES IN EXCESS OF THE AMOUNT, IF ANY, OF THE LIMITED REMEDY
                      PROVIDED HEREUNDER. YOUR SOLE AND EXCLUSIVE REMEDY FOR DISSATISFACTION WITH
                      THE SERVICES WILL BE TO STOP USING THE SERVICES.
                    </p>
                  </li>
                  <li>
                    <p className='bold'>Clear Capital’s Maximum Liability for Any Claim.</p>
                    <p>
                      IN ANY EVENT, THE MAXIMUM TOTAL LIABILITY OF THE CLEAR CAPITAL PARTIES, FOR
                      ANY CLAIM WHATSOEVER RELATING IN ANY WAY TO THIS AGREEMENT OR YOUR USE OF THE
                      SERVICES OR PURCHASE OF A PRODUCT THROUGH THE SERVICES OR YOUR USE OF ANY SUCH
                      PRODUCT, INCLUDING CLAIMS FOR BREACH OF CONTRACT, TORT (INCLUDING, NEGLIGENCE
                      OR STRICT LIABILITY) OR OTHERWISE, AND YOUR SOLE REMEDY, SHALL BE AN AWARD FOR
                      DIRECT, PROVABLE DAMAGES NOT TO EXCEED ONE HUNDRED U.S. DOLLARS ($100.00 USD).
                    </p>
                  </li>
                  <li>
                    <p className='bold'>State Law Waiver.</p>
                    <p>
                      In entering into this release you expressly waive any protections (whether
                      statutory or otherwise), including Section 1542 of the California Civil Code
                      if applicable (and any other comparable statute), that would otherwise limit
                      the coverage of this release to include only those claims which you may know
                      or suspect to exist in your favor at the time of agreeing to this release.
                      Section 1542 of the California Civil Code reads as follows: “A general release
                      does not extend to claims that the creditor or releasing party does not know
                      or suspect to exist in his or her favor at the time of executing the release
                      and that, if known by him or her, would have materially affected his or her
                      settlement with the debtor or released party.”
                    </p>
                  </li>
                </ol>
              </li>
              {/* 9. Indemnification */}
              <li>
                <h4>Indemnification.</h4>
                <p>
                  To the fullest extent permitted by applicable law, you agree to hold harmless,
                  indemnify, and defend Clear Capital from and against any and all claims (including
                  liabilities, fines, damages, losses, costs, expenses, and reasonable attorneys’
                  fees) arising out of or relating to (i) your access to and/or use of the Platform;
                  (ii) your breach of any term or condition of this Agreement, (iii) your violation
                  of any law or the rights of a third party; (iv) any dispute or issue between you
                  and a third party; (v) any information you provide through the Platform or
                  Services: (vi) your willful misconduct; (vii) your acts or omissions during use of
                  the Services, or (iv) your unauthorized acts or omissions during use of the
                  Services.
                </p>
                <p>
                  You will have the right to defend and compromise such claim at your expense for
                  the benefit of the Clear Capital Parties; provided, however, you will not have the
                  right to obligate the Clear Capital Parties in any respect in connection with any
                  such settlement without the written consent of the indemnified party; provided,
                  further, Clear Capital will have the right to participate in the defense of such
                  claim at its expense using counsel of its choice. Notwithstanding the foregoing,
                  if you fail to assume your obligation to defend or if Clear Capital elects to
                  defend such claims itself, the Clear Capital Parties may do so to protect their
                  interests and you will reimburse all costs incurred by the Clear Capital Parties
                  in connection with such defense.
                </p>
                <p>This section shall survive the termination of the Agreement.</p>
              </li>
              {/* 10. Agreement to Arbitrate */}
              <li>
                <h4>Agreement to Arbitrate and Prohibition on Class Actions.</h4>
                <ol type='a'>
                  <li>
                    <p className='bold'>Choice of Law.</p>
                    <p>
                      The validity, construction, and effect of this Agreement will be governed by
                      the laws of the state of California, without giving effect to that state’s
                      conflict of laws rules. Any legal suit, action or proceeding arising out of,
                      or related to, the Agreement or the Platform, shall be instituted exclusively
                      in the federal courts of the United States or the courts of the State of
                      California, in each case located in Placer County, California, although we
                      retain the right to bring any suit, action, or proceeding against you for
                      breach of this Agreement in your country or county of residence, or any other
                      relevant country or county. You waive any and all objections to the exercise
                      of jurisdiction over you by such courts and to venue in such courts.
                    </p>
                  </li>
                  <li>
                    <p className='bold'>
                      Arbitration Procedure. <br />
                      If you have any issues or dispute with Clear Capital you agree to first
                      contact us at legal@clearcapital.com and attempt to resolve the dispute with
                      us informally. All disputes arising out of, or relating to, this Agreement
                      (including formation, performance, breach, enforceability, and validity of
                      this Agreement), our operation of the Services, or a purchase made through the
                      Services shall be resolved by final and binding arbitration pursuant to the
                      Commercial Arbitration Rules of the American Arbitration Association. The
                      arbitration will be held in Reno, Nevada or another location if Clear Capital
                      consents to such other location, which consent may be withheld in the sole
                      discretion of Clear Capital. The arbitrator, and not any federal, state, or
                      local court or agency, shall have exclusive authority to resolve any dispute
                      relating to the interpretation, applicability, enforceability, or formation of
                      this Agreement, including any claim that all or any part of this Agreement is
                      void or voidable. The award rendered by the arbitrator shall include costs of
                      arbitration, reasonable attorneys’ fees, and reasonable costs for expert and
                      other witnesses, and any judgment on the award rendered by the arbitrator may
                      be entered in any court of competent jurisdiction.
                    </p>
                  </li>
                  <li>
                    <p className='bold'>Waiver of Class Actions; Jury Trials.</p>
                    <p>
                      Any dispute resolution proceedings will be conducted only on an individual
                      basis and not in a class, consolidated or representative action. Class actions
                      and class arbitrations are prohibited.
                    </p>
                    <p className='bold'>
                      If for any reason a claim proceeds in court rather than in arbitration the
                      parties each waive any right to a jury trial.
                    </p>
                  </li>
                  <li>
                    <p className='bold'>Limitation on Claims.</p>
                    <p>
                      No action arising under this Agreement may be brought by a user more than one
                      (1) year after the cause of action has accrued.
                    </p>
                  </li>
                  <li>
                    <p className='bold'>Injunctive Relief.</p>
                    <p>
                      Without prejudice to the agreement to resolve disputes in binding arbitration
                      set forth above, either party to this Agreement may obtain preliminary
                      injunctive relief in a court of competent jurisdiction, for the purpose of
                      enforcing any of the terms of this Agreement pending a final determination in
                      arbitration or permanent relief for the purpose of enforcing arbitral awards.
                    </p>
                  </li>
                </ol>
              </li>
              {/* 11. Digital Millennium */}
              <li>
                <h4>Digital Millennium Copyright Act Procedure.</h4>
                <p>
                  In the event you become aware of any material on the Platform that you believe
                  infringes upon any copyright that you own or control, you may send a written
                  notification of such alleged infringement to Clear Capital’s agent for
                  notification of claims of copyright in accordance with the following requirements:
                </p>
                <ol type='a'>
                  <li>
                    an electronic or physical signature of the person authorized to act on behalf of
                    the owner of the exclusive right which is allegedly being infringed;
                  </li>
                  <li>
                    identification of the copyrighted work that is alleged to have been infringed
                    or, if more than one, a representative list;
                  </li>
                  <li>
                    identification of the material which needs to be removed or altered and a
                    sufficiently detailed description of the location of the protected material on
                    the site (e.g., URL);
                  </li>
                  <li>
                    a sufficient means for Clear Capital to contact you, such as your address,
                    telephone number, and e-mail address (if available);
                  </li>
                  <li>
                    a statement by you that you have a good faith belief that the disputed use is
                    not authorized by the copyright owner, its agent, or the law and that you have
                    authority to make such a statement; and
                  </li>
                  <li>
                    a statement by you, made under penalty of perjury, that the above information in
                    your notice is accurate and that you are the copyright owner or authorized to
                    act on the copyright’s owner’s behalf.
                  </li>
                </ol>
                <p>
                  <span>The foregoing information shall be sent to the following address:</span>
                  <br />
                  <span>legal@clearcapital.com with the Subject Line “DMCA Notification.”.</span>
                </p>
              </li>
              {/* 12. Cooperation with LE */}
              <li>
                <h4>Cooperation with Law Enforcement and Regulatory Bodies.</h4>
                <p>
                  We have the right to fully cooperate with any law enforcement authorities,
                  regulatory agencies, or court order requesting or directing us to disclose the
                  identity or other information of anyone sharing information with us through the
                  Platform. YOU WAIVE AND HOLD HARMLESS CLEAR CAPITAL AND ITS AFFILIATES, LICENSEES
                  AND SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY THE CLEAR
                  CAPITAL AND/OR ANY OF THE FOREGOING PARTIES DURING OR AS A RESULT OF ITS
                  INVESTIGATIONS AND FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY
                  EITHER THE CLEAR CAPITAL OR SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES.
                </p>
              </li>
              {/* 13. Electronic Communication */}
              <li>
                <h4>Electronic Communication.</h4>
                <p>
                  By accepting this Agreement and using the Services, you consent to receive
                  electronically all communications or notices sent by Clear Capital with regard to
                  the Services or this Agreement to any phone number or email address you provide to
                  Clear Capital. It is your responsibility to update your contact information
                  provided to Clear Capital. In order to receive electronic communications, you must
                  have a working connection to the internet and meet any specifications required by
                  your email service provider. Clear Capital may send communications in text message
                  or a non-electronic format in Clear Capital’s discretion. Emails sent to you by
                  Clear Capital are governed by our Privacy Policy.
                </p>
              </li>
              {/* 14. Misc. terms */}
              <li>
                <h4>Miscellaneous Terms.</h4>
                <ol type='a'>
                  <li>
                    <p className='bold'>Term and Termination.</p>
                    <p>
                      You may terminate this Agreement at any time by closing your account,
                      discontinuing your use of the Services, and providing Clear Capital or an
                      authorized Clear Capital reseller with a notice of termination; however, you
                      will not be entitled to any refund of any prepaid amounts. Please review this
                      Agreement and our Privacy Policy for information about what we do with your
                      account when terminated. We may terminate your account, suspend your ability
                      to use certain portions of the Services and/or ban you altogether from the
                      Services for any or no reason, and without notice or liability of any kind.
                      Any such action could prevent you from accessing your account, the Services,
                      your user content, other content on the Services, or any other related
                      information. In the event of any termination of this Agreement, any provisions
                      which by their nature should continue following termination shall so continue.
                    </p>
                  </li>
                  <li>
                    <p className='bold'>Survival of Terms.</p>
                    <p>
                      The terms and conditions providing for any activity following the termination
                      or expiration of the Agreement, any warranties, disclaimers, remedies and any
                      indemnification obligations, and any other provision which, by its terms are
                      intended to survive the termination of the Agreement, shall survive the
                      termination or expiration of the Agreement.
                    </p>
                  </li>
                  <li>
                    <p className='bold'>Complete Agreement.</p>
                    <p>
                      This Agreement, along with the Privacy Policy, constitutes the entire
                      agreement between you and Clear Capital relating to your use of, and access
                      to, the Services and supersedes any prior or contemporaneous agreements or
                      representations. This Agreement may not be amended except as set forth herein.
                      Any user of the Platform may be subject to additional terms and services that
                      may apply through the use of affiliated services or third party sites.
                    </p>
                  </li>
                  <li>
                    <p className='bold'>Independent Contractors.</p>
                    <p>
                      The parties and their respective personnel, are and shall be independent
                      contractors and neither party by virtue of this Agreement shall have any
                      right, power or authority to act or create any obligation, express or implied,
                      on behalf of the other party.
                    </p>
                  </li>
                  <li>
                    <p className='bold'>Force Majeure.</p>
                    <p>
                      Clear Capital shall not be liable for any failure to perform any services or
                      other obligation related to this Agreement or the Services to the extent that
                      performance of its obligations are delayed or prevented by reason of any act
                      of God, fire, natural disaster, accident, riots, acts of government, shortage
                      of materials or supplies, or any other cause beyond the reasonable control of
                      Clear Capital.
                    </p>
                  </li>
                  <li>
                    <p className='bold'>Severability.</p>
                    <p>
                      The provisions of the Agreement are severable. If any provision of the
                      Agreement is declared invalid, illegal, or unenforceable by a court of
                      competent jurisdiction, such invalidity, illegality, or unenforceability will
                      not affect any other provision of the Agreement, and the remainder of the
                      Agreement will remain entirely in force.
                    </p>
                  </li>
                  <li>
                    <p className='bold'>No Waivers.</p>
                    <p>
                      Our failure to enforce or exercise any provision of this Agreement or related
                      right will not constitute a waiver of that right or provision. This Agreement
                      shall not be modified by any course of performance or course of dealing.
                    </p>
                  </li>
                  <li>
                    <p className='bold'>No Assignments and Transfers.</p>
                    <p>
                      No rights or obligations under this Agreement may be assigned or transferred
                      by you, either voluntarily or by operation of law, without our express prior
                      written consent and in our sole discretion. We reserve the right to transfer,
                      assign, sublicense or pledge this or any part of the Agreement or the
                      Services, without notice and without your consent.
                    </p>
                  </li>
                  <li>
                    <p className='bold'>No Third Party Beneficiaries.</p>
                    <p>
                      Subject to Sections 8 and 9, nothing in this Agreement will confer upon any
                      person or entity, other than the parties, any rights, remedies, obligations,
                      or liabilities whatsoever.
                    </p>
                  </li>
                  <li>
                    <p className='bold'>Notices.</p>
                    <p>
                      You shall provide any notices to us under this Agreement by e-mail or mail
                      using the contact information provided below. Unless you tell us otherwise, or
                      the law requires otherwise, you agree to receive all communications from us by
                      e-mail or through posting notices to your account. You are responsible for
                      providing Clear Capital with up-to-date contact information, which you may do
                      by updating your account information through the Services or by sending a
                      message to us via the contact information provided below. You agree that all
                      communications that we send to you electronically satisfy any legal
                      requirement that a communication be in writing. You may print the
                      communications for your records.
                    </p>
                  </li>
                </ol>
              </li>
              {/* 15. Other Agreements */}
              <li>
                <h4>Other Agreements with Us.</h4>
                <span className='underline'>
                  Unless expressly stated otherwise, your purchase or use of any products may be
                  subject to additional terms of use that will be reflected on the product.
                </span>
                <p>
                  If you enter specifically with us into a separate agreement, order form, or
                  statement of work with product specific terms (“
                  <span className='bold'>ClearCollateral Agreement</span>”), then the
                  ClearCollateral Agreement shall apply. If there is a conflict between an
                  applicable term in these Terms of Service and the ClearCollateral Agreement, then
                  the ClearCollateral Agreement will control with respect to that specific term as
                  it relates to your use of that product, service, or portion thereof.
                </p>
              </li>
              {/* 16. Contact Us */}
              <li>
                <h4>Contact Us.</h4>
                <span>
                  If you have any questions or need to contact us for any reason relating to this
                  Agreement, please contact us by email or write to us at:
                </span>
                <p>
                  <span>ClearCapital.com, Inc.</span>
                  <br />
                  <span>Attn: General Counsel</span>
                  <br />
                  <span>300 E 2nd St, Ste 1405</span>
                  <br />
                  <span>Reno, NV 89501</span>
                  <br />
                  <span>legal@clearcapital.com</span>
                  <br />
                </p>
              </li>
            </ol>
          </section>
          <section id='exhibit-a' data-sqa-id='cc-tos-exhibit-a'>
            <h3>Exhibit A</h3>
            <h4>CCR SLA and Standard Support Schedule</h4>
            <ol>
              <li>
                <span className='bold'>Standard Support.</span>
                <ol type='a'>
                  <li>
                    Standard support hours are from 6:00 am - 6:00 pm Pacific Time, Monday through
                    Friday, excluding Clear Capital holidays. Clear Capital holidays include the
                    following: New Year's Day, Independence Day, Veteran's Day, Thanksgiving Day,
                    Christmas Eve and Christmas Day. Live telephone support and email support will
                    be provided during standard support hours. Outside of these hours, Clear Capital
                    will monitor the customer support email box on a twenty-four by seven by 365-day
                    basis (24/7/365) for any critical incidents. Technical staff members are
                    available outside of business hours to manage critical issue resolution.
                  </li>
                  <li>
                    Clear Capital will use commercially reasonable efforts to respond to all service
                    requests within one (1) business day.
                  </li>
                  <li>
                    As a web-based platform, at this time CCR maintains support for most current
                    versions of Chrome and Firefox browsers.
                  </li>
                </ol>
              </li>
              <li>
                <span className='bold'>Availability SLA.</span>
                <ol type='a'>
                  <li>
                    <span>
                      System availability is defined as the Services are available for Major System
                      Functions for a predominance of users and user groups. The Services would be
                      deemed unavailable for a period of time if the predominance of users and user
                      groups could not perform one or more of the Major System Functions. Clear
                      Capital will use commercially reasonable efforts to make the Services
                      available per the following standards.
                    </span>
                    <ol type='i'>
                      <li>
                        CCR’s production system will be available, using commercially reasonable
                        efforts, 99% or more of the month, excluding reserved maintenance windows.
                        This shall be calculated on a calendar month basis, 24 hours per day, 7 days
                        per week, and 365 days per year.
                      </li>
                      <li>
                        Reserved maintenance windows will typically be scheduled to take place
                        between 7:00 PM PT and 4:00 AM PT, with 7 days prior notification.
                      </li>
                      <li>
                        Major System Functions are defined as the ability to place and receive
                        orders, and complete the review process, including automated rule execution,
                        on delivered valuation products. This ability applies to any APIs, any
                        System services, and all aspects of the CCR user interface that support
                        these aforementioned functions.
                      </li>
                      <li>
                        Secondary System Functions include all CCR functionality outside of the
                        Major System Functions.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li>
                <span className='bold'>Incident Resolution SLAs:</span>
                <ol type='a'>
                  <li>
                    Clear Capital will use commercially reasonable efforts to address and resolve
                    any incidents identified. The following table defines standard severity
                    classifications and associated resolution SLAs. Clear Capital will assign a
                    severity for every incident identified and work to resolve the incident within
                    the outlined SLAs.
                  </li>
                  <li>
                    Where agreed to by the parties, Clear Capital may also resolve Critical and High
                    Severity incident with a workaround or patch. In such cases, Clear Capital will
                    advise of a workaround. Clear Capital will then provide a timeline for final
                    fixes of Critical or High severity failures as quickly as reasonably possible.
                  </li>
                  <li>
                    If during triage or troubleshooting an incident Clear Capital requires
                    additional information from third parties and/or Users, then Clear Capital will
                    request such information via email or phone. The duration of time taken by third
                    parties and/or Users to respond with such information will be added to the total
                    SLA for the resolution time.
                  </li>
                </ol>
              </li>
            </ol>
            <table>
              <thead>
                <tr>
                  <th>Severity</th>
                  <th>Description</th>
                  <th>Resolution Time</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Critical</td>
                  <td>
                    <ol>
                      <li>
                        A major system function is so severely impacted, it results in a material
                        disruption to transaction processing productivity or operations.
                      </li>
                    </ol>
                  </td>
                  <td>
                    12 hours from incident identification by Clear Capital through system monitoring
                    or notification by an outside entity.
                  </td>
                </tr>
                <tr>
                  <td>High</td>
                  <td>
                    <ol>
                      <li>
                        A major system function is materially degraded, thereby causing
                        significantly delayed response or limited system use, or
                      </li>
                      <li>
                        Incomplete results are returned to users, even if system functions are
                        available, and
                      </li>
                      <li>No workaround exists that is acceptable.</li>
                    </ol>
                  </td>
                  <td>
                    By Midnight Pacific Time 1 business day from incident identification by Clear
                    Capital through system monitoring or notification by an outside entity.
                  </td>
                </tr>
                <tr>
                  <td>Medium</td>
                  <td>
                    <ol>
                      <li>
                        Major system functions are severely restricted, however, it has been
                        determined that an acceptable workaround exists.
                      </li>
                    </ol>
                  </td>
                  <td>
                    By Midnight Pacific Time 5 business days after notification from incident
                    identification by Clear Capital through system monitoring or notification by an
                    outside entity.
                  </td>
                </tr>
                <tr>
                  <td>Low</td>
                  <td>
                    <ol>
                      <li>Secondary system function or Infrastructure minor issue or defect and</li>
                      <li>Workarounds are available.</li>
                    </ol>
                  </td>
                  <td>
                    By Midnight Pacific Time 30 calendar days after notification from incident
                    identification by Clear Capital through system monitoring or notification by an
                    outside entity.
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
          <section id='exhibit-b' data-sqa-id='cc-tos-exhibit-b'>
            <h3>Exhibit B</h3>
            <h4>Additional terms for authorized Clear Capital reseller end users:</h4>
            <p>
              If any amounts required to be due for using the Services pursuant to your agreement
              with an authorized Clear Capital reseller are not paid within one hundred and twenty
              (120) days following the applicable billing period, Clear Capital shall provide you
              direct written notice identifying the amounts due and owing to Clear Capital along
              with copies of the associated notices. Upon receipt of such notice, you shall be
              responsible for said amounts and have thirty (30) days to cure; otherwise we will have
              the right to immediately suspend access to your account.
            </p>
          </section>
        </div>
      </div>
    </TermsOfService.Styled>
  )
}

TermsOfService.Styled = styled.div`
  background-color: ${(props) => theme.palette.dsm.greyscale.white};

  .tos-content-wrap {
    padding-top: 64px;
    width: 800px;
    margin-left: auto;
    margin-right: auto;
    /* TOS Specific Typography */
    font-family: Inter;
    color: ${(props) => theme.palette.dsm.greyscale.black};
    letter-spacing: 0px;
    font-style: normal;
    a {
      color: ${(props) => theme.palette.dsm.cobalt.dark};
      font-weight: 500;
    }
    ol {
      padding-left: 16px;
    }
    p, li {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
    h2 {
      font-size: 24px;
      font-weight: 500;
      line-height: 30px;
      text-align: center;
    }
    h3 {
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
    }
    h4 {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      margin-bottom: 16px;
    }

    .bold {
      font-weight: 600;
    }
    .underline {
      text-decoration: underline;
    }

    .tos-header {
      margin-top: 40px;
      display: flex;
      flex-direction: column;

      h2 {
        margin-top: 0;
        margin-bottom: 0;
      }
      p {
        text-align: center;
        margin-top: 4px;
        color: #767676;
      }
    }
    .tos-body {
      li:not(:last-child) {
        margin-bottom: 16px;
      }
      ol[type='a'] {
        li {
          p {
            margin: 0;
          }
        }
      }
      .user-representations {
        ol[type='i'] {
          margin-top: 4px;
        }
      }
      #terms-list {
        margin-top: 32px;
      }
      #exhibit-a {
        margin-top: 40px;
        ol[type='a'] {
          margin-top: 4px;
        }
        table {
          border-collapse: collapse;
          max-width: 771px;
          margin: 28px auto 0;
          thead {
            tr {
              box-shadow: inset 0px -1px 0px ${(props) => theme.palette.dsm.interface.dark};
            }
            th {
              text-align: left;
              padding-bottom: 16px;
              font-size: 14px;
              &:nth-child(2) {
                padding-left: 26px;
              }
            }
          }
          tbody {
            tr {
              border-bottom: 1px solid #D0D4D9;
            }
            td {
              padding: 12px 12px 12px 0;
              font-size: 14px;
              line-height: 22px;
              ol, li {
                margin-bottom: 0;
              }
              ol {
                padding-left: 40px;
              }
            }
          }
        }
      }
      #exhibit-b {
        margin-top: 40px;
        padding-bottom: 125px;
      }
    }
  }
}
`

export default TermsOfService
