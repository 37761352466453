import {productsApiV2} from 'services/apis'
import {addNotification as notify} from 'reapop'
import {ERROR_NOTIFICATION} from 'constants/notifications'
import {SET_PRODUCTS_FAILED} from 'constants/appMessages'
import {SET_ALL_PRODUCTS} from 'constants/actionTypes'

// gets ALL available products, regardless of org
export function getAllProducts() {
  return (dispatch) => {
    return productsApiV2
      .get('products')
      .then((products) => {
        dispatch({
          type: SET_ALL_PRODUCTS,
          payload: products,
        })
      })
      .catch(() => {
        dispatch(
          notify({
            ...ERROR_NOTIFICATION,
            message: SET_PRODUCTS_FAILED,
          })
        )
      })
  }
}
