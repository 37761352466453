import {RESET_CASE_EVENTS, SET_CASE_EVENTS, ADD_CASE_EVENTS} from 'constants/actionTypes'

export const initialState = []

export default function caseEvents(state = initialState, action) {
  switch (action.type) {
    case RESET_CASE_EVENTS:
      return []
    case SET_CASE_EVENTS:
      return action.payload
    case ADD_CASE_EVENTS:
      return Array.isArray(action.payload)
        ? [...action.payload, ...state]
        : [action.payload, ...state]
    default:
      return state
  }
}
