import {SET_ORDER_ACTIVITY} from 'constants/actionTypes'

export const initialState = {}

export default function orderActivityReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ORDER_ACTIVITY:
      return {...state, [action.payload.orderId]: action.payload}
    default:
      return state
  }
}
