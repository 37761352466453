import cloneDeep from 'lodash/cloneDeep'
import has from 'lodash/has'
import {appUserIdSelector} from 'selectors/appSelectors'

export function createAllFieldsDictionary(fields) {
  return fields.reduce((dictionary, field) => {
    if (field.fieldName) {
      const newField = {
        ...field,
      }
      if (field.fieldName === 'addToRule' || field.fieldName === 'addToComp') {
        newField.value = []
      }
      dictionary[field.fieldName] = newField

      return dictionary
    } else if (field.fields && field.fields.length) {
      // recursion
      return {
        ...dictionary,
        ...createAllFieldsDictionary(field.fields),
      }
    }
  }, {})
}

export function createAllFieldsWithValuesDictionary(fields, formValues) {
  const allFieldsDictionary = createAllFieldsDictionary(fields)
  const clonedDictionary = cloneDeep(allFieldsDictionary)
  formValues &&
    formValues.length &&
    formValues.forEach((field) => {
      if (field.fieldName && clonedDictionary[field.fieldName]) {
        clonedDictionary[field.fieldName].value = field.value
      }
    })

  return clonedDictionary
}

export function createCollectionNameToMembersDictionary(fields) {
  return fields.reduce((collectionDictionary, field) => {
    if (field.type === 'collection' && field.isRequired) {
      const collectionName = field.collectionName
      if (!collectionDictionary[collectionName]) {
        collectionDictionary[collectionName] = []
      }
      if (field.fields && field.fields.length) {
        // recursion
        return {
          ...collectionDictionary,
          ...createCollectionNameToMembersDictionary(field.fields, collectionDictionary),
        }
      }
    } else if (field.collectionName && collectionDictionary[field.collectionName]) {
      collectionDictionary[field.collectionName].push(field.fieldName)
    }

    return collectionDictionary
  }, {})
}

/**
 * Will create a dictionary that looks like this
 * {
 *  <conditionalFieldName>: [
 *    {
 *      key: <collectionName || fieldName>,
 *      requiredValue: <conditionalValue>
 *    },
 *    ...
 *  ],
 *  ...
 * }
 */
export function createConditionalDependenciesDictionary(
  fields,
  conditionalField = null,
  conditionalValue = null
) {
  return fields.reduce((dictionary, field) => {
    let key
    if (field.type === 'collection') {
      key = 'collectionName'
    } else {
      key = 'fieldName'
    }
    //  we want to add it to the top level array
    if (conditionalField) {
      if (!dictionary[conditionalField]) {
        dictionary[conditionalField] = []
      }
      dictionary[conditionalField].push({
        key: field[key],
        requiredValue: conditionalValue,
      })
    }

    // we also want to add a separate entry to track any conditional fields under this field
    if (field.conditionalField) {
      if (!dictionary[field.conditionalField]) {
        dictionary[field.conditionalField] = []
      }
      dictionary[field.conditionalField].push({
        key: field[key],
        requiredValue: field.conditionalValue,
      })
    }

    if (field.fields && field.fields.length) {
      let nestedDictionary

      // recursion
      if (field.conditionalField) {
        nestedDictionary = createConditionalDependenciesDictionary(
          field.fields,
          field.conditionalField,
          field.conditionalValue
        )
      } else {
        nestedDictionary = createConditionalDependenciesDictionary(field.fields)
      }

      Object.entries(nestedDictionary).forEach(([key, value]) => {
        dictionary[key] = dictionary[key] ? dictionary[key].concat(value) : value
      })
    }

    return dictionary
  }, {})
}

export function createFormSubmissions(review, fieldValuesDictionary) {
  const formSubmissions = []

  review.forEach((field, i) => {
    addFieldToSubmissionArray(field, fieldValuesDictionary, formSubmissions)
  })

  return formSubmissions
}

export function parseReviewFromApi(caseReview) {
  return caseReview.formSubmissions.map((submission) => {
    if (
      submission.fieldName === 'addToRule' ||
      submission.fieldName === 'addToComp' ||
      submission.fieldName === 'marketStrat'
    ) {
      submission.value = JSON.parse(submission.value)
    } else if (submission.value === 'true') {
      submission.value = true
    } else if (submission.value === 'false') {
      submission.value = false
    } else if (
      submission.fieldName === 'appraisedValue' ||
      submission.fieldName === 'conclusion' ||
      submission.fieldName === 'valueDecision'
    ) {
      const valueAsNumber = Number(submission.value)
      // an empty string converted to a number is 0, we don't want to represent a non entry as a 0
      submission.value =
        submission.value === '' || isNaN(valueAsNumber) ? submission.value : valueAsNumber
    }

    return submission
  })
}

export function prepReviewForApi(review, state) {
  const {coreCase, fieldValuesDictionary, formTemplate} = state.caseReview
  const userId = appUserIdSelector(state)

  const formSubmissions = createFormSubmissions(review, fieldValuesDictionary)

  formSubmissions.push({
    fieldName: 'lastUpdateDate',
    value: Date.now(),
  })

  formSubmissions.push({
    fieldName: 'lastUpdateUserId',
    value: userId,
  })

  return {
    caseId: coreCase.id,
    formId: formTemplate.id,
    formType: formTemplate.type,
    formUpdateId: formTemplate.updateId,
    organizationKey: formTemplate.organizationKey,
    formSubmissions,
  }
}

// this is used both when rendering the form fields, and also when creating the submission array
export function shouldRenderField(fieldValuesDictionary, field) {
  let shouldRender = true
  if (has(field, 'conditionalField') && has(field, 'conditionalValue')) {
    shouldRender = conditionsForFieldRenderAreMet(fieldValuesDictionary, field)
  }
  return shouldRender
}

function addFieldToSubmissionArray(field, fieldValuesDictionary, formSubmissions) {
  if (!field) {
    return
  }

  const fieldIsRendered = shouldRenderField(fieldValuesDictionary, field)
  // we only want to include values for fields that would be rendered (this includes collections and any of their children - unless the
  // collection is showing we don't want to include any child fields)
  if (fieldIsRendered) {
    if (field.type === 'collection' && field.fields && field.fields.length) {
      field.fields.forEach((field) => {
        addFieldToSubmissionArray(field, fieldValuesDictionary, formSubmissions)
      })
    } else {
      let value
      // if the field has a value (this will be the case for fields related to the complexConclusion), we want to use that
      if (field.value !== undefined && field.value !== null) {
        value = field.value
      } else {
        // for non-complexConclusion values the value we want to use is in the fieldValuesDictionary
        const valueItem = field.fieldName && fieldValuesDictionary[field.fieldName] // we need to pull the value from the fieldValuesDictionary
        if (valueItem && valueItem.value) {
          value = valueItem.value
        }
      }

      if (value !== undefined && value !== null) {
        if (typeof value === 'object' && value.value) {
          // Parses the values out of our select menu values
          value = value.value
        } else if (field.fieldName === 'addToRule' || field.fieldName === 'addToComp') {
          value = JSON.stringify(value)
        }

        const submissionObject = {
          fieldName: field.fieldName,
          value,
        }

        // only run the next bit of code when the field has a fieldCode (for collections)
        if (field.fieldCode) {
          submissionObject.fieldCode = field.fieldCode
          if (field.name) {
            submissionObject.name = field.name
          }
        }

        formSubmissions.push(submissionObject)
      }
    }
  }
}

function conditionsForFieldRenderAreMet(fieldValuesDictionary, field) {
  const conditionalField = fieldValuesDictionary[field.conditionalField]
  return conditionalField && conditionalField.value === field.conditionalValue
}
