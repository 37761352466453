// determine if the given latitude longitude pair is numeric and within ranage (+/- 180 deg
// for longitude, +/- 90 deg for latitude)
export function isValidLatLng(property) {
  if (
    isNaN(parseFloat(property.lat)) ||
    !isFinite(property.lat) ||
    isNaN(parseFloat(property.lng)) ||
    !isFinite(property.lng) ||
    property.lat > 90.0000001 ||
    property.lat < -90.0000001 ||
    property.lng > 180.0000001 ||
    property.lng < -180.0000001
  ) {
    return false
  }

  return true
}
