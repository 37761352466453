import {CLEAR_REPORT_URL, SET_REPORT_URL} from 'constants/actionTypes'

const initialState = null

export function reportUrl(state = initialState, action) {
  switch (action.type) {
    case SET_REPORT_URL:
      return action.payload
    case CLEAR_REPORT_URL:
      return initialState
    default:
      return state
  }
}
