import {SET_CONFIGURATIONS} from 'constants/actionTypes'

const initialState = {
  data: {},
  loaded: false,
}

export default function configurations(state = initialState, action) {
  switch (action.type) {
    case SET_CONFIGURATIONS:
      return {
        ...state,
        loaded: true,
        data: {
          ...Object.keys(action.payload).reduce((acc, key) => {
            try {
              acc[key] = JSON.parse(action.payload[key])
            } catch (err) {
              acc[key] = action.payload[key] // error in parsing
            }
            return acc
          }, {}),
        },
      }
    default:
      return state
  }
}
