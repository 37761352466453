import {SET_CLEAR_PROP, RESET_CLEAR_PROP, SET_CLEAR_PROP_TOKEN} from 'constants/actionTypes'

const initialState = {
  reportId: '',
  orderedDate: null,
  token: '',
}

export default function clearProp(state = initialState, action) {
  switch (action.type) {
    case SET_CLEAR_PROP:
      return action.payload
    case RESET_CLEAR_PROP:
      return initialState
    case SET_CLEAR_PROP_TOKEN:
      return {
        ...state,
        token: action.payload,
      }
    default:
      return state
  }
}
