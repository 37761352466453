// Styles
import styled from 'styled-components'

// Core
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'

// Components, services, etc.
import AuthForm from 'components/AuthForm/MainAuthForm'
import Footer from 'components/Footer'
import TosPrivacyLinks from 'components/TosPrivacyLinks'
import ParticleAnimation from 'components/LoginAnimation/AnimationWrapper'
import PasswordRequirements from 'components/AuthForm/PasswordRequirements'
import {TextField} from '@clearcapital/ui-resources'
import {errorBoundary} from 'components/Error'
import {changeRoute, getSearchParams} from 'actions/appActions'
import {logout} from 'actions/authActions'
import {updatePassword, performTempLogin, showPasswordError} from 'actions/usersActions'
import {validatePassword} from 'services/passwordValidation'
import {USER_PASSWORD_CHANGE_NOMATCH} from 'constants/appMessages'
import InitiallyEnabledButton from 'components/InitiallyEnabledButton'

// 3rd-party
import isEmpty from 'lodash/isEmpty'
import cloneDeep from 'lodash/cloneDeep'

const mapStateToProps = (state) => ({})
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logout,
      performTempLogin,
      showPasswordError,
      updatePassword,
    },
    dispatch
  )

@errorBoundary()
export class ActivationForm extends Component {
  static propTypes = {
    username: PropTypes.string.isRequired,
    passcode: PropTypes.string.isRequired,
    logout: PropTypes.func.isRequired,
    performTempLogin: PropTypes.func.isRequired,
    updatePassword: PropTypes.func.isRequired,
    showPasswordError: PropTypes.func.isRequired,
  }

  static defaultProps = {
    updatePassword,
    performTempLogin,
    username: getSearchParams().username,
    passcode: getSearchParams().passcode,
  }

  state = {
    password: '',
    confirmPassword: '',
    tempLogin: {},
  }

  submit = (se) => {
    const {password, confirmPassword, tempLogin} = this.state
    const {logout, performTempLogin, updatePassword} = this.props
    const validationError = validatePassword(password)
    se.preventDefault()
    return password === confirmPassword
      ? !validationError
        ? (async () => {
            let localRes = cloneDeep(tempLogin)
            if (isEmpty(localRes)) {
              localRes = await performTempLogin(this.props.username, this.props.passcode)
              if (localRes) {
                this.setState({tempLogin: localRes})
              } else {
                changeRoute('/password/forgot')
              }
            }
            if (localRes && localRes.user && (await updatePassword(password, localRes.user))) {
              logout()
              // logout is performed here to clear any previously existing session cookies.
              // See JIRA ticket ORD-1906 for details - subtask ORD-1908
            }
          })()
        : this.error(validationError)
      : this.error(USER_PASSWORD_CHANGE_NOMATCH)
  }

  error = (message) => {
    this.setState({
      password: '',
      confirmPassword: '',
    })
    this.props.showPasswordError(message)
  }

  handleChange = (se) => {
    this.setState({
      [se.target.name]: se.target.value,
    })
  }

  validityChange = (passwordValid) => {
    this.setState({passwordValid})
  }

  render() {
    const isResetPage = window.location.pathname.match(/reset/)
    const enableSubmit =
      this.state.password.length && this.state.confirmPassword.length && this.state.passwordValid

    return (
      <ActivationForm.Styled>
        <div className='auth-form-wrapper'>
          <AuthForm
            heading={isResetPage ? 'Reset Password' : 'Activate Account'}
            introText={`Fill out the form below to ${
              isResetPage ? 'reset your password' : 'activate your account'
            }`}
          >
            <form id='user-activation-form' onSubmit={this.submit}>
              <div className='auth-row'>
                <TextField
                  label='Username*'
                  value={this.props.username}
                  type='text'
                  name='username'
                  fullWidth
                  id='username'
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
              <div className='auth-row'>
                <TextField
                  label='Passcode*'
                  value={this.props.passcode}
                  type='text'
                  name='passcode'
                  fullWidth
                  id='passcode'
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
              <div className='auth-row'>
                <TextField
                  label='New password*'
                  value={this.state.password}
                  type='password'
                  name='password'
                  onChange={this.handleChange}
                  fullWidth
                  id='new-password'
                />
                <PasswordRequirements
                  username={this.props.username}
                  password={this.state.password}
                  confirmPassword={this.state.confirmPassword}
                  validityChange={this.validityChange}
                  isValid={this.state.passwordValid}
                />
              </div>
              <div className='auth-row'>
                <TextField
                  label='Confirm new password*'
                  value={this.state.confirmPassword}
                  type='password'
                  name='confirmPassword'
                  onChange={this.handleChange}
                  fullWidth
                  id='confirm-password'
                />
              </div>
              <div className='button'>
                <InitiallyEnabledButton
                  color='primary'
                  disabled={!enableSubmit}
                  className='auth-button'
                  form='user-activation-form'
                  type='submit'
                  sqaPrefix='user-activation-submit'
                >
                  Submit
                </InitiallyEnabledButton>
              </div>
              <TosPrivacyLinks />
            </form>
          </AuthForm>
          <ParticleAnimation />
        </div>
        <Footer />
      </ActivationForm.Styled>
    )
  }

  static Styled = styled.div`
    display: flex;
    height: 100%;

    & .auth-form-wrapper {
      align-items: center;
      display: flex;
      width: 100%;

      .auth-container {
        width: 37vw;
      }

      .animation-wrapper {
        width: inherit;
      }
    }
  `
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivationForm)
