import {RESET_VALIDATION, SET_VALIDATION} from 'constants/actionTypes'

export const initialState = {
  loaded: false,
  data: null,
}

export default function validation(state = initialState, action) {
  switch (action.type) {
    case RESET_VALIDATION:
      return initialState
    case SET_VALIDATION:
      const isFailed = Boolean(action?.payload?.failed)
      return {
        loaded: true,
        failed: isFailed,
        data: !isFailed && action.payload ? {...action.payload} : null,
      }
    default:
      return state
  }
}
