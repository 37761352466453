import {addNotification as notify, removeNotifications} from 'reapop'
import * as Sentry from '@sentry/react'
import history from 'services/history'
import {acceptPanelInvitation, changeRoute} from 'actions/appActions'
import {getAllProducts} from 'actions/allProductsActions'
import {getCustomerOrganization} from 'actions/customerOrganizationActions'
import {getQueryStringParams} from 'services/queryString'
import {authApiV2, authApi} from 'services/apis'
import {clearLocalStorageState} from 'services/localStorage'
import {userHasPermission} from 'components/HasPermission'
import {getOrgUsers, getOrgPreferences} from './organizationsActions'
import {appUserOrgKeySelector, appUserIdSelector, userRolesSelector} from 'selectors'
import {PASSWORD_EXPIRATION_LIMIT} from 'constants/app'
import {ERROR_NOTIFICATION, EXPIRING_PASSWORD_NOTIFICATION} from 'constants/notifications'
import {
  DELETE_STATE,
  SET_SESSION_THRESHOLD,
  SET_AUTH_STATE,
  SET_INITIAL_APP_DATA_STATUS,
} from 'constants/actionTypes'
import {
  SESSION_EXPIRED,
  SESSION_EXPIRED_TITLE,
  LOGIN_FAIL_TITLE,
  LOGIN_FAIL_DEFAULT,
  LOGIN_FAIL_UNRECOGNIZED_CODE,
  LOGIN_FAIL_USER_OBJECT_ERROR,
} from 'constants/appMessages'
import {identifyMixpanelUser} from 'services/mixpanel'

// 3rd Party
import get from 'lodash/get'
import has from 'lodash/has'
import {LOADED, LOADING} from 'constants/reduxStatuses'

export function login(username, password) {
  return function login(dispatch) {
    // clear existing reapop notifications
    dispatch(removeNotifications())

    const headerOverrides = {Authorization: `Basic ${btoa(`${username}:${password}`)}`}
    return (
      authApiV2
        .post('login', {username, password}, headerOverrides)
        // JWT is in a cookie
        .then(async (response) => {
          const authUser = get(response, 'user', {})
          const tokenDuration = get(response, 'tokenDurationInMinutes', 30) * 60000
          const permissions = get(response, 'user.permissions', [])
          const userType = get(response, 'user.type')

          // Api users are not allowed to log into the app
          if (userType === 'API_USER') {
            handleApiUserError(dispatch)
            return
          }

          try {
            // Try getting the user with the id
            const user = await authApi.get(`users/${get(response, 'user.id', {})}`)

            // Verify person obj exist on returned user
            if (has(user, 'person')) {
              dispatch({
                type: SET_AUTH_STATE,
                payload: {
                  user,
                  permissions,
                },
              })

              dispatch({
                type: SET_SESSION_THRESHOLD,
                payload: tokenDuration,
              })

              dispatch(
                handleSuccessfulLogin({
                  redirect: true,
                  checkForPanelInvite: true,
                })
              )

              const dayCount = get(authUser, 'daysUntilPasswordChange', null)
              if (dayCount && dayCount <= PASSWORD_EXPIRATION_LIMIT) {
                dispatch(notifyPasswordExpiration(dayCount))
              }
            } else {
              handleMissingUserPersonObjectError(dispatch)
            }
            // lastly, wrap mixpanel function in try/catch to handle any potential mixpanel error
            try {
              const {id, organizationKey, person} = user
              if (person) {
                const {role, companyName} = person
                identifyMixpanelUser(id, organizationKey, role, companyName)
              } else {
                identifyMixpanelUser(id, organizationKey)
              }
            } catch {}
          } catch (err) {
            throw new Error(err)
          }
        })
        .catch((err) => {
          let errorMsg = ''

          if (err.response && err.response.status) {
            switch (err.response.status) {
              case 403:
                errorMsg = LOGIN_FAIL_DEFAULT
                break
              case 400:
              case 401:
              case 404:
              case 410:
              case 422:
                errorMsg = err.response.statusText || LOGIN_FAIL_DEFAULT
                break
              default:
                errorMsg = err.response.statusText || LOGIN_FAIL_UNRECOGNIZED_CODE
            }
          }

          dispatch(
            notify({
              ...ERROR_NOTIFICATION,
              title: LOGIN_FAIL_TITLE,
              message: errorMsg,
              position: 'tc',
            })
          )
        })
    )
  }
}

export function logout() {
  return function logout(dispatch) {
    return authApiV2
      .post('logout')
      .then(() => {
        Sentry.setUser(null)
        dispatch(tokenExpired())
      })
      .catch((err) => {
        console.error(err)
        dispatch(tokenExpired())
      })
  }
}

export function tokenExpired() {
  return function tokenExpired(dispatch) {
    history.push('/login', {logoutRedirect: true})
    clearLocalStorageState()
    dispatch({
      type: DELETE_STATE,
    })
  }
}

export function renewToken() {
  return (dispatch) => {
    return authApiV2
      .post('extend')
      .then((response) => {
        // This call essentially 'resets' the timer to renew the token
        if (window.debouncedSessionTimeoutHandler) {
          window.debouncedSessionTimeoutHandler()
        }

        // Set the session threshold
        const tokenDuration = get(response, 'tokenDurationInMinutes', 30) * 60000
        dispatch({
          type: SET_SESSION_THRESHOLD,
          payload: tokenDuration,
        })
      })
      .catch((err) => {
        console.error('Auth /extend error', err)
        dispatch(logout())
      })
  }
}

export function loadLocalState(localState) {
  return function loadLocalState(dispatch) {
    dispatch(removeNotifications())

    return authApiV2
      .post('extend')
      .then((response) => {
        const user = get(localState, 'app.authentication.user')
        const tokenDuration = get(response, 'tokenDurationInMinutes', 30) * 60000
        const permissions = get(response, 'user.permissions', [])
        const userType = get(response, 'user.type')

        if (userType === 'API_USER') {
          handleApiUserError(dispatch)
          return
        }

        dispatch({
          type: SET_AUTH_STATE,
          payload: {
            user,
            permissions,
          },
        })

        dispatch({
          type: SET_SESSION_THRESHOLD,
          payload: tokenDuration,
        })

        dispatch(handleSuccessfulLogin())
      })
      .catch(() => {
        dispatch(
          notify({
            ...ERROR_NOTIFICATION,
            title: SESSION_EXPIRED_TITLE,
            message: SESSION_EXPIRED,
          })
        )

        dispatch(logout())
      })
  }
}

// This function will set up an array of promises, then once all of those are complete it will
// dispatch an action to update Redux and set appReady to true. At that point we will actually
// render the app. This ensures any actions called in handleSuccessfulLogin will be completed
// and Redux will be updated accordingly before the app is actually rendered. This avoids any
// potential race conditions from occurring where we render the app, but all the calls have not
// yet been completed (like org preferences for example).
export function handleSuccessfulLogin(options) {
  return function handleSuccessfulLogin(dispatch, getState) {
    dispatch({
      type: SET_INITIAL_APP_DATA_STATUS,
      payload: LOADING,
    })
    const state = getState()
    const redirect = get(options, 'redirect', false)
    const checkForPanelInvite = get(options, 'checkForPanelInvite', false)
    let prevRoute = get(options, 'prevRoute', localStorage.getItem('prevRoute'))

    const promises = []

    if (checkForPanelInvite) {
      const params = getQueryStringParams()
      // Add user to panel if there is an id query param
      if (params.id) {
        // redirect to profile on login after panel accept
        prevRoute = '/profile'
        promises.push(dispatch(acceptPanelInvitation(params.id)))
      }
    }

    const orgKey = appUserOrgKeySelector(state)
    const userId = appUserIdSelector(state)

    try {
      Sentry.setUser({
        id: userId,
        firstName: get(state, 'app.authentication.user.person.firstName'),
        lastName: get(state, 'app.authentication.user.person.lastName'),
        orgKey,
        roles: userRolesSelector(state),
      })
    } catch (err) {
      console.error(err)
    }

    if (orgKey) {
      promises.push(
        dispatch(getOrgPreferences(orgKey)),
        dispatch(getOrgUsers(orgKey)),
        dispatch(getAllProducts()),
        dispatch(getCustomerOrganization(orgKey))
      )
    }

    // Each dispatched action in the promises array should handle its own error, but we're adding
    // a .catch here just in case. In the .finally we're continuing on with the loading of the app
    // and handling the redirect if needed. We want that to happen regardless of whether there was
    // any errors or not.
    return Promise.all(promises)
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {
        // We won't actually render the app until after the following action is dispatched

        dispatch({
          type: SET_INITIAL_APP_DATA_STATUS,
          payload: LOADED,
        })

        if (redirect) {
          if (userHasPermission('WEB:PROVIDER_INTERFACE:MANAGE') && !prevRoute) {
            prevRoute = '/'
          }
          changeRoute(prevRoute || '/')
          localStorage.removeItem('prevRoute')
        }
      })
  }
}

export function notifyPasswordExpiration(dayCount) {
  return function notifyPasswordExpiration(dispatch) {
    dispatch(
      notify({
        ...EXPIRING_PASSWORD_NOTIFICATION,
        title: `Your password expires in ${dayCount} days`,
        buttons: [
          {
            name: 'Yes',
            onClick: () => history.push('/profile'),
          },
          {
            name: 'Close',
          },
        ],
      })
    )
  }
}

function handleApiUserError(dispatch) {
  dispatch(
    notify({
      ...ERROR_NOTIFICATION,
      title: LOGIN_FAIL_TITLE,
      message: LOGIN_FAIL_DEFAULT,
      position: 'tc',
    })
  )
}

function handleMissingUserPersonObjectError(dispatch) {
  dispatch(
    notify({
      ...ERROR_NOTIFICATION,
      title: LOGIN_FAIL_TITLE,
      message: LOGIN_FAIL_USER_OBJECT_ERROR,
      position: 'tc',
    })
  )
}
