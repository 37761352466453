export default function prepareSignup(invitation, values) {
  // Data shared between both user types
  const initialData = {
    password: values.password,
    business: {
      email: values.email || invitation.email,
      phone: values.primaryPhone,
      sameAddress: true,
      physicalAddress: {
        street: values.street,
        city: values.city,
        state: values.state ? values.state.value : null,
        zip: values.zip,
      },
    },
  }

  if (invitation.providerType === 'valuator') {
    return {
      ...initialData,
      business: {
        ...initialData.business,
        lastName: values.lastName,
        firstName: values.firstName,
      },
    }
  } else {
    return {
      ...initialData,
      business: {
        ...initialData.business,
        name: values.name,
      },
    }
  }
}
