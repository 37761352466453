import {FETCH_ERROR_MESSAGE_PREFIX} from 'constants/appMessages'
import {captureException} from '@sentry/react'

export const actionTransformer = (action) => {
  let transformedAction

  switch (action.type) {
    // case '':
    //   transformedAction = {
    //     ...action,
    //     payload: {
    //       _:
    //         'this is not the complete payload, it is partially redacted. see sentryServices.js in the web-ccp repo to view the custom Sentry action transformer',
    //     },
    //   }
    //   break
    default:
      transformedAction = {
        ...action,
        payload:
          'redacted: see sentryServices.js in the web-ccp repo to view the custom Sentry action transformer',
      }
  }

  return transformedAction
}

export const stateTransformer = (state) => {
  /**
   * BE SURE TO REDACT ANY NPI OR PII FROM ANY DATA SENT TO SENTRY
   */

  const transformedState =
    'redacted: see sentryServices.js in the web-ccp repo to view the custom Sentry action transformer'

  return transformedState
}

export const beforeSend = (event, hint) => {
  const eventMessage = event.message
  const eventRequestUrl = event.request?.url
  const eventRequestUrlWithoutQueryParams = eventRequestUrl?.split('?')[0]
  let modifiedEvent = event
  /****************************************************
   * Filter certain CaptureConsole integration events *
   ****************************************************/
  if (event.logger === 'console') {
    // don't sent React warnings if Sentry is enabled on localhost
    if (event.environment === 'localhost' && eventMessage?.startsWith('Warning')) {
      modifiedEvent = null
    }

    // don't send logged fetch errors, those are sent to Sentry by the HttpClient integration
    if (hint.originalException?.message?.startsWith(FETCH_ERROR_MESSAGE_PREFIX)) {
      modifiedEvent = null
    }
  }
  /************************************************
   * Filter certain HttpClient integration events *
   ************************************************/
  if (eventMessage?.startsWith('HTTP Client Error')) {
    // don't send failed token extension errors
    if (
      eventMessage?.includes('401') &&
      eventRequestUrlWithoutQueryParams?.includes('/ccp-login/v2/extend')
    ) {
      modifiedEvent = null
    }

    if (eventMessage?.includes('403')) {
      // don't send 403 errors for order management calls
      if (
        event.request?.method === 'GET' &&
        (eventRequestUrlWithoutQueryParams?.includes('/panels') ||
          eventRequestUrlWithoutQueryParams?.includes('/providers') ||
          eventRequestUrlWithoutQueryParams?.includes('/valuator') || // catches /valuators and /valuator-companies
          eventRequestUrlWithoutQueryParams?.includes('/customers'))
      ) {
        modifiedEvent = null
      }

      // don't set failed login attempts
      if (eventRequestUrlWithoutQueryParams?.includes('/login')) {
        modifiedEvent = null
      }
    }

    if (eventMessage?.includes('404')) {
      // don't send expected/valid 404 errors
      if (event.request?.method === 'GET') {
        if (
          eventRequestUrlWithoutQueryParams?.endsWith('/tiers') ||
          eventRequestUrlWithoutQueryParams?.endsWith('/reviewFormSubmission') ||
          eventRequestUrlWithoutQueryParams?.includes('/case_review_form_submissions') ||
          eventRequestUrlWithoutQueryParams?.includes('/reviewScope')
        ) {
          modifiedEvent = null
        }
      }

      // don't send 404 errors for order management calls
      if (
        event.request?.method === 'GET' &&
        (eventRequestUrlWithoutQueryParams?.includes('/panels') ||
          eventRequestUrlWithoutQueryParams?.includes('/providers') ||
          eventRequestUrlWithoutQueryParams?.includes('/valuator') || // catches /valuators and /valuator-companies
          eventRequestUrlWithoutQueryParams?.includes('/customers'))
      ) {
        modifiedEvent = null
      }
    }
  }

  return modifiedEvent
}

export const trackError = (error, extraData) => {
  captureException(error, {
    ...(extraData && {extra: extraData}),
  })
}
