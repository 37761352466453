export const validators = {
  password: [
    {
      regex: /^.{8}.*$/,
      msg: 'Please use at least 8 characters.',
    },
    {
      regex: /((?=.*[a-z].*)(?=.*[A-Z].*)(?=.*[0-9].*))|((?=.*[a-z].*)(?=.*[A-Z].*)(?=.*[^a-zA-Z0-9].*))|((?=.*[A-Z].*)(?=.*[0-9].*)(?=.*[^a-zA-Z0-9].*))|((?=.*[a-z].*)(?=.*[0-9].*)(?=.*[^a-zA-Z0-9].*))/,
      msg: 'Please use characters from 3 different categories.',
    },
  ],
}

export function validatePassword(password) {
  let message = ''
  for (let i = 0; i < validators.password.length; i++) {
    const passwordRegex = new RegExp(validators.password[i].regex)
    if (!passwordRegex.test(password)) {
      message = validators.password[i].msg
      break
    }
  }
  return message
}
