import React from 'react'
import States from './States'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styled from 'styled-components'
import {theme, Button, TextField, NumberFormat, ValidatedForm} from '@clearcapital/ui-resources'
import {
  requiredPhoneValidationRules,
  requiredValidationRules,
} from 'constants/validationRulesConstants'
import {TELEPHONE_NUMBER_FORMAT} from 'constants/contactInfoConstants'

export default class StepOne extends React.Component {
  state = {valid: false}

  handleChange = (name) => (event) => {
    this.props.onChange({[name]: event.target.value})
  }

  updateValidity = (valid) => this.setState({valid})

  onSubmit = (valid) => {
    const {setStep} = this.props
    valid && setStep('stepTwo')
  }

  render() {
    const {valid} = this.state
    const {values, invitation} = this.props

    return (
      <StepOne.Styled
        requestingValidationReset={false}
        formId='user-signup-form'
        onSubmitCb={this.onSubmit}
        updateFormValidityCb={this.updateValidity}
      >
        {invitation.providerType === 'valuator' ? (
          <div className='auth-row'>
            <div className='auth-half-field'>
              <TextField
                fullWidth
                type='text'
                name='firstName'
                label='First Name'
                value={values.firstName}
                onChange={this.handleChange('firstName')}
                validationRules={requiredValidationRules}
              />
            </div>
            <div className='auth-half-field'>
              <TextField
                fullWidth
                type='text'
                name='lastName'
                label='Last Name'
                value={values.lastName}
                onChange={this.handleChange('lastName')}
                validationRules={requiredValidationRules}
              />
            </div>
          </div>
        ) : (
          <div className='auth-row'>
            <TextField
              fullWidth
              type='text'
              name='name'
              label='Company Name'
              value={values.name}
              onChange={this.handleChange('name')}
              validationRules={requiredValidationRules}
            />
          </div>
        )}
        <div className='auth-row'>
          <TextField
            fullWidth
            type='text'
            name='street'
            label='Physical Address'
            value={values.street}
            validationRules={requiredValidationRules}
            onChange={this.handleChange('street')}
          />
        </div>
        <div
          className={classnames('auth-row-three', {
            'has-message': invitation.providerType === 'valuator',
          })}
        >
          <div className='auth-third-field'>
            <TextField
              fullWidth
              type='text'
              name='city'
              label='City'
              value={values.city}
              onChange={this.handleChange('city')}
              validationRules={requiredValidationRules}
            />
          </div>
          <div className='auth-third-field'>
            <States
              value={values.state}
              onChange={this.handleChange('state')}
              validationRules={requiredValidationRules}
            />
          </div>
          <div className='auth-third-field'>
            <TextField
              fullWidth
              type='text'
              name='zip'
              label='Zip code'
              value={values.zip}
              onChange={this.handleChange('zip')}
              validationRules={requiredValidationRules}
            />
          </div>
        </div>
        {invitation.providerType === 'valuator' && (
          <p className='helper-text'>You will receive work base on your physical address</p>
        )}
        <div className='auth-row'>
          <TextField
            fullWidth
            type='text'
            name='primaryPhone'
            label='Mobile Phone'
            InputProps={{
              inputComponent: NumberFormat,
              inputProps: {
                format: TELEPHONE_NUMBER_FORMAT,
              },
            }}
            value={values.primaryPhone}
            onChange={this.handleChange('primaryPhone')}
            validationRules={requiredPhoneValidationRules}
          />
        </div>
        <p className='helper-text helper-last'>You will receive text messages at this number</p>
        <div className='button'>
          <Button
            color='primary'
            disabled={!valid}
            className='auth-button'
            form='user-signup-form'
            type='submit'
          >
            NEXT
          </Button>
        </div>
      </StepOne.Styled>
    )
  }

  static Styled = styled(ValidatedForm)`
    .helper-text {
      font-size: 0.75rem;
      color: ${theme.palette.colors.grey['550']};
      margin: 0;
      margin-bottom: 16px;
    }

    .helper-last {
      margin-bottom: 24px;
    }

    .auth-row-three {
      display: flex;
      flex-direction: center;
      justify-content: space-between;
      margin-bottom: 16px;
    }

    .has-message {
      margin-bottom: 8px;
    }

    .button {
      display: flex;
      justify-content: center;
    }
  `
}

StepOne.propTypes = {
  onChange: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  invitation: PropTypes.object.isRequired,
}
