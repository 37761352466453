// Core
import {createSelector} from 'reselect'

// Selectors, services, etc
import {orderStageSelector} from 'selectors/orderSelectors'
import {orderProviderSelector} from 'selectors/orderProvidersSelectors'
import {appUserIdSelector} from 'selectors/appSelectors'
import {usersSelector} from 'selectors/usersSelectors'
import {
  getVisibleSections,
  getVisibleQuestions,
  getLatestDocumentSummary,
  getSelectedFormTemplate,
} from 'services/orderReviewHelpers'
import {isCaseAssignedToCurrentUserSelector} from 'selectors/coreCaseSelectors'
import {productNameSelector} from 'selectors/productSelectors'
import {NO_DATA} from 'constants/app'
import {shortNumericFullDateToISODate} from 'services/datetime'
import {dataPointsDataSelector} from 'selectors/dataPointsSelectors'
import {orderReviewPopoutDataSelector} from 'selectors/popoutsSelectors'
import {businessUnitsDictionarySelector} from 'selectors/businessUnitsSelectors'
import {getBusinessUnitDisplayName} from 'services/businessUnitsHelpers'

// 3rd Party
import get from 'lodash/get'
import has from 'lodash/has'
import isEmpty from 'lodash/isEmpty'

export const orderReviewSelector = (state) => get(state, 'orderReview')

export const orderReviewCompleteSelector = createSelector([orderReviewSelector], (review) =>
  get(review, 'reviewComplete')
)

export const orderReviewIsLoadingSelector = createSelector([orderReviewSelector], (review) =>
  get(review, 'isLoading')
)

export const orderReviewIsOpenSelector = createSelector([orderReviewSelector], (review) =>
  get(review, 'isOpen')
)

export const orderReviewIsSavingSelector = createSelector([orderReviewSelector], (review) =>
  get(review, 'isSaving')
)

export const orderReviewCoreCaseSelector = createSelector([orderReviewSelector], (review) =>
  get(review, 'coreCase')
)

export const orderReviewOrderIdSelector = createSelector([orderReviewSelector], (review) =>
  get(review, 'orderId')
)

export const orderReviewCoreCaseAddressSelector = createSelector(
  [orderReviewCoreCaseSelector],
  (coreCase) => get(coreCase, 'property.address', {})
)

export const orderReviewCoreCaseIdSelector = createSelector(
  [orderReviewCoreCaseSelector],
  (coreCase) => get(coreCase, 'id')
)

export const orderReviewCoreCaseAssignedToSelector = createSelector(
  [orderReviewCoreCaseSelector],
  (coreCase) => get(coreCase, 'assignedTo')
)

export const orderReviewCoreCaseStatusSelector = createSelector(
  [orderReviewCoreCaseSelector],
  (coreCase) => get(coreCase, 'status', null)
)

export const orderReviewDocumentSummariesSelector = createSelector(
  [orderReviewSelector],
  (review) => get(review, 'documentSummaries')
)

export const isOrderReviewCaseCompletedSelector = createSelector(
  [orderReviewCoreCaseStatusSelector],
  (status) => status === 'COMPLETED'
)

export const orderReviewSelectedTemplateIdSelector = createSelector(
  [orderReviewSelector],
  (orderReview) => {
    return get(orderReview, 'selectedFormTemplateId')
  }
)
export const orderReviewSelectedTemplateUpdateIdSelector = createSelector(
  [orderReviewSelector],
  (orderReview) => {
    return get(orderReview, 'selectedFormTemplateUpdateId')
  }
)
export const orderReviewSelectedTemplateIdsSelector = createSelector(
  [orderReviewSelectedTemplateIdSelector, orderReviewSelectedTemplateUpdateIdSelector],
  (orderReviewSelectedTemplateId, orderReviewSelectedTemplateUpdateId) => {
    return {
      id: orderReviewSelectedTemplateId,
      updateId: orderReviewSelectedTemplateUpdateId,
    }
  }
)

export const orderReviewTemplatesSelector = createSelector([orderReviewSelector], (orderReview) =>
  get(orderReview, 'orderReviewFormTemplates')
)

export const orderReviewSelectedTemplateSelector = createSelector(
  [orderReviewTemplatesSelector, orderReviewSelectedTemplateIdsSelector],
  (formTemplates, selectedTemplateIds) =>
    getSelectedFormTemplate(formTemplates, selectedTemplateIds)
)

export const orderReviewFormSubmissionSelector = createSelector(
  [orderReviewSelector],
  (orderReview) => get(orderReview, 'orderReviewFormSubmission')
)

export const orderReviewFormAnswersSelector = createSelector(
  [orderReviewFormSubmissionSelector],
  (formSubmission) => get(formSubmission, 'formSubmissions.answers', {})
)

export const orderReviewCompsSelector = createSelector([orderReviewSelector], (orderReview) =>
  get(orderReview, 'orderReviewComps', [])
)

export const orderReviewRulesSelector = createSelector([orderReviewSelector], (orderReview) =>
  get(orderReview, 'orderReviewRules', [])
)

export const orderReviewRulesArraySelector = createSelector([orderReviewRulesSelector], (rules) =>
  rules.reduce((rules, ruleSet) => rules.concat(ruleSet.rules), [])
)

export const orderReviewCompsArraySelector = createSelector([orderReviewCompsSelector], (comps) =>
  comps.reduce((comps, compSet) => comps.concat(compSet.comps), [])
)

export const isAddRuleEnabledSelector = createSelector(
  [orderReviewSelectedTemplateSelector, orderReviewIsOpenSelector],
  (selectedTemplate, isOpen) => {
    // Checking for both plural and singular - this may be able to go away just a safety check
    const templateHasRulesSection =
      has(selectedTemplate, 'template.sections.rulesSection') ||
      has(selectedTemplate, 'template.sections.ruleSection')
    return isOpen && templateHasRulesSection
  }
)

export const isAddCompEnabledSelector = createSelector(
  [orderReviewSelectedTemplateSelector, orderReviewIsOpenSelector],
  (selectedTemplate, isOpen) => {
    // Checking for both plural and singular - this may be able to go away just a safety check
    const templateHasRulesSection =
      has(selectedTemplate, 'template.sections.compsSection') ||
      has(selectedTemplate, 'template.sections.compSection')
    return isOpen && templateHasRulesSection
  }
)

export const orderReviewFormSubmissionAdditionalInfoSelector = createSelector(
  [orderReviewFormSubmissionSelector],
  (formSubmission) => get(formSubmission, 'additionalInformation', {})
)

export const orderReviewLastUpdatedTimestampSelector = createSelector(
  [orderReviewFormSubmissionAdditionalInfoSelector],
  (additionalInfo) => get(additionalInfo, 'lastUpdateTimestamp', null)
)

export const orderReviewLastUpdatedUserIdSelector = createSelector(
  [orderReviewFormSubmissionAdditionalInfoSelector],
  (additionalInfo) => get(additionalInfo, 'lastUpdateUserId', null)
)

export const orderReviewLastUpdatedUserSelector = createSelector(
  [usersSelector, orderReviewLastUpdatedUserIdSelector],
  (users, userId) => users.find((user) => user.id === userId)
)

export const orderReviewLastUpdatedUserDisplayNameSelector = createSelector(
  [orderReviewLastUpdatedUserSelector],
  (user) => get(user, 'person.displayName', null)
)

export const orderReviewFormValuesSelector = createSelector([orderReviewSelector], (orderReview) =>
  get(orderReview, 'orderReviewFormValues', {})
)

export const orderReviewVisibleSectionsSelector = createSelector(
  [orderReviewFormValuesSelector, orderReviewSelectedTemplateSelector],
  (formValues, formTemplate) => getVisibleSections(formTemplate.template, formValues)
)

export const orderReviewVisibleQuestionsSelector = createSelector(
  [orderReviewFormValuesSelector, orderReviewSelectedTemplateSelector],
  (formValues, formTemplate) => getVisibleQuestions(formTemplate.template, formValues)
)

export const orderReviewFormNavItemsSelector = createSelector(
  [orderReviewVisibleSectionsSelector],
  (visibleSections) => [
    {id: 'summary', name: 'Summary'},
    ...visibleSections.reduce((items, {id, displayText}) => {
      if (displayText) {
        items.push({id, name: displayText})
      }
      return items
    }, []),
  ]
)

export const orderReviewLatestDocumentSummarySelector = createSelector(
  [orderReviewSelector],
  (orderReview) => {
    const {documentSummaries, orderId} = orderReview
    let summary = {}
    if (documentSummaries) {
      summary = getLatestDocumentSummary(
        documentSummaries.filter(({documentId}) => documentId === orderId)
      )
    }

    return summary
  }
)

export const orderReviewFormSubmissionMetaDataPayloadSelector = createSelector(
  [
    orderReviewOrderIdSelector,
    orderReviewLatestDocumentSummarySelector,
    orderReviewSelectedTemplateSelector,
  ],
  (orderId, documentSummary, formTemplate) => {
    const {documentId, documentUpdateId} = documentSummary
    const {id: formId, updateId: formUpdateId, name: formName, organizationKey} = formTemplate

    return {
      formId,
      formUpdateId,
      formName,
      orderId,
      organizationKey,
      documentId,
      documentUpdateId,
    }
  }
)

export const orderReviewInvestorSelector = createSelector(
  [orderReviewCoreCaseSelector],
  (coreCase) => coreCase?.investor ?? null
)

export const orderReviewLoanProgramSelector = createSelector(
  [orderReviewCoreCaseSelector],
  (coreCase) => coreCase?.originatorInfo?.loanProgram ?? null
)

export const orderReviewBusinessUnitNameSelector = createSelector(
  [businessUnitsDictionarySelector, orderReviewCoreCaseSelector],
  (businessUnit, coreCase) => getBusinessUnitDisplayName(businessUnit?.[coreCase.businessUnitId])
)

export const orderReviewLoanNumberSelector = createSelector(
  [orderReviewCoreCaseSelector],
  (coreCase) => coreCase?.originatorInfo?.loanNumber ?? null
)

export const orderReviewProviderNameSelector = createSelector(
  [orderProviderSelector],
  (orderProvider) => orderProvider?.name ?? null
)

export const orderReviewRuleBasedAnalysesSelector = createSelector(
  [orderReviewSelector],
  (orderReview) => orderReview?.orderReviewRuleBasedAnalyses ?? []
)

// Rules come back in descending order so the first should be the latest
export const orderReviewCuScoreSelector = createSelector(
  [orderReviewRuleBasedAnalysesSelector],
  (analyses) => analyses?.[0]?.fnmCuScore ?? null
)

export const orderReviewRwReliefSelector = createSelector(
  [orderReviewRuleBasedAnalysesSelector],
  (analyses) => analyses?.[0]?.freRwRelief ?? null
)

export const orderReviewFormSubmissionAdditionalInfoPayloadSelector = createSelector(
  [
    appUserIdSelector,
    orderReviewProviderNameSelector,
    orderReviewLoanProgramSelector,
    orderReviewLoanNumberSelector,
    orderReviewInvestorSelector,
    productNameSelector,
    orderReviewPopoutDataSelector,
    orderReviewCoreCaseAddressSelector,
    orderReviewCuScoreSelector,
    orderReviewRwReliefSelector,
  ],
  (
    lastUpdateUserId,
    provider,
    loanProgram,
    loanNumber,
    investor,
    product,
    orderReviewPopoutData,
    address,
    cuScore,
    rwRelief
  ) => {
    const {
      documentSummary: {value: appraisedValue, effectiveDate: valuationDate},
    } = orderReviewPopoutData
    const {street, city, state, zipcode, county = null} = address
    const effectiveDate = shortNumericFullDateToISODate(valuationDate)
    return {
      lastUpdateUserId,
      provider,
      loanProgram,
      loanNumber,
      investor,
      product,
      appraisedValue,
      effectiveDate,
      propertyAddress: {
        city,
        county,
        state,
        addressLine1: street,
        addressLine2: null,
        zip: zipcode,
      },
      cuScore,
      rwRelief,
    }
  }
)

export const orderReviewSummaryDataSelector = createSelector(
  [
    orderReviewBusinessUnitNameSelector,
    orderReviewProviderNameSelector,
    orderReviewInvestorSelector,
    orderReviewRwReliefSelector,
    orderReviewCuScoreSelector,
    orderReviewLoanProgramSelector,
    orderReviewLoanNumberSelector,
  ],
  (businessUnitName, provider, investor, rwRelief, cuScore, loanProgram, loanNumber) => [
    {
      label: 'Provider',
      value: provider ?? NO_DATA,
    },
    {
      label: 'Freddie R&W Relief',
      value: rwRelief ?? NO_DATA,
    },
    {
      label: 'Loan Program',
      value: loanProgram ?? NO_DATA,
    },
    {
      label: 'Business Unit',
      value: businessUnitName ?? NO_DATA,
    },
    {
      label: 'Investor',
      value: investor ?? NO_DATA,
    },
    {
      label: 'CU Score',
      value: cuScore ?? NO_DATA,
    },
    {
      label: 'Loan #',
      value: loanNumber ?? NO_DATA,
    },
  ]
)

// when the order review form slice of Redux is initialized we set isLoading to false and set the
// orderReviewFormSubmission an empty object if there isn't a review form.
export const orderReviewHasSubmissionsSelector = createSelector(
  [orderReviewIsLoadingSelector, orderReviewFormSubmissionSelector],
  (isOrderReviewLoading, orderReviewFormSubmission) => {
    return Boolean(!isOrderReviewLoading && !isEmpty(orderReviewFormSubmission))
  }
)

// right now there is no other way to know if an order review has been completed aside from
// checking both that there is an order review form submission and the order.stage === 'CLOSED'
export const isOrderReviewCompletedSelector = createSelector(
  [orderReviewHasSubmissionsSelector, orderStageSelector],
  (orderReviewHasSubmissions, orderStage) => orderReviewHasSubmissions && orderStage === 'CLOSED'
)

export const isOrderReviewInReadOnlyModeSelector = createSelector(
  [isOrderReviewCompletedSelector, isCaseAssignedToCurrentUserSelector],
  (isComplete, isAssigned) => isComplete || !isAssigned
)

export const questionIdDataSourceDictionarySelector = createSelector(
  [orderReviewSelectedTemplateSelector, dataPointsDataSelector],
  ({template: {questions}}, dataPointsPrefillValues) => {
    return Object.keys(dataPointsPrefillValues).length
      ? Object.keys(questions).reduce((accumulator, questionId) => {
          const prefillDataPoint = questions[questionId]?.prefillDataPoint
          if (prefillDataPoint) {
            const response = dataPointsPrefillValues[prefillDataPoint]
            accumulator[questionId] = response
          }
          return accumulator
        }, {})
      : {}
  }
)

export const isOrderReviewFormValuesLoading = createSelector(
  [orderReviewSelector],
  ({isOrderReviewFormValuesLoading}) => isOrderReviewFormValuesLoading
)
