import {
  ERROR_RULE_BASED_ANALYSES,
  RESET_RULE_BASED_ANALYSES,
  SET_RULE_BASED_ANALYSES,
} from 'constants/actionTypes'

export const initialState = {
  error: false,
  data: {},
}

export default function ruleBasedAnalyses(state = initialState, action) {
  switch (action.type) {
    case RESET_RULE_BASED_ANALYSES:
      return initialState
    case SET_RULE_BASED_ANALYSES:
      return {
        data: action.payload,
      }
    case ERROR_RULE_BASED_ANALYSES:
      return {
        ...initialState,
        error: true,
      }
    default:
      return state
  }
}
