import {CLEAR_SMART_VIEW_PHOTOS, SET_SMART_VIEW_PHOTOS} from 'constants/actionTypes'

const initialState = {}

export default function smartView(state = initialState, action) {
  switch (action.type) {
    case SET_SMART_VIEW_PHOTOS:
      return action.payload
    case CLEAR_SMART_VIEW_PHOTOS:
      return initialState
    default:
      return state
  }
}
