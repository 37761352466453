import {createSelector} from 'reselect'
import {loadingSelector} from 'selectors/appSelectors'
import {LOADING_TIERS} from 'constants/app'
import {localCompareSortByKey} from 'services/sort'
import {selectInputFormatter} from 'services/formatData'
import {casesByTierGridRowDataSelector} from 'selectors/reviewDashboardSelectors'
import get from 'lodash/get'

export const isTierDataFetchedSelector = (state) => get(state, 'tiers.isDataFetched')

// returns the whole parent tiers object
export const tiersSelector = (state) => get(state, 'tiers.data', {})

// returns only the tiers array
export const tierDetailsSelector = createSelector([tiersSelector], (tiersData) =>
  get(tiersData, 'tierDetails', [])
)

export const tierDetailsWithKeysSelector = createSelector([tierDetailsSelector], (tierDetails) =>
  tierDetails.map((tier) => ({
    ...tier,
    key: tier.id,
  }))
)

export const enabledTiersSelector = createSelector([tierDetailsSelector], (tierData) =>
  tierData.filter((tier) => tier.enabled === true)
)

export const tierDictionarySelector = createSelector([tierDetailsSelector], (tierData) =>
  tierData.reduce((accumulator, current) => {
    if (current.id) {
      accumulator[current.id] = current
    }
    return accumulator
  }, {})
)

export const tierDropdownOptionsSelector = createSelector([enabledTiersSelector], (tierData) =>
  localCompareSortByKey(selectInputFormatter(tierData, 'name', 'id'), 'label')
)

export const allTiersDropdownOptionsSelector = createSelector([tierDetailsSelector], (tierData) =>
  localCompareSortByKey(
    tierData.map((tier) => ({
      value: tier.id,
      label: `${tier.name}${!tier.enabled ? ' (Disabled)' : ''}`,
    })),
    'label'
  )
)

export const tierDropdownOptionsBySequenceSelector = createSelector(
  (state) => casesByTierGridRowDataSelector(state),
  (casesByTier) => selectInputFormatter(casesByTier, 'tierName', 'tierId')
)

export const isTierDataLoadingSelector = createSelector(
  (state) => loadingSelector(state),
  (loading = []) => loading.includes(LOADING_TIERS)
)
