// Styles
import styled from 'styled-components'

// Core
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {Link, Redirect} from 'react-router-dom'

// Components, services, etc.
import {signupNewUser, acceptOrLoadInvitation} from 'actions/panelSignupActions'
import Footer from 'components/Footer'
import ParticleAnimation from 'components/LoginAnimation/AnimationWrapper'
import AuthForm from 'components/AuthForm/MainAuthForm'
import StepOne from './components/StepOne'
import StepTwo from './components/StepTwo'
import prepareSignup from './prepareSignup'

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      signupNewUser,
      acceptOrLoadInvitation,
    },
    dispatch
  )

export class PanelSignup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      step: 'stepOne',
      values: {},
      invitation: {},
    }
  }

  componentDidMount() {
    const {acceptOrLoadInvitation} = this.props

    acceptOrLoadInvitation({
      onAccept: () => this.setState({done: true}),
      onInvitation: (data) => this.setState(data),
    })
  }

  signup = () => {
    const {signupNewUser} = this.props
    const {invitation, values} = this.state

    signupNewUser({
      invitation,
      signUpData: prepareSignup(invitation, values),
      onError: (error) => this.setState({error}),
    })
  }

  setStep = (step) => this.setState({step})

  updateValues = (data) =>
    this.setState((currentState) => ({values: {...currentState.values, ...data}}))

  render() {
    const {values, step, invitation, done, error} = this.state
    let content = (
      <PanelSignup.Styled>
        <div className='auth-form-wrapper'>
          <AuthForm
            heading='Create an account'
            errorMessage={error}
            introText={`You've been invited to ${invitation.panelName}'s panel! Fill out the information below to get started`}
          >
            {step === 'stepOne' ? (
              <StepOne
                values={values}
                invitation={invitation}
                setStep={this.setStep}
                onChange={this.updateValues}
              />
            ) : (
              <StepTwo
                values={values}
                invitation={invitation}
                signup={this.signup}
                setStep={this.setStep}
                onChange={this.updateValues}
              />
            )}
            <div className='existing-account'>
              <p>Already have an account?</p>
              <Link to={`/login${window.location.search}`}>Log in</Link>
            </div>
          </AuthForm>
          <ParticleAnimation />
        </div>
        <Footer />
      </PanelSignup.Styled>
    )
    // If they are done signing up
    if (done) {
      content = <Redirect to='/profile' />
    } else if (!invitation.id && !error) {
      // wait for the invitation to load
      content = null
    }

    return content
  }

  static Styled = styled.div`
    display: flex;
    height: 100%;

    & .auth-form-wrapper {
      align-items: center;
      display: flex;
      width: 100%;

      .auth-container {
        width: 37vw;
      }

      .animation-wrapper {
        width: inherit;
      }
    }
  `
}

PanelSignup.propTypes = {
  acceptOrLoadInvitation: PropTypes.func.isRequired,
  signupNewUser: PropTypes.func.isRequired,
}

export default connect(null, mapDispatchToProps)(PanelSignup)
