import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {Button, TextField, Icon, theme, ValidatedForm} from '@clearcapital/ui-resources'
import PasswordRequirements from 'components/AuthForm/PasswordRequirements'
import {
  requiredValidationRules,
  requiredEmailValidationRules,
} from 'constants/validationRulesConstants'

export default class StepTwo extends React.Component {
  state = {}

  handleChange = (name) => (event) => {
    this.props.onChange({[name]: event.target.value})
  }

  updateValidity = (valid) => this.setState({hasChangedValidity: !valid && this.state.valid, valid})

  onSubmit = (valid) => {
    const {signup, values} = this.props
    valid && values.passwordValid && signup('stepTwo')
  }

  onPasswordValidity = (passwordValid) => this.props.onChange({passwordValid})

  render() {
    const {values, setStep, invitation} = this.props
    const {valid, hasChangedValidity} = this.state

    return (
      <StepTwo.Styled
        requestingValidationReset={false}
        formId='user-signup-form'
        onSubmitCb={this.onSubmit}
        updateFormValidityCb={this.updateValidity}
      >
        <div className='auth-back' onClick={() => setStep('stepOne')}>
          <Icon icon='chevron_left' />
          <div>BACK</div>
        </div>
        <div className='auth-row'>
          <TextField
            fullWidth
            type='text'
            name='email'
            label='Email'
            value={values.email || invitation.email}
            onChange={this.handleChange('email')}
            validationRules={requiredEmailValidationRules}
          />
        </div>
        <div className='auth-row'>
          <TextField
            fullWidth
            type='password'
            name='password'
            label='Password'
            value={values.password}
            onChange={this.handleChange('password')}
            validationRules={requiredValidationRules}
          />

          <PasswordRequirements
            username={values.email || invitation.email}
            password={values.password || ''}
            confirmPassword={values.reEnterPassword}
            validityChange={this.onPasswordValidity}
            isValid={values.passwordValid}
            validationRules={requiredValidationRules}
          />
        </div>
        <div className='auth-row'>
          <TextField
            fullWidth
            type='password'
            name='reEnterPassword'
            label='Confirm Password'
            value={values.reEnterPassword}
            onChange={this.handleChange('reEnterPassword')}
          />
        </div>
        <div className='buttons'>
          <Button
            color='primary'
            disabled={(!valid || !values.passwordValid) && hasChangedValidity}
            form='user-signup-form'
            type='submit'
            className='auth-button'
          >
            SIGN UP
          </Button>
        </div>
      </StepTwo.Styled>
    )
  }

  static Styled = styled(ValidatedForm)`
    width: 100%;
    .helper-text {
      font-size: 0.75rem;
      color: ${theme.palette.colors.grey[550]};
      margin-bottom: 24px;
    }

    .auth-row-last {
      display: flex;
      flex-direction: center;
      justify-content: space-between;
      margin-bottom: 12px;
    }

    .buttons {
      display: flex;
      align-items: center;
    }

    .flex-start {
      flex: 0.5;
      color: #5f6367;
      div {
        margin-left: 31px;
        cursor: pointer;
      }
    }
  `
}

StepTwo.propTypes = {
  onChange: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  invitation: PropTypes.object.isRequired,
  signup: PropTypes.func.isRequired,
}
