import {RESET_COMPS, SET_PARSED_COMPS, SET_FILTERED_COMPS} from 'constants/actionTypes'

export const initialState = {}

export default function parsedComps(state = initialState, action) {
  switch (action.type) {
    case RESET_COMPS:
      return initialState
    case SET_PARSED_COMPS:
      return action.payload
    case SET_FILTERED_COMPS:
      return {
        ...state,
        filteredComps: action.payload,
      }
    default:
      return state
  }
}
