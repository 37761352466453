export default class Product {
  constructor(props) {
    if (props) {
      Object.keys(props).forEach((key) => {
        this[key] = props[key]
      })
    }
  }
}

/*
V2 product model

{
  "code": "string",
  "classification": {
    "type": {
      "id": "string",
      "name": "string"
    },
    "form": "string"
  },
  "name": "string",
  "description": "string",
  "enabled": boolean,
  "shareHistoricalCasesWithProvider": boolean
}
*/
