export const PROD_TOKEN = '98fa16ea22efa993e06ed434e79a93a5'
export const DEV_TOKEN = '0f23227ecb789772235492a182bc5f63'
export const NPE_TOKEN = '4b6feaafc149aa4e9625d332f02e681c'

export const SUBMIT_CASE_CONCLUSION_DECISION = 'Submit Case Conclusion Decision'
export const CLICK_NAVIGATION_ITEM = 'Click Navigation Item'
export const APPLY_REVIEW_QUEUE_FILTER = 'Apply Review Queue Filter'
export const SAVE_REVIEW_SCOPE = 'Save Review Scope'
export const SAVE_TIER = 'Save Tier'
export const ASSIGN_CASE_TIER = 'Assign Case Tier'
export const ADD_CASE_NOTE = 'Add Case Note'
export const SUBMIT_CASE_ON_HOLD = 'Submit Case on Hold'
export const EDIT_QUEUE_PRIORITY = 'Edit Queue Priority'
export const ASSIGN_CASE_USER = 'Assign Case User'
export const ADD_USER = 'Add User'
export const INTERACT_WITH_MLS_PHOTOS = 'Interact with MLS Photos'
export const CLICK_VIEWS = 'Click Views'
export const ACCESS_CLEARPROP = 'Access ClearProp'

// Revision Request
export const REVIEW_FORM_SUBMITTED = 'Submit Review Form'
export const REQUEST_REVISION = 'Request Revision'
export const ENABLE_FULL_REVIEW = 'Enable Full Review'

// Reopen Order
export const REOPEN_ORDER = 'Reopen Order'

// Validations
export const VALIDATION_DATA_LOADED = 'Load Validation Data'

// Bulk Case Actions
export const CLOSE_CASE_IN_BULK = 'Close Case in Bulk'

// Order Details Card
export const EXPAND_ORDER_MENU = 'Expand Order Menu'
export const NAVIGATE_ORDERS_TABS = 'Navigate Order Tabs'

// Review Form Actions
export const CLICK_REVIEW_ORDER = 'Click Review Order'
export const CLICK_VIEW_SUMMARY = 'Click View Summary'
