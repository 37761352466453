import {NO_DATA} from 'constants/app'
import {sqftToAcre} from 'services/areaConverter'
import {isValidLatLng} from 'services/geocode'
import get from 'lodash/get'
import {COMP_TYPE_NAME_VALUE_CONCLUSION} from 'constants/comp'
import {format} from 'd3-format'

export function commaSeparatedStringToNumber(string) {
  string = string.replace(/,/g, '')
  return +string
}

export function formatText(textToFormat) {
  return textToFormat && typeof textToFormat === 'string'
    ? titleCase(removeUnderscores(textToFormat))
    : textToFormat
}

export function money(x, decimals = false) {
  return x && x !== NO_DATA ? '$' + numberWithCommas(decimals ? Number(x).toFixed(2) : x) : NO_DATA
}

export function numberWithCommas(x) {
  return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : NO_DATA
}

export function formatSqft(x) {
  return x && x !== NO_DATA ? `${numberWithCommas(x)} ft\u00B2` : NO_DATA
}

export function formatAcres(x) {
  return x && x !== NO_DATA ? (x += ' ac') : NO_DATA
}

export function removeUnderscores(string) {
  let newString

  if (typeof string !== 'string') {
    newString = string
  } else {
    newString = string.split('_').join(' ')
  }

  return newString
}

export function percentageFormatter(numerator, denominator) {
  if (numerator > 0 && numerator / denominator < 0.01) {
    return '<1%'
  } else if (numerator < denominator && numerator / denominator > 0.99) {
    return '>99%'
  } else {
    return `${((100 * numerator) / denominator).toFixed(0)}%`
  }
}

/**
 * Takes a string (of words) and title cases each word
 */
export function titleCase(str = NO_DATA) {
  // standardize spaces
  str = str.replace(/\s+/g, ' ')
  // remove white space from both ends of string
  str = str.trim()

  return str
    .toLowerCase()
    .split(' ')
    .map((word) => {
      return word.replace(word[0], word[0].toUpperCase())
    })
    .join(' ')
}

/*
 * We have common UI data values that we need to honor as they were inputted (saved) by the end-user,
 * so long as the value is defined, even in cases where the value is zero (0); otherwise, default to
 * our em-dash value (NO_DATA).
 *
 * @param {String} value
 *
 * @returns {String|Number}
 *
 */
export function formatMissingData(value) {
  return !value && value !== 0 ? NO_DATA : value
}

/*
So, let me explain what's going on here. Get a drink, and take a seat, because it's an involved story.
A long time ago, in a webapp not so far away, the subject data being displayed in the app came from
the appraisal entity. However, after running into issues with not getting valid data, we found that
using the appraisal entity was Not The Right Thing To Do, and instead, the core team created a nice
API call to get an aggregated property. So the webapp team wrote the function getSubject, which called
the aggregated property API, and we looked at it, and it was good. The core team did a great job at
reducing the amount of unnecessary data, and coalesced the aggregated property into a nice, compact
object. Sounds great, right? Not so fast, Chester. It turns out that the base of this aggregated
property was supposed to be the appraisal, but it wasn't. That meant that certain parts of the data may
not match the text that the rules engine supplies in the rules grid. That would lead to Inconsistent
Data, and that would most certainly be A Bad Thing. So, as a quick fix, the webapp team resorted back
to using the appraisal entity. But the appraisal entity was void and without form, so, in order to avoid
changing quite a bit of code in the ClearQC and GoogleMap Controllers, the getSubject call was commented
out, and a new function was created to take the already-present appraisal data and coalesce it into a
format similar to what was returned by getSubject. I hope that was clear.
*/
export function valuationToFauxAggregate(valuation) {
  let aggregate

  if (!valuation) {
    aggregate = null
  } else if (valuation.appraisalType) {
    // if appraisalType is defined then we're dealing with an appraisal
    const appraisalProfileValues = valuation.profileValues ? valuation.profileValues[0] : {}

    // TODO: clean this up. it appears the only properties we use are the following:
    // bathroomCount, bedroomCount, buildingSize, daysOnMarket, halfBathroomCount,
    // property, propertyType, siteSize, valuationDate, valuationType, value, yearBuilt
    aggregate = {
      basementFinishedSize:
        appraisalProfileValues.BASEMENT_FINISH && appraisalProfileValues.BASEMENT_FINISH.value,
      basementSize:
        appraisalProfileValues.BASEMENT_SQFT && +appraisalProfileValues.BASEMENT_SQFT.value,
      basementType: NO_DATA,
      bathroomCount:
        appraisalProfileValues.BATHROOM_COUNT && +appraisalProfileValues.BATHROOM_COUNT.value,
      bedroomCount:
        appraisalProfileValues.BEDROOM_COUNT && +appraisalProfileValues.BEDROOM_COUNT.value,
      buildingSize: appraisalProfileValues.GLA && +appraisalProfileValues.GLA.value,
      carParkingCount:
        appraisalProfileValues.CAR_STORAGE_DRIVEWAY_PARKING_COUNT &&
        +appraisalProfileValues.CAR_STORAGE_DRIVEWAY_PARKING_COUNT.value,
      condition: appraisalProfileValues.CONDITION && appraisalProfileValues.CONDITION.value,
      constructionQuality:
        appraisalProfileValues.QUALITY_OF_CONSTRUCTION &&
        appraisalProfileValues.QUALITY_OF_CONSTRUCTION.value,
      coolingType: NO_DATA,
      daysOnMarket:
        appraisalProfileValues.DAYS_ON_MARKET && appraisalProfileValues.DAYS_ON_MARKET.value,
      effectiveYearBuilt: NO_DATA,
      femafloodZoneIndicator:
        appraisalProfileValues.FEMA_FLOOD_ZONE && appraisalProfileValues.FEMA_FLOOD_ZONE.value,
      fireplaceIndicator:
        appraisalProfileValues.FIREPLACE_INDICATOR &&
        appraisalProfileValues.FIREPLACE_INDICATOR.value,
      garageType:
        appraisalProfileValues.GARAGE_CARPORT && appraisalProfileValues.GARAGE_CARPORT.value,
      halfBathroomCount:
        appraisalProfileValues.HALF_BATHROOM_COUNT &&
        +appraisalProfileValues.HALF_BATHROOM_COUNT.value,
      hasValidGeocode: valuation.property && isValidLatLng(valuation.property),
      heatingType: NO_DATA,
      neighborhoodLocationType:
        appraisalProfileValues.NEIGHBORHOOD_LOCATION_TYPE &&
        appraisalProfileValues.NEIGHBORHOOD_LOCATION_TYPE.value,
      neighborhoodName:
        appraisalProfileValues.NEIGHBORHOOD_NAME && appraisalProfileValues.NEIGHBORHOOD_NAME.value,
      occupancyType:
        appraisalProfileValues.OCCUPANCY_TYPE && appraisalProfileValues.OCCUPANCY_TYPE.value,
      ownerName: appraisalProfileValues.OWNER_NAME && appraisalProfileValues.OWNER_NAME.value,
      ownerType: NO_DATA,
      parcelApn: appraisalProfileValues.APN && appraisalProfileValues.APN.value,
      poolIndicator:
        appraisalProfileValues.POOL_INDICATOR && appraisalProfileValues.POOL_INDICATOR.value,
      porchIndicator:
        appraisalProfileValues.PORCH_INDICATOR && appraisalProfileValues.PORCH_INDICATOR.value,
      property: valuation.property && valuation.property,
      propertyStyle: NO_DATA,
      propertySubType: NO_DATA,
      propertyType:
        valuation.appraisalType &&
        formTypeToPropertyType(valuation.appraisalType.value, appraisalProfileValues.DESIGN_STYLE),
      pudindicator:
        appraisalProfileValues.PUD_INDICATOR && appraisalProfileValues.PUD_INDICATOR.value,
      roomCount: appraisalProfileValues.ROOM_COUNT && +appraisalProfileValues.ROOM_COUNT.value,
      siteSize:
        appraisalProfileValues.SITE_SIZE && sqftToAcre(+appraisalProfileValues.SITE_SIZE.value),
      storyCount: appraisalProfileValues.STORY_COUNT && +appraisalProfileValues.STORY_COUNT.value,
      typeDisplay: 'Subject',
      unitCount: NO_DATA,
      valuationDate: NO_DATA,
      valuationType: COMP_TYPE_NAME_VALUE_CONCLUSION,
      value:
        appraisalProfileValues.RECONCILIATION_MARKET_PRICE &&
        +appraisalProfileValues.RECONCILIATION_MARKET_PRICE.value,
      viewRating: appraisalProfileValues.VIEW_RATING && appraisalProfileValues.VIEW_RATING.value,
      yearBuilt: appraisalProfileValues.YEAR_BUILT && +appraisalProfileValues.YEAR_BUILT.value,
    }
  } else if (valuation.productType) {
    // if productType is defined then we're dealing with a BPO
    const alternativeValuationValue = valuation.alternativeValuationValues[0]

    aggregate = {
      bathroomCount: +get(alternativeValuationValue, 'FULL_BATHROOM_COUNT.value', NO_DATA),
      bedroomCount: +get(alternativeValuationValue, 'BEDROOM_COUNT.value', NO_DATA),
      buildingSize: calculateBpoBuildingSize(
        alternativeValuationValue.ABOVE_GRADE_GROSS_LIVING_AREA,
        alternativeValuationValue.BELOW_GRADE_GROSS_LIVING_AREA
      ),
      daysOnMarket:
        get(alternativeValuationValue, 'CURRENT_LISTING_DAYS_ON_MARKET.value', NO_DATA) ||
        get(alternativeValuationValue, 'PRIOR_LISTING_DAYS_ON_MARKET.value', NO_DATA),
      halfBathroomCount: +get(alternativeValuationValue, 'HALF_BATHROOM_COUNT.value', NO_DATA),
      property: valuation.property,
      propertyType: get(alternativeValuationValue, 'PROPERTY_TYPE.value', NO_DATA),
      siteSize: sqftToAcre(+get(alternativeValuationValue, 'SITE_SIZE.value', NO_DATA)),
      valuationDate: NO_DATA,
      valuationType: COMP_TYPE_NAME_VALUE_CONCLUSION,
      value: get(
        alternativeValuationValue,
        'PRICE_CONCLUSION_LIST.children[1].PRICE_CONCLUSION_AS_IS_SALE_PRICE.value',
        NO_DATA
      ),
      yearBuilt: get(alternativeValuationValue, 'YEAR_BUILT.value', NO_DATA),
    }
  }

  return aggregate
}

function calculateBpoBuildingSize(aboveGradeGLA, belowGradeGLA) {
  aboveGradeGLA = aboveGradeGLA ? commaSeparatedStringToNumber(aboveGradeGLA.value) : 0
  belowGradeGLA = belowGradeGLA ? commaSeparatedStringToNumber(belowGradeGLA.value) : 0

  return aboveGradeGLA + belowGradeGLA
}

function decodeDesign(design) {
  var result = NO_DATA
  if (design === 'row' || /(mid|hi(gh)?).?rise/.test(design)) {
    result = 'CND'
  } else if (design === 'garden' || design === 'detached') {
    result = 'SFR'
  } else if (design === 'other') {
    result = 'UNK'
  }

  return result
}

/**
 * Given an appriasal form type and design style (also from the appraisal), determine
 * the property's type.
 * @param  {Object} appraisalType Appraisal metadata, including form type. May be null.
 * @param  {String} designStyle   The design style, used only for forms 2090/2095
 * @return {String}               Property type
 */
function formTypeToPropertyType(appraisalType, designStyle) {
  const design = String(designStyle).toLowerCase()
  if (appraisalType && appraisalType.form) {
    switch (appraisalType.form) {
      case 'FORM_2055':
      case 'FORM_1004':
        return 'SFR'

      case 'FORM_1073':
      case 'FORM_1075':
        return 'CND'

      case 'FORM_1025':
        return 'MFR'

      case 'FORM_1004c':
        return 'MFG'

      case 'FORM_2090':
      case 'FORM_2095':
        return decodeDesign(design)

      default:
        return NO_DATA
    }
  } else {
    return NO_DATA
  }
}

export function listToArray(list) {
  // Split list string by the following: [ (blank space) ,(comma) ;(semi-colon) \r(carriage return) \n(new line) \t(tab)]
  return list
    .split(/[ |,|;|\r|\n|\t]/g)
    .map((string) => string.trim())
    .filter((string) => string)
}

export function formatNumber(value, suffix = null) {
  let result
  if (!value) {
    result = NO_DATA
  } else {
    const formattedNumber = typeof value === 'number' ? numberWithCommas(value) : value
    result = `${formattedNumber}${suffix ? ` ${suffix}` : ''}`
  }
  return result
}

// converts objects key values to comma separated values of type string.
// Input
// {
// "toolbar": false,
// "location": false
// }
// Output
// toolbar=no,location=no
export function formatObjectKeysToCommaSeparatedStringValues(props) {
  return Object.keys(props)
    .map((key) => {
      switch (typeof props[key]) {
        case 'function':
          return `${key}=${props[key].call(this, props, window)}`
        case 'boolean':
          return `${key}=${props[key] ? 'yes' : 'no'}`
        default:
          return `${key}=${props[key]}`
      }
    })
    .join(',')
}

export const d3DistanceFormat = (distance) => {
  return distance ? format(',.2f')(distance) : formatMissingData(distance)
}

export const numberToMoneyFormatter = (number) => {
  const SI_SYMBOL = ['', 'k', 'M', 'G', 'T', 'P', 'E']
  // what tier? (determines SI symbol)
  const tier = (Math.log10(number) / 3) | 0
  let result

  // if zero, we don't need a suffix
  if (tier === 0) {
    result = number
  } else {
    // get suffix and determine scale
    const suffix = SI_SYMBOL[tier]
    const scale = Math.pow(10, tier * 3)

    // scale the number
    const scaled = number / scale
    // format number and add suffix
    if (suffix === 'k') {
      result = scaled.toFixed(0) + 'K'
    } else {
      result = scaled.toFixed(1) + suffix
    }
  }

  // format number and add suffix
  return `$${result}`
}

export const round = (value, precision) => {
  var multiplier = Math.pow(10, precision || 0)
  return Math.round(value * multiplier) / multiplier
}

export const capitalize = (input) => {
  return typeof input === 'string'
    ? input.charAt(0).toUpperCase() + input.slice(1).toLowerCase()
    : ''
}

export const selectInputFormatter = (data, labelKey, valueKey) =>
  data.map((option) => ({
    label: option[labelKey],
    value: option[valueKey],
  }))
