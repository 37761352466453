import {
  AVAILABLE_TOOLS,
  AVAILABLE_VIEWS,
  FEATURE_TYPE_FORMS,
  FEATURE_TYPE_TOOLS,
  FEATURE_TYPE_VIEWS,
  NEW_DEFAULT_SCOPE_BODY,
  REVIEW_SCOPE_RECOMMENDED_FORM_OPTION_NONE,
} from 'constants/reviewScopeConstants'
import {
  ALL_FORMS_ID,
  ALL_TOOLS_ID,
  ALL_VIEWS_ID,
  NONE_RECOMMENDED_FORM_ID,
} from 'constants/reviewScopeFeatureIdConstants'
import cloneDeep from 'lodash/cloneDeep'

export const getReviewScopesExcludingDefault = (scopes) => {
  let newScopes
  switch (scopes.length) {
    case 0:
      newScopes = []
      break
    case 1:
      newScopes = []
      break
    case scopes.length > 1:
      // take all but the last element
      newScopes = scopes.slice(0, scopes.length - 1)
      // remove any duplicate defaults
      newScopes = newScopes.filter((scope) => scope.criteria.predicateType !== 'AlwaysMatch')
      break
    default:
      break
  }

  return newScopes
}

export const parseScopes = (reviewScopes) => {
  const newScopes = {}
  const clonedReviewScopes = cloneDeep(reviewScopes)

  newScopes.defaultReviewScope = clonedReviewScopes.slice(-1)[0]
  newScopes.reviewScopes = clonedReviewScopes.slice(0, -1)

  // the BE strips the childPredicates key from the default scope for some reason
  // we need it so that the criteria builder doesn't throw an error
  newScopes.defaultReviewScope.criteria.childPredicates = []
  // we always want to display the latest default scope name and
  // description as defined on NEW_DEFAULT_SCOPE_BODY
  newScopes.defaultReviewScope.name = NEW_DEFAULT_SCOPE_BODY.name
  newScopes.defaultReviewScope.description = NEW_DEFAULT_SCOPE_BODY.description
  // the initial default scope set by the BE sets all scope settings to null
  // which implies 'show all'.
  newScopes.defaultReviewScope.settings.views ??= [ALL_VIEWS_ID]
  newScopes.defaultReviewScope.settings.forms ??= [ALL_FORMS_ID]
  newScopes.defaultReviewScope.settings.tools ??= [ALL_TOOLS_ID]
  // null on the default scope's recommendedFormId means 'none'
  newScopes.defaultReviewScope.settings.recommendedFormId ??= NONE_RECOMMENDED_FORM_ID

  return newScopes
}

export const getRecommendedFormOptions = (formsOptions, selectedFormIds) => {
  const noneOption = {
    label: REVIEW_SCOPE_RECOMMENDED_FORM_OPTION_NONE.displayName,
    value: REVIEW_SCOPE_RECOMMENDED_FORM_OPTION_NONE.id,
  }
  const isAllFormsSelected = selectedFormIds.includes(ALL_FORMS_ID)
  const filteredFormOptions = isAllFormsSelected
    ? formsOptions.filter((formOption) => formOption.value !== ALL_FORMS_ID)
    : formsOptions
  const filteredSelectedFormIds = isAllFormsSelected
    ? filteredFormOptions.map((formOption) => formOption.value)
    : selectedFormIds

  return [noneOption, ...getOptionsFromIds(filteredFormOptions, filteredSelectedFormIds)]
}

export const isDefaultScope = (scope) => !!scope?.defaultScope

export const findOptionById = (options, optionId) =>
  options.find((option) => option.value === optionId)

export const getOptionsFromIds = (options, ids) =>
  options.filter((option) => ids.includes(option.value))

export const getIdsFromOptions = (options) => options.map((option) => option.value)

export const getSelectedFeatures = (featureType, selectedIds, allId, suppliedAvailableFeatures) => {
  // if selectedIds is null we show all features, if selectedIds is an empty array we show nothing
  // if 'all' is selected we show everything
  const shouldShowAll = !selectedIds || selectedIds.includes(allId)
  let features = []
  let availableFeatures

  switch (featureType) {
    case FEATURE_TYPE_VIEWS:
      availableFeatures = AVAILABLE_VIEWS
      break
    case FEATURE_TYPE_TOOLS:
      availableFeatures = AVAILABLE_TOOLS
      break
    case FEATURE_TYPE_FORMS:
      availableFeatures = suppliedAvailableFeatures
      break
    default:
      break
  }

  if (shouldShowAll) {
    // remove the 'all' feature from the returned list
    features = availableFeatures.filter((feature) => feature.id !== allId)
  } else {
    if (featureType === FEATURE_TYPE_FORMS) {
      // ensure we get all versions of a form in this array.
      // we can dedupe this array later, if appropriate
      availableFeatures.forEach((availableFeature) => {
        if (selectedIds.includes(availableFeature.id)) {
          features.push(availableFeature)
        }
      })
    } else {
      const availableFeaturesByIdDictionary = availableFeatures.reduce((acc, feature) => {
        // this will result in 1 entry per id but there's no guarentee as to which version of a given id you get
        acc[feature.id] = feature

        return acc
      }, {})

      selectedIds.forEach((id) => {
        const feature = availableFeaturesByIdDictionary[id]

        if (feature) {
          features.push(feature)
        }
      })
    }
  }

  return features
}
