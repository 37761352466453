import store from 'services/store'
import {logout} from 'actions/authActions'

/*
  This function passed to a new instance of an api to intercept errors
  We handle specific response codes then continue with throwing the error
*/
export default function errorHandler(error) {
  const {response} = error

  // intercept specific status codes
  if (response) {
    switch (response.status) {
      case 401:
        store.dispatch(logout())
        break
    }
  }

  // Continue to allow the action creator to handle errors as expected
  throw error
}
