export const BASE_REVIEW_QUEUE_COLUMNS = [
  {
    label: 'Address',
    id: 'address',
  },
  {
    label: 'Revision',
    id: 'revision',
  },
  {
    label: 'Tier',
    id: 'tier',
  },
  {
    label: 'Orders',
    id: 'orders',
  },
  {
    label: 'Borrower & Loan Number',
    id: 'borrower-name-and-loan-number',
  },
  {
    label: 'Investor',
    id: 'investor',
  },
  {
    label: 'Time in Review',
    id: 'time-in-review',
  },
  {
    label: 'Review Due',
    id: 'review-due',
  },
]

export const REVIEW_QUEUE_ON_HOLD_FILTER_OPTIONS = [
  {
    value: 'true',
    label: 'On Hold',
  },
  {
    value: 'false',
    label: 'Exclude On Hold',
  },
]

export const REVIEW_QUEUE_AUTO_APPROVED_FILTER_OPTIONS = [
  {
    value: 'true',
    label: 'Auto Approved Only',
  },
  {
    value: 'false',
    label: 'Exclude Auto Approve',
  },
]

export const REVIEW_QUEUE_REVISION_STAGE_FILTER_OPTIONS = [
  {
    value: 'REVISION_REQUESTED',
    label: 'Revision Requested',
  },
  {
    value: 'REVISED',
    label: 'Revised',
  },
  {
    value: 'NONE',
    label: 'None',
  },
]

export const AGE_PREVIOUS_ONE_HOUR = 'AGE_PREVIOUS_ONE_HOUR'
export const AGE_PREVIOUS_TWO_HOURS = 'AGE_PREVIOUS_TWO_HOURS'
export const AGE_PREVIOUS_FOUR_HOURS = 'AGE_PREVIOUS_FOUR_HOURS'
export const AGE_PREVIOUS_TWELVE_HOURS = 'AGE_PREVIOUS_TWELVE_HOURS'
export const AGE_ONE_DAY = 'AGE_ONE_DAY'
export const AGE_TWO_DAYS = 'AGE_TWO_DAYS'
export const AGE_TWO_PLUS_DAYS = 'AGE_TWO_PLUS_DAYS'

export const REVIEW_QUEUE_TIME_IN_REVIEW_FILTER_OPTIONS = [
  {
    value: 'hours',
    label: 'Hours',
  },
  {
    value: 'days',
    label: 'Days',
  },
  {
    value: 'weeks',
    label: 'Weeks',
  },
]

export const QUEUE_STATUS_VIEW_IDLE = 'QUEUE_STATUS_VIEW_IDLE'
export const QUEUE_STATUS_VIEW_EMPTY = 'QUEUE_STATUS_VIEW_EMPTY'
export const QUEUE_STATUS_VIEW_UNAUTHORIZED = 'QUEUE_STATUS_VIEW_UNAUTHORIZED'
export const QUEUE_STATUS_LOADING_VIEW = 'QUEUE_STATUS_LOADING_VIEW'
export const QUEUE_STATUS_LOADING_TABLE_DATA = 'QUEUE_STATUS_LOADING_TABLE_DATA'
export const QUEUE_STATUS_TABLE_DATA_LOADED = 'QUEUE_STATUS_TABLE_DATA_LOADED'

export const VIEW_ACTIVE = 'VIEW_ACTIVE'
export const VIEW_ASSIGNED = 'VIEW_ASSIGNED'
export const VIEW_CLOSED = 'VIEW_CLOSED'

export const REVIEW_QUEUE_VIEW_OPTIONS = [
  {
    value: VIEW_ASSIGNED,
    label: 'Assigned to Me',
  },
  {
    value: VIEW_ACTIVE,
    label: 'Active Cases',
  },
  {
    value: VIEW_CLOSED,
    label: 'Closed Cases',
  },
]

export const CASES_PER_PAGE_OPTIONS = [50, 100, 200]

export const REVIEW_QUEUE_ACTIVE_FILTER_CONFIG = [
  {
    type: 'select',
    label: 'On Hold State',
    name: 'onHold',
    placeholder: 'Select Option',
    sqaId: 'on-hold-stage',
  },
  {
    type: 'select',
    label: 'State',
    multiple: true,
    name: 'state',
    placeholder: 'Select State',
    sqaId: 'filter-state',
  },
  {
    type: 'select',
    label: 'Tier',
    multiple: true,
    name: 'tier',
    placeholder: 'Select Tier',
    sqaId: 'filter-tier',
  },
  {
    type: 'input',
    label: 'Investor',
    name: 'investor',
    placeholder: 'Investor Name',
    sqaId: 'filter-investor',
  },
  {
    type: 'date-range',
    label: 'Review Due',
    name: 'reviewDue',
    startDateProps: {
      placeholder: 'Date',
      size: 'small',
      sqaId: 'filter-review-due-start',
      name: 'reviewDueStart',
    },
    endDateProps: {
      placeholder: 'Date',
      size: 'small',
      sqaId: 'filter-review-due-end',
      name: 'reviewDueEnd',
    },
  },
  {
    type: 'relative-range',
    label: 'Time In Review',
    name: 'timeInReview',
    timeInReviewFormat: 'days',
    sqaId: 'filter-time-in-review-format',
    startRangeProps: {
      placeholder: 'Min',
      size: 'small',
      sqaId: 'filter-time-in-review-start',
      name: 'timeInReviewStart',
    },
    endRangeProps: {
      placeholder: 'Max',
      size: 'small',
      sqaId: 'filter-time-in-review-end',
      name: 'timeInReviewEnd',
    },
  },
  {
    type: 'select',
    label: 'Assigned To',
    multiple: true,
    name: 'assignedTo',
    placeholder: 'Select Reviewer',
    sqaId: 'filter-assigned-to',
  },
  {
    type: 'date-range',
    label: 'Created Date',
    name: 'createdDate',
    startDateProps: {
      placeholder: 'Date',
      size: 'small',
      sqaId: 'filter-created-date-state',
      name: 'createdDateStart',
    },
    endDateProps: {
      placeholder: 'Date',
      size: 'small',
      sqaId: 'filter-created-date-end',
      name: 'createdDateEnd',
    },
  },
]

export const REVIEW_QUEUE_BUSINESS_UNIT_FILTER_CONFIG = {
  type: 'select',
  label: 'Business Unit',
  multiple: true,
  name: 'businessUnit',
  placeholder: 'Select Business Unit',
  sqaId: 'business-unit',
}
export const MAX_TIME_IN_REVIEW_INPUT_VALUE = 999

export const REVIEW_QUEUE_CLOSED_FILTER_CONFIG = [
  {
    type: 'select',
    label: 'Auto Approved',
    name: 'autoApproved',
    placeholder: 'Select Option',
    sqaId: 'auto-approved',
  },
  {
    type: 'select',
    label: 'Tier',
    multiple: true,
    name: 'tier',
    placeholder: 'Select Tier',
    sqaId: 'filter-tier',
  },
  {
    type: 'select',
    label: 'Assigned To',
    multiple: true,
    name: 'assignedTo',
    placeholder: 'Select Reviewer',
    sqaId: 'filter-assigned-to',
  },
  {
    type: 'date-range',
    label: 'Closed',
    name: 'closed',
    startDateProps: {
      placeholder: 'Date',
      size: 'small',
      sqaId: 'filter-closed-start',
      name: 'closedStart',
    },
    endDateProps: {
      placeholder: 'Date',
      size: 'small',
      sqaId: 'filter-closed-end',
      name: 'closedEnd',
    },
  },
]
