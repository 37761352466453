import {FETCH_ERROR_MESSAGE_PREFIX} from 'constants/appMessages'

export default class Api {
  static timeout = 120000

  static headers = {
    Accept: 'application/json, text/javascript; q=0.9, */*; q=0.6',
    'Accept-Encoding': 'gzip, deflate, br',
    'Content-Type': 'application/json',
    'X-Caller': 'ccp-webapp',
  }

  constructor(props) {
    const {baseUrl, headers, errorHandler} = props
    this.errorHandler = errorHandler
    this.baseUrl = baseUrl
    this.headers = {
      ...Api.headers,
    }

    if (headers) {
      Object.keys(headers).forEach((key) => {
        if (headers[key] === null) {
          delete this.headers[key]
        } else {
          this.headers[key] = headers[key]
        }
      })
    }
  }

  request = (url, options, passThrough) => {
    const {errorHandler} = this

    return fetch(url, options).then(
      async (response) => {
        if (options.method === 'HEAD') {
          return response
        }
        if (response.ok) {
          if (response.status !== 204) {
            let data
            switch (response.headers.get('content-type')?.split(';')[0]) {
              case 'image/jpeg':
              case 'text/csv':
                data = await response.text()
                break
              case 'application/pdf':
                data = response.blob()
                break
              case 'application/json':
                data = response.json()
                break
              default:
                data = await response.text()
            }

            return passThrough ? {...passThrough, ...data} : data
          }
        } else {
          // Do not move the FETCH_ERROR_MESSAGE_PREFIX from the beginning of the error message.
          // Our Sentry implementation looks for it (startWith()) in the event filtering logic.
          // See the `beforeSend` configuration in the Sentry initialization code.
          const apiError = new Error(
            `${FETCH_ERROR_MESSAGE_PREFIX}: ${options.method} ${url} ${response.status}${
              response.statusText ? ` (${response.statusText})` : ''
            }`
          )
          apiError.response = response

          const errorHandle = (apiError) => {
            if (errorHandler) {
              errorHandler(apiError)
            } else {
              throw apiError
            }
          }
          if (response.headers.get('content-type') === 'application/json') {
            try {
              const json = await response.json()
              apiError.json = json
            } catch (e) {
              console.error(e, 'could not parse response json')
            }
          }

          errorHandle(apiError)
        }
      },
      (err) => {
        const callError = new Error('Call Error')
        callError.response = err

        if (errorHandler) {
          errorHandler(callError)
        } else {
          throw callError
        }
      }
    )
  }

  transformRequest(contentType, data) {
    let transformedData
    switch (contentType) {
      case 'application/json':
        transformedData = JSON.stringify(data)
        break
      case 'application/x-www-form-urlencoded':
        const tempData = []
        Object.keys(data).forEach((key) => {
          if (Array.isArray(data[key])) {
            data[key].forEach((value) => {
              tempData.push(encodeURIComponent(key) + '=' + encodeURIComponent(value))
            })
          } else {
            tempData.push(encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
          }
        })
        transformedData = tempData.join('&')
        break
      default:
        transformedData = data
        break
    }
    return transformedData
  }

  get = (url, headerOverrides, passThrough) => {
    const {request, baseUrl} = this
    const headers = headerOverrides
      ? {
          ...this.headers,
          ...headerOverrides,
        }
      : this.headers
    const options = {
      headers,
      credentials: 'include',
      method: 'GET',
      mode: 'cors',
    }

    // add a cache-busting param for GET requests
    url += (url.indexOf('?') > -1 ? '&_=' : '?_=') + Date.now()

    return request(`${baseUrl}/${url}`, options, passThrough)
  }

  head = (url, headerOverrides, passThrough) => {
    const {request, baseUrl} = this
    const headers = headerOverrides
      ? {
          ...this.headers,
          ...headerOverrides,
        }
      : this.headers
    const options = {
      headers,
      credentials: 'include',
      method: 'HEAD',
      mode: 'cors',
    }

    // add a cache-busting param for GET requests
    url += (url.indexOf('?') > -1 ? '&_=' : '?_=') + Date.now()

    return request(`${baseUrl}/${url}`, options, passThrough)
  }

  post = (url, data, headerOverrides, passThrough) => {
    const {request, baseUrl} = this
    const headers = headerOverrides
      ? {
          ...this.headers,
          ...headerOverrides,
        }
      : this.headers
    const options = {
      headers,
      credentials: 'include',
      body: this.transformRequest(headers['Content-Type'], data),
      method: 'POST',
      mode: 'cors',
    }

    return request(`${baseUrl}/${url}`, options, passThrough)
  }

  put = (url, data, headerOverrides, passThrough) => {
    const {request, baseUrl} = this
    const headers = headerOverrides
      ? {
          ...this.headers,
          ...headerOverrides,
        }
      : this.headers
    const options = {
      headers,
      credentials: 'include',
      body: this.transformRequest(headers['Content-Type'], data),
      method: 'PUT',
      mode: 'cors',
    }

    return request(`${baseUrl}/${url}`, options, passThrough)
  }

  delete = (url, headerOverrides, passThrough) => {
    const {request, baseUrl} = this
    const headers = headerOverrides
      ? {
          ...this.headers,
          ...headerOverrides,
        }
      : this.headers
    const options = {
      headers,
      credentials: 'include',
      method: 'DELETE',
      mode: 'cors',
    }

    return request(`${baseUrl}/${url}`, options, passThrough)
  }
}
