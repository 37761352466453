import {LOADING} from 'constants/reduxStatuses'
import {createSelector} from 'reselect'

export const caseCountsByUserRootSelector = (state) => state.caseCountsByUser

export const caseCountsByUserSelector = createSelector(
  [caseCountsByUserRootSelector],
  (caseCountsByUserRoot) => caseCountsByUserRoot.data
)

export const isCaseCountsByUserLoadingSelector = createSelector(
  [caseCountsByUserRootSelector],
  (caseCountsByUserRoot) => caseCountsByUserRoot.status === LOADING
)

export const caseCountsByUserIdDictionarySelector = createSelector(
  [caseCountsByUserSelector],
  (caseCountsByUser) => {
    return caseCountsByUser.reduce((acc, {userId, caseCount}) => {
      acc[userId] = caseCount
      return acc
    }, {})
  }
)
