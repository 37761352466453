// Styles
import styled from 'styled-components'
import {theme} from '@clearcapital/ui-resources'

// Core
import PropTypes from 'prop-types'
import React from 'react'

// Components, services, etc.
import logo from 'assets/ClearCollateral.png'

const AuthFormHeading = ({children, errorMessage, heading, introText}) => (
  <AuthFormHeading.Styled>
    <img
      data-sqa-id='CC-Logo'
      className='auth-logo-image'
      src={logo}
      alt='ClearCollateral Platform Logo'
    />
    <h1 className='auth-heading' data-sqa-id='welcomeBack'>
      {heading}
    </h1>
    {errorMessage ? (
      <p className='auth-intro-text error'>{errorMessage}</p>
    ) : (
      <p className='auth-intro-text' data-sqa-id='IntroText'>
        {introText}
      </p>
    )}
    {children}
  </AuthFormHeading.Styled>
)

AuthFormHeading.Styled = styled.div`
  .auth-logo-image {
    width: 157px;
    margin-bottom: 24px;
  }

  .auth-heading {
    margin-bottom: 16px;
    font-weight: ${theme.typography.fontWeightLight};
    font-family: Roboto;
    font-size: 1.875rem;
    text-transform: none;
  }

  .auth-intro-text {
    margin: 0;
    margin-bottom: 40px;
    color: ${theme.palette.colors.grey[550]};
    font-size: 1rem;
  }

  .error {
    color: ${theme.palette.colors.red[450]};
  }
`

AuthFormHeading.propTypes = {
  heading: PropTypes.string,
  introText: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  errorMessage: PropTypes.string,
}

export default AuthFormHeading
