// Styles
import styled from 'styled-components'

// Core
import React from 'react'
import {Link} from 'react-router-dom'

const TosPrivacyLinks = () => {
  return (
    <TosPrivacyLinks.Styled data-sqa-id='tos-privacy-links'>
      By continuing, you agree to Clear Capital's{' '}
      <Link to='/terms-of-service' target='_blank' data-sqa-id='tos-privacy-link-tos'>
        Terms of Service
      </Link>{' '}
      and{' '}
      <a
        href='https://www.clearcapital.com/privacy-policy/'
        target='_blank'
        data-sqa-id='tos-privacy-link-privacy'
      >
        Privacy Policy
      </a>
    </TosPrivacyLinks.Styled>
  )
}

TosPrivacyLinks.Styled = styled.p`
  font-family: 'Inter';
  margin-top: 16px;
  font-size: 12px;
  line-height: 20px;
  a {
    font-weight: 500;
  }
`
export default TosPrivacyLinks
