export const getQueryStringParams = (search = window.location.search) => {
  const params = new URLSearchParams(search)
  const parsed = {}
  for (var [key, value] of params.entries()) {
    parsed[key] = value
  }
  return parsed
}

export const objectToQueryString = (object) => {
  const params = new URLSearchParams()
  Object.keys(object).forEach((key) => params.append(key, object[key]))
  return params.toString()
}
