import {VIEW_ACTIVE} from 'constants/queueConstants'
import moment from 'moment'
import {getHrefWithBasename} from 'services/hrefService'
import {ALL_TIERS_ID, NO_TIER_ID} from 'constants/dashboardConstants'

export const mergeCasesByTierDataWithTiers = (tiers, casesByTier) => {
  // If neither of these exist just return an empty array
  if (tiers.length === 0 && casesByTier.length === 0) {
    return []
  }

  const defaultCaseTierData = {
    casesClosedToday: 0,
    casesAssigned: 0,
    casesUnassigned: 0,
    casesReadyForReview: 0,
    casesRevisionRequested: 0,
    casesOnHold: 0,
  }

  const dataDictionary = casesByTier.reduce((acc, curr) => {
    const id = curr.tierId || null
    acc[id] = curr
    return acc
  }, {})

  const noTierData = {
    tierName: 'No Tier',
    enabled: true,
    ...(dataDictionary[null] ?? defaultCaseTierData),
  }

  const combineTiersWithCasesByTier = tiers.reduce((accumulator, {id, name, enabled, sequence}) => {
    const caseTierData = dataDictionary[id]
    const isDisabledTierWithNoCases = !enabled && !caseTierData
    if (!isDisabledTierWithNoCases) {
      accumulator.push({
        tierName: `${name}${enabled ? '' : ' (Disabled)'}`,
        tierId: id,
        sequence: sequence + 1,
        enabled: enabled,
        ...(dataDictionary[id] ?? defaultCaseTierData),
      })
    }

    return accumulator
  }, [])

  return [noTierData, ...combineTiersWithCasesByTier]
}

export const encodeParamValue = (value) => encodeURIComponent(JSON.stringify(value))

export const generateQueryStringFromKey = (
  key,
  tierId,
  assignedTo,
  view = VIEW_ACTIVE,
  setHrefWithBasename,
  timeInReviewData
) => {
  const localQuery = new URLSearchParams()
  const redirectPath = '/queues/review-queue?'
  const baseRoute = setHrefWithBasename ? getHrefWithBasename(redirectPath) : redirectPath
  localQuery.set('view', view)
  // we need to check for undefined specifically, as null is the tierId value for 'No Tier'
  if (tierId !== undefined) {
    localQuery.set('tier', encodeParamValue([tierId]))
  }

  assignedTo && localQuery.set('assignedTo', encodeParamValue([assignedTo]))

  switch (key) {
    case 'casesReadyForReview':
      localQuery.set('onHold', false)
      localQuery.set('reviewStage', encodeParamValue(['IN_REVIEW']))
      localQuery.set('revisionStage', encodeParamValue(['NONE', 'REVISED']))
      localQuery.set('onHold', false)
      break
    case 'casesRevisionRequested':
      localQuery.set('reviewStage', encodeParamValue(['IN_FULFILLMENT']))
      localQuery.set('revisionStage', encodeParamValue(['REVISION_REQUESTED']))
      break
    case 'casesOnHold':
      localQuery.set('reviewStage', encodeParamValue(['NEW', 'IN_FULFILLMENT', 'IN_REVIEW']))
      localQuery.set('onHold', true)
      break
    case 'casesClosedToday':
      localQuery.set('closedStart', moment().startOf('day'))
      break
    case 'autoApprovedToday':
      localQuery.set('autoApproved', true)
      localQuery.set('closedStart', moment().startOf('day'))
      break
    case 'casesByAssignedTo':
      localQuery.set('assignedTo', encodeParamValue([assignedTo]))
      break
    case 'casesByTimeInReview':
      const {min, max, timeInReviewFormat} = timeInReviewData
      min && localQuery.set('timeInReviewStart', min)
      max && localQuery.set('timeInReviewEnd', max)
      timeInReviewFormat && localQuery.set('timeInReviewFormat', timeInReviewFormat)
      localQuery.set('reviewStage', encodeParamValue(['IN_FULFILLMENT', 'IN_REVIEW']))
      break
    case 'totalCasesByTimeInReview':
      localQuery.set('reviewStage', encodeParamValue(['IN_FULFILLMENT', 'IN_REVIEW']))
      break
    default:
      break
  }
  return `${baseRoute}${localQuery.toString()}`
}

export const getUpdatedTierId = (tierId) => {
  let result = tierId
  if (tierId === ALL_TIERS_ID) {
    result = undefined
  } else if (tierId === NO_TIER_ID) {
    result = null
  }
  return result
}
