import {authApi} from 'services/apis'
import {SET_ORG_PREFERENCES, SET_ORG_USERS, SET_ORG_USER_ROLES} from 'constants/actionTypes'
import {getAllCasesOnHoldReasons} from 'actions/casesOnHoldActions'

export function getOrgPreferences(orgKey) {
  return function getOrgPreferences(dispatch) {
    return authApi
      .get(`organizations/${orgKey}/preferences`)
      .then((preferences) => {
        dispatch({
          type: SET_ORG_PREFERENCES,
          payload: preferences,
        })
        dispatch(getAllCasesOnHoldReasons())
      })
      .catch(() => {})
  }
}

export function getOrgUsers(orgKey) {
  return function getOrgUsers(dispatch) {
    return authApi
      .get(`organizations/${orgKey}/users`)
      .then((users) => {
        dispatch({
          type: SET_ORG_USERS,
          payload: users,
        })
      })
      .catch(() => {})
  }
}

export function getOrgUserRoles(orgKey) {
  return (dispatch, getState) => {
    const state = getState()
    const {activeOrgKey} = state.app

    return authApi
      .get(`organizations/${orgKey || activeOrgKey}/user_roles`)
      .then((roles) => {
        dispatch({
          type: SET_ORG_USER_ROLES,
          payload: roles,
        })
      })
      .catch(() => {})
  }
}
