export const SUCCESS_NOTIFICATION = {
  title: 'Success',
  message: 'Request successful',
  status: 'success',
  position: 'br',
  dismissible: true,
  dismissAfter: 8000,
}

export const ERROR_NOTIFICATION = {
  title: 'Error',
  message: 'An unknown error occurred',
  status: 'error',
  position: 'br',
  dismissible: true,
  dismissAfter: 8000,
  buttons: [{name: 'close'}],
}

export const INFO_NOTIFICATION = {
  title: 'Processing Request',
  message: 'We are processing your request',
  status: 'info',
  position: 'br',
  dismissible: false,
  dismissAfter: 8000,
  allowHTML: true,
}

export const EXPIRING_PASSWORD_NOTIFICATION = {
  message: 'Would you like to update your password now?',
  status: 'warning',
  position: 'br',
  dismissible: true,
  dismissAfter: 0,
}

export const ORDER_REVIEW_SUBMITTED = {
  allowHTML: true,
  dismissAfter: 8000,
  message: 'A PDF version of your review is available in the Document Center',
  position: 'br',
  status: 'success',
  title: 'Review Submitted',
}

export const QUEUE_PRIORITY_SUCCESS_NOTIFICATION = {
  title: 'Success',
  message: 'Queue Priority Sorting Criteria has been saved',
  status: 'success',
  position: 'br',
  dismissible: false,
  dismissAfter: 8000,
  allowHTML: true,
}
