// returns organization users desired - primarily review quals for now
export function organizationUsers(user) {
  return (
    user.roles &&
    user.person &&
    user.person.displayName &&
    user.status === 'ENABLED' &&
    // user.providerManager and user.realEstateProfessional are
    // not part of the new roles renovation work. The check for them
    // can be removed in the future
    (user.roles.indexOf('user.reviewer') !== -1 ||
      user.roles.indexOf('user.reviewModuleAdmin') !== -1 ||
      user.roles.indexOf('user.providerManager') !== -1 ||
      user.roles.indexOf('user.realEstateProfessional') !== -1)
  )
}
