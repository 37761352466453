import {CLEAR_ACTIVE_COMP, SET_ACTIVE_COMP} from 'constants/actionTypes'

export const initialState = {}

export default function activeComp(state = initialState, action) {
  switch (action.type) {
    case SET_ACTIVE_COMP:
      return {...action.payload}
    case CLEAR_ACTIVE_COMP:
      return initialState
    default:
      return state
  }
}
