import {createSelector} from 'reselect'
import get from 'lodash/get'
import {NO_DATA} from 'constants/app'
import {money} from 'services/formatData'
import {userHasPermission} from 'components/HasPermission'

export const orderSelector = (state) => state.order

export const isOrderActiveSelectorCreator = () =>
  createSelector([orderSelector], (order) => order.stage !== 'CLOSED' && order.stage !== 'CANCELED')

export const isOrderActiveSelector = isOrderActiveSelectorCreator()

export const isOrderEditableSelectorCreator = () =>
  createSelector([isOrderActiveSelector], (isOrderActive) => {
    return userHasPermission('WEB:ORDERS:MANAGE') && isOrderActive
  })

export const isOrderAutoReviewedSelector = createSelector(
  [orderSelector],
  (order) => order?.autoReviewed
)

export const orderRequestedEventCreatedTimeSelectorCreator = () =>
  createSelector([orderSelector], (order) => {
    const events = get(order, 'events', [])
    return Array.isArray(events) && events.length
      ? get(
          events.find((event) => event.eventType === 'OrderRequested'),
          'createdTime',
          null
        )
      : null
  })

export const documentCaseIdSelector = createSelector([orderSelector], (order) =>
  get(order, 'caseId')
)

export const productCodeSelectorCreator = () =>
  createSelector([orderSelector], (order) => get(order, 'productCode', null))

export const priceSelectorCreator = () =>
  createSelector([orderSelector], (order) => {
    const price = get(order, 'price') || get(order, 'acceptedPrice') || null
    return price ? money(Number(price)) : NO_DATA
  })

export const inspectionCompletedSelector = createSelector([orderSelector], (order) =>
  get(order, 'inspectionCompleted')
)

export const inspectionDateSelector = createSelector([orderSelector], (order) =>
  get(order, 'inspectionScheduledDateTime')
)

export const orderIdSelector = createSelector([orderSelector], (order) => get(order, 'orderId'))

export const orderStageSelector = createSelector([orderSelector], (order) => get(order, 'stage'))

export const providerDueDateSelectorCreator = () =>
  createSelector([orderSelector], (order) => get(order, 'dueDate'))

export const reviewDueDateSelectorCreator = () =>
  createSelector([orderSelector], (order) => get(order, 'reviewDueDate'))

export const specialInstructionsSelectorCreator = () =>
  createSelector([orderSelector], (order) => get(order, 'specialInstructions'))

export const contactsSelector = createSelector(
  [orderSelector],
  (order) => get(order, 'contacts') ?? []
)

export const createSortedByPrimaryContactsSelector = () =>
  createSelector([contactsSelector], (contacts) => contacts.sort((c1) => (c1.isPrimary ? -1 : 1)))

// there is no property on the order review submissions that tell us this. it is based on
// whether the order has a stage of 'CLOSED' according to the BE team
export const orderReviewIsSubmittedSelector = createSelector(
  [orderSelector],
  (order) => order.stage === 'CLOSED'
)
