import {
  INITIALIZE_REVIEW_SCOPES_DATA,
  RESET_REVIEW_SCOPES,
  SET_ACTIVE_REVIEW_SCOPE,
  SET_CASE_REVIEW_SCOPE,
  SET_REVIEW_SCOPE_STATUS,
  SET_REVIEW_SCOPES,
  RESET_CASE_REVIEW_SCOPE,
} from 'constants/actionTypes'
import {STATUS_INIT, STATUS_LOADED} from 'constants/loadingStatusConstants'
import {parseScopes} from 'services/reviewScopeHelpers'

export const initialState = {
  status: STATUS_INIT,
  caseReviewScope: null,
  defaultReviewScope: null,
  activeReviewScope: null,
  reviewScopes: [],
}

export default function reviewScopes(state = initialState, action) {
  switch (action.type) {
    case INITIALIZE_REVIEW_SCOPES_DATA: {
      // all scopes are stored in the same array on the BE, so we need to seperate the defaultScope from reviewScopes
      const {reviewScopes, defaultReviewScope} = parseScopes(action.payload.reviewScopes)

      return {
        ...state,
        status: STATUS_LOADED,
        reviewScopes,
        defaultReviewScope,
      }
    }
    case SET_REVIEW_SCOPES: {
      return {
        ...state,
        status: STATUS_LOADED,
        reviewScopes: action.payload,
      }
    }
    case SET_REVIEW_SCOPE_STATUS: {
      return {
        ...state,
        status: action.payload,
      }
    }
    case RESET_REVIEW_SCOPES: {
      return initialState
    }
    case SET_ACTIVE_REVIEW_SCOPE: {
      return {
        ...state,
        activeReviewScope: action.payload,
      }
    }
    case SET_CASE_REVIEW_SCOPE: {
      return {
        ...state,
        caseReviewScope: action.payload,
      }
    }
    case RESET_CASE_REVIEW_SCOPE: {
      return {
        ...state,
        caseReviewScope: initialState.caseReviewScope,
      }
    }
    default:
      return state
  }
}
