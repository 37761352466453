import {createSelector} from 'reselect'
import {appAuthSelector} from 'selectors/appSelectors'
import {customerOrganizationSelector} from './customerOrganizationSelectors'

export const permissionsSelector = createSelector(
  [appAuthSelector],
  (auth) => auth?.permissions ?? []
)

export const webPermissionsSelector = createSelector(
  [permissionsSelector, customerOrganizationSelector],
  (permissions, {organizationKey}) => {
    // TODO: this is hacky thing to hide most of the app from RSDS users.  Delete when possible and never do this again.
    if (organizationKey === 'RSDS') {
      const permissionsWhiteList = [
        'CORE:RSDS:VALUATIONS:.*?:READ',
        'WEB:SETTINGS:VIEW',
        'WEB:USERS:MANAGE',
      ]
      permissions = permissions.filter((permission) => permissionsWhiteList.includes(permission))
    } else {
      permissions = permissions.filter((permission) => permission.startsWith('WEB:'))
    }
    return permissions
  }
)

export const isCaseReviewManagerSelector = createSelector([webPermissionsSelector], (permissions) =>
  permissions.includes('WEB:CASE_REVIEW:MANAGE')
)

export const isCasesOnHoldManagePermissionSelector = createSelector(
  [webPermissionsSelector],
  (permissions) => permissions.includes('WEB:CASES_ON_HOLD:MANAGE')
)

export const isClearPropReportManagerSelector = createSelector(
  [webPermissionsSelector],
  (permissions) => permissions.includes('WEB:CLEAR_PROP_REPORT:MANAGE')
)

export const isOrgAdminSelector = createSelector([webPermissionsSelector], (permissions) =>
  permissions.includes('WEB:ORG_SETTINGS:MANAGE')
)

export const isSearchEnabledSelector = createSelector([webPermissionsSelector], (permissions) =>
  permissions.includes('WEB:SEARCH:MANAGE')
)
