// Core
import PropTypes from 'prop-types'
import React from 'react'

// Components, services, etc.
import AuthForm from './AuthForm'
import AuthFormHeading from './AuthFormHeading'

const MainAuthForm = ({heading, children, errorMessage, introText}) => (
  <AuthForm>
    <AuthFormHeading heading={heading} errorMessage={errorMessage} introText={introText}>
      {children}
    </AuthFormHeading>
  </AuthForm>
)

MainAuthForm.propTypes = {
  heading: PropTypes.string.isRequired,
  introText: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  errorMessage: PropTypes.string,
}

export default MainAuthForm
