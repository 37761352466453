// Styles
import styled from 'styled-components'
import {theme} from '@clearcapital/ui-resources'

// Core
import React from 'react'
import PropTypes from 'prop-types'

const AuthForm = ({children}) => (
  <AuthForm.Styled className='auth-container'>
    <div className='auth-panel'>{children}</div>
  </AuthForm.Styled>
)

AuthForm.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}

AuthForm.Styled = styled.div`
  background-color: ${theme.palette.colors.white};
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.04);
  height: 100%;
  min-height: 879px;
  min-width: 375px;
  padding-left: 48px;
  padding-right: 48px;
  width: 30vw;

  .header {
    margin-top: 240px;

    .auth-logo-image {
      width: 157px;
    }

    .auth-heading {
      font-size: 1.875rem;
      font-weight: ${theme.typography.fontWeightLight};
      line-height: 35px;
      margin: 20px 0 14px 0;
      text-transform: none;
    }

    .auth-intro-text {
      margin: 0;
      margin-bottom: 40px;
      color: ${theme.palette.colors.grey[550]};
      font-size: 1rem;
    }

    .error {
      color: ${theme.palette.colors.red[450]};
    }
  }

  .auth-panel {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: center;
  }

  .auth-row {
    display: flex;
    flex-direction: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  .auth-half-field {
    width: 184px;
  }

  .auth-third-field {
    width: 117px;
  }

  .existing-account {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 24px;
    p {
      font-size: 1rem;
      color: ${theme.palette.colors.grey[550]};
      margin: 0;
    }
    a {
      margin-left: 5px;
      color: ${theme.palette.colors.blue[350]};
      font-weight: ${theme.typography.fontWeightMedium};
    }
  }

  .auth-button {
    width: 100%;
    font-weight: ${theme.typography.fontWeightMedium};
    font-size: 0.875rem;
  }

  .auth-back {
    margin-bottom: 28px;
    display: flex;
    cursor: pointer;
    align-items: center;

    font-weight: ${theme.typography.fontWeightMedium};
    font-size: 0.875rem;
    line-height: 1;
  }
`
export default AuthForm
