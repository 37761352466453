import Person from './Person'

export default class User {
  constructor(props) {
    Object.keys(props).forEach((key) => {
      switch (key) {
        case 'person':
          this[key] = new Person(props[key])
          break
        default:
          this[key] = props[key]
      }
    })
  }
}

/*
https://auth-qa.clearcollateral.com/api-ui/#!/users

auth User {
  id (integer, optional),
  key (string, optional),
  password (string, optional),
  ssoOnly (boolean, optional),
  passwordUpdateId (integer, optional),
  roles (array[string], optional),
  organizationReferences (Map[string,string], optional),
  person (Person, optional),
  status (UserStatusEnum, optional) = ['ENABLED' or 'DISABLED' or 'PENDING'],
  type (UserTypeEnum, optional) = ['APP_USER' or 'API_USER' or 'PROVIDER_USER'],
  organizationKey (string, optional),
  updateId (integer, optional),
  passcode (integer, optional),
  daysUntilPasswordChange (integer, optional),
  qualificationsPredicate (ObjectPredicate, optional)
}
*/
