import Address from './Address'
import Property from './Property'
import User from './User'

export default class Case {
  constructor(props) {
    Object.keys(props).forEach((key) => {
      switch (key) {
        case 'property':
          const property = props[key]
          if (property.hasOwnProperty.address) {
            property.address = new Address(property.address)
          }
          this[key] = property ? new Property(property) : null
          break
        case 'assignedTo':
          this[key] = props[key] ? new User(props[key]) : null
          break
        default:
          this[key] = props[key]
      }
    })
  }
}

/*
CoreCase {
  id	integer($int64)
  updateId	integer($int64)
  organizationKey	string
  property	Property{...}
  description	string
  status	CaseStatusEnumstring = [ 'NONEXISTENT' or 'PENDING' or 'ACTIVE' or 'REVISION' or 'REVISED' or 'COMPLETED' or 'CANCELED' ]
  lastStatusUpdate	string($date-time)
  reviewDueDate	string($date-time)
  reviewStartDate	string($date-time)
  vip	boolean
  rush	boolean
  stage	CaseStageEnumstring = [ 'NEW' or 'IN_FULFILLMENT' or 'IN_REVIEW' or 'CLOSED' or 'CANCELED' ]
  organizationReferences	{...}
  previousCaseId	integer($int64)
  previousConclusion	integer($int64)
  previousPortfolioConclusion	integer($int64)
  assignedTo	User{...}
  lastAssigneeUpdate	string($date-time)
  completedBy	integer($int64)
  conclusion	integer($int64)
  conclusionDate	string($date-time)
  userIsQualified	boolean
  This is a transitive field indicating whether the "triggering user" is qualified to make changes to the Case.
  The "triggering user" is the session User that is reading or modifying the Case.
  
  collaboratingOrganizations	[...]
  pdfOnly	boolean
  investor	string
  loanInfo	LoanInfo{...}
  valuationPurpose	ValuationPurpose{...}
  sellerLoanNumber	string
  originatorInfo	OriginatorInformation{...}
  concludingDocuments	[ConcludingDocument{...}]
  tierId	{...}
  onHold	boolean
  onHoldDetails	OnHoldDetails{...}
  revisionStage	RevisionStageEnumstring = ['REVISION REQUESTED' or 'REVISED' or 'NONE']
  lastRevisionStageUpdate	string($date-time)
  autoReviewed	boolean
  }
  */
