import {ORDER_REVIEW_FORM_POPOUT, SMART_VIEW_POPOUT} from 'constants/popouts'
import {createSelector} from 'reselect'

export const popoutsSelector = (state) => state.popouts

export const smartViewPopoutDataSelector = createSelector(
  [popoutsSelector],
  (popouts) => popouts[SMART_VIEW_POPOUT]?.data ?? {}
)

export const orderReviewPopoutDataSelector = createSelector(
  [popoutsSelector],
  (popouts) => popouts[ORDER_REVIEW_FORM_POPOUT]?.data ?? {}
)
