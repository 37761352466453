import {CTE, LOCALHOST, PROD, QA, UAT, XE_DEV} from 'constants/environmentConstants'

export function getHostedEnv() {
  let env
  const origin = window.location.origin

  if (origin === 'https://xe-dev.clearcollateral.com') {
    env = XE_DEV
  } else if (origin === 'https://qa.clearcollateral.com') {
    env = QA
  } else if (origin === 'https://uat.clearcollateral.com') {
    env = UAT
  } else if (origin === 'https://cte.clearcollateral.com') {
    env = CTE
  } else if (origin === 'https://clearcollateral.com') {
    env = PROD
  } else if (origin.includes('localhost') || origin.includes('127.0.0.1')) {
    env = LOCALHOST
  }

  return env
}

export function getBackendEnv() {
  // process.env.REACT_APP_ENV is only set when running the app locally. It is set as part of the
  // start commands we run. For example `npm run start:qa` will start the app and set process.env.REACT_APP_ENV
  // to `qa`. process.env.NODE_ENV will always be set to 'production' when running the built version of
  // the app (by running `npm run build`).
  let env
  const isDevServer = process.env.NODE_ENV !== 'production' && !!process.env.REACT_APP_ENV

  if (isDevServer) {
    env = process.env.REACT_APP_ENV.toLowerCase()
  } else if (isProdBuild()) {
    const origin = window.location.origin

    if (origin === 'https://xe-dev.clearcollateral.com') {
      env = XE_DEV
    } else if (origin === 'https://qa.clearcollateral.com') {
      // the prod build is being loaded from its hosted QA URL
      env = QA
    } else if (origin === 'https://uat.clearcollateral.com') {
      // the prod build is being loaded from its hosted INTEG URL
      env = UAT
    } else if (origin === 'https://cte.clearcollateral.com') {
      // the prod build is being loaded from its hosted INTEG URL
      env = CTE
    } else if (origin === 'https://clearcollateral.com') {
      // the prod build is being loaded from its hosted PROD URL
      env = PROD
    } else if (origin.includes('localhost') || origin.includes('127.0.0.1')) {
      // the prod build is being loaded from localhost
      env = LOCALHOST
    }
  }

  return env
}

export function isProdBuild() {
  return process.env.NODE_ENV === 'production'
}
