import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {Icon, theme, validators} from '@clearcapital/ui-resources'

export const defaultRules = [
  {
    label: 'One number',
    check: (password) => /\d/.test(password),
  },
  {
    label: 'One uppercase character',
    check: (password) => /[A-Z]/.test(password),
  },
  {
    label: 'One lowercase character',
    check: (password) => /[a-z]/.test(password),
  },
  {
    label: '8 characters minimum',
    check: (password) => validators.minStringLength(password, 8),
  },
  {
    label: 'Not be your username',
    check: (password, props) => password !== props.username,
  },
  {
    label: 'Matches confirm password',
    show: (props) => props.confirmPassword,
    check: (password, props) => {
      return password === props.confirmPassword
    },
  },
]

export default class PasswordRequirements extends React.Component {
  state = {}

  checkValidity() {
    const {isValid} = this.state
    const {rules = defaultRules, password, validityChange, ...props} = this.props

    const requirements = rules
      .filter((rule) => !rule.show || rule.show(props))
      .map((rule) => ({
        label: rule.label,
        valid: rule.check(password, props),
      }))
    const invalid = requirements.find((requirement) => !requirement.valid)

    // if it is valid and not previously called
    if (!invalid && !isValid) {
      validityChange(true)
      // if invalid and previously was valid
    } else if ((invalid && isValid) || isValid === undefined) {
      validityChange(false)
    }
    this.setState({requirements, isValid: !invalid})
  }

  componentDidMount() {
    this.checkValidity()
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.password !== this.props.password ||
      prevProps.username !== this.props.username ||
      prevProps.confirmPassword !== this.props.confirmPassword
    ) {
      this.checkValidity()
    }
  }

  render() {
    const {password} = this.props
    const {requirements} = this.state
    if (!requirements || !password) {
      return null
    }
    return (
      <Container>
        <div className='content'>
          <div className='title'>Password Requirements</div>
          <ul className='list'>
            {requirements.map((requirement) => (
              <li key={requirement.label} className={requirement.valid ? 'valid' : undefined}>
                {requirement.valid && <Icon icon='done' className='done' />}
                {requirement.label}
              </li>
            ))}
          </ul>
        </div>
      </Container>
    )
  }
}

PasswordRequirements.propTypes = {
  rules: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      check: PropTypes.func.isRequired,
      show: PropTypes.func,
    })
  ),
  username: PropTypes.string,
  isValid: PropTypes.bool,
  password: PropTypes.string.isRequired,
  confirmPassword: PropTypes.string,
  validityChange: PropTypes.func.isRequired,
}

const Container = styled.div`
  position: relative;

  .green {
    color: green;
  }

  .valid {
    list-style: none;
    color: ${theme.palette.primary.main};
  }

  .done {
    font-size: 0.75rem;
    margin-left: -18px;
    padding-right: 18px;
    padding-top: 3px;
  }

  .content {
    z-index: 10;
    position: absolute;
    top: -10px;
    right: -212px;
    width: 204px;
    padding: 16px;
    border-radius: 4px;
    background: ${theme.palette.colors.white};
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14),
      0 3px 14px 2px rgba(0, 0, 0, 0.12);
  }

  .title {
    font-size: 0.75rem;
    font-weight: ${theme.typography.fontWeightMedium};
    margin-left: -2px;
  }

  li {
    font-size: 0.75rem;
    padding: 1px;
  }

  ul {
    margin: 0;
    padding: 0.75rem;
    padding-bottom: 0;
  }
`
