// Styles
import styled from 'styled-components'

// Core
import React from 'react'
import {Redirect, useLocation} from 'react-router-dom'
import {useSelector} from 'react-redux'

// Components, services, etc
import {CircularProgress} from '@clearcapital/ui-resources'
import {initialAppDataStatusSelector, appUserSelector} from 'selectors/appSelectors'

// 3rd-party
import Loadable from 'react-loadable'
import {LOADED, LOADING, UNSET} from 'constants/reduxStatuses'

const MainApp = Loadable({
  loader: () => import('../App'),
  loading: () => null,
})

const RootRoute = () => {
  const location = useLocation()
  const appUser = useSelector(appUserSelector)
  const hasUser = Boolean(appUser)
  const initialAppDataStatus = useSelector(initialAppDataStatusSelector)

  const createLoginRedirect = () => {
    // save route before redirect so you can send them back on auth
    localStorage.setItem('prevRoute', location.pathname)
    return <Redirect to='/login' />
  }

  // If the user tries to access / before they've logged in, we just want to redirect
  // to the /login route. Once the user either logs in or we're reloading the app and
  // have a user, we will set initialAppDataStatus to LOADING as all the initial app
  // data is being fetched. At that point we want to render a loading spinner. Once
  // the data has been loaded we set initialAppDataStatus to LOADED and we want to
  // render the actual app (which depends on whether the user has the
  // 'WEB:PROVIDER_INTERFACE:MANAGE' permission or not).
  let content
  if (initialAppDataStatus === UNSET) {
    content = createLoginRedirect()
  } else if (initialAppDataStatus === LOADING) {
    content = (
      <RootRoute.Styled className='loading-wrap'>
        {/* inner div needed for IE11 */}
        <div>
          <CircularProgress size={100} />
        </div>
      </RootRoute.Styled>
    )
  } else if (initialAppDataStatus === LOADED) {
    if (hasUser) {
      content = <MainApp location={location} />
    } else {
      content = createLoginRedirect()
    }
  }

  return content
}

RootRoute.Styled = styled.div`
  &.loading-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
`

export default RootRoute
