// Styles
import styled from 'styled-components'
import CCLogoLight from 'assets/ccp-whitebg.svg'

// Core
import React from 'react'
import {NavLink} from 'react-router-dom'

// Components, services, etc.
import {theme} from '@clearcapital/ui-resources'

const StaticHeader = () => {
  return (
    <StaticHeader.Styled data-sqa-id='nav' className='nav-container top-nav-component'>
      <nav className='navBar nav-component'>
        <div className='navBar-branding' data-sqa-id='nav-branding'>
          <NavLink to='/' className='logo-wrap'>
            <img src={CCLogoLight} alt='CC_logo' data-sqa-id='nav-logo' />
          </NavLink>
        </div>
      </nav>
    </StaticHeader.Styled>
  )
}
StaticHeader.Styled = styled.div`
  z-index: 2;

  .navBar {
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    position: fixed;
    flex-direction: row;
    background-color: ${(props) => theme.palette.dsm.greyscale.white};
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.08);
    height: 64px;
    z-index: 2;

    .navBar-branding {
      display: flex;
      align-items: center;

      .logo-wrap {
        display: flex;

        img {
          width: 134px;
          height: 20px;
          margin-left: 32px;
        }
      }
    }
  }
`

export default StaticHeader
