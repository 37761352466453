export const DEFAULT_SESSION_TIMER_THRESHOLD = 1000 * 60 * 30 // 30 mins in milliseconds
export const SESSION_BUFFER = 1000 * 60 * 3 // 3 mins in milliseconds
export const DEFAULT_BUFFERED_SESSION_LENGTH = DEFAULT_SESSION_TIMER_THRESHOLD - SESSION_BUFFER // the buffered session in milliseconds
export const USER_IDLE_THRESHOLD = 1000 * 60 * 10 // 10 mins in milliseconds
export const SESSION_TIMER_COUNTDOWN_LENGTH = 119 // 1 min 59 seconds
export const SESSION_TIMER_COUNTDOWN_LENGTH_IN_MS = SESSION_TIMER_COUNTDOWN_LENGTH * 1000

// when any of these events fire, the user will be considered 'active' and the idle timer will be reset
export const IDLE_EVENTS = ['click', 'scroll', 'keypress']

export const EXPIRES_IN = 'Your session will expire in'
export const KEEP_WORKING = 'Keep Working'
export const LOG_OUT = 'Log out'
export const SESSION_MESSAGE_BODY = 'Would you like to log out now, or keep working?'
export const SESSION_TIMEOUT_MODAL_TITLE = 'Session Timeout'
