import {SET_ALL_PRODUCTS} from 'constants/actionTypes'

export const initialState = {
  data: [],
  loaded: false,
}

export default function allProductsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ALL_PRODUCTS:
      return {
        data: action.payload,
        loaded: true,
      }
    default:
      return state
  }
}
