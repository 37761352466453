import {productsApiV2} from 'services/apis'
import {
  CUSTOMER_ORGANIZATION_FETCH_COMPLETE,
  SET_CUSTOMER_ORGANIZATION,
} from 'constants/actionTypes'

// This action might not be necessary.  The data was mostly used in Order Management, which is being removed.
// there are a couple stragglers using the customerOrganization's panelId an organizationKey, but they might be possible to clean up
export function getCustomerOrganization(organizationKey) {
  return function getCustomerOrganization(dispatch) {
    return productsApiV2
      .get(`customers/organization/${organizationKey}`)
      .then((org) => {
        dispatch({
          type: SET_CUSTOMER_ORGANIZATION,
          payload: org,
        })
      })
      .catch(() => {
        dispatch({
          type: CUSTOMER_ORGANIZATION_FETCH_COMPLETE,
        })
      })
  }
}
