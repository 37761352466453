// This function creates an href that includes the basename. This is needed because
// when the app runs on xe-dev envs we need to include the basename in order for links
// to properly work and avoid stripping out the repo and branch name from the URL when trying
// to navigate to a URL that starts with a '/'.
// IMPORTANT!! - This should only be used for hrefs/URLs used in anchor tags or navigated
// to programatically. It should NOT be used for the 'to' prop of Link components.
export const getHrefWithBasename = (href) => {
  const basename = process.env.REACT_APP_BASENAME || ''
  return `${basename}${href}`
}
