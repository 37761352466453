// Styles
import styled from 'styled-components'
import {theme} from '@clearcapital/ui-resources'

// Core
import React, {Fragment} from 'react'
import PropTypes from 'prop-types'

// Components, actions, services
import {isIE} from 'services/browserIdentity'
import Animation from './particle-animation'
import Image from './assets/MapMarker-01@2x.png'

const Particles = ({numParticles, className}) => {
  return (
    <Animation
      numParticles={isIE ? numParticles / 2 : numParticles}
      className={`${className} absolute`}
      color={{
        r: 247,
        g: 190,
        b: 0,
        a: 255,
      }}
      background={{
        r: 250,
        g: 250,
        b: 250,
        a: 250,
      }}
      lineWidth={1}
      particleSpeed={0.7}
    />
  )
}
Particles.propTypes = {
  numParticles: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,
}

class AnimationWrapper extends React.Component {
  state = {}

  imageLoaded = () => this.setState({loaded: true})

  render() {
    const {loaded} = this.state
    return (
      <AnimationWrapper.Styled className='animation-wrapper'>
        <div className='container'>
          <img src={Image} className='img' onLoad={this.imageLoaded} data-sqa-id='AnimatedLogo' />
          {loaded && !isIE ? (
            <Fragment>
              <Particles numParticles={60} className='particle-1' />
              <Particles numParticles={90} className='particle-2' />
              <Particles numParticles={40} className='particle-3' />
              <Particles numParticles={80} className='particle-4' />
              <Particles numParticles={30} className='particle-5' />
              <Particles numParticles={30} className='particle-6' />
              <Particles numParticles={30} className='particle-7' />
            </Fragment>
          ) : null}
        </div>
      </AnimationWrapper.Styled>
    )
  }

  static Styled = styled.div`
    align-items: center;
    background-color: ${theme.palette.colors.grey['50']};
    display: flex;
    justify-content: center;
    width: 70vw;

    .container {
      display: flex;
      max-height: 720px;
      max-width: 518px;
      position: relative;
    }

    .img {
      pointer-events: none;
      width: 100%;
      height: 100%;
      z-index: 10;
    }

    .absolute {
      position: absolute;
    }

    .particle-1 {
      border-radius: 50px;
      height: 247px;
      margin-left: 68px;
      transform: rotate(41deg);
      width: 102px;
    }

    .particle-2 {
      border-radius: 41px;
      height: 337px;
      margin-left: 80px;
      margin-top: 211px;
      transform: rotate(-27deg);
      width: 109px;
    }

    .particle-3 {
      border-radius: 52px;
      height: 183px;
      margin-top: -16px;
      right: 151px;
      transform: rotate(-65deg);
      width: 101px;
    }

    .particle-4 {
      border-radius: 38px;
      height: 364px;
      margin-top: 172px;
      right: 111px;
      transform: rotate(-155deg);
      width: 93px;
    }

    .particle-5 {
      border-radius: 19px;
      height: 126px;
      margin-top: 329px;
      right: 221px;
      width: 84px;
    }

    .particle-6 {
      border-radius: 56px;
      height: 127px;
      margin-top: 511px;
      right: 225px;
      transform: rotate(29deg);
      width: 84px;
    }

    .particle-7 {
      border-radius: 15px;
      height: 85px;
      margin-top: 118px;
      right: 103px;
      transform: rotate(58deg);
      width: 56px;
    }
  `
}
export default AnimationWrapper
