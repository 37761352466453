import {
  CLEAR_STREET_VIEW,
  ERROR_SUBJECT_STREET_VIEW,
  SET_SUBJECT_STREET_VIEW,
  SET_SUBJECT_STREET_VIEW_AVAILABILITY,
} from 'constants/actionTypes'

const initialState = {
  subject: null,
  loaded: false,
  streetViewMetaLoaded: false,
  streetViewAvailable: false,
}

export default function streetViews(state = initialState, action) {
  switch (action.type) {
    case SET_SUBJECT_STREET_VIEW_AVAILABILITY:
      return {
        ...state,
        streetViewMetaLoaded: true,
        streetViewAvailable: action.payload,
      }
    case SET_SUBJECT_STREET_VIEW:
      return {
        ...state,
        loaded: true,
        subject: action.payload,
      }

    case ERROR_SUBJECT_STREET_VIEW:
      return {
        ...state,
        loaded: true,
      }
    case CLEAR_STREET_VIEW:
      return initialState
    default:
      return state
  }
}
