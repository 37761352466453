import {SET_BUSINESS_UNITS, SET_BUSINESS_UNITS_LOADING} from 'constants/actionTypes'
import {LOADED, LOADING, UNSET} from 'constants/reduxStatuses'

export const initialState = {
  status: UNSET,
  data: [],
}

export default function businessUnitsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_BUSINESS_UNITS:
      return {
        ...state,
        data: action.payload,
        status: LOADED,
      }
    case SET_BUSINESS_UNITS_LOADING:
      return {
        ...state,
        status: LOADING,
      }
    default:
      return state
  }
}
