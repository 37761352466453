import {PROD_ORIGIN, XE_ORIGIN} from 'constants/app'
import {PROD_TOKEN, DEV_TOKEN, NPE_TOKEN} from 'constants/mixpanelConstants'

const origin = window.location.origin

export const getToken = () => {
  let token = DEV_TOKEN

  if (process.env.NODE_ENV === 'production' && origin === PROD_ORIGIN) {
    token = PROD_TOKEN
  } else if (!origin.includes('localhost') && origin !== XE_ORIGIN) {
    token = NPE_TOKEN
  }
  return token
}
