import {
  INITIALIZE_RULE_LIBRARY_DATA,
  SET_RULE_LIBRARY_RULES,
  SET_RULE_LIBRARY_STATUS,
  SET_ACTIVE_RULE,
} from 'constants/actionTypes'
import {STATUS_INIT, STATUS_LOADED} from 'constants/loadingStatusConstants'

export const initialState = {
  status: STATUS_INIT,
  rules: [],
  activeRuleId: '',
}

export default function ruleLibrary(state = initialState, action) {
  switch (action.type) {
    case INITIALIZE_RULE_LIBRARY_DATA: {
      return {
        status: STATUS_LOADED,
        rules: action.payload,
      }
    }
    case SET_RULE_LIBRARY_RULES: {
      return {
        ...state,
        rules: action.payload,
      }
    }
    case SET_RULE_LIBRARY_STATUS: {
      return {
        ...state,
        status: action.payload,
      }
    }
    case SET_ACTIVE_RULE: {
      return {
        ...state,
        activeRuleId: action.payload,
      }
    }
    default:
      return state
  }
}
