// Styles
import styled from 'styled-components'

// Core
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {Link} from 'react-router-dom'

// Components, actions, services
import {theme, TextField, Checkbox, FormControlLabel} from '@clearcapital/ui-resources'
import {login} from 'actions/authActions'
import {errorBoundary} from 'components/Error'
import {getQueryStringParams} from 'services/queryString'
import InitiallyEnabledButton from 'components/InitiallyEnabledButton'
import AuthForm from 'components/AuthForm/MainAuthForm'
import ParticleAnimation from '../LoginAnimation/AnimationWrapper'
import TosPrivacyLinks from 'components/TosPrivacyLinks'

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      login,
    },
    dispatch
  )

@errorBoundary()
@connect(null, mapDispatchToProps)
export default class Login extends Component {
  static propTypes = {
    login: PropTypes.func.isRequired,
  }

  state = {
    username: localStorage.getItem('CC_rememberEmail') || '',
    remember: localStorage.getItem('CC_rememberEmail'),
    password: '',
    submitted: false,
  }

  componentDidMount() {
    const params = getQueryStringParams()
    const {username, password} = params
    /**
     * This is to satisfy a special, one-off request for a customer
     *
     * TODO - Check w/Biz to determine if this is still needed, or can scrap
     *
     */
    if (username && password) {
      this.props.login(username, password)
    }
  }

  submit = (se) => {
    se.preventDefault()
    const {username, password, remember} = this.state
    this.props.login(username.trim(), password)
    this.setState({submitted: true})
    if (remember) {
      localStorage.setItem('CC_rememberEmail', username)
    } else {
      localStorage.removeItem('CC_rememberEmail')
    }
  }

  handleChange = (key) => (se) => {
    this.setState({
      [key]: se.target.value,
    })
  }

  rememberChange = () => {
    this.setState({remember: !this.state.remember})
  }

  render() {
    const enableSubmit = this.state.username.length && this.state.password.length
    const {remember} = this.state

    return (
      <Login.Styled className='login-wrapper'>
        <AuthForm heading='Welcome back' introText='Login to continue your work'>
          <form onSubmit={this.submit} id='login-form' data-sqa-id='LoginForm'>
            <div className='auth-row' data-sqa-id='LoginUsername'>
              <TextField
                autoFocus
                fullWidth
                type='text'
                name='username'
                label='Email'
                value={this.state.username}
                onChange={this.handleChange('username')}
                sqaPrefix=''
              />
            </div>

            <div data-sqa-id='LoginPassword' className='auth-row'>
              <TextField
                fullWidth
                type='password'
                name='password'
                label='Password'
                value={this.state.password}
                onChange={this.handleChange('password')}
                sqaPrefix=''
              />
            </div>
            <div className='forgot-remember'>
              <FormControlLabel
                label='Remember Me'
                data-sqa-id='RememberMe'
                control={
                  <Checkbox color='primary' checked={!!remember} onChange={this.rememberChange} />
                }
              />
              <Link
                to={{
                  pathname: '/password/forgot',
                  state: {username: this.state.username},
                }}
                data-sqa-id='LoginForgot'
              >
                Forgot Password?
              </Link>
            </div>

            <div className='button'>
              <InitiallyEnabledButton
                data-sqa-id='LoginButton'
                color='primary'
                disabled={!enableSubmit}
                className='auth-button'
                form='login-form'
                type='submit'
              >
                Login
              </InitiallyEnabledButton>
            </div>
            <TosPrivacyLinks />
          </form>
        </AuthForm>
        <ParticleAnimation />
      </Login.Styled>
    )
  }

  static Styled = styled.div`
    display: flex;
    height: 100%;
    .forgot-remember {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 0.875rem;
      font-weight: ${theme.typography.fontWeightMedium};
      line-height: 16px;
      font-family: Roboto;
      a {
        text-transform: uppercase;
      }
      margin-bottom: 28px;
    }
  `
}
