import {
  SET_REVISION_REQUESTS,
  SET_REVISION_REQUEST_CATEGORIES,
  RESET_REVISION_REQUESTS,
  SET_ACTIVE_REQUEST,
  RESET_ACTIVE_REQUEST,
  UPDATE_ACTIVE_REQUEST,
  SET_REVISION_PANEL_OPEN,
  ADD_REVISION_REQUEST_ITEM,
  DELETE_REVISION_REQUEST_ITEM,
  SET_ACTIVE_REVISION_REQUEST_ITEMS,
  SET_IS_CANCEL_REVISION_REQUEST_OPEN,
  REMOVE_REVISION_REQUEST_BY_ID,
  UPDATE_REVISION_REQUEST_BY_ID,
} from 'constants/actionTypes'

export const initialState = {
  activeRequest: null,
  categories: [],
  categoriesLoaded: false,
  isRevisionPanelOpen: false,
  isCancelRevisionRequestOpen: false,
  requests: [],
}

export default function revisionRequestsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_REVISION_REQUESTS: {
      return {
        ...state,
        requests: action.payload,
      }
    }
    case SET_REVISION_REQUEST_CATEGORIES: {
      return {
        ...state,
        categories: action.payload,
        categoriesLoaded: true,
      }
    }
    case SET_ACTIVE_REQUEST: {
      return {
        ...state,
        activeRequest: action.payload,
      }
    }
    case SET_REVISION_PANEL_OPEN: {
      return {
        ...state,
        isRevisionPanelOpen: action.payload,
      }
    }
    case RESET_REVISION_REQUESTS: {
      return {
        ...state,
        requests: initialState.requests,
      }
    }
    case RESET_ACTIVE_REQUEST: {
      return {
        ...state,
        activeRequest: initialState.activeRequest,
      }
    }
    case UPDATE_ACTIVE_REQUEST: {
      return {
        ...state,
        activeRequest: {
          ...state.activeRequest,
          ...action.payload,
        },
      }
    }
    case ADD_REVISION_REQUEST_ITEM: {
      return {
        ...state,
        activeRequest: {
          ...state.activeRequest,
          revisionRequestItems: [...state.activeRequest.revisionRequestItems, action.payload],
        },
      }
    }
    case DELETE_REVISION_REQUEST_ITEM: {
      return {
        ...state,
        activeRequest: {
          ...state.activeRequest,
          revisionRequestItems: state.activeRequest.revisionRequestItems.filter(
            (item) => item.id !== action.payload
          ),
        },
      }
    }
    case SET_ACTIVE_REVISION_REQUEST_ITEMS: {
      return {
        ...state,
        activeRequest: {
          ...state.activeRequest,
          revisionRequestItems: action.payload,
        },
      }
    }
    case SET_IS_CANCEL_REVISION_REQUEST_OPEN: {
      return {
        ...state,
        isCancelRevisionRequestOpen: action.payload,
      }
    }
    case REMOVE_REVISION_REQUEST_BY_ID: {
      return {
        ...state,
        requests: state.requests.filter(({requestId}) => requestId !== action.payload),
      }
    }
    case UPDATE_REVISION_REQUEST_BY_ID: {
      const requests = [...state.requests]

      const indexOfUpdatedRequest = requests.findIndex(
        ({requestId}) => requestId === action.payload?.requestId
      )

      // If the request exist update it in it's place otherwise push it to the front
      indexOfUpdatedRequest !== -1
        ? (requests[indexOfUpdatedRequest] = action.payload)
        : requests.unshift(action.payload)

      return {
        ...state,
        requests,
      }
    }
    default:
      return state
  }
}
