import {createSelector} from 'reselect'

const customerOrganizationStateSelector = (state) => state.customerOrganization

const customerOrganizationSelector = createSelector(
  [customerOrganizationStateSelector],
  (customerState = {}) => customerState.org
)

const panelSelector = createSelector(
  [customerOrganizationSelector],
  (customerOrg = {}) => customerOrg.panel
)

const panelIdSelector = createSelector([panelSelector], (panel = {}) => panel.id)

export {customerOrganizationSelector, panelIdSelector, panelSelector}
