import {CLEAR_MLS_PHOTOS, ERROR_MLS_PHOTOS, SET_MLS_PHOTOS} from 'constants/actionTypes'

export const initialState = {
  loaded: false,
  data: {},
}

export default function mlsPhotos(state = initialState, action) {
  switch (action.type) {
    case SET_MLS_PHOTOS:
      return {
        ...state,
        loaded: true,
        data: action.payload,
      }
    case CLEAR_MLS_PHOTOS:
      return initialState
    case ERROR_MLS_PHOTOS:
      return {...initialState, loaded: true}
    default:
      return state
  }
}
