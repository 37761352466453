import moment from 'moment'
import {NO_DATA} from 'constants/app'
import {PST_OFFSET} from 'constants/datetimeConstants'

/**
 * Converts a tuid into a date
 */
export function tuidToFormattedDate(tuid, format) {
  let formattedDate = NO_DATA

  const ms = tuidToMilliseconds(tuid)

  formattedDate = msToFormattedDate(ms, format)

  return formattedDate
}

export function tuidToMilliseconds(tuid) {
  let ms = 0

  if (tuid && !isNaN(tuid)) {
    const inputString = tuid.toString()

    // if it is not 17-19 numbers, it is not what we are expecting
    if (inputString.length >= 17 && inputString.length <= 19) {
      ms = Math.floor(Number(tuid) / 262144) // 2**18
    }
  }

  return ms
}

export function convert8601DurationToFormattedDuration(dateString, durationUnits, precision) {
  const duration = moment.duration(dateString).as(durationUnits)
  return precision ? duration.toFixed(precision) : duration
}

export function convert8601ToFormattedDate(dateString, format = 'small') {
  return msToFormattedDate(Date.parse(dateString), format)
}

/*
  ms = since UTC.
  It will be converted to *LOCAL TIME*

  Keep this in mind if you let the user select a date,
  this will stay local time and NOT convert to UTC
*/
export function msToFormattedDate(ms, format) {
  let formattedDate = NO_DATA

  if (ms && !isNaN(ms)) {
    const inputString = ms.toString()

    // if it is not 12-14 numbers, it is not what we are expecting
    if (inputString.length >= 12 && inputString.length <= 14) {
      switch (format) {
        case 'medium':
          // e.g. 'Sep 4, 1986 8:30 PM'
          formattedDate = moment(ms).format('lll')
          break
        case 'small':
          // e.g. 'Sep 4, 1986'
          formattedDate = moment(ms).format('ll')
          break
        case 'YYYYMMDD':
          formattedDate = moment(ms).format('YYYYMMDD')
          break
        case 'date-time':
          formattedDate = moment(ms).format('MMM D, YYYY h:mm A')
          break
        case 'M-DD-YYYY':
          formattedDate = moment(ms).format('M-DD-YYYY')
          break
        case 'jsDate':
          // returns a js Date object
          formattedDate = moment(ms).toDate()
          break
        case 'small-diff':
          const isSmallAndDiffCombined = true
          formattedDate = `${moment(ms).format('ll')} (${getDifferenceOrDate(
            ms,
            isSmallAndDiffCombined
          )})`
          break
        default:
          formattedDate = getDifferenceOrDate(ms)
          break
      }
    }
  }

  return formattedDate
}

/* 
    getDifferenceOrDate() logic was pulled out of the default case of msToFormattedDate() to be reused in
    the case where we need to display both the date and relative time to or from that date in UI 
*/

export const getDifferenceOrDate = (ms, isSmallAndDiffCombined = false) => {
  const now = moment()
  const then = moment(ms)
  const diff = now.diff(then, 'days')

  // use relative time if within a week
  if (diff > 7 && !isSmallAndDiffCombined) {
    return then.format('lll')
  } else {
    // customize moment relative time output
    moment.updateLocale('en', {
      relativeTime: {
        future: 'in %s',
        past: '%s ago',
        s: 'a few seconds',
        ss: '%d seconds',
        m: '1 minute',
        mm: '%d minutes',
        h: 'an hour',
        hh: '%d hours',
        d: '1 day',
        dd: '%d days',
        M: '1 month',
        MM: '%d months',
        y: '1 year',
        yy: '%d years',
      },
    })

    // e.g. '43 minutes ago'
    // e.g. '1 day ago'
    return then.fromNow()
  }
}

/**
 * Converts a milliseconds timestamp into a date
 */
export function convertFromMilliseconds(input, format) {
  let formattedDate = NO_DATA

  if (input && !isNaN(input)) {
    const inputString = input.toString()

    // if it is not 12-14 numbers, it is not what we are expecting
    if (inputString.length >= 12 && inputString.length <= 14) {
      const inputMillis = parseInt(input, 10)
      formattedDate = moment(inputMillis).format(format)
    }
  }

  return formattedDate
}

/**
 * Coverts a date in the format YYYYMMDD into a locale-specific date. Dates in this format
 * are provided in the properties' local time, so no time conversion is necessary.
 */
export function convertFromNumeric(input, format = 'll') {
  let formattedDate = NO_DATA

  format = format || 'll'

  if (input) {
    var inputString = input.toString()
    inputString = inputString.replace(/-/g, '')
    inputString = inputString.replace(/\//g, '')

    // if it is not 8 numbers, it is not what we are expecting
    if (!isNaN(inputString) && inputString.length === 8) {
      const year = inputString.substr(0, 4)
      const month = inputString.substr(4, 2)
      const day = inputString.substr(6, 2)

      formattedDate = moment(year + month + day).format(format)
    }
  }

  return formattedDate
}

/**
 * Converts a date in the format YYYYMMDD into a ISO date string
 */
export function shortNumericFullDateToISODate(input) {
  let isoDate = ''

  if (input) {
    const inputString = input.toString()

    // if it is not 8 numbers, it is not what we are expecting
    if (!isNaN(inputString) && inputString.length === 8) {
      isoDate = moment(inputString).toISOString()
    }
  }

  return isoDate
}

export const DEFAULT_DATE_FORMAT = 'MMDDYY'

/**
 * This accepts a date string in any format, and the format that the date string is in and returns a JS Date object
 *
 * @param {string} date - a formatted date string (like '072318')
 * @param {string} format - represents the format of the date string that's passed in (like 'MMDDYY' for the example above)
 */
export function convertFromFormattedToJSDate(date, format) {
  const ms = moment(date, format).valueOf()
  return msToFormattedDate(ms, 'jsDate')
}

/**
 * Accepts a js date string and returns a date string formatted to the passed in format string
 *
 * @param {string} date - a js date string (for example Sun Jul 22 2018 00:00:00 GMT-0700 (PDT))
 * @param {*} format - represents the desired format of the returned date string (for example 'MMDDYY')
 */
export function convertFromJSDateToFormattedDate(date, format) {
  return moment(date).format(format)
}

/**
 * Accepts a js date string and returns an ISO8601 Standard string set to the EOD (23:59:59) in
 * PST (Pacific Standard Time) timezone.
 * this is used for due dates which must be in this format
 *
 * @param {string} date - a js date string (for example Sun Jul 22 2018 00:00:00 GMT-0700 (PDT))
 */
export function convertDateToEodPstIsoString(date) {
  return moment(date).endOf('day').utcOffset(PST_OFFSET).toISOString(true)
}

/**
 * Accepts a js date string and returns an ISO8601 Standard string
 * this is used for due dates which must be in this format
 *
 * @param {string} date - a js date string (for example Sun Jul 22 2018 00:00:00 GMT-0700 (PDT))
 */
export function convertDateToIsoString(date) {
  return moment(date).toISOString(false)
}

export function convertDateTimeToIsoString(date) {
  return moment(date, 'MM-DD-YYYYHH:mm:ss').toISOString(false)
}

export function convertFromFormattedDateToFormattedDate(date, fromFormat, toFormat) {
  return moment(date, fromFormat).format(toFormat)
}

export function getTimezoneAbbreviation() {
  let timezoneAbbr = ''
  const regex = /\((.*?)\)/g
  const dateString = new Date() // Thu Jun 20 2019 15:43:11 GMT-0700 (Pacific Daylight Time)
  const timezoneArr = regex.exec(dateString) // ["(Pacific Daylight Time)", "Pacific Daylight Time"]
  if (Array.isArray(timezoneArr) && timezoneArr.length === 2) {
    // Safari automatically abbreviates the timezone from new Date()
    // Max timezone abbreviation length is 5 letters - https://www.timeanddate.com/time/zones/
    if (timezoneArr[1].length <= 5) {
      timezoneAbbr = timezoneArr[1]
    } else {
      const timezoneParts = timezoneArr[1].split(' ') // ["Pacific", "Daylight", "Time"]
      timezoneParts.forEach((part) => {
        timezoneAbbr += part.slice(0, 1) // Take first letter only
      })
    }
  }
  return timezoneAbbr // "PDT"
}

// returns days in ddd format starting from current day
export function getConsecutiveDays() {
  const days = []
  for (let i = 0; i < 6; i++) {
    days.push(
      moment()
        .add(i + 1, 'days')
        .format('ddd')
    )
  }
  return days
}

export function Timer(actionToRun, desiredInterval) {
  let timer = setInterval(actionToRun, desiredInterval)

  this.stop = () => {
    if (timer) {
      clearInterval(timer)
      timer = null
    }
    return this
  }

  // start timer using current settings (if it's not already running)
  this.start = () => {
    if (!timer) {
      this.stop()
      timer = setInterval(actionToRun, desiredInterval)
    }
    return this
  }

  // start with new or original interval, stop current interval
  this.reset = (newInterval = desiredInterval) => {
    desiredInterval = newInterval
    return this.stop().start()
  }
}

export const convertDateToTuid = (date, format) => {
  const milliseconds = moment(date, format).valueOf()
  const tuid = (milliseconds * 262144).toString()
  return tuid
}
