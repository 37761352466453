import {SET_FORM_TEMPLATE} from 'constants/actionTypes'

const initialState = {}

export default function documentNoteTemplate(state = initialState, action) {
  switch (action.type) {
    case SET_FORM_TEMPLATE:
      action.payload.template.fields.forEach((field) => {
        // A radio group must have a default value
        if (field.type === 'radioGroup' && !field.value) {
          field.value = field.options[0].value
        }
      })

      return action.payload
    default:
      return state
  }
}
