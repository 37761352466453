import React from 'react'
import {Button} from '@clearcapital/ui-resources'

export default class InititiallyEnabledButton extends React.Component {
  state = {clicked: false}

  handleClick = (e) => {
    const {clicked} = this.state
    const {disabled, onClick} = this.props

    if (!clicked) {
      this.setState({clicked: true})
    }
    if (onClick && !disabled) {
      onClick(e)
    }
  }

  render() {
    const {clicked} = this.state
    const {disabled, sqaPrefix, ...props} = this.props

    return (
      <Button
        {...props}
        disabled={disabled && clicked}
        onClick={this.handleClick}
        sqaPrefix={sqaPrefix}
      />
    )
  }
}
