import {
  CLEAR_PRODUCT_PANEL_PROVIDERS_AMCS_WITH_ORG_KEY,
  SET_PRODUCT_PANEL_PROVIDERS_AMCS_WITH_ORG_KEY,
} from 'constants/actionTypes'

const initialState = null

export default function productProviders(state = initialState, action) {
  switch (action.type) {
    case SET_PRODUCT_PANEL_PROVIDERS_AMCS_WITH_ORG_KEY:
      return action.payload.sort((a, b) => {
        return a.name.substring(0, 13) === 'Clear Capital'
          ? -1
          : b.name.substring(0, 13) === 'Clear Capital'
          ? 1
          : a.name.localeCompare(b.name)
      })
    case CLEAR_PRODUCT_PANEL_PROVIDERS_AMCS_WITH_ORG_KEY:
      return initialState
    default:
      return state
  }
}
