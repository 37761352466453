import {createSelector} from 'reselect'
import get from 'lodash/get'
import {IS_COMPLETED} from 'constants/statusesConstants'
import {loadingSelector} from 'selectors/appSelectors'
import {CLEAR_PROP_LOADING} from 'constants/actionTypes'

export const clearPropSelector = (state) => state.clearProp

export const isCaseClearPropOrderedSelector = createSelector(
  [clearPropSelector],
  (clearProp) =>
    !!get(clearProp, 'reportSummary.value', false) ||
    get(clearProp, 'reportSummary.status', '').toUpperCase() === IS_COMPLETED
)

export const isClearPropBeingOrderedSelector = createSelector([loadingSelector], (loading) =>
  loading.includes(CLEAR_PROP_LOADING)
)
