import {CLEAR_ALL_RELATED_ORDERS, SET_ALL_RELATED_ORDERS} from 'constants/actionTypes'
const initialState = null

export default function relatedOrders(state = initialState, action) {
  switch (action.type) {
    case CLEAR_ALL_RELATED_ORDERS:
      return initialState
    case SET_ALL_RELATED_ORDERS:
      return action.payload ? action.payload : state
    default:
      return state
  }
}
