export default class Address {
  constructor(props) {
    Object.keys(props).forEach((key) => {
      this[key] = props[key]
    })
  }
}

/*
core Address {
  street (string, optional),
  unitNumber (string, optional),
  city (string, optional),
  state (string, optional),
  zipcode (string, optional),
  county (string, optional),
  country (string, optional),
  sourceId (integer, optional),
  sourceName (SourceNameEnum, optional) = ['APPRAISAL' or 'CCP' or 'CLEAR_RE' or 'COLLATERAL_ANALYTICS' or 'DIGITALMAP' or 'GOOGLE' or 'HVE' or 'MELISSADATA' or 'MLS' or 'PROPERTY_ACTIVITY_IMPORT' or 'RELAR' or 'UNKNOWN']
}
*/
