import Api from './Api'
import errorHandler from 'services/apiErrorHandler'

const apikey = 'feca8dc55a837d04f7c3c0d5cb3c7607'
const apisecret = '3eac1f3c2ef5215eb9bd13e28ae0975a'

export const authContent = `Basic ${btoa(apikey + ':' + apisecret)}`
export const apiUrl = getApiUrl()

// https://auth-qa2.clearcollateral.com/api-ui/index.html
export const authApi = new Api({
  errorHandler,
  baseUrl: process.env.REACT_APP_ENV === 'local' ? 'http://localhost:8086/v1' : `${apiUrl}/auth/v1`,
  headers: {
    Authorization: authContent,
  },
})

// No swagger for this. tearsheet: https://docs.google.com/document/d/1DUXQdLjR0GXZHEEpZ7hQ7QdQYuzGX6MnF7fk3z750Js
export const authApiV2 = new Api({
  errorHandler,
  baseUrl: `${apiUrl}/ccp-login/v2`,
})

// https://qa2-ccp-bulkorder.clearcollateral.com/swagger
export const bulkOrderApi = new Api({
  errorHandler,
  baseUrl: `${apiUrl}/ccp-bulkorder/v1`,
  headers: {
    Authorization: authContent,
  },
})

// http://blue.clearcollateral.com:9961/swagger
export const caseApi = new Api({
  errorHandler,
  baseUrl: `${apiUrl}/ccp-case/v1`,
  headers: {
    Authorization: authContent,
  },
})

export const clearPropApi = new Api({
  errorHandler,
  baseUrl: `${apiUrl}/ccp-clearprop-service/v1`,
  headers: {
    Authorization: authContent,
  },
})

// https://qa2-ccp-form-template.clearcollateral.com/swagger-ui/index.html
export const formTemplateApi = new Api({
  errorHandler,
  baseUrl: `${apiUrl}/ccp-form-template/v1`,
  headers: {
    Authorization: authContent,
  },
})

// https://qa2-ccp-order.clearcollateral.com/swagger
export const orderApi = new Api({
  errorHandler,
  baseUrl: `${apiUrl}/ccp-order/v1`,
  headers: {
    Authorization: authContent,
  },
})

export const orderUploadApi = new Api({
  errorHandler,
  baseUrl: `${apiUrl}/ccp-order/v1`,
  headers: {
    Authorization: authContent,
    'Content-Type': null,
  },
})

// https://qa2-ccp-photo-service.clearcollateral.com/swagger
export const photosApi = new Api({
  errorHandler,
  baseUrl: `${apiUrl}/ccp-photo/v1`,
  headers: {
    Authorization: authContent,
  },
})

// https://qa2-ccp-product.clearcollateral.com/swagger
export const productsApiV2 = new Api({
  errorHandler,
  baseUrl: `${apiUrl}/ccp-product/v2`,
  headers: {
    Authorization: authContent,
  },
})

// https://qa2-ccp-product.clearcollateral.com/swagger
export const propertyApi = new Api({
  errorHandler,
  baseUrl: `${apiUrl}/ccp-property/v1`,
  headers: {
    Authorization: authContent,
  },
})

// https://qa2-ccp-product.clearcollateral.com/swagger
export const productsUploadsApi = new Api({
  errorHandler,
  baseUrl: `${apiUrl}/ccp-product/v2`,
  headers: {
    Authorization: authContent,
    // do not provide a content-type as you must let FormData handle that
    'Content-Type': null,
  },
})

// https://qa-ccp-quicksight.clearcollateral.com/swagger/
export const reportApi = new Api({
  errorHandler,
  baseUrl: `${apiUrl}/ccp-quicksight/v1`,
  headers: {
    Authorization: authContent,
  },
})

export const rbaApi = new Api({
  errorHandler,
  baseUrl: `${apiUrl}/ccp-rba/v1`,
  headers: {
    Authorization: authContent,
  },
})

// https://qa-ccp-tier.clearcollateral.com/swagger
export const tierApi = new Api({
  errorHandler,
  baseUrl: `${apiUrl}/ccp-tier/v1`,
  headers: {
    Authorization: authContent,
  },
})

// https://qa-ccp-validation.clearcollateral.com/swagger
export const validationApi = new Api({
  errorHandler,
  baseUrl: `${apiUrl}/ccp-validation/v1`,
  headers: {
    Authorization: authContent,
  },
})

export function getClearPropHostname() {
  return `${apiUrl}/clearprop`
}

export const loeApi = new Api({
  errorHandler,
  baseUrl: `${apiUrl}/ccp-loe/v1`,
  headers: {
    Authorization: authContent,
  },
})

export const caseSearchApi = new Api({
  errorHandler,
  baseUrl: `${apiUrl}/ccp-case-search/v1`,
  headers: {
    Authorization: authContent,
  },
})

export const verosIntegrationApi = new Api({
  errorHandler,
  baseUrl: `${apiUrl}/ccp-veros-integration/v1`,
  headers: {
    Authorization: authContent,
  },
})

export const documentUploadApi = new Api({
  errorHandler,
  baseUrl: `${apiUrl}/ccp-document/v1`,
  headers: {
    Authorization: authContent,
    // do not provide a content-type as you must let FormData handle that
    'Content-Type': null,
  },
})

export const documentApi = new Api({
  errorHandler,
  baseUrl: `${apiUrl}/ccp-document/v1`,
  headers: {
    Authorization: authContent,
  },
})

// http://red.clearcollateral.com:7300/swagger-ui/index.html
export const dataServiceApi = new Api({
  errorHandler,
  baseUrl: `${apiUrl}/ccr-data`,
  headers: {
    Authorization: authContent,
  },
})

export const auraApi = new Api({
  errorHandler,
  baseUrl: ` ${apiUrl}/ccp-validation/v1/aura`,
})

function getApiUrl() {
  // only one of `REACT_APP_ENV` or `BUILT_APP_ENV` will be set at one time. builds (`npm run build`)
  // set `BUILT_APP_ENV` and the local dev server (`npm run start`) set `REACT_APP_ENV`
  let env = (process.env.REACT_APP_ENV || process.env.BUILT_APP_ENV || 'qa').toLowerCase()
  let apiUrl

  if (process.env.REACT_APP_STAGING_DEPLOY) {
    env = sessionStorage.getItem('env') || 'qa'
  }

  switch (env) {
    case 'production':
      // 'production' is the default value for BUILT_APP_ENV when running a build.  setting the `apiUrl`
      // to an empty string allows the browser to use domain the webapp is deployed to when making api
      // call.  This allows a single built to work in QA, QA2, UAT, CTE and PROD.
      apiUrl = ''
      break
    default:
      apiUrl = `https://${env}.clearcollateral.com`
      break
  }

  return apiUrl
}
