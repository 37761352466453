import {authApi, authApiV2} from 'services/apis'
import {UPDATE_USER, CREATE_USER} from 'constants/actionTypes'
import {addNotification as notify} from 'reapop'
import {SUCCESS_NOTIFICATION, ERROR_NOTIFICATION} from 'constants/notifications'
import {updateAuthUser} from './appActions'
import {
  USER_UPDATED,
  USER_NAME_CHANGE_FAILED,
  USER_UPDATE_FAILED,
  USER_CREATE_FAILED,
  USER_PASSWORD_CHANGED,
  USER_PASSWORD_CHANGE_FAILED,
  USER_PASSWORD_RESET,
  USER_PASSWORD_RESET_EXISTS,
  USER_PASSWORD_RESET_FAILED,
  USER_PREVIOUS_PASSWORD,
  USER_PASSCODE_EXPIRED,
  USER_PARAMS_MISSING,
  USER_NAME_CHANGED,
  USER_CREATED,
  USER_RESEND_ACTIVATION_SUCCESS,
  USER_RESEND_ACTIVATION_FAILED,
} from 'constants/appMessages'
import get from 'lodash/get'
import {getHrefWithBasename} from 'services/hrefService'
import {trackMixpanelEvent, createAddUserPayload} from 'services/mixpanel'
import {ADD_USER} from 'constants/mixpanelConstants'
import {tierDropdownOptionsSelector} from 'selectors'

export function createUser(userObj) {
  return async (dispatch, getState) => {
    try {
      const user = await authApi.post(`registrations`, {
        link: window.location.origin + getHrefWithBasename('/account/activation'),
        user: userObj,
      })
      dispatch({
        type: CREATE_USER,
        payload: user,
      })
      dispatch(
        notify({
          // change messages later - after submission works
          ...SUCCESS_NOTIFICATION,
          message: `${user.person.displayName} ${USER_CREATED}`,
        })
      )
      trackMixpanelEvent(
        ADD_USER,
        createAddUserPayload({
          userObj,
          tierQualificationOptions: tierDropdownOptionsSelector(getState()),
        })
      )
    } catch (err) {
      dispatch(
        notify({
          ...ERROR_NOTIFICATION,
          message: USER_CREATE_FAILED,
        })
      )
    }
  }
}

export function updateUser(userObj) {
  return async function updateUser(dispatch) {
    try {
      const user = await authApi.put(`users/${userObj.id}`, userObj)
      dispatch({
        type: UPDATE_USER,
        payload: user,
      })
      if (userObj.status !== 'PENDING') {
        dispatch(
          notify({
            ...SUCCESS_NOTIFICATION,
            message: USER_UPDATED,
          })
        )
      }
    } catch (err) {
      dispatch(
        notify({
          ...ERROR_NOTIFICATION,
          message: USER_UPDATE_FAILED,
        })
      )
    }
  }
}

export function enableUser(userObj) {
  return (dispatch) => {
    return dispatch(updateUser({...userObj, status: 'PENDING'})).then(() => {
      dispatch(updateUser(userObj))
    })
  }
}

export function updateName(displayName, user) {
  return async (dispatch) => {
    try {
      const res = await authApi.put(`users/${user.id}`, {
        ...user,
        person: {...user.person, displayName},
      })
      dispatch({
        type: UPDATE_USER,
        payload: res,
      })
      dispatch(updateAuthUser(res))
      dispatch(
        notify({
          ...SUCCESS_NOTIFICATION,
          message: `${USER_NAME_CHANGED} ${displayName}`,
        })
      )
    } catch (err) {
      dispatch(
        notify({
          ...ERROR_NOTIFICATION,
          message: USER_NAME_CHANGE_FAILED,
        })
      )
    }
  }
}

export function updatePassword(password, user, successCb, updateSubmittingStateCb) {
  updateSubmittingStateCb && updateSubmittingStateCb(true)

  return async (dispatch) => {
    try {
      const payload = {
        id: user.id,
        key: user.key,
        password,
        status: 'ENABLED',
      }
      const res = await authApi.put(`users/${user.id}`, payload)
      dispatch({
        type: UPDATE_USER,
        payload: res,
      })
      dispatch(
        notify({
          ...SUCCESS_NOTIFICATION,
          message: USER_PASSWORD_CHANGED,
        })
      )

      successCb && successCb()

      return true
    } catch (err) {
      if (err.response.status === 409) {
        dispatch(
          notify({
            ...ERROR_NOTIFICATION,
            message: USER_PREVIOUS_PASSWORD,
          })
        )
      } else {
        dispatch(
          notify({
            ...ERROR_NOTIFICATION,
            message: USER_PASSWORD_CHANGE_FAILED,
          })
        )
      }

      updateSubmittingStateCb && updateSubmittingStateCb(false)
    }
  }
}

export function resetPassword(username) {
  const link = window.location.origin + '/password/reset'
  return async (dispatch) => {
    try {
      await authApi.post(`users/${username}/passcodes`, {
        link,
        coreMessageTemplate: 'EMAIL_TEMPLATE_PASSWORD_RESET',
      })
      dispatch(
        notify({
          ...SUCCESS_NOTIFICATION,
          message: USER_PASSWORD_RESET,
          position: 'tc',
        })
      )
    } catch (err) {
      if (err.response.status === 429) {
        dispatch(
          notify({
            ...ERROR_NOTIFICATION,
            message: USER_PASSWORD_RESET_EXISTS,
            position: 'tc',
          })
        )
      } else {
        dispatch(
          notify({
            ...ERROR_NOTIFICATION,
            message: USER_PASSWORD_RESET_FAILED,
            position: 'tc',
          })
        )
      }
    }
  }
}

export function performTempLogin(username, passcode) {
  const postData = {
    key: username,
    passcode: passcode,
  }
  let res
  return async (dispatch) => {
    try {
      res = await authApiV2.post('password-change', postData)
    } catch (err) {
      const status = get(err, 'response.status', null)

      switch (status) {
        case 410:
          dispatch(
            notify({
              ...ERROR_NOTIFICATION,
              message: USER_PASSCODE_EXPIRED,
              position: 'tc',
            })
          )
          break
        case 403:
          dispatch(
            notify({
              ...ERROR_NOTIFICATION,
              message: USER_PASSWORD_CHANGE_FAILED,
              position: 'tc',
            })
          )
          break
        case 401:
          dispatch(
            notify({
              ...ERROR_NOTIFICATION,
              message: USER_PASSCODE_EXPIRED,
              position: 'tc',
            })
          )
          break
        case 400:
          dispatch(
            notify({
              ...ERROR_NOTIFICATION,
              message: USER_PARAMS_MISSING,
              position: 'tc',
            })
          )
          break
        default:
          break
      }
    }
    return res
  }
}

export function resendActivation(username) {
  const link = window.location.origin + getHrefWithBasename('/account/activation')
  return async (dispatch) => {
    try {
      await authApi.post(`users/${username}/passcodes`, {
        link,
        coreMessageTemplate: 'EMAIL_TEMPLATE_NEW_USER_PASSCODE',
      })
      dispatch(
        notify({
          ...SUCCESS_NOTIFICATION,
          message: USER_RESEND_ACTIVATION_SUCCESS,
          position: 'tc',
        })
      )
    } catch (err) {
      dispatch(
        notify({
          ...ERROR_NOTIFICATION,
          message: USER_RESEND_ACTIVATION_FAILED,
          position: 'tc',
        })
      )
    }
  }
}

export function showPasswordError(errorMessage) {
  return (dispatch) => {
    dispatch(
      notify({
        ...ERROR_NOTIFICATION,
        message: errorMessage,
        position: 'tc',
      })
    )
  }
}
