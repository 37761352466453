import {createSelector} from 'reselect'
import get from 'lodash/get'

import {organizationUsers} from 'services/filter'
import {personByDisplayName} from 'services/sort'

export const usersSelector = (state) => state.users

export const appUsersSelector = createSelector([usersSelector], (users) =>
  users.filter((user) => user.type === 'APP_USER')
)

export const organizationUsersSelector = createSelector([usersSelector], (users) =>
  users.filter(organizationUsers)
)

export const appUsersMapSelector = createSelector([appUsersSelector], (appUsers = []) =>
  appUsers.reduce((userMap, currentUser) => {
    return {
      ...userMap,
      [currentUser.id]: {
        userId: currentUser.id,
        initials: get(currentUser, 'person.initials'),
        firstName: get(currentUser, 'person.firstName'),
        lastName: get(currentUser, 'person.lastName'),
        displayName: get(currentUser, 'person.displayName'),
        ...currentUser,
      },
    }
  }, {})
)

export const allUsersMapSelector = createSelector([usersSelector], (users) =>
  users.reduce((acc, curr) => ({...acc, [curr.id]: curr}), {})
)

export const appUsersOptionsSelector = createSelector([appUsersSelector], (users) =>
  users
    .filter((user) => user?.person?.displayName)
    .sort(personByDisplayName)
    .map((user) => ({value: user?.id, label: user?.person?.displayName}))
)
