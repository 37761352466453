import {SET_ORG_USERS, UPDATE_USER, CREATE_USER} from 'constants/actionTypes'
import {User} from 'models'

export const initialState = []

export default function users(state = initialState, action) {
  switch (action.type) {
    case SET_ORG_USERS:
      return action.payload.map((u) => new User(u))
    case CREATE_USER:
      return [...state, new User(action.payload)]
    case UPDATE_USER:
      const foundIndex = state.findIndex((u) => u.id === action.payload.id)

      return foundIndex === -1
        ? state
        : [...state.slice(0, foundIndex), new User(action.payload), ...state.slice(foundIndex + 1)]
    default:
      return state
  }
}
