import {requiredValidationRules} from 'constants/validationRulesConstants'

export const casesOnHoldFields = [
  {
    id: 'reason',
    validation: [...requiredValidationRules],
  },
  {
    id: 'notes',
  },
]

export const MAX_NOTES_TEXT_LENGTH = 480

export const NOTES_ELLIPSIS_CUTOFF_LENGTH = 135
